import { Divider } from 'antd';
import { EnrolledProgramService } from '../../../../../services/EnrolledProgramService';
import { CarePlan } from '../../../../../uc-api-sdk';
import { PrintCarePlanBehavioralGoalComponent } from '../PrintCarePlanBehavioralGoalComponent/PrintCarePlanBehavioralGoalComponent';
import { PrintCarePlanBehaviorChangeComponent } from '../PrintCarePlanBehaviorChangeComponent/PrintCarePlanBehaviorChangeComponent';
import { PrintCarePlanClinicalGoalComponent } from '../PrintCarePlanClinicalGoalComponent/PrintCarePlanClinicalGoalComponent';
import { PrintCarePlanDiagnosisHealthConditionsComponent } from '../PrintCarePlanDiagnosisHealthConditionsComponent/PrintCarePlanDiagnosisHealthConditionsComponent';
import { PrintCarePlanDiseaseHistoryComponent } from '../PrintCarePlanDiseaseHistoryComponent/PrintCarePlanDiseaseHistoryComponent';
import { PrintCarePlanMedicationComponent } from '../PrintCarePlanMedicationComponent/PrintCarePlanMedicationComponent';
import { LifestyleAssessmentComponent } from '../PrintCarePlanLifestyleAssessmentComponent/PrintCarePlanLifestyleAssessmentComponent';
import { PrintCarePlanSocialSupportComponent } from '../PrintCarePlanSocialSupportComponent/PrintCarePlanSocialSupportComponent';
import { PrintCarePlanVitalsMonitoringGoalComponent } from '../PrintCarePlanVitalsMonitoringGoalComponent/PrintCarePlanVitalsMonitoringGoalComponent';
import { PrintOldNutritionComponent } from '../PrintOldLifestyleNutritionComponent/PrintOldNutritionComponent';
import { PrintOldLifestyleComponent } from '../PrintOldLifestyleNutritionComponent/PrintOldLifestyleComponent';

export interface PrintGeneralCarePlanComponentProps {
  enrolledProgramService?: EnrolledProgramService;
  carePlan?: CarePlan;
}

export const PrintGeneralCarePlanComponent = ({
  enrolledProgramService,
  carePlan,
}: PrintGeneralCarePlanComponentProps) => {
  const {
    profile,
    healthCondition,
    diseaseHistory,
    lifeStyleAssessment,
    lifeStyle,
    nutrition,
    clinicGoal,
    behaviorGoal,
  } = carePlan || {};
  return (
    <>
      <div className="print-care-plan-preview-clinical-goals">
        <PrintCarePlanClinicalGoalComponent
          clinicalGoals={clinicGoal?.doc?.clinicalGoals || []}
        />
      </div>
      <div className="print-care-plan-preview-vitals-monitoring-goal">
        <PrintCarePlanVitalsMonitoringGoalComponent
          monitoringVitals={enrolledProgramService?.getMonitoringVitals() || []}
        />
      </div>
      <div className="print-care-plan-preview-behavior-change">
        <PrintCarePlanBehaviorChangeComponent
          behaviorChange={profile?.behaviorChangeInfo?.behaviorChangeLevel ?? undefined}
        />
      </div>
      <div className="print-care-plan-preview-behavioral-goal">
        <PrintCarePlanBehavioralGoalComponent
          behavioralGoals={behaviorGoal?.doc?.behaviorGoals || []}
        />
      </div>
      <div className="print-care-plan-preview-medication-reconciliation-compliance">
        <PrintCarePlanMedicationComponent
          visit={carePlan?.visit}
          medication={carePlan?.medication}
          medicationList={carePlan?.medicationList}
        />
      </div>
      {
        lifeStyleAssessment?.doc
          ? (
            <div className="print-care-plan-preview-lifestyle-assessment">
              <LifestyleAssessmentComponent
                lifeStyleAssessment={lifeStyleAssessment?.doc}
              />
            </div>
          ) : (
            <>
              <div className="print-care-plan-preview-nutrition">
                <PrintOldNutritionComponent
                  nutrition={nutrition?.doc}
                />
              </div>
              <div className="print-care-plan-preview-lifestyle">
                <PrintOldLifestyleComponent
                  lifeStyle={lifeStyle?.doc}
                />
              </div>
            </>
          )
      }
      <div className="print-care-plan-preview-social-support">
        <PrintCarePlanSocialSupportComponent
          profile={profile}
        />
      </div>
      <div className="print-care-plan-preview-disease-history">
        <PrintCarePlanDiseaseHistoryComponent
          diseaseHistory={diseaseHistory?.diseaseHistory}
        />
      </div>
      <div className="print-care-plan-preview-health-condition">
        <PrintCarePlanDiagnosisHealthConditionsComponent
          // diagnosis={healthCondition?.diagnoses}
          healthConditions={healthCondition?.healthConditions}
        />
        <Divider />
      </div>
    </>
  );
};
