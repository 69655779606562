import { InfoCircleFilled } from '@ant-design/icons';
import { keys } from 'lodash';
import { ReactNode } from 'react';
import { FormInstanceType } from '../../types/form';
import { MissingErrorComponent } from '../../uiComponent/MissingErrorComponent/MissingErrorComponent';

// eslint-disable-next-line import/prefer-default-export
export const createRequiredRule = (
  msg = 'Missing!',
  icon: ReactNode = <InfoCircleFilled className="danger-svg" />,
) => ({
  required: true,
  message: (
    <MissingErrorComponent icon={icon}>
      {msg}
    </MissingErrorComponent>
  ),
});

export function mergeFormValues<T>(
  forms: Record<string, FormInstanceType>,
) {
  let allValues = {} as T;
  keys(forms).forEach((formKey) => {
    const form = forms[formKey];
    const formValues = form.getFieldsValue();
    allValues = {
      ...allValues,
      ...formValues,
    };
  });
  return allValues;
}
