import { useCallback, useState } from 'react';
import { map, partial } from 'lodash';
import { Alert } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { InsuranceFormContextProvider } from '../../features/Insurance/context/InsuranceFormContext';
import {
  BenefitOrderEnum,
  EligibilityReport,
  EligibilityStatusEnum,
  InsuranceSubmitForm,
  LimitedUser,
  Patient,
} from '../../uc-api-sdk';
import { useInsuranceHelper } from '../../features/Insurance/hook/useInsuranceHelper';
import { InsuranceCardSubmitValue, InsuranceEligibilityRequestSubmitValue } from '../../hooks/formHook/useInsuranceForm';

import './PatientCreateInsuranceComponent.scss';
import { InsuranceEligibilityVerifyButtonComponent } from '../../features/Insurance/component/InsuranceEligibilityVerifyButtonComponent/InsuranceEligibilityVerifyButtonComponent';
import { LinkButton } from '../LinkButton/LinkButton';
import { InsuranceCardTitleComponent } from '../../features/Insurance/component/InsuranceCardComponent/InsuranceCardTitleComponent';
import { InsuranceEligibilityStatusEnumComponent } from '../../enumComponent/EligibilityStatusEnumComponent/EligibilityStatusEnumComponent';
import { WaystarResultModalComponent } from '../../features/Insurance/component/WaystarComponent/WaystarResultModalComponent/WaystarResultModalComponent';
import { makeInsuranceSelectedValue } from '../../features/Insurance/container/WaystarProviderSelectContainer/WaystarProviderSelectContainer';

interface CreateInsuranceValue extends InsuranceCardSubmitValue {
  firstName: string;
  lastName: string;
  birthday: string | Dayjs;
  eligibilityStatus?: EligibilityStatusEnum | null,
  wayStarInquiryId?: string | null,
  shortHtml?: string | null,
  inquiredByUser?: LimitedUser | null,
  inquiredAt?: string | null,
}

export interface PatientCreateInsuranceComponentProps {
  patient: Patient;
  value?: InsuranceSubmitForm;
  onChange?: (
    value: {
      eligibilityValue: InsuranceEligibilityRequestSubmitValue,
      insuranceList: InsuranceCardSubmitValue[]
    },
  ) => void;
}

export const PatientCreateInsuranceComponent = ({
  patient,
  value,
  onChange,
}: PatientCreateInsuranceComponentProps) => {
  const {
    initialValues,
    getNextOrderOfBenefit,
    promptDeleteConfirmation,
  } = useInsuranceHelper(value);
  const [
    insuranceList,
    setInsuranceList,
  ] = useState<Partial<InsuranceCardSubmitValue>[]>(
    initialValues.insuranceList || []
  );
  const [
    eligibilityReportToView,
    setEligibilityReportToView,
  ] = useState<EligibilityReport | undefined>(undefined);

  const handleDelete = async (idx: number) => {
    const container = document.querySelector('.patient-create-insurance') as HTMLDivElement;
    setTimeout(() => {
      promptDeleteConfirmation(
        () => {
          const newInsuranceList = [...insuranceList];
          newInsuranceList.splice(idx, 1);
          setInsuranceList(newInsuranceList);
        },
        {
          getContainer: container,
        }
      );
    }, 100);
  };

  const handleSave = (
    idx: number,
    value: CreateInsuranceValue,
  ) => {
    const newInsuranceList = [...insuranceList];
    newInsuranceList[idx] = {
      ...newInsuranceList[idx],
      ...value
    };
    setInsuranceList(newInsuranceList);
    onChange?.({
      insuranceList: newInsuranceList as InsuranceCardSubmitValue[],
      eligibilityValue: value,
    });
  };

  const handleOnVerificationDone = (
    idx: number,
    eligibilityReport?: EligibilityReport | null,
    eligibilityRequestValue?: InsuranceEligibilityRequestSubmitValue | null,
  ) => {
    handleSave(idx, {
      firstName: eligibilityRequestValue?.firstName || '',
      lastName: eligibilityRequestValue?.lastName || '',
      birthday: eligibilityRequestValue?.birthday || '',
      provider: eligibilityRequestValue?.provider,
      insuranceProviderId: eligibilityRequestValue?.insuranceProviderId,
      benefitOrder: eligibilityRequestValue?.benefitOrder as BenefitOrderEnum,
      policyNumber: eligibilityRequestValue?.policyNumber,
      patientRelationship: eligibilityRequestValue?.patientRelationship,
      eligibilityStatus: eligibilityReport?.eligibilityStatus,
      wayStarInquiryId: eligibilityReport?.inquiryId,
      shortHtml: eligibilityReport?.shortHtml,
      inquiredByUser: eligibilityReport?.inquiredByUser,
      inquiredAt: eligibilityReport?.serviceDate,
    });
  };

  const renderAddButton = useCallback((open: () => void) => (
    <LinkButton
      useBlueColor
      onClick={open}
    >
      <PlusOutlined />
      Add Another Insurance to Verify
    </LinkButton>
  ), []);

  const renderReVerifyButton = useCallback((open: () => void) => (
    <LinkButton
      useBlueColor
      onClick={open}
    >
      Re-verify
    </LinkButton>
  ), []);

  return (
    <>
      <div className="patient-create-insurance">
        <InsuranceFormContextProvider
          patient={patient}
          insurances={insuranceList}
          insuranceFormValues={{}}
        >
          {
            insuranceList.length
              ? (
                map(insuranceList, (insurance, idx) => {
                  const {
                    provider,
                    benefitOrder,
                    providerAlias,
                    eligibilityStatus,
                  } = insurance;
                  return (
                    <Alert
                      key={idx}
                      closable={false}
                      description={(
                        <>
                          <div className="flex jc-e">
                            <CloseOutlined
                              onClick={() => handleDelete(idx)}
                            />
                          </div>
                          <div className="patient-create-insurance__alert mt10">
                            <InsuranceCardTitleComponent
                              provider={
                                makeInsuranceSelectedValue(provider, providerAlias, !!providerAlias)
                              }
                              benefitOrder={benefitOrder as BenefitOrderEnum}
                              showOptions={false}
                            />
                            <div className="flex gap1 ai-c">
                              <div>
                                <InsuranceEligibilityStatusEnumComponent
                                  value={eligibilityStatus}
                                />
                              </div>
                              <LinkButton
                                showUnderline
                                onClick={() => setEligibilityReportToView(insurance)}
                              >
                                Details
                              </LinkButton>
                              <InsuranceEligibilityVerifyButtonComponent
                                insurance={insurance}
                                onDone={partial(handleOnVerificationDone, idx)}
                                trigger={renderReVerifyButton}
                                autoSync={false}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    />
                  );
                })
              ) : (
                <Alert
                  description={(
                    <div className="flex fd-c create-patient-insurance__empty">
                      Add insurance to verify eligibility.
                      <InsuranceEligibilityVerifyButtonComponent
                        insurance={{
                          // first one is always primary
                          benefitOrder: BenefitOrderEnum.PRIMARY,
                        }}
                        onDone={partial(handleOnVerificationDone, 0)}
                        autoSync={false}
                      />
                    </div>
                  )}
                />
              )
          }
          {
            insuranceList.length
              ? (
                <InsuranceEligibilityVerifyButtonComponent
                  insurance={{
                    benefitOrder: (
                      getNextOrderOfBenefit(insuranceList as InsuranceCardSubmitValue[])
                    ),
                  }}
                  onDone={partial(handleOnVerificationDone, insuranceList.length)}
                  trigger={renderAddButton}
                  autoSync={false}
                />
              ) : null
          }
        </InsuranceFormContextProvider>
      </div>
      {
        eligibilityReportToView
        && (
          <WaystarResultModalComponent
            isOpen={!!eligibilityReportToView}
            eligibilityStatus={(
              eligibilityReportToView.eligibilityStatus as EligibilityStatusEnum
            )}
            inquiryInfo={eligibilityReportToView}
            report={eligibilityReportToView.shortHtml || ''}
            onDone={() => setEligibilityReportToView(undefined)}
          />
        )
      }
    </>
  );
};
