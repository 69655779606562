import { SmokeDrinkRecord$AlcoholTypeEnum } from '../../uc-api-sdk';

export interface AlcoholTypeEnumComponentProps {
  value?: SmokeDrinkRecord$AlcoholTypeEnum | null;
}

export const AlcoholTypeEnumComponent = ({
  value,
}: AlcoholTypeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case SmokeDrinkRecord$AlcoholTypeEnum.BEER:
        return 'beer';
      case SmokeDrinkRecord$AlcoholTypeEnum.LIQUOR:
        return 'liquor';
      case SmokeDrinkRecord$AlcoholTypeEnum.WINE:
        return 'wine';
      default:
        return '';
    }
  };

  return (<>{getText()}</>);
};
