import { FrequencyUnitEnum } from '../../uc-api-sdk';

export interface FreqEnumComponentProps {
  value: FrequencyUnitEnum | string;
  type?: 'short' | 'long' | 'shortCap';
}

export const FreqEnumComponent = ({
  value,
  type,
}: FreqEnumComponentProps) => {
  const getShortText = () => {
    switch (value) {
      case FrequencyUnitEnum.DAILY: return 'day';
      case FrequencyUnitEnum.WEEKLY: return 'week';
      case FrequencyUnitEnum.MONTHLY: return 'month';
      case FrequencyUnitEnum.ANNUALLY: return 'year';
      default: return null;
    }
  };

  const getShortCapText = () => {
    switch (value) {
      case FrequencyUnitEnum.DAILY: return 'Day';
      case FrequencyUnitEnum.WEEKLY: return 'Week';
      case FrequencyUnitEnum.MONTHLY: return 'Month';
      case FrequencyUnitEnum.ANNUALLY: return 'Year';
      default: return null;
    }
  };

  const getLongText = () => {
    switch (value) {
      case FrequencyUnitEnum.DAILY: return 'Daily';
      case FrequencyUnitEnum.WEEKLY: return 'Weekly';
      case FrequencyUnitEnum.MONTHLY: return 'Monthly';
      case FrequencyUnitEnum.ANNUALLY: return 'Yearly';
      default: return null;
    }
  };
  const getText = () => {
    switch (type) {
      case 'short': return getShortText();
      case 'shortCap': return getShortCapText();
      case 'long':
      default: return getLongText();
    }
  };

  return (
    <span>{getText()}</span>
  );
};
