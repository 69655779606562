import { map } from 'lodash';
import { BehaviorGoalItem } from '../../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';

import './BehaviorGoalCardListComponent.scss';
import { useBehaviorGoalHelpers } from '../../../hook/useBehaviorGoalHelpers';
import { BehaviorGoalCategoryLabelComponent } from '../BehaviorGoalCategoryLabelComponent/BehaviorGoalCategoryLabelComponent';
import { MeetingStatusSelectComponent } from '../../../../patient/component/MeetingStatusSelectComponent/MeetingStatusSelectComponent';
import { GoalFormTimeComponent } from '../../ClinicalGoalComponent/GoalFormTimeComponent/GoalFormTimeComponent';

interface BehaviorGoalCardListComponentProps {
  value: BehaviorGoalItem[]
}

export const BehaviorGoalCardListComponent = ({
  value,
}: BehaviorGoalCardListComponentProps) => {
  const { groupBehaviorGoalsByCategory } = useBehaviorGoalHelpers(value);
  const groupedValue = useDeepCompareMemo(groupBehaviorGoalsByCategory, [value]);

  return (
    <div className="behavior-goal-list">
      {map(groupedValue, (behaviorGoals, category) => (
        <div key={category} className="mb20">
          <BehaviorGoalCategoryLabelComponent
            category={behaviorGoals[0].category}
            customCategory={behaviorGoals[0].customCategory}
          />

          {map(behaviorGoals, (goal, idx) => (
            <div key={idx} className="flex jc-sb">
              <div className="pr24">
                {goal.behaviorGoalValue}
              </div>
              <div className="flex ai-c">
                <div className="fs12 text-gray-scale-3">
                  Status:
                </div>
                <MeetingStatusSelectComponent
                  bordered={false}
                  disabled
                  value={goal?.goalStatus || undefined}
                  useExtendedStatus
                />
                <GoalFormTimeComponent value={goal.updatedDate as string} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
