import classNames from 'classnames';
import { PatientComplexity } from '../../../../uc-api-sdk';
import { ComplexityModalComponent } from './ComplexityModalComponent';
import { ComplexityComponent } from '../ComplexityComponent/ComplexityComponent';

export interface ComplexityEditComponentProps {
  patientComplexity?: PatientComplexity;
  onUpdate: (newComplexity: PatientComplexity) => void;
  className?: string;
  disabled?: boolean;
}

export enum PatientComplexitySource {
  Condition = 'CONDITION',
  IcdCode = 'ICD_CODE',
  ManualInput = 'MANUAL_INPUT'
}

export const ComplexityEditComponent = ({
  patientComplexity,
  onUpdate,
  className = '',
  disabled,
}: ComplexityEditComponentProps) => (
  <div
    className={classNames({
      'flex ai-c': true,
      [className]: !!className,
    })}
  >
    <ComplexityComponent
      complexityLevel={patientComplexity?.level || undefined}
      updatedByUser={patientComplexity?.updatedByUser || {}}
    />
    <ComplexityModalComponent
      complexityLevel={patientComplexity?.level}
      onSubmit={onUpdate}
      disabled={disabled}
    />
  </div>
);
