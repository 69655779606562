import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { FormType } from '../../../Input/types';
import { CgmGoalFormComponent, CgmGoalFormComponentProps, CgmGoalFormValue } from '../../component/CgmGoalFormComponent/CgmGoalFormComponent';

export interface CgmGoalFormContainerProps extends FormType<CgmGoalFormValue> {
  onSubmit: CgmGoalFormComponentProps['onSubmit'];
  onCancel: CgmGoalFormComponentProps['onCancel'];
  initialValues?: CgmGoalFormValue | undefined;
  includeNote?: boolean;
}

export const CgmGoalFormContainer = ({
  initialValues,
  onSubmit,
  onCancel,
  isLoading,
  formButtons,
  onValuesChange,
  includeNote
}: CgmGoalFormContainerProps) => (
  <LoadingOverlayComponent
    isLoading={isLoading}
    showSkeleton
  >
    <CgmGoalFormComponent
      isLoading={isLoading}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      formButtons={formButtons}
      onValuesChange={onValuesChange}
      includeNote={includeNote}
    />
  </LoadingOverlayComponent>
);
