import { DateTimeType } from '../../../../types/common';
import {
  CgmMetricEnum, CgmMetricResult, CgmPercentGoal, PatientCgmDevice
} from '../../../../uc-api-sdk';
import { CgmBarChartComponent } from './CgmBarChartComponent';
import { CgmStatisticsComponent } from './CgmStatisticsComponent';
import './CgmVitalSummaryComponent.scss';

export interface CgmVitalSummaryComponentProps {
  metricData?: CgmMetricResult[];
  percentGoals?: CgmPercentGoal[];
  cgmDeviceInfo?: PatientCgmDevice;
  startDate?: DateTimeType;
  endDate?: DateTimeType;
  isForReport?: boolean;
}

export const CgmVitalSummaryComponent = ({
  metricData,
  percentGoals,
  cgmDeviceInfo,
  startDate,
  endDate,
  isForReport
}: CgmVitalSummaryComponentProps) => {
  const getMetricValue = (metricName: CgmMetricEnum) => metricData?.find(
    i => i.metricName === metricName && i.applicable
  )?.values?.value;

  // calculate CGM statistics data
  const activeTime = getMetricValue(CgmMetricEnum.CGM_ACTIVE_PERCENTAGE);
  const avgCgm = getMetricValue(CgmMetricEnum.AVERAGE_GLUCOSE);
  const gv = getMetricValue(CgmMetricEnum.GV);
  const gmi = getMetricValue(CgmMetricEnum.GMI);

  // calculate CGM reading minutes
  const tbrLevel2Min = getMetricValue(CgmMetricEnum.TBR_LEVEL_2);
  const tbrLevel1Min = getMetricValue(CgmMetricEnum.TBR_LEVEL_1);
  const tirMin = getMetricValue(CgmMetricEnum.TIR);
  const tarLevel1Min = getMetricValue(CgmMetricEnum.TAR_LEVEL_1);
  const tarLevel2Min = getMetricValue(CgmMetricEnum.TAR_LEVEL_2);

  return (
    <div className="CgmVitalSummaryComponent">
      <CgmStatisticsComponent
        activeTime={activeTime}
        avgCgm={avgCgm}
        gv={gv}
        gmi={gmi}
        cgmDeviceInfo={cgmDeviceInfo}
        startDate={startDate}
        endDate={endDate}
        isForReport={isForReport}
      />
      <CgmBarChartComponent
        tbrLevel2Min={tbrLevel2Min}
        tbrLevel1Min={tbrLevel1Min}
        tirMin={tirMin}
        tarLevel1Min={tarLevel1Min}
        tarLevel2Min={tarLevel2Min}
        percentGoals={percentGoals || undefined}
        showGoals={isForReport}
      />
    </div>
  );
};
