/* eslint-disable no-underscore-dangle */
import { Employee } from '../../types/user';

export const _getFirstName = (employee: Employee) => employee.employeeProfile?.firstName;

export const _getLastName = (employee: Employee) => employee.employeeProfile?.lastName;

export const _getFullName = (employee: Employee) => {
  const firstName = _getLastName(employee);
  const lastName = _getLastName(employee);
  if (firstName && lastName) {
    return `${_getFirstName(employee)} ${_getLastName(employee)}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return undefined;
};

export const _getCredential = (employee: Employee) => (
  employee.employeeProfile?.credentials?.join(', ')
);

export const _getFullNameWithCredential = (employee: Employee) => {
  const fullName = _getFullName(employee);
  const credential = _getCredential(employee);
  return `${fullName}${credential ? `, ${credential}` : ''}`;
};
