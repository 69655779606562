import { Modal } from 'antd';
import { ProgramCategoryEnumComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoriesComponent';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { Icons } from '../../../../icons/Icons';
import { ProgramCategoryEnum, VitalEnumType } from '../../../../uc-api-sdk';
import { ListComponent } from '../../../../uiComponent/ListComponent/ListComponent';
import './ConsentFormSignedComponent.scss';

export interface ConsentFormSignedComponentProps {
  programs?: ProgramCategoryEnum[];
  vitalsToMonitor?: VitalEnumType[];
  onOk?: () => void;
}

export const showConsentFormSignedComponent = ({
  programs,
  vitalsToMonitor,
  onOk
}: ConsentFormSignedComponentProps) => (
  Modal.info({
    centered: true,
    width: 680,
    className: 'consent-form-signed-modal',
    icon: <div className="flex jc-c ai-c mb30"><Icons.PersonSuccessIcon /></div>,
    title: <div className="text-align-center fs18">Consent Form Signed and Patient Enrolled Successfully</div>,
    content: (
      <div className="p20 bg-gray5 text-gray-scale-2 mt30">
        <div className="flex">
          <div className="inline-block w140">Program:</div>
          <div className="text-gray-scale-1 ta-l">
            <ListComponent renderItem={(p) => <ProgramCategoryEnumComponent value={p} />} value={programs || []} splitter=", " />
          </div>
        </div>
        <div className="flex">
          <div className="inline-block w140">Vitals to Monitor:</div>
          <div className="text-gray-scale-1 ta-l">
            <ListComponent renderItem={v => <VitalsEnumTypeComponent value={v} />} value={vitalsToMonitor || []} splitter=", " />
          </div>
        </div>
      </div>),
    okButtonProps: {
      type: 'primary',
      ghost: true,
      className: 'px30'
    },
    okText: 'Next',
    onOk
  })
);
