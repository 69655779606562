import { startCase } from 'lodash';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';
import { EnumComponentProps } from '..';

export interface BasicEnumComponentProps<T extends string> extends
  EnumComponentProps<T, 'camelCase'> {
}

const getText = <T extends string>({
  value,
  type = 'camelCase',
}: BasicEnumComponentProps<T>) => {
  switch (type) {
    case 'camelCase':
    default: return startCase(value?.toLowerCase());
  }
};

export const BasicEnumComponent = <T extends string>({
  value,
  type = 'camelCase',
}: BasicEnumComponentProps<T>) => (
    value
      ? <span>{getText({ value, type })}</span>
      : <EmptyComponent />
  );

BasicEnumComponent.getText = getText;
