import { FormListFieldData, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { map } from 'lodash';
import { TextComponent } from '../../../../../../uiComponent/TextComponent/TextComponent';
import { LifestyleAssessmentFreqFormItemComponent } from '../../../LifestyleAssessmentFreqFormItemComponent/LifestyleAssessmentFreqFormItemComponent';
import { LifestyleAssessmentFormListComponent } from '../../LifestyleAssessmentFormListComponent';
import { FastFoodBeverageInputCardLayout } from './FastFoodBeverageInputCardLayout';
import { useFastFoodBeverageForm } from '../../../../hook/useILifestyleAssessmentForm/useFastFoodBeverageForm';

export const BeverageInputCardComponent = () => {
  const formHook = useFastFoodBeverageForm();

  const renderItem = (
    field: FormListFieldData,
    remove: (idx: number) => void,
  ) => (
    <FastFoodBeverageInputCardLayout
      key={field.name}
      type={(
        <FormItem
          name={formHook.getBeverageInputInfo('beverageType', field.name).name}
        >
          <Input
            placeholder="eg. Coke"
          />
        </FormItem>
      )}
      frequency={(
        <LifestyleAssessmentFreqFormItemComponent
          freqName={formHook.getBeverageInputInfo('beverageFreq', field.name).name}
          freqUnitName={formHook.getBeverageInputInfo('beverageFreqUnit', field.name).name}
        />
      )}
      removeAction={() => remove(field.name)}
    />
  );

  return (
    <LifestyleAssessmentFormListComponent
      formListName={formHook.beverageInputForm.getMainFormItemName()}
    >
      {
        ({ fields, remove }) => (
          <>
            <FastFoodBeverageInputCardLayout
              type={(
                <TextComponent size="small">
                  {formHook.beverageInputForm.getLabel('beverageType')}
                </TextComponent>
              )}
              frequency={(
                <TextComponent size="small">
                  {formHook.beverageInputForm.getLabel('beverageFreq')}
                </TextComponent>
              )}
            />
            {map(fields, (field) => renderItem(field, remove))}
          </>
        )
      }
    </LifestyleAssessmentFormListComponent>
  );
};
