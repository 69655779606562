import classNames from 'classnames';
import { ReactNode } from 'react';

import './LifestyleAssessmentInputCardLayout.scss';

export interface LifestyleAssessmentInputCardLayoutProps {
  className?: string;
  children: ReactNode;
}

export const LifestyleAssessmentInputCardLayout = ({
  className = '',
  children
}: LifestyleAssessmentInputCardLayoutProps) => (
  <div
    className={classNames({
      'lifestyle-assessment__input-card': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);
