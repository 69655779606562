import { Col } from 'antd';
import { CgmMetricEnum } from '../../../../uc-api-sdk';
import { CgmGoalFormValue } from '../CgmGoalFormComponent/CgmGoalFormComponent';
import './CgmGoalThresholdComponent.scss';
import { MeasureUnitHelper } from '../../../../helpers/measurement/measureUnit';

interface CgmGoalThresholdComponentProps {
  cgmGoal?: CgmGoalFormValue;
}

export const CgmGoalThresholdComponent = ({
  cgmGoal,
}: CgmGoalThresholdComponentProps) => {
  const cgmUnit = () => (
    <div className="CgmGoalThresholdComponent__cgm-unit">
      {MeasureUnitHelper.defaultBGUnit}
    </div>
  );

  const renderCgmGoalThresholdAndGoal = (metricName: CgmMetricEnum) => {
    const metricConfig: Partial<Record<CgmMetricEnum, {
      title: string; lowerBound?: number; upperBound?: number; sign?: string
    }>> = {
      [CgmMetricEnum.TBR_LEVEL_2]: {
        title: 'TBR',
        upperBound: cgmGoal?.tbrLevel2UpperBound,
        sign: '≤',
      },
      [CgmMetricEnum.TBR_LEVEL_1]: {
        title: 'TBR',
        lowerBound: cgmGoal?.tbrLevel1LowerBound,
        upperBound: cgmGoal?.tbrLevel1UpperBound,
      },
      [CgmMetricEnum.TIR]: {
        title: 'TIR',
        lowerBound: cgmGoal?.tirLowerBound,
        upperBound: cgmGoal?.tirUpperBound,
      },
      [CgmMetricEnum.TAR_LEVEL_1]: {
        title: 'TAR',
        lowerBound: cgmGoal?.tarLevel1LowerBound,
        upperBound: cgmGoal?.tarLevel1UpperBound,
      },
      [CgmMetricEnum.TAR_LEVEL_2]: {
        title: 'TAR',
        lowerBound: cgmGoal?.tarLevel2LowerBound,
        sign: '≥',
      },
    };

    const config = metricConfig[metricName];

    return (
      <Col>
        <div className="CgmGoalThresholdComponent__cgm-threshold-title">
          {config?.title}
          {' '}
          {config?.sign || ''}
          {config?.lowerBound !== undefined && `${config.lowerBound}`}
          {config?.sign === undefined && '-'}
          {config?.upperBound !== undefined && config.upperBound}
          {cgmUnit()}
        </div>
      </Col>
    );
  };

  return (
    <div className="flex">
      {renderCgmGoalThresholdAndGoal(CgmMetricEnum.TBR_LEVEL_2)}
      {renderCgmGoalThresholdAndGoal(CgmMetricEnum.TBR_LEVEL_1)}
      {renderCgmGoalThresholdAndGoal(CgmMetricEnum.TIR)}
      {renderCgmGoalThresholdAndGoal(CgmMetricEnum.TAR_LEVEL_1)}
      {renderCgmGoalThresholdAndGoal(CgmMetricEnum.TAR_LEVEL_2)}
    </div>
  );
};
