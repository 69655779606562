import { usePhoneReportInfoGetAppInfoByPatientId } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { AppInfoComponent } from '../../component/AppInfoComponent/AppInfoComponent';

export interface AppInfoContainerProp {
  patientId: string;
}

export const AppInfoContainer = ({
  patientId,
}: AppInfoContainerProp) => {
  const appInfoResponseHook = usePhoneReportInfoGetAppInfoByPatientId({
    params: { memberId: patientId },
    options: { sendOnMount: !!patientId },
  });

  return (
    <FetchComponent info={appInfoResponseHook}>
      <AppInfoComponent
        patientId={patientId}
        appInfoResponse={appInfoResponseHook?.data?.data || undefined}
      />
    </FetchComponent>
  );
};
