import {
  Button, Dropdown
} from 'antd';
import { filter, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useCallCenterContext } from '../../../../contexts/CallCenterContext/CallCenterContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { orderByArray } from '../../../../helpers/order/orderByArray';
import { formatPhoneNumber } from '../../../../helpers/phone';
import { Icons } from '../../../../icons/Icons';
import {
  Patient,
  PatientEnrolledProgram,
  Phone,
  PhoneTypeEnum
} from '../../../../uc-api-sdk';

export interface PhoneCallDropdownComponentProps {
  patientInfo: Patient;
  enrolledProgramInfo?: PatientEnrolledProgram;
  isEnrolled: boolean;
}
export const PhoneCallDropdownComponent = ({
  patientInfo,
  enrolledProgramInfo,
  isEnrolled,
}: PhoneCallDropdownComponentProps) => {
  const callContext = useCallCenterContext();
  const { handleMakePhoneCall } = useCallCenterContext();
  const { send: sendMixpanel } = useMixpanelContext();

  const phoneItems = useMemo(() => {
    const phones = filter(patientInfo.phone, (p) => p.type && p.number) as Phone[];
    const sortedPhones = orderByArray(phones, [PhoneTypeEnum.MOBILE, PhoneTypeEnum.HOME], 'type');
    return sortedPhones.map((item) => ({
      label: `${item.type} ${formatPhoneNumber(item.number as string)}`,
      key: `${item.type}-${item.number}`,
      onClick: () => {
        sendMixpanel({
          event: MixpanelEvents.PatientHeaderCall,
          patientId: patientInfo.id,
          properties: {
            phoneType: item.type,
            phoneNumber: item.number,
            isEnrolled,
          }
        });
        handleMakePhoneCall(
          item.number as string,
          patientInfo,
          {
            isEnrolled,
            info: enrolledProgramInfo
          },
        );
      },
    }));
  }, [
    patientInfo.phone,
    handleMakePhoneCall,
    sendMixpanel
  ]);

  if (isEmpty(callContext)) {
    // some platform doesn't support call center
    return null;
  }

  return (
    <Dropdown
      menu={{ items: phoneItems }}
      trigger={['click']}
      placement="bottomRight"
      overlayClassName="dot-dropdown-component"
    >
      <Button
        type={isEnrolled ? 'primary' : 'ghost'}
        className="flex b5"
        icon={(
          <div className="flex ai-c">
            {
              isEnrolled ? <Icons.Call /> : <Icons.CallUnenrolled />
            }
          </div>
        )}
      >
        Call
      </Button>
    </Dropdown>
  );
};
