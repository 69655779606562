import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';
import { Patient } from '../../../../../uc-api-sdk';
import { useSocialSupportForm } from '../../../../patient/hook/useSocialSupportForm';

export interface PrintCarePlanSocialSupportComponentProps {
  profile?: Patient['profile'];
}

export const PrintCarePlanSocialSupportComponent = ({
  profile,
}: PrintCarePlanSocialSupportComponentProps) => {
  const socialForm = useSocialSupportForm();
  if (isEmpty(profile)) {
    return null;
  }

  const renderLabel = (label: string) => (
    `${label}:`
  );
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Social Support
      </TextComponent>
      <PrintCarePlanSimpleItemComponent
        label={renderLabel(socialForm.getLabel('communitySupport'))}
        content={<DisplayOrEmptyComponent value={profile?.communitySupport} />}
      />
      <PrintCarePlanSimpleItemComponent
        label={renderLabel(socialForm.getLabel('contactInfo'))}
        content={<DisplayOrEmptyComponent value={profile?.contactInfo} />}
      />
      <PrintCarePlanSimpleItemComponent
        label={renderLabel(socialForm.getLabel('otherProviders'))}
        content={<DisplayOrEmptyComponent value={profile?.otherProviders} />}
      />
      <PrintCarePlanSimpleItemComponent
        label={renderLabel(socialForm.getLabel('easeOfFoodAccess'))}
        content={<DisplayOrEmptyComponent value={profile?.easeOfFoodAccess} />}
      />
      <Divider />
    </>
  );
};
