import { useCallback } from 'react';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import { useIterableFormHook } from '../../../../hooks/useIterableFormListHook/useIterableFormHook';
import { GetFieldValue } from '../../../../types/form';

export enum SmokeDrinkObjectName {
  smoke = 'smoke',
  drink = 'drink',
}

export const useSmokeDrinkForm = (options?: FormOptions) => {
  const smokeDrinkForm = useIterableFormHook('smokeDrinkRecord', {
    isSmoking: {
      name: 'isSmoking',
      label: 'Does the patient smoke?',
    },
    quitSmokingYear: {
      name: 'quitSmokingYear',
      label: 'Quit year',
    },
    smokingAmount: {
      name: 'smokingAmount',
      label: 'Frequency',
    },
    cigaretteUnit: {
      name: 'cigaretteUnit',
      label: ' ', // to fake label height
    },
    smokingFrequency: {
      name: 'smokingFrequency',
      label: ' ', // to fake label height
    },
    isDrinking: {
      name: 'isDrinking',
      label: 'Does the patient drink?',
    },
    quitDrinkingYear: {
      name: 'quitDrinkingYear',
      label: 'Quit year',
    },
    drinkingAmount: {
      name: 'drinkingAmount',
      label: 'Frequency',
    },
    alcoholType: {
      name: 'alcoholType',
      label: ' ', // to fake label
    },
    containerType: {
      name: 'containerType',
      label: ' ', // to fake label
    },
    drinkingFrequency: {
      name: 'drinkingFrequency',
      label: ' ', // to fake label height
    }
  }, options);

  const getInfo = useCallback((
    key: keyof typeof smokeDrinkForm.formInput,
  ) => ({
    name: smokeDrinkForm.makeListNamePath(key, smokeDrinkForm.getMainFormItemName()),
    label: smokeDrinkForm.getLabel(key),
  }), [smokeDrinkForm]);

  const getObjectValue = useCallback((
    key: keyof typeof smokeDrinkForm.formInput,
    getFieldValue: GetFieldValue,
  ) => (
    getFieldValue([smokeDrinkForm.getMainFormItemName(), key])
  ), [smokeDrinkForm]);

  const shouldUpdate = useCallback((
    keys: (keyof typeof smokeDrinkForm.formInput)[],
  ) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (prev: any, curr: any) => (
      keys.some((key) => (
        prev[smokeDrinkForm.getMainFormItemName()]?.[key]
        !== curr[smokeDrinkForm.getMainFormItemName()]?.[key]
      ))
    )
  ), []);

  return useGetContextValue({
    ...smokeDrinkForm,
    getInfo,
    shouldUpdate,
    getObjectValue,
  });
};
