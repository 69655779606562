import { Col, Form, FormProps } from 'antd';
import {
  entries,
  filter,
  intersection,
  keyBy,
  map,
  omit,
  uniq
} from 'lodash';
import { useRef, useState } from 'react';
import { FormType } from '../../../Input/types';
import {
  AddRemoveVitalsMonitoringSubmitValue,
  useAddRemoveVitalsMonitoringForm,
  SupportedVitalScheduleEnumType,
} from '../../hook/useAddRemoveVitalsMonitoringForm';
import { MonitoringVital, MonitorMethodEnum, VitalEnumType } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PatientProfileVitalsMonitoringFormItemComponent } from './PatientProfileVitalsMonitoringFormItemComponent';
import { PatientProfileDateDisplayComponent } from '../PatientProfileDateDisplayComponent/PatientProfileDateDisplayComponent';
import { vitalsMonitoringOrder } from '../../../../lib/constants/vitalsMonitoringOrder';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { DeviceDrawerComponent, DeviceDrawerComponentProps } from '../../../device/component/DeviceDrawerComponent/DeviceDrawerComponent';
import { SingleVitalMonitoringScheduleDrawerComponent, SingleVitalMonitoringScheduleDrawerComponentProps } from '../../../vitals/component/SingleVitalMonitoringScheduleDrawerComponent/SingleVitalMonitoringScheduleDrawerComponent';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { NestedFormControlProvider } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import { SubmitValue as DeviceMonitorSubmitValue } from '../../../device/component/DeviceMonitorFormComponent/DeviceMonitorFormComponent';
import { SubmitValue as ScheduleSubmitValue } from '../../../vitals/component/VitalsMonitoringScheduleFormComponent/VitalsMonitoringScheduleFormComponent';
import { VitalRemovePrecheckModalComponent } from '../../../vitals/component/VitalRemovePrecheckModalComponent/VitalRemovePrecheckModalComponent';
import { VitalRemovePrecheckContainerProps } from '../../../vitals/container/VitalRemovePrecheckContainer/VitalRemovePrecheckContainer';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useGetDeviceInfo } from '../../../device/hook/useGetDeviceInfo';
import { CGMDeviceDrawerComponent, CGMDeviceDrawerComponentProps } from '../../../CGMDevice/component/CGMDeviceDrawerComponent/CGMDeviceDrawerComponent';
import { CGMConsentFormDrawerComponent } from '../../../CGMDevice/component/CGMConsentFormDrawerComponent/CGMConsentFormDrawerComponent';

export type PatientProfileVitalsMonitoringDeviceValues = {
  [key in SupportedVitalScheduleEnumType]: DeviceMonitorSubmitValue
}

export type PatientProfileVitalsMonitoringScheduleValues = {
  [key in SupportedVitalScheduleEnumType]: ScheduleSubmitValue
}

export type PatientProfileVitalDevicesToRemove = {
  [key in SupportedVitalScheduleEnumType]?: string[];
}

export interface PatientProfileVitalsMonitoringFormComponentProps
  extends FormType<AddRemoveVitalsMonitoringSubmitValue> {
  patientId: string;
  isCGMEnabled?: boolean;
}

export const PatientProfileVitalsMonitoringFormComponent = ({
  patientId,
  initialValues,
  isCGMEnabled,
  isLoading,
  onSubmit,
  onCancel,
  onValuesChange,
  formButtons = <CancelSubmitButtonsComponent onCancel={onCancel} />,
}: PatientProfileVitalsMonitoringFormComponentProps) => {
  const { devicesService } = usePatientContext()?.info || {};
  const { getMonitorMethodByType } = useGetDeviceInfo(devicesService?.getPatientDevices());
  const profileVitalsMonitoringForm = useAddRemoveVitalsMonitoringForm();
  const devices = useRef<PatientProfileVitalsMonitoringDeviceValues>();
  const schedules = useRef<PatientProfileVitalsMonitoringScheduleValues>();
  const removeDevicesFor = useRef<PatientProfileVitalDevicesToRemove>();
  const [
    openDeviceFor,
    setOpenDeviceFor,
  ] = useState<SupportedVitalScheduleEnumType | undefined>();
  const [
    openScheduleFor,
    setOpenScheduleFor,
  ] = useState<SupportedVitalScheduleEnumType | undefined>();
  const [
    openRemovePrecheckFor,
    setRemovePrecheckFor,
  ] = useState<SupportedVitalScheduleEnumType | undefined>();

  const getAvailableVitals = () => {
    let availList = intersection(vitalsMonitoringOrder, map(VitalEnumType));
    if (!isCGMEnabled) {
      availList = filter(vitalsMonitoringOrder, (v) => v !== VitalEnumType.CGM);
    }
    return availList;
  };

  const setFieldValue = (key: SupportedVitalScheduleEnumType, value: unknown) => {
    const allValues = profileVitalsMonitoringForm.form.getFieldsValue();
    const newValue = {
      ...allValues.vitals[key],
      ...(value || {})
    };
    const newVitals = {
      ...allValues.vitals,
      [key]: newValue,
    };
    profileVitalsMonitoringForm.form.setFieldsValue({
      vitals: newVitals,
    });
    // manually trigger onValuesChanges
    profileVitalsMonitoringForm.onValuesChange(onValuesChange, initialValues)?.(
      { vitals: { [key]: newValue } },
      {
        ...allValues,
        vitals: newVitals,
        removeDevicesFor: removeDevicesFor.current,
      }
    );
  };

  const setRemoveDevicesFor = (removeDevicesFor?: PatientProfileVitalDevicesToRemove) => {
    if (!removeDevicesFor) return;
    const allValues = profileVitalsMonitoringForm.form.getFieldsValue();
    profileVitalsMonitoringForm.form.setFieldsValue({
      removeDevicesFor,
    });
    // manually trigger onValuesChanges
    profileVitalsMonitoringForm.onValuesChange(onValuesChange, initialValues)?.(
      { removeDevicesFor },
      {
        ...allValues,
        removeDevicesFor,
      }
    );
  };

  const resetSelectionTo = (key: SupportedVitalScheduleEnumType, isChecked?: boolean) => {
    devices.current = (
      omit({ ...devices.current }, key) as PatientProfileVitalsMonitoringDeviceValues
    );
    schedules.current = (
      omit({ ...schedules.current }, key) as PatientProfileVitalsMonitoringScheduleValues
    );
    setFieldValue(key, { type: isChecked ? key : null });
  };

  const handleOnValuesChange: FormProps['onValuesChange'] = (changedValues, allValues) => {
    onValuesChange?.(changedValues, allValues);
    const { vitals } = changedValues;
    const [vitalType, value] = entries(vitals)[0];
    const isChecked = !!(value as MonitoringVital).type;
    if (isChecked) {
      setOpenDeviceFor(vitalType as SupportedVitalScheduleEnumType);
      return;
    }
    if (vitalType === VitalEnumType.CGM) {
      return; // no-op
    }
    if (!removeDevicesFor.current?.[vitalType as SupportedVitalScheduleEnumType]) {
      // prompt when it never prompts
      const isManual = getMonitorMethodByType(
        vitalType as SupportedVitalScheduleEnumType
      ) === MonitorMethodEnum.MANUALLY_INPUT;
      if (!isManual) {
        setRemovePrecheckFor(vitalType as SupportedVitalScheduleEnumType);
      }
    } else {
      resetSelectionTo(vitalType as SupportedVitalScheduleEnumType);
    }
  };

  const handleSubmitDevice: DeviceDrawerComponentProps['onSubmit'] = (
    values
  ) => {
    if (!openDeviceFor) return;
    devices.current = ({
      ...(devices.current || {}),
      [openDeviceFor]: values
    }) as PatientProfileVitalsMonitoringDeviceValues;
    setOpenScheduleFor(openDeviceFor);
    setOpenDeviceFor(undefined);
    setFieldValue(openDeviceFor, { deviceMonitor: values });
  };

  const handleSubmitSchedule: SingleVitalMonitoringScheduleDrawerComponentProps['onSubmit'] = (
    values
  ) => {
    if (!openScheduleFor) return;
    schedules.current = ({
      ...(schedules.current || {}),
      [openScheduleFor]: values
    }) as PatientProfileVitalsMonitoringScheduleValues;
    setOpenScheduleFor(undefined);
    setFieldValue(openScheduleFor, { schedule: values });
  };

  const handleSubmitCGMConsentForm = () => {
    setOpenScheduleFor(openDeviceFor);
    setOpenDeviceFor(undefined);
  };

  const handleSaveCGMDevice: CGMDeviceDrawerComponentProps['onSave'] = (
    values
  ) => {
    setOpenScheduleFor(undefined);
    setFieldValue(VitalEnumType.CGM, { deviceMonitor: values });
  };

  const handleConfirmRemovePrecheck: VitalRemovePrecheckContainerProps['onConfirm'] = (
    devices,
  ) => {
    if (!openRemovePrecheckFor) return;
    removeDevicesFor.current = {
      ...(removeDevicesFor.current || {}),
      [openRemovePrecheckFor]: uniq([
        ...(removeDevicesFor.current?.[openRemovePrecheckFor] || []),
        ...map(devices, 'deviceId')
      ])
    };
    setRemovePrecheckFor(undefined);
    setRemoveDevicesFor(removeDevicesFor.current);
  };

  const handleCancelRemovePrecheck = () => {
    resetSelectionTo(openRemovePrecheckFor as SupportedVitalScheduleEnumType, true);
    setRemovePrecheckFor(undefined);
  };

  const handleCancelAddDevice = () => {
    setOpenDeviceFor(undefined);
    if (openDeviceFor && !devices?.current?.[openDeviceFor]) {
      resetSelectionTo(openDeviceFor);
    }
  };

  const handleCancelAddSchedule = () => {
    setOpenScheduleFor(undefined);
    if (openScheduleFor && !schedules?.current?.[openScheduleFor]) {
      resetSelectionTo(openScheduleFor);
    }
  };

  const renderOpenDeviceFor = () => {
    if (!openDeviceFor) {
      return null;
    }
    switch (openDeviceFor) {
      case VitalEnumType.CGM:
        return (
          <CGMConsentFormDrawerComponent
            patientId={patientId}
            open
            onCancel={handleCancelAddDevice}
            onSubmit={handleSubmitCGMConsentForm}
          />
        );
      default:
        return (
          <DeviceDrawerComponent
            vitalType={openDeviceFor as VitalEnumType}
            open
            onSubmit={handleSubmitDevice}
            onClose={handleCancelAddDevice}
            title="Choose Monitoring Method and Device"
            useDefaultTemplate
          />
        );
    }
  };

  const renderOpenScheduleFor = () => {
    if (!openScheduleFor) {
      return null;
    }
    switch (openScheduleFor) {
      case VitalEnumType.CGM:
        return (
          <CGMDeviceDrawerComponent
            patientId={patientId}
            title="Continuous Glucose Monitor"
            open
            onSave={handleSaveCGMDevice}
            onClose={handleCancelAddSchedule}
          />
        );
      default:
        return (
          <SingleVitalMonitoringScheduleDrawerComponent
            vitalType={openScheduleFor as VitalEnumType}
            open
            onSubmit={handleSubmitSchedule}
            onClose={handleCancelAddSchedule}
            autoSetDefault
          />
        );
    }
  };

  return (
    <>
      <Form
        form={profileVitalsMonitoringForm.form}
        name={profileVitalsMonitoringForm.formName}
        onFinish={profileVitalsMonitoringForm.handleSubmit(onSubmit, initialValues)}
        disabled={isLoading}
        initialValues={{ vitals: keyBy(initialValues?.vitals, 'type') }}
        onValuesChange={(
          profileVitalsMonitoringForm.onValuesChange(handleOnValuesChange, initialValues)
        )}
        className="ant-form-item-no-margin"
      >
        <FormItem
          name={profileVitalsMonitoringForm.getMainFormItemName()}
        />
        {
          map(getAvailableVitals(), (v: VitalEnumType, idx: number) => {
            const formField = {
              name: v,
              key: idx,
              isListField: true,
            };

            if ([VitalEnumType.EXERCISE].includes(v)) return null;

            return (
              <div key={v} className="flex ai-bl jc-sb">
                <Col span={16}>
                  <PatientProfileVitalsMonitoringFormItemComponent
                    key={v}
                    vitalType={v}
                    formField={formField}
                  />
                </Col>
                <Col span={8}>
                  <FormItem noStyle shouldUpdate>
                    {
                      ({ getFieldValue }) => {
                        const hasVital = profileVitalsMonitoringForm.getObjectValue('type', getFieldValue, formField.name);
                        const createdAt = profileVitalsMonitoringForm.getObjectValue('createdAt', getFieldValue, formField.name);
                        return (hasVital && createdAt)
                          ? (
                            <PatientProfileDateDisplayComponent
                              value={createdAt}
                              label="Added on"
                            />
                          ) : <div />;
                      }
                    }
                  </FormItem>
                </Col>
              </div>
            );
          })
        }
        <FormItem
          noStyle
          shouldUpdate
        >
          {
            ({ getFieldValue }) => {
              const vitals = getFieldValue(profileVitalsMonitoringForm.getMainFormItemName());
              const hasVitals = (
                !!vitals
                && (
                  filter(
                    Object.values(vitals || {}) as AddRemoveVitalsMonitoringSubmitValue['vitals'],
                    (v) => v?.type
                  ).length > 0
                )
              );
              if (hasVitals) return null;
              return (
                <MissingErrorComponent>
                  At least one vital must be selected
                </MissingErrorComponent>
              );
            }
          }
        </FormItem>
        {formButtons}
      </Form>
      <NestedFormControlProvider>
        {/* put drawers inside NestedFormComponent to free the forms in nested form */}
        {
          // use this condition to reset form when drawer closes
          openDeviceFor && (
            <NestedFormComponent>
              {renderOpenDeviceFor()}
            </NestedFormComponent>
          )
        }
        {
          openScheduleFor && (
            <NestedFormComponent>
              {renderOpenScheduleFor()}
            </NestedFormComponent>
          )
        }
      </NestedFormControlProvider>
      <VitalRemovePrecheckModalComponent
        patientId={patientId}
        vitalType={openRemovePrecheckFor as VitalEnumType}
        open={!!openRemovePrecheckFor}
        onConfirm={handleConfirmRemovePrecheck}
        onCancel={handleCancelRemovePrecheck}
      />
    </>
  );
};
