import { FC } from 'react';
import { RoleType } from '../../types/roles';
import { RoleTypeEnum } from '../../uc-api-sdk';

export interface RoleTypeComponentProps {
  value?: RoleType | RoleTypeEnum;
}

export const RoleTypeComponent: FC<RoleTypeComponentProps> = ({
  value,
}) => {
  const getRoleTypeName = () => {
    switch (value) {
      case RoleType.ADMIN: return 'Admin';
      case RoleType.BILLER: return 'Biller';
      case RoleType.CA: return 'CA';
      case RoleType.HC: return 'HC';
      case RoleType.MA: return 'MA';
      case RoleType.PROVIDER: return 'MD';
      case RoleType.MEMBER: return 'Member';
      case RoleType.CLINICAL_GROUP_BILLER: return 'Clinical Group Biller';
      case RoleType.RD: return 'RD';
      case RoleType.SUPER_ADMIN: return 'Super Admin';
      case RoleType.ACCOUNTING_AUDIT: return 'Account Audit';
      case RoleType.CALL_CENTER_REPRESENTATIVE: return 'Call Center Representative';
      case RoleType.CLINICAL_MANAGER: return 'Clinical Manager';
      case RoleType.MANAGER: return 'Manager';
      case RoleType.OPERATION_MANAGER: return 'Operation Manager';
      case RoleType.QUALITY_TEAM_MEMBER: return 'Quality Team Member';
      default: return null;
    }
  };

  return (
    <span data-testid="role-type-component">{getRoleTypeName()}</span>
  );
};

export default RoleTypeComponent;
