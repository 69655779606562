import { VitalEnumType } from '../../uc-api-sdk';

// prevent using this as list of options
// - CGM is available only when Admin portal configures
export const vitalsMonitoringOrder = [
  VitalEnumType.BG,
  VitalEnumType.CGM,
  VitalEnumType.BP,
  VitalEnumType.PO,
  VitalEnumType.HS,
  VitalEnumType.TM,
  VitalEnumType.EXERCISE,
];
