import { MealRoutineTypeEnum } from '../../uc-api-sdk';

export interface MealRoutineTypeEnumProps {
  value?: MealRoutineTypeEnum | null;
}

export const MealRoutineTypeEnumComponent = ({
  value
}: MealRoutineTypeEnumProps) => {
  const getText = () => {
    switch (value) {
      case MealRoutineTypeEnum.WAKEUP:
        return 'Wake up';
      case MealRoutineTypeEnum.BREAKFAST:
        return 'Breakfast';
      case MealRoutineTypeEnum.LUNCH:
        return 'Lunch';
      case MealRoutineTypeEnum.DINNER:
        return 'Dinner';
      case MealRoutineTypeEnum.SNACK:
        return 'Snack';
      case MealRoutineTypeEnum.SLEEP:
        return 'Sleep';
      default:
        return '';
    }
  };

  return (<>{getText()}</>);
};
