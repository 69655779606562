import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { CgmMetricEnum, useCgmMobileGetPatientCgmReading } from '../../../../uc-api-sdk';
import { CgmHelper } from '../../../vitals/helper/CgmHelper';
import './OutstandingCgmSummaryComponent.scss';

export interface OutstandingCgmSummaryComponentProps {
  memberId: string;
  startTime?: Dayjs;
  endTime?: Dayjs;
}

export const OutstandingCgmSummaryComponent = ({
  memberId,
  startTime,
  endTime
}: OutstandingCgmSummaryComponentProps) => {
  const cgmReadingInfo = useCgmMobileGetPatientCgmReading({
    options: { sendOnMount: !!memberId && !!startTime && !!startTime },
    params: {
      request: {
        patientId: memberId,
        includeRawData: false,
        includeSummary: true,
        startTime: startTime?.toISOString(),
        endTime: endTime?.toISOString(),
      }
    }
  });

  const getMetricValue = (metricName: CgmMetricEnum) => cgmReadingInfo.data?.data?.metrics?.find(
    i => i.metricName === metricName
    // && i.applicable
  )?.values?.value || 0;

  const { thresholds } = cgmReadingInfo.data?.data?.context?.patientContext || {};

  const tar2 = getMetricValue(CgmMetricEnum.TAR_LEVEL_2);
  const tar1 = getMetricValue(CgmMetricEnum.TAR_LEVEL_1);
  const tir = getMetricValue(CgmMetricEnum.TIR);
  const tbr1 = getMetricValue(CgmMetricEnum.TBR_LEVEL_1);
  const tbr2 = getMetricValue(CgmMetricEnum.TBR_LEVEL_1);
  return (
    <div className="OutstandingCgmSummaryComponent">
      <div className="mb10">
        The chart compares the patient's time in range to
        CGM medical-standard goals for clinical evaluation.
      </div>
      <div className="flex gap1 fd-c">
        {/* TAR_LEVEL_2 */}
        <div className="flex ai-c bg-gray5 p10 gap2 default-br">
          <div className="flex ai-c">
            <div className="bold">
              Time Above Range
            </div>
            <div className="fs12">
              (
              {
                CgmHelper.getDefaultThreshold(CgmMetricEnum.TAR_LEVEL_2, thresholds)
              }
              )
            </div>
          </div>
          <div className="dashed-border" />
          <div>
            <span className={classNames({
              bold: true,
              warning: tar2 >= CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TAR_LEVEL_2)
            })}
            >
              {`${tar2}%`}
            </span>
            <span className="text-gray-scale-2">
              {CgmHelper.getDefaultThresholdPercentString(CgmMetricEnum.TAR_LEVEL_2)}
            </span>
          </div>
        </div>
        {/* TAR_LEVEL_1 */}
        <div className="flex ai-c bg-gray5 p10 gap2 default-br">
          <div className="flex ai-c">
            <div className="bold">
              Time Above Range
            </div>
            <span className="fs12">
              (
              {
                CgmHelper.getDefaultThreshold(CgmMetricEnum.TAR_LEVEL_1, thresholds)
              }
              )
            </span>
          </div>
          <div className="dashed-border" />
          <div>
            <span className={classNames({
              bold: true,
              warning: tar1 >= CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TAR_LEVEL_1)
            })}
            >
              {`${tar1}%`}
            </span>
            <span className="text-gray-scale-2">
              {CgmHelper.getDefaultThresholdPercentString(CgmMetricEnum.TAR_LEVEL_1)}
            </span>
          </div>
        </div>

        {/* TIR */}
        <div className="flex ai-c bg-gray5 p10 gap2 default-br">
          <div className="flex ai-c">
            <div className="bold">
              Time In Range
            </div>
            <span className="fs12">
              (
              {
                CgmHelper.getDefaultThreshold(CgmMetricEnum.TIR, thresholds)
              }
              )
            </span>
          </div>
          <div className="dashed-border" />
          <div>
            <span className={classNames({
              bold: true,
              warning: tir < CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TIR)
            })}
            >
              {`${tir}%`}
            </span>
            <span className="text-gray-scale-2">
              {CgmHelper.getDefaultThresholdPercentString(CgmMetricEnum.TIR)}
            </span>
          </div>
        </div>

        {/* TBR_LEVEL_1 */}
        <div className="flex ai-c bg-gray5 p10 gap2 default-br">
          <div className="flex ai-c">
            <div className="bold">
              Time Below Range
            </div>
            <span className="fs12">
              (
              {
                CgmHelper.getDefaultThreshold(CgmMetricEnum.TBR_LEVEL_1, thresholds)
              }
              )
            </span>
          </div>
          <div className="dashed-border" />
          <div>
            <span className={classNames({
              bold: true,
              warning: tbr1 >= CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TBR_LEVEL_1)
            })}
            >
              {`${tbr1}%`}
            </span>
            <span className="text-gray-scale-2">
              {CgmHelper.getDefaultThresholdPercentString(CgmMetricEnum.TBR_LEVEL_1)}
            </span>
          </div>
        </div>
        {/* TBR_LEVEL_2 */}
        <div className="flex ai-c bg-gray5 p10 gap2 default-br">
          <div className="flex ai-c">
            <div className="bold">
              Time Below Range
            </div>
            <span className="fs12">
              (
              {
                CgmHelper.getDefaultThreshold(CgmMetricEnum.TBR_LEVEL_2, thresholds)
              }
              )
            </span>
          </div>
          <div className="dashed-border" />
          <div>
            <span className={classNames({
              bold: true,
              warning: tbr2 >= CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TBR_LEVEL_2)
            })}
            >
              {`${tbr2}%`}
            </span>
            <span className="text-gray-scale-2">
              {CgmHelper.getDefaultThresholdPercentString(CgmMetricEnum.TBR_LEVEL_2)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
