import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import {
  ReactNode,
  useEffect,
  useState
} from 'react';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { DateTimeType } from '../../../../types/common';
import {
  useCgmGetPatientAgp,
  useCgmMobileGetPatientCgmReading
} from '../../../../uc-api-sdk';
import { DateNavigatorComponent, DateNavigatorComponentProps } from '../../../../uiComponent/DateNavigatorComponent/DateNavigatorComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { AGPChartComponent } from '../../component/AGPChartComponent/AGPChartComponent';
import { CGMChartMainComponent } from '../../component/CGMChartComponent/CGMChartMainComponent';
import { CGMChartViewSwitchComponent } from '../../component/CGMChartComponent/CGMChartViewSwitchComponent';
import { MeasurementTimestampTextComponent } from '../../component/MeasurementTimestampTextComponent/MeasurementTimestampTextComponent';
import { CGM_DAYS_LIMIT } from '../../constant/cgmConstant';
import { CGMChartView } from '../CGMVitalContainer/type';
import './CGMChartContainer.scss';

interface ViewDates {
  viewStartDate: Dayjs;
  viewEndDate: Dayjs;
}

export interface CGMChartContainerProps {
  patientId: string;
  startDate: DateTimeType;
  endDate: DateTimeType;
}

export const CGMChartContainer = ({
  patientId,
  startDate,
  endDate,
}: CGMChartContainerProps) => {
  const [view, setView] = useState<CGMChartView | undefined>(CGMChartView.AGP);
  const [viewDates, setViewDates] = useState<ViewDates>();
  const isAGPView = view === CGMChartView.AGP;
  const isOneDayRange = dayjs(startDate).isSame(endDate, 'day');
  const daysInRange = dayjs(endDate).diff(dayjs(startDate), 'day') + 1; // inclusive

  const cgmReadingInfo = useCgmMobileGetPatientCgmReading({
    options: { sendOnMount: false }
  });
  const agpInfo = useCgmGetPatientAgp({
    options: { sendOnMount: false }
  });

  const cgmData = cgmReadingInfo.data?.data?.rawData;
  const cgmPatientContext = cgmReadingInfo.data?.data?.context?.patientContext || {};
  const cgmAvailableDays = cgmReadingInfo.data?.data?.availableDates?.length || 0;

  const agpData = agpInfo.data?.data;
  const agpPatientContext = agpInfo.data?.data?.context?.patientContext || {};
  const isAGPApplicable = agpInfo.data?.data?.isApplicable;

  const handleSetViewDates: DateNavigatorComponentProps['onChange'] = useDebounce((endDate, startDate) => {
    const newViewEndDate = dayjs(endDate).endOf('day');
    setViewDates({
      viewStartDate: startDate || newViewEndDate.startOf('day'),
      viewEndDate: newViewEndDate,
    });
  }, 250);

  const renderDateNav = (defaultUI: ReactNode) => {
    const rangeDays = dayjs(endDate).diff(dayjs(startDate), 'day');
    if (isAGPView || rangeDays < CGM_DAYS_LIMIT) {
      return null;
    }
    return defaultUI;
  };

  const getDateNavPeriod = () => {
    if (daysInRange > CGM_DAYS_LIMIT) return CGM_DAYS_LIMIT;
    if (daysInRange <= 1) return 1;
    return daysInRange;
  };

  useEffect(() => {
    if (startDate && endDate) {
      agpInfo.send({
        params: {
          request: {
            patientId,
            startTime: dayjs(startDate).utc().toISOString(),
            endTime: dayjs(endDate).utc().toISOString(),
          }
        },
      });
    }
  }, [startDate, endDate]);

  const debouncedCGMFetch = useDebounce((viewDates: ViewDates) => {
    cgmReadingInfo.send({
      params: {
        request: {
          patientId,
          startTime: dayjs(viewDates?.viewStartDate).utc().toISOString(),
          endTime: dayjs(viewDates?.viewEndDate).utc().toISOString(),
          includeAvailableDates: true,
          includeRawData: true,
        }
      },
    });
  }, 250);

  useEffect(() => {
    if (!isAGPView && viewDates !== undefined) {
      // debounce to prevent from being called twice when AGP is not applicable
      debouncedCGMFetch(viewDates);
    }
  }, [viewDates?.viewStartDate, viewDates?.viewEndDate, isAGPView]);

  useEffect(() => {
    if (
      isAGPView
      && agpInfo.data?.code !== undefined
      && !isAGPApplicable
    ) {
      // initial view switch
      setView(CGMChartView.DAILY_VIEW);
    }
  }, [
    agpInfo.data?.code,
    isAGPApplicable,
    isAGPView
  ]);

  return (
    <>
      <div className="cgm-chart-wrapper white-card">
        <div className="flex jc-sb ai-c mb40 p10">
          <CGMChartViewSwitchComponent
            value={view}
            onChange={setView}
            isAGPDisabled={!isAGPApplicable}
          />
          <DateNavigatorComponent
            endDate={endDate}
            period={getDateNavPeriod()}
            onChange={handleSetViewDates}
            startDateRange={startDate}
            endDateRange={endDate}
            prevButton={renderDateNav(<LeftOutlined />)}
            nextButton={renderDateNav(<RightOutlined />)}
            dateFormat={['D_MMM', 'D_MMM']}
          />
          <TextComponent
            className={classNames({
              invisible: isAGPView || isOneDayRange
            })}
            color="gray2"
            size="small"
          >
            Each daily profile represents a midnight-to-midnight period.
          </TextComponent>
        </div>
        {
          isAGPView
            ? (
              <div className="p10">
                <LoadingOverlayComponent isLoading={agpData === undefined}>
                  {
                    isAGPApplicable && (
                      <AGPChartComponent
                        interval={agpData?.interval ?? undefined}
                        data={agpData?.percentile}
                        cgmPatientContext={agpPatientContext}
                      />
                    )
                  }
                </LoadingOverlayComponent>
              </div>
            ) : (
              <LoadingOverlayComponent isLoading={cgmReadingInfo?.data === undefined}>
                <CGMChartMainComponent
                  startDate={viewDates?.viewStartDate}
                  endDate={viewDates?.viewEndDate}
                  data={cgmData || []}
                  cgmPatientContext={cgmPatientContext}
                  availableDays={cgmAvailableDays}
                  isLoading={cgmReadingInfo.isLoading}
                  allowStartFromRight={daysInRange > CGM_DAYS_LIMIT}
                  showAsCompact={!isOneDayRange}
                />
              </LoadingOverlayComponent>
            )
        }
      </div>
      <div className="mt10 mb20">
        <MeasurementTimestampTextComponent />
      </div>
    </>
  );
};
