import { ComplianceAlertTypeEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export const ComplianceAlertTypeVisitDue = 'Visit Due';
export const ComplianceAlertTypeOthers = 'Others';

export interface ComplianceAlertTypeEnumComponentProps {
  value?: ComplianceAlertTypeEnum | string;
}

export const ComplianceAlertTypeEnumComponent = ({
  value,
}: ComplianceAlertTypeEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case ComplianceAlertTypeEnum.NEW_PATIENT_ENGAGEMENT_14_DAY:
        return '14d New Patient Engagement';
      case ComplianceAlertTypeEnum.NEW_PATIENT_ENGAGEMENT_1_DAY:
        return '1d New Patient Engagement';
      case ComplianceAlertTypeEnum.NEW_CGM_PATIENT_ENGAGEMENT_1_DAY:
        return 'New CGM Patient Engagement';
      case ComplianceAlertTypeEnum.INACTIVE_7_DAY:
      case ComplianceAlertTypeEnum.INACTIVE_14_DAY:
        return 'Inactive Engagement';
      case ComplianceAlertTypeEnum.A1C_DUE:
        return 'A1C Due';
      case ComplianceAlertTypeEnum.VISIT_DUE_INIT:
      case ComplianceAlertTypeEnum.VISIT_DUE_FOLLOW_UP:
      case ComplianceAlertTypeEnum.VISIT_DUE_TECH_ONBOARDING:
      case ComplianceAlertTypeEnum.VISIT_DUE_POST_MD:
      case ComplianceAlertTypeEnum.VISIT_OVERDUE:
        return 'Visit Due';
      case ComplianceAlertTypeEnum.INACTIVE_2_WEEK_LOW_RISK: return 'Low Risk 2 Week Inactive';
      case ComplianceAlertTypeEnum.LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG: return 'No call log (hub user)';
      case ComplianceAlertTypeEnum.LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS: return 'No provider notes in 6w';
      case ComplianceAlertTypeEnum.LEVEL_FOUR_A1C_OVERDUE: return 'A1C Overdue';
      case ComplianceAlertTypeEnum.LEVEL_FOUR_NO_PROVIDERNOTE_IN_FOUR_WEEKS: return 'No provider notes in 4w';
      case ComplianceAlertTypeEnum.LEVEL_THREE_20_DAYS_LOW_ACTIVITY: return '20d low activity';
      case ComplianceAlertTypeEnum.LEVEL_THREE_8_DAYS_LOW_ACTIVITY: return '8d low activity';
      case ComplianceAlertTypeEnum.LEVEL_THREE_BIRTHDAY: return 'Birthday';
      case ComplianceAlertTypeEnum.LEVEL_THREE_PATIENT_APP_ENGAGEMENT: return 'App patient engagement';
      case ComplianceAlertTypeEnum.LEVEL_THREE_PATIENT_ENGAGEMENT: return 'Patient engagement';
      case ComplianceAlertTypeEnum.LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT: return 'Non-App patient engagement';
      case ComplianceAlertTypeOthers: return ComplianceAlertTypeOthers;
      case ComplianceAlertTypeVisitDue: return ComplianceAlertTypeVisitDue;
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
