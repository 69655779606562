import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { last } from 'lodash';
import {
  CgmBrandEnum,
  CgmDeviceStatusEnum,
  useCgmGetPatientCgmDevice,
  useCgmMobileGetPatientCgmReading,
  usePatientGetPatientCgmDevice
} from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { CGMComplianceAlertContentComponent, CGMComplianceAlertContentComponentProps } from '../../component/AlertComponent/CGMComplianceAlertContentComponent';
import { CGMAlertStatus } from '../../component/AlertComponent/cgmAlertType';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface CGMComplianceAlertContainerProps
  extends CGMComplianceAlertContentComponentProps { }

export const CGMComplianceAlertContainer = ({
  alert,
  onLinkAccountClick
}: CGMComplianceAlertContainerProps) => {
  const patientId = alert?.memberId || '';
  const [
    cgmAlertStatus,
    setCgmAlertStatus
  ] = useState<CGMAlertStatus | undefined | null>();
  const deviceInfo = usePatientGetPatientCgmDevice({
    params: { memberId: patientId }
  });
  const linkingInfo = useCgmGetPatientCgmDevice({
    options: { sendOnMount: false }
  });
  const readingInfo = useCgmMobileGetPatientCgmReading({
    options: { sendOnMount: false }
  });

  const fetchCgmAlertStatus = useDebounce((
    brand?: CgmBrandEnum | null,
  ) => {
    if (brand === undefined) {
      return;
    }

    ApiRequestHelper.tryCatch(
      linkingInfo.send({
        params: { patientId, request: { brandIn: [brand as CgmBrandEnum] } }
      }),
      {
        success: '',
        error: '',
        onSuccess: (res) => {
          const linkStatus = last(res?.data);
          const { status, accountLinkedAt } = linkStatus || {};
          if (
            status !== CgmDeviceStatusEnum.LINK_SUCCESS
            || !accountLinkedAt
          ) {
            setCgmAlertStatus(CGMAlertStatus.MISSING_LINKING);
            return;
          }
          readingInfo.send({
            params: {
              request: {
                patientId,
                includeAvailableDates: true,
                startTime: dayjs(accountLinkedAt).toISOString(),
                endTime: dayjs().toISOString(),
              }
            }
          });
        }
      }
    );
  }, 250, []);

  const refetchStatus = useDebounce(
    () => deviceInfo.refetch(),
    1000,
    [fetchCgmAlertStatus]
  );

  useUpdateListener('CGM_DEVICE_UPDATED', refetchStatus);
  useUpdateListener('CGM_LINKING_UPDATED', refetchStatus);

  useEffect(() => {
    if (
      !patientId
      || !alert?.alertType
      || deviceInfo?.data?.data?.brand === undefined) {
      return;
    }
    fetchCgmAlertStatus(deviceInfo.data.data.brand);
  }, [deviceInfo?.data?.data]);

  useEffect(() => {
    if (readingInfo?.data?.data === undefined) {
      return;
    }
    const daysOfData = readingInfo?.data?.data?.availableDates?.length;
    if (daysOfData) {
      setCgmAlertStatus(CGMAlertStatus.JUST_ENGAGE);
    } else if (daysOfData === 0) {
      setCgmAlertStatus(CGMAlertStatus.MISSING_DATA);
    } else {
      setCgmAlertStatus(null);
    }
  }, [readingInfo?.data?.data]);

  return (
    <LoadingOverlayComponent
      isLoading={cgmAlertStatus === undefined}
    >
      <CGMComplianceAlertContentComponent
        alert={alert}
        value={cgmAlertStatus}
        onLinkAccountClick={onLinkAccountClick}
      />
    </LoadingOverlayComponent>
  );
};
