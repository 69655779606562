import { Row, Typography } from 'antd';
import { FC } from 'react';
import { useSocialSupportForm } from '../../hook/useSocialSupportForm';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { InfoComponent } from '../InfoComponent/InfoComponent';

const { Title } = Typography;
export interface DemographicSocialSupportComponentProps {
  socialSupport?: string;
  contactInfo?: string;
  otherProviders?: string;
  easeOfFoodAccess?: string;
}

export const DemographicSocialSupportComponent: FC<DemographicSocialSupportComponentProps> = ({
  socialSupport,
  contactInfo,
  otherProviders,
  easeOfFoodAccess,
}) => {
  const socialForm = useSocialSupportForm();

  return (
    <div className="DemographicContactInfoComponent flex gap3 fd-c">
      <Title level={5}>Social Support</Title>
      <Row align="bottom" gutter={7}>
        <InfoComponent
          name={socialForm.getLabel('communitySupport')}
          value={<DisplayOrEmptyComponent value={socialSupport} />}
          span={6}
        />
        <InfoComponent
          name={socialForm.getLabel('contactInfo')}
          value={<DisplayOrEmptyComponent value={contactInfo} />}
          span={6}
        />
        <InfoComponent
          name={socialForm.getLabel('otherProviders')}
          value={<DisplayOrEmptyComponent value={otherProviders} />}
          span={6}
        />
      </Row>
      <Row>
        <InfoComponent
          name={socialForm.getLabel('easeOfFoodAccess')}
          value={<DisplayOrEmptyComponent value={easeOfFoodAccess} />}
          span={20}
        />
      </Row>
    </div>
  );
};
