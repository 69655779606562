import { FreqEnumComponent } from '../../../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface OldLifestyleSmokingOrDrinkingComponentProps {
  quitYear?: string;
  value?: boolean;
  type?: 'smoke' | 'drink';
  activityFreq?: string;
  activityValue?: string;
  drinkChoice?: string;
  drinkQuantity?: string;
}

// ---
// WARNING: OLD UI for version view only, don't use this component
// ---
export const OldLifestyleSmokingOrDrinkingComponent = ({
  quitYear,
  value = false,
  type = 'smoke',
  activityFreq,
  activityValue,
  drinkChoice,
  drinkQuantity,
}: OldLifestyleSmokingOrDrinkingComponentProps) => (
  value
    ? (
      <div>
        Yes,
        {' '}
        {activityValue}
        {' times per '}
        {' '}
        <FreqEnumComponent value={activityFreq || ''} type="short" />
        {',  '}
        {
          type === 'drink' && (
            <>
              <DisplayOrEmptyComponent value={drinkQuantity} />
              {', '}
              <DisplayOrEmptyComponent value={drinkChoice} />
            </>
          )
        }
      </div>
    ) : (
      <div>
        No, Quit year:
        {' '}
        <DisplayOrEmptyComponent value={quitYear} />
      </div>
    )
);
