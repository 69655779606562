import { useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useCgmGoalForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    tbrLevel2: {
      name: 'tbrLevel2',
      label: ''
    },
    tbrLevel2UpperBound: {
      name: 'tbrLevel2UpperBound',
      label: ''
    },
    tbrLevel1: {
      name: 'tbrLevel1',
      label: ''
    },
    tbrLevel1LowerBound: {
      name: 'tbrLevel1LowerBound',
      label: ''
    },
    tbrLevel1UpperBound: {
      name: 'tbrLevel1UpperBound',
      label: ''
    },
    tir: {
      name: 'tir',
      label: ''
    },
    tirLowerBound: {
      name: 'tirLowerBound',
      label: ''
    },
    tirUpperBound: {
      name: 'tirUpperBound',
      label: ''
    },
    tarLevel1: {
      name: 'tarLevel1',
      label: ''
    },
    tarLevel1LowerBound: {
      name: 'tarLevel1LowerBound',
      label: ''
    },
    tarLevel1UpperBound: {
      name: 'tarLevel1UpperBound',
      label: ''
    },
    tarLevel2: {
      name: 'tarLevel2',
      label: ''
    },
    tarLevel2LowerBound: {
      name: 'tarLevel2LowerBound',
      label: ''
    },
    status: {
      name: 'status',
      label: ''
    },
    latestNote: {
      name: 'latestNote',
      label: '',
    },
    notes: {
      name: 'notes',
      label: '',
    },
    updatedTime: {
      name: 'updatedTime',
      label: ''
    }
  }, options);

  const response = useMemo(() => ({
    ...factory,
  }), [factory]);

  return response;
};
