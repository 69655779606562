import { ReactNode } from 'react';
import { MeasurementResultTypeEnum, VitalEnumType } from '../../../../../uc-api-sdk';
import { MessageDate } from '../../../../../services/CHSServices/types/data';
import { VitalsEnumTypeComponent } from '../../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import EmptyComponent from '../../../../../uiComponent/EmptyComponent/EmptyComponent';

import './MessageVitalSingleComponent.scss';

export interface MessageVitalSingleComponentProps {
  vitalName: MeasurementResultTypeEnum;
  measuredAt: MessageDate;
  text?: string;
  children: ReactNode;
}

export const MessageVitalSingleComponent = ({
  vitalName,
  measuredAt,
  text,
  children,
}: MessageVitalSingleComponentProps) => (
  <div className="message-vital-single message-item__message-content message-item__message-highlight">
    <div className="message-vital-single__title">
      <span>
        Single
        {' '}
        <VitalsEnumTypeComponent value={vitalName as unknown as VitalEnumType} />
        {' '}
        Data
      </span>
      <span>
        (
        {measuredAt || <EmptyComponent />}
        )
      </span>
    </div>
    <div className="message-vital-single__body flex mt10">
      <div className="message-vital-single__info">
        {children}
      </div>
      <div className="message-vital-single__text">
        {text}
      </div>
    </div>
  </div>
);
