import { map } from 'lodash';
import {
  FormListFieldData, Input
} from 'antd';
import { LifestyleAssessmentFormListComponent } from '../../LifestyleAssessmentFormListComponent';
import { TextComponent } from '../../../../../../uiComponent/TextComponent/TextComponent';
import FormItem from '../../../../../../uiComponent/FormItem/FormItem';
import { LifestyleAssessmentFreqFormItemComponent } from '../../../LifestyleAssessmentFreqFormItemComponent/LifestyleAssessmentFreqFormItemComponent';
import { FastFoodBeverageInputCardLayout } from './FastFoodBeverageInputCardLayout';
import { useFastFoodBeverageForm } from '../../../../hook/useILifestyleAssessmentForm/useFastFoodBeverageForm';

export const FastFoodInputCardComponent = () => {
  const formHook = useFastFoodBeverageForm();

  const renderItem = (
    field: FormListFieldData,
    remove: (idx: number) => void,
  ) => (
    <FastFoodBeverageInputCardLayout
      key={field.name}
      type={(
        <FormItem
          name={formHook.getFastFoodInputInfo('fastFoodType', field.name).name}
        >
          <Input
            placeholder="eg. Instant noodle"
          />
        </FormItem>
      )}
      frequency={(
        <LifestyleAssessmentFreqFormItemComponent
          freqName={formHook.getFastFoodInputInfo('fastFoodFreq', field.name).name}
          freqUnitName={formHook.getFastFoodInputInfo('fastFoodFreqUnit', field.name).name}
        />
      )}
      removeAction={() => remove(field.name)}
    />
  );

  return (
    <LifestyleAssessmentFormListComponent
      formListName={formHook.fastFoodInputForm.getMainFormItemName()}
    >
      {
        ({ fields, remove }) => (
          <>
            <FastFoodBeverageInputCardLayout
              type={(
                <TextComponent size="small">
                  {formHook.fastFoodInputForm.getLabel('fastFoodType')}
                </TextComponent>
              )}
              frequency={(
                <TextComponent size="small">
                  {formHook.fastFoodInputForm.getLabel('fastFoodFreq')}
                </TextComponent>
              )}
            />
            {map(fields, (field) => renderItem(field, remove))}
          </>
        )
      }
    </LifestyleAssessmentFormListComponent>
  );
};
