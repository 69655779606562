import dayjs from 'dayjs';
import { Checkbox, Divider, Typography } from 'antd';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { getSnoozeIntervalDate } from '../../constant/constants';
import './AlertItemComponent.scss';
import { ComplianceAlertTypeEnumComponent } from '../../../../enumComponent/SmartAlertTypeEnumComponent/SmartAlertTypeEnumComponent';
import { PriorityLevelEnum, ComplianceAlert, ComplianceAlertTypeEnum } from '../../../../uc-api-sdk';
import { AlertSnoozeButtonComponent } from './AlertSnoozeButtonComponent';
import { DueInComponent } from '../../../../uiComponent/DueInComponent/DueInComponent';
import { PatientProfileTabEnum, usePatientPageControllerContext } from '../../../patient/context/PatientPageControllerContext/PatientPageControllerContext';
import { CGMComplianceAlertContainer } from '../../container/AlertContainer/CGMComplianceAlertContainer';

const { Text } = Typography;

export interface AlertItemComponentProps {
  data?: ComplianceAlert;
  showCheckbox: boolean;
  type?: 'snooze' | 'compliance' | 'medical';
}

export const AlertItemComponent = ({
  data,
  showCheckbox = true,
  type,
}: AlertItemComponentProps) => {
  const { onTabChange } = usePatientPageControllerContext();

  const titleColor = () => {
    switch (data?.priority) {
      case PriorityLevelEnum.HIGH:
        return 'error';
      case PriorityLevelEnum.MEDIUM:
        return 'warning';
      case PriorityLevelEnum.LOW:
        return 'success';
      default:
        return 'success';
    }
  };
  const title = () => (
    <TagComponent
      type={titleColor()}
      iconType="solidDot"
      background="none"
      iconSize="middle"
    >
      <Text className="b5">
        <ComplianceAlertTypeEnumComponent
          value={data?.alertType ?? undefined}
        />
      </Text>
    </TagComponent>
  );

  const description = () => {
    if (data?.alertType === ComplianceAlertTypeEnum.NEW_PATIENT_ENGAGEMENT_14_DAY) {
      if (data?.reasonDetail) {
        const content = `Patient was enrolled ${dayjs().diff(data.reasonDetail.patientEnrolledDate, 'day')} days ago. 
        Days with no measurement: ${dayjs().diff(data.reasonDetail.lastMeasurementDate, 'day')} days since enrollment.`;
        if (data.reasonDetail.isAppUser && data.reasonDetail.isAppUser === true) {
          return content;
        }
        return `${content} This patient is a non-app user.`;
      }
    } else if (data?.alertType === ComplianceAlertTypeEnum.A1C_DUE
      && data?.a1cTestWindow?.startTime
      && data?.a1cTestWindow?.endTime) {
      return (
        <>
          {data?.reason}
          {' '}
          <DisplayDateComponent value={data?.a1cTestWindow?.startTime} format="USA_DATE" />
          -
          <DisplayDateComponent value={data?.a1cTestWindow?.endTime} format="USA_DATE" />
          .
        </>
      );
    }
    if (data?.alertType === ComplianceAlertTypeEnum.NEW_CGM_PATIENT_ENGAGEMENT_1_DAY) {
      return (
        <CGMComplianceAlertContainer
          alert={data}
          onLinkAccountClick={() => onTabChange?.(PatientProfileTabEnum.Device)}
        />
      );
    }
    return data?.reason;
  };

  return (
    <div className="mb20" key={data?.id}>
      <div>
        {showCheckbox ? (
          <Checkbox value={data} key={data?.id}>
            {title()}
          </Checkbox>
        )
          : (
            <div className="snooze-title">
              {title()}
            </div>
          )}
      </div>
      {type !== 'snooze' && (
        <Text type="secondary">
          <DisplayDateComponent value={data?.createdAt} format="DATE_COMMA_TIME_AM_PM" fallbackText={<DisplayOrEmptyComponent />} />
          <Divider type="vertical" />
          <DueInComponent dueDate={data?.dueDate ?? undefined} />
        </Text>
      )}
      <div>
        <DisplayOrEmptyComponent value={description()} />
      </div>
      {type === 'snooze' && (
        <>
          <Text type="secondary">
            Snoozed until
            {' '}
            <DisplayDateComponent
              value={getSnoozeIntervalDate(data?.snoozeInterval ?? '', data?.snoozedDate ?? undefined)}
              format="USA_DATE"
            />
          </Text>
          {data && (
            <AlertSnoozeButtonComponent
              type="link"
              title="Edit"
              data={[data]}
            />
          )}
        </>
      )}
    </div>
  );
};
