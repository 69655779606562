import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import { useIterableFormHook } from '../../../../hooks/useIterableFormListHook/useIterableFormHook';

export interface SubmitValue {
  a: 1;
}

export enum fastFoodBeverageObjectName {
  fastFood = 'fastFood',
  beverage = 'beverage',
}

export const useFastFoodBeverageForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    isEatingFastFood: {
      name: 'isEatingFastFood',
      label: 'Does the patient eat fast food?'
    },
  }, options);

  const fastFoodInputForm = useIterableFormHook('fastFoodFreq', {
    fastFoodType: {
      name: 'type',
      label: 'Type'
    },
    fastFoodFreq: {
      name: 'value',
      label: 'Frequency',
    },
    fastFoodFreqUnit: {
      name: 'unit',
      label: '',
    }
  }, options);

  const beverageInputForm = useIterableFormHook('beverageFreq', {
    beverageType: {
      name: 'type',
      label: 'Type'
    },
    beverageFreq: {
      name: 'value',
      label: 'Frequency',
    },
    beverageFreqUnit: {
      name: 'unit',
      label: '',
    }
  }, options);

  const getFastFoodInputInfo = (
    key: keyof typeof fastFoodInputForm.formInput,
    field: number
  ) => ({
    name: fastFoodInputForm.makeListNamePath(key, field),
    label: fastFoodInputForm.getLabel(key) as string
  });

  const getBeverageInputInfo = (
    key: keyof typeof beverageInputForm.formInput,
    field: number
  ) => ({
    name: beverageInputForm.makeListNamePath(key, field),
    label: beverageInputForm.getLabel(key) as string
  });

  return useGetContextValue({
    ...factory,
    fastFoodInputForm,
    beverageInputForm,
    getFastFoodInputInfo,
    getBeverageInputInfo,
  });
};
