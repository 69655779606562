import { InsuranceEligibilityStatusEnumComponent } from '../../../../enumComponent/EligibilityStatusEnumComponent/EligibilityStatusEnumComponent';
import { InsuranceRelationshipToInsuredEnumComponent } from '../../../../enumComponent/InsuranceRelationshipToInsuredEnumComponent/InsuranceRelationshipToInsuredEnumComponent';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import {
  BenefitOrderEnum,
  EligibilityStatusEnum,
  InsuranceRelationshipEnum,
  InsuranceTypeEnum,
  PriorAuthCase
} from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { makeInsuranceSelectedValue } from '../../container/WaystarProviderSelectContainer/WaystarProviderSelectContainer';
import { useInsuranceFormContext } from '../../context/InsuranceFormContext';
import { PriorAuthFormItemLayoutComponent } from '../PriorAuthFormItemComponent/PriorAuthFormItemLayoutComponent';
import { PriorAuthHistoryComponent } from '../PriorAuthHistoryComponent/PriorAuthHistoryComponent';
import { PriorAuthCheckboxComponent } from '../PriorAuthLayout/PriorAuthCheckboxComponent';
import { PriorAuthViewCardComponent } from '../PriorAuthLayout/PriorAuthViewCardComponent';
import { InsuranceEligibilityStatusComponent, InsurancePolicyNumberComponent, InsuranceRelationshipToInsuredComponent } from './InsuranceCardLayoutComponent';
import { InsuranceCardTitleComponent, InsuranceCardTitleComponentProps } from './InsuranceCardTitleComponent';

export interface InsuranceCardContentComponentProps {
  provider: string;
  providerAlias?: string;
  insuranceType?: InsuranceTypeEnum[];
  insuranceProviderId?: string;
  benefitOrder: BenefitOrderEnum;
  policyNumber?: string;
  eligibilityStatus?: EligibilityStatusEnum;
  wayStarInquiryId?: string;
  patientRelationship?: InsuranceRelationshipEnum;
  priorAuth?: boolean;
  priorAuthActiveCase?: PriorAuthCase;
  priorAuthHistoryCases?: PriorAuthCase[];
  isEditable?: boolean;
  isDeletable?: boolean;
  onEdit?: InsuranceCardTitleComponentProps['onEdit'];
  onDelete?: InsuranceCardTitleComponentProps['onEdit'];
}

export const InsuranceCardContentComponent = ({
  provider,
  providerAlias,
  insuranceType,
  insuranceProviderId,
  benefitOrder,
  policyNumber,
  eligibilityStatus,
  wayStarInquiryId,
  patientRelationship,
  priorAuth,
  priorAuthActiveCase,
  priorAuthHistoryCases,
  isEditable = true,
  isDeletable = true,
  onEdit,
  onDelete,
}: InsuranceCardContentComponentProps) => {
  const {
    shouldShowPA,
  } = useInsuranceFormContext();

  const insurance = useGetContextValue({
    provider,
    providerAlias,
    insuranceType,
    insuranceProviderId,
    benefitOrder,
    policyNumber,
    patientRelationship,
    eligibilityStatus,
    wayStarInquiryId
  });

  const renderPriorAuth = () => (
    <div className="insurance-collapsible-item">
      <PriorAuthFormItemLayoutComponent>
        <div className="flex jc-sb">
          <PriorAuthCheckboxComponent
            value={priorAuth}
            disabled
          />
        </div>
        <div className="prior-auth-card-wrapper mt10">
          {
            priorAuthActiveCase
            && (
              <PriorAuthViewCardComponent
                priorAuth={priorAuthActiveCase}
              />
            )
          }
          <PriorAuthHistoryComponent
            priorAuthCases={priorAuthHistoryCases}
          />
        </div>
      </PriorAuthFormItemLayoutComponent>
    </div>
  );

  const renderIncomplete = () => (
    <span className="danger">Incomplete</span>
  );
  return (
    <div>
      <InsuranceCardTitleComponent
        provider={makeInsuranceSelectedValue(provider, providerAlias, !!providerAlias)}
        benefitOrder={benefitOrder}
        isEditable={isEditable}
        isDeletable={isDeletable}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      <div className="flex gap3 mt20">
        <InsurancePolicyNumberComponent>
          <DisplayOrEmptyComponent
            value={policyNumber}
            emptyText={renderIncomplete()}
          />
        </InsurancePolicyNumberComponent>
        <InsuranceRelationshipToInsuredComponent>
          {
            patientRelationship
              ? (
                <InsuranceRelationshipToInsuredEnumComponent
                  value={patientRelationship}
                />
              ) : <DisplayOrEmptyComponent emptyText={renderIncomplete()} />
          }
        </InsuranceRelationshipToInsuredComponent>
        <InsuranceEligibilityStatusComponent
          insurance={insurance}
        >
          {
            eligibilityStatus
              ? (
                <InsuranceEligibilityStatusEnumComponent
                  value={eligibilityStatus}
                />
              ) : <DisplayOrEmptyComponent emptyText={renderIncomplete()} />
          }
        </InsuranceEligibilityStatusComponent>
      </div>
      <div className="mt20">
        {shouldShowPA(benefitOrder) && renderPriorAuth()}
      </div>
    </div>
  );
};
