import { last } from 'lodash';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext/LoggedInUserContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  AddOnServiceEnum,
  CgmMetricEnum,
  CgmPercentGoal,
  CgmThresholdRangeClass, usePatientUpdateCgmGoal
} from '../../../../uc-api-sdk';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { CgmHelper } from '../../../vitals/helper/CgmHelper';
import { CgmGoalCardComponent } from '../../component/CgmGoalCardComponent/CgmGoalCardComponent';
import { CgmGoalFormValue } from '../../component/CgmGoalFormComponent/CgmGoalFormComponent';
import { InterventionContainerProps } from '../../type';
import { CgmGoalFormContainer } from './CgmGoalFormContainer';

export interface CgmGoalContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps<CgmGoalFormValue> {
  includeNote?: boolean;
  showUpdateInfo?: boolean;
  showTitle?: boolean;
}

export const CgmGoalContainer = ({
  showEdit,
  onEdit,
  isEditing,
  onSubmit,
  onError,
  onCancel,
  patientId,
  onValuesChange,
  formButtons,
  includeNote,
  showUpdateInfo,
  showTitle
}: CgmGoalContainerProps) => {
  const { send } = useMixpanelContext();
  const { userInfo } = useLoggedInUserFromContext();
  const updateCgmGoalHook = usePatientUpdateCgmGoal({});
  const { info } = usePatientContext();
  const { cgmService } = info || {};
  const data = { ...cgmService?.getCgmGoal() };
  useUpdateListener('CGM_GOAL_UPDATED', () => info?.refetch(['cgmGoalRefetch']));
  const refetchCgmGoal = useUpdate('CGM_GOAL_UPDATED'); // for refetching in other places

  const handleCancel = () => {
    onCancel?.();
  };

  const handleOnEdit = () => {
    onEdit?.();
  };

  const getThresholdValueHelper = (metric: CgmMetricEnum, isLowerBound?: boolean) => {
    const percentGoal = (data?.percentGoals)?.find(
      (i: CgmPercentGoal | {
        metric: CgmMetricEnum; thresholdRange: CgmThresholdRangeClass; goal: string
      }) => {
        if ('metric' in i && i.metric !== undefined) {
          return i.metric === metric;
        }
        return false;
      }
    );
    if (!percentGoal) return undefined;

    if (isLowerBound === undefined) {
      return Number(percentGoal.goal);
    }
    if (isLowerBound) {
      return Number(percentGoal?.thresholdRange?.lowerBound);
    }
    return Number(percentGoal?.thresholdRange?.upperBound);
  };

  const renderInitFormValue = () => {
    const initFormValue: CgmGoalFormValue = {
      tbrLevel2: getThresholdValueHelper(CgmMetricEnum.TBR_LEVEL_2) || 1,
      tbrLevel2UpperBound: getThresholdValueHelper(CgmMetricEnum.TBR_LEVEL_2, false) || 53,
      tbrLevel1: getThresholdValueHelper(CgmMetricEnum.TBR_LEVEL_1) || 4,
      tbrLevel1LowerBound: getThresholdValueHelper(CgmMetricEnum.TBR_LEVEL_1, true) || 54,
      tbrLevel1UpperBound: getThresholdValueHelper(CgmMetricEnum.TBR_LEVEL_1, false) || 69,
      tir: getThresholdValueHelper(CgmMetricEnum.TIR) || 70,
      tirLowerBound: getThresholdValueHelper(CgmMetricEnum.TIR, true) || 70,
      tirUpperBound: getThresholdValueHelper(CgmMetricEnum.TIR, false) || 179,
      tarLevel1: getThresholdValueHelper(CgmMetricEnum.TAR_LEVEL_1) || 25,
      tarLevel1LowerBound: getThresholdValueHelper(CgmMetricEnum.TAR_LEVEL_1, true) || 180,
      tarLevel1UpperBound: getThresholdValueHelper(CgmMetricEnum.TAR_LEVEL_1, false) || 249,
      tarLevel2: getThresholdValueHelper(CgmMetricEnum.TAR_LEVEL_2) || 5,
      tarLevel2LowerBound: getThresholdValueHelper(CgmMetricEnum.TAR_LEVEL_2, true) || 250,
      status: data?.goalStatus || undefined,
      latestNote: last(data?.notes || [])?.note || undefined,
      notes: data?.notes || [],
      updatedTime: data?.updatedAt || undefined,
    };
    return initFormValue;
  };

  const buildPercentGoals = (metricName: CgmMetricEnum, goal: number) => {
    if (data && data.percentGoals) {
      const target = data?.percentGoals.find(i => i.metric === metricName);
      if (target) {
        target.goal = goal;
        return target;
      }
    }
    return { metric: metricName, goal };
  };

  const buildCgmGoalRequest = (formValue: CgmGoalFormValue) => {
    const goals = [
      {
        metric: CgmMetricEnum.TBR_LEVEL_2,
        value: formValue?.tbrLevel2
          || CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TBR_LEVEL_2)
      },
      {
        metric: CgmMetricEnum.TBR_LEVEL_1,
        value: formValue?.tbrLevel1
          || CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TBR_LEVEL_1)
      },
      {
        metric: CgmMetricEnum.TIR,
        value: formValue?.tir
          || CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TIR)
      },
      {
        metric: CgmMetricEnum.TAR_LEVEL_1,
        value: formValue?.tarLevel1
          || CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TAR_LEVEL_1)
      },
      {
        metric: CgmMetricEnum.TAR_LEVEL_2,
        value: formValue?.tarLevel2
          || CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TAR_LEVEL_2)
      },
    ];
    const percentGoals = goals.map(g => buildPercentGoals(g.metric, g.value));
    const requestData = { ...data, percentGoals };
    requestData.notes = [{ note: formValue.latestNote ?? '' }];
    requestData.goalStatus = formValue.status;
    requestData.updatedBy = userInfo?.id;
    return requestData;
  };

  const handleSubmit = (formValue: CgmGoalFormValue) => {
    const cgmGoalReq = buildCgmGoalRequest(formValue);
    if (cgmGoalReq) {
      ApiRequestHelper.tryCatch(
        updateCgmGoalHook.send({
          params: {
            request: cgmGoalReq,
            memberId: patientId
          },
        }),
        {
          success: 'Updated CGM goals!',
          error: 'Failed to update CGM goals!',
          onSuccess: () => {
            onSubmit?.();
            refetchCgmGoal.updateValue();
            send({ event: MixpanelEvents.InterventionCgmGoalUpdate, patientId });
          },
          onError,
        }
      );
    } else {
      onSubmit?.();
    }
  };

  if (!info?.enrolledProgram?.additionalServices?.some(
    v => v.service === AddOnServiceEnum.CGM
  )) {
    return null;
  }

  return (
    <PatientCommonCardComponent
      title="CGM Goal"
      showHeaderOutside={false}
      isEditing={isEditing}
      showEdit={showEdit}
      onEdit={handleOnEdit}
      updateInfo={data}
      showTitle={showTitle}
      showUpdateInfo={showUpdateInfo}
      content={(
        <CgmGoalCardComponent
          cgmGoal={renderInitFormValue()}
        />
      )}
      formContent={(
        <CgmGoalFormContainer
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isLoading={info.isLoading}
          initialValues={renderInitFormValue()}
          onValuesChange={onValuesChange}
          formButtons={formButtons}
          includeNote={includeNote}
        />
      )}
    />
  );
};
