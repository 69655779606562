import { Button } from 'antd';
import React from 'react';
import useBoolean from '../../../../../hooks/useBoolean/useBoolean';
import { CgmReportProps } from '../../type';
import './CgmReportDownloadButton.scss';

const CgmReportPreviewComponent = React.lazy(() => import('../CgmReportPreviewComponent/CgmReportPreviewComponent'));

export interface CgmReportDownloadButtonProps extends CgmReportProps {
  buttonText?: string;
}

export const CgmReportDownloadButton = ({
  buttonText = 'Download CGM Report',
  ...props
}: CgmReportDownloadButtonProps) => {
  const { value: print, setTrue, setFalse } = useBoolean(false);

  const renderReport = () => {
    if (!print) {
      return null;
    }

    return (
      <div className="display-none">
        <CgmReportPreviewComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          print={print}
          onPrint={setFalse}
        />
      </div>
    );
  };

  return (
    <div className="CgmReportDownloadButton">
      <Button
        onClick={setTrue}
        loading={print}
        type="link"
        disabled={print}
      >
        {buttonText}
      </Button>
      {renderReport()}
    </div>
  );
};
