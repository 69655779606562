import React from 'react';
import { Space } from 'antd';
import { Icons } from '../../../../icons/Icons';
import { LastMeasurementResponse, VitalEnumType } from '../../../../uc-api-sdk';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { LastMeasurementComponent, LastMeasurementDiffDays } from '../../../vitals/component/LastMeasurementComponent/LastMeasurementComponent';
import './PatientVitalListComponent.scss';

export type PatientVital = VitalEnumType | null | undefined | {
  type: VitalEnumType;
  disabled?: boolean;
}

interface PatientVitalListComponentProps {
  value?: PatientVital[];
  lastMeasurementDate?: LastMeasurementResponse[];
}

export const PatientVitalListComponent = ({
  value = [],
  lastMeasurementDate,
}: PatientVitalListComponentProps) => (
  <Space wrap size={0}>
    {value.map((vital, index) => {
      let disabled = typeof vital === 'object' && vital?.disabled;
      const type = typeof vital === 'string' ? vital : vital?.type;

      let xDaysAgo: number | undefined;
      const latestMeasurementDate = lastMeasurementDate?.find((item) => item.type === type)?.date;
      if (latestMeasurementDate) {
        xDaysAgo = LastMeasurementDiffDays(
          TimezoneService.calcDateTimeDayjs(latestMeasurementDate)
        );
      }
      // latestMeasurementDate = dayjs().add(-1, 'day').toISOString();
      // Check if the last measurement date is within 7 days
      if (xDaysAgo === undefined || (xDaysAgo && xDaysAgo >= 7)) {
        disabled = true;
      }
      const i = index.toString();
      let icon = null;
      switch (type) {
        case VitalEnumType.BG:
          icon = <Icons.VitalsBG disabled={disabled} asSVG={disabled} key={i} />;
          break;
        case VitalEnumType.BP:
          icon = <Icons.VitalsBP disabled={disabled} asSVG={disabled} key={i} />;
          break;
        case VitalEnumType.PO:
          icon = <Icons.VitalsPO disabled={disabled} asSVG={disabled} key={i} />;
          break;
        case VitalEnumType.TM:
          icon = <Icons.VitalsTM disabled={disabled} asSVG={disabled} key={i} />;
          break;
        case VitalEnumType.HS:
          icon = <Icons.VitalsHS disabled={disabled} asSVG={disabled} key={i} />;
          break;
        case VitalEnumType.CGM:
          icon = <Icons.CgmIcon key={i} />;
          break;
        default:
          return null;
      }

      const getTooltipTitle = () => {
        if (type === VitalEnumType.CGM) {
          return (
            <div>
              <h4 className="text-white mb0">
                Continuous Glucose (CGM)
              </h4>
            </div>
          );
        }

        return (
          <LastMeasurementComponent
            fontColor="font-color"
            showWarning={false}
            showNoMeasurement
            value={
              latestMeasurementDate
                ? TimezoneService.calcDateTimeDayjs(latestMeasurementDate) : undefined
              }
          />
        );
      };

      return (
        <TooltipComponent
          title={getTooltipTitle()}
          key={i}
          type={null}
        >
          {icon}
        </TooltipComponent>
      );
    })}
  </Space>
);
