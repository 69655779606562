import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { WorklistStepEnumComponent } from '../../../../enumComponent/WorklistStepEnumComponent/WorklistStepEnumComponent';
import { FixedChildComponent } from '../../../../uiComponent/FixedComponent/FixedChildComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import {
  ClinicEventsService
} from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { FollowUpScheduleComponent } from '../FollowUpScheduleComponent/FollowUpScheduleComponent';
import { GoalEducationComponent } from '../GoalEducationComponent/GoalEducationComponent';
import { HealthRecordUpdateComponent } from '../HealthRecordUpdateComponent/HealthRecordUpdateComponent';
import { VitalsDataReviewComponent } from '../VitalsDataReviewComponent/VitalsDataReviewComponent';
import { WorklistDiseaseHistoryComponent } from '../WorklistDiseaseHistoryComponent/WorklistDiseaseHistoryComponent';
import { WorklistStepEnum } from '../WorklistDrawerComponent/WorklistDrawerComponent';
import { WorklistLifestyleAssessmentComponent } from '../WorklistLifestyleAssessmentComponent/WorklistLifestyleAssessmentComponent';
import { CGMOptOut } from '../../../../uc-api-sdk';

export interface WorklistSectionComponentProps {
  step: WorklistStepEnum;
  clinicEvent?: ClinicEventsService | null;
  followupVisitSkipped?: boolean;
  isEligibleForCGMReview?: boolean;
  cgmOptOut?: CGMOptOut;
}

export const WorklistSectionComponent = ({
  step,
  clinicEvent,
  followupVisitSkipped,
  isEligibleForCGMReview,
  cgmOptOut,
}: WorklistSectionComponentProps) => {
  const {
    info: {
      id,
      patientInfoService,
      refetch
    } = {},
  } = usePatientContext();
  if (!id || patientInfoService === undefined) return null;

  const renderSection = () => {
    switch (step) {
      case WorklistStepEnum.VITALS_DATA_REVIEW:
        return (
          <VitalsDataReviewComponent
            isEligibleForCGMReview={isEligibleForCGMReview}
            patientId={id}
          />
        );
      case WorklistStepEnum.HEALTH_RECORDS_UPDATES:
        return (
          <HealthRecordUpdateComponent
            patientId={id}
            patientInfoService={patientInfoService}
            onSubmit={refetch}
          />
        );
      case WorklistStepEnum.DISEASE_HISTORY_REVIEW:
        return (
          <WorklistDiseaseHistoryComponent
            patientId={id}
          />
        );
      case WorklistStepEnum.LIFESTYLE_ASSESSMENT:
        return (
          <WorklistLifestyleAssessmentComponent
            patientId={id}
            patientInfoService={patientInfoService}
            onSubmit={refetch}
          />
        );
      case WorklistStepEnum.GOALS_EDUCATION_SETUP:
        return (
          <GoalEducationComponent
            patientId={id}
            clinicId={patientInfoService?.getClinicId()}
            isEligibleForCGMReview={isEligibleForCGMReview}
          />
        );
      case WorklistStepEnum.FOLLOW_UP_SCHEDULE:
        return (
          <FollowUpScheduleComponent
            clinicEvent={clinicEvent}
            followupVisitSkipped={followupVisitSkipped}
            cgmOptOut={cgmOptOut}
          />
        );
      default:
        return <div>{step}</div>;
    }
  };

  return (
    <FixedComponent>
      <FixedChildComponent isFixed>
        <Title>
          <WorklistStepEnumComponent value={step} />
        </Title>
      </FixedChildComponent>
      <FixedChildComponent>
        {renderSection()}
      </FixedChildComponent>
    </FixedComponent>
  );
};
