import { toUpper } from 'lodash';
import { useDeepCompareMemo } from '../../../hooks/useDeepCompareEffect';
import {
  BgMealTypeEnum,
  FrequencyUnitEnum,
  Lifestyle,
  MealIntake,
  Nullable,
  Nutrition
} from '../../../uc-api-sdk';
import { VersionedLifestyleComponent } from './Lifestyle/VersionedLifestyleComponent';
import { OldNutrition } from './Nutrition/oldType';
import { VersionedNutritionComponent } from './Nutrition/VersionedNutritionComponent';
import { HistoryInfo } from '../../../uiComponent/HistoryComponent/HistoryItemComponent';
import { PatientCommonCardComponent } from '../../patient/component/PatientCardComponent/PatientCommonCardComponent';

interface IntakeTableProps extends MealIntake {
  key: string;
}

export interface VersionedLifestyleAndNutritionComponentProps {
  lifeStyle?: Lifestyle | null;
  nutrition?: Nutrition | null;
}

export const VersionedLifestyleAndNutritionComponent = ({
  lifeStyle,
  nutrition,
}: VersionedLifestyleAndNutritionComponentProps) => {
  const getInitialIntakes = (inputs: Nullable<MealIntake[]> | undefined) => {
    const res: OldNutrition = {
      additionalComments: undefined,
      fastFoodFreq: undefined,
      fastFoodFreqUnit: undefined,
      intake: [],
      nutritionUnderstanding: undefined,
      sweetBeverageFreq: undefined,
      sweetBeverageFreqUnit: undefined,
    };
    let extraObj = {};
    const intake: IntakeTableProps[] = [];
    [
      BgMealTypeEnum.BREAKFAST,
      BgMealTypeEnum.LUNCH,
      BgMealTypeEnum.DINNER,
      BgMealTypeEnum.SNACK,
    ].forEach((meal, index) => {
      const targetMeal = inputs?.find(it => meal === toUpper(it?.meal || ''));
      if (targetMeal) {
        intake.push({
          key: `${index}`,
          meal,
          timeRange: targetMeal.timeRange,
          foodTypeAmount: targetMeal.foodTypeAmount,
          mealFreq: targetMeal.mealFreq,
          skipFreq: targetMeal.skipFreq,
        });
        extraObj = { ...extraObj, [`${meal}_timeRange`]: targetMeal.timeRange };
        extraObj = { ...extraObj, [`${meal}_foodTypeAmount`]: targetMeal.foodTypeAmount };
        extraObj = { ...extraObj, [`${meal}_mealFreq`]: targetMeal.mealFreq };
        extraObj = { ...extraObj, [`${meal}_skipFreq`]: targetMeal.skipFreq };
      } else {
        intake.push({
          key: `${index}`,
          meal,
          timeRange: '',
          foodTypeAmount: '',
          mealFreq: '',
          skipFreq: '',
        });
      }
    });
    res.intake = intake;
    return { ...res, ...extraObj };
  };

  const nutritionHistory = useDeepCompareMemo<HistoryInfo[] | undefined>(
    () => nutrition?.notes?.map(note => ({
      content: note.note,
      date: note.createdAt,
    })).reverse(),
    [nutrition],
  );

  const parsedNutrition = useDeepCompareMemo(() => ({
    ...getInitialIntakes(nutrition?.intake),
    previousDiets: nutrition?.previousDiets
      ? nutrition?.previousDiets : [],
    fastFoodFreq: nutrition?.fastFoodFreq?.value
      ? nutrition?.fastFoodFreq?.value : 0,
    fastFoodFreqUnit: nutrition?.fastFoodFreq?.unit
      ? nutrition?.fastFoodFreq?.unit : FrequencyUnitEnum.WEEKLY,
    sweetBeverageFreq: nutrition?.sweetBeverageFreq?.value
      ? nutrition?.sweetBeverageFreq?.value : 0,
    sweetBeverageFreqUnit: nutrition?.sweetBeverageFreq?.unit
      ? nutrition?.sweetBeverageFreq?.unit : FrequencyUnitEnum.WEEKLY,
    nutritionUnderstanding: nutrition?.nutritionUnderstanding
      ? nutrition?.nutritionUnderstanding : undefined,
    additionalComments: nutrition?.additionalComments
      ? nutrition?.additionalComments : undefined,
  }), [nutrition]);

  return (
    <>
      <PatientCommonCardComponent
        title="Lifestyle"
        updateInfo={lifeStyle}
        showEdit={false}
        content={(
          <VersionedLifestyleComponent
            lifeStyle={lifeStyle}
          />
        )}
        formContent={null}
      />

      <div className="mt40" />

      <PatientCommonCardComponent
        title="Nutrition"
        updateInfo={nutrition}
        showEdit={false}
        content={(
          <VersionedNutritionComponent
            nutrition={parsedNutrition}
            history={nutritionHistory}
          />
        )}
        formContent={null}
      />
    </>
  );
};
