import { FilterTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Popover } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { FC, useMemo } from 'react';
import { CareNoteTagEnumComponent } from '../../../../enumComponent/CareNoteTagEnumComponent/CareNoteTagEnumComponent';
import { CareNoteTagEnum } from '../../../../uc-api-sdk';

export interface CareNoteTagFilterComponentProps {
  onChange: CheckboxGroupProps['onChange'];
}

export const CareNoteTagFilterComponent: FC<CareNoteTagFilterComponentProps> = ({
  onChange,
}) => {
  const options = useMemo(() => (
    Object.keys(CareNoteTagEnum).map((k) => ({
      label: <CareNoteTagEnumComponent value={k as CareNoteTagEnum} />,
      value: k
    }))
  ), []);

  return (
    <Popover
      trigger="click"
      content={(
        <Checkbox.Group
          className="flex fd-c"
          options={options}
          onChange={onChange}
        />
      )}
      placement="bottom"
    >
      <Button type="link">
        <FilterTwoTone />
        {' '}
        Filter
      </Button>
    </Popover>
  );
};
