import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Image, Tooltip } from 'antd';
import classNames from 'classnames';
import { sum } from 'lodash';
import CgmBarChartItem from '../../../../assets/icons/cgmBarChartItem.svg';
import CgmBarChartLabelDm2 from '../../../../assets/icons/cgmBarChartLabelDm2.svg';
import CgmBarChartLabelPreDm from '../../../../assets/icons/cgmBarChartLabelPreDm.svg';
import { CgmMetricEnum, CgmPercentGoal } from '../../../../uc-api-sdk';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { CgmEmptySummaryComponent } from './CgmEmptySummaryComponent';
import { CGM_BAR_CHART_CONFIG } from '../../constant/cgmConstant';
import { CgmBarChartImageComponent } from '../CgmBarChartImageComponent/CgmBarChartImageComponent';
import './CgmVitalSummaryComponent.scss';

export interface CgmBarChartComponentProps {
  tbrLevel2Min?: number;
  tbrLevel1Min?: number;
  tirMin?: number;
  tarLevel1Min?: number;
  tarLevel2Min?: number;
  percentGoals?: CgmPercentGoal[];
  showGoals?: boolean;
}

export const CgmBarChartComponent = ({
  tbrLevel2Min,
  tbrLevel1Min,
  tirMin,
  tarLevel1Min,
  tarLevel2Min,
  percentGoals,
  showGoals = false
}: CgmBarChartComponentProps) => {
  const isDataValid = [tbrLevel2Min, tbrLevel1Min, tirMin, tarLevel1Min, tarLevel2Min].some(
    value => value !== undefined
  );

  const totalMin = sum([tbrLevel2Min, tbrLevel1Min, tirMin, tarLevel1Min, tarLevel2Min]);

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    if (hours > 0) {
      return `(${hours}h ${mins}min)`;
    }
    return `(${mins}min)`;
  };

  const renderLegend = (label: CgmMetricEnum, isTooltip = false) => {
    const config = CGM_BAR_CHART_CONFIG[label];
    if (!config) return null;

    const comparisonSymbol = label === CgmMetricEnum.TIR ? '≥' : '<';

    return (
      <div className={classNames({
        'CgmVitalSummaryComponent__legend-tooltip': isTooltip,
        CgmVitalSummaryComponent__legend: !isTooltip,
      })}
      >
        {!isTooltip && <TagComponent type={config.tagType} iconType="solidDot" background="none" />}
        <div className={classNames({
          'CgmVitalSummaryComponent__tir-title': !isTooltip && label === CgmMetricEnum.TIR,
          ml5: true,
          w100: true
        })}
        >
          {' '}
          {config.name}
          {' '}
          <span className={classNames({
            'CgmVitalSummaryComponent__tir-goal': !isTooltip && label === CgmMetricEnum.TIR
          })}
          >
            (
            {comparisonSymbol}
            <span>
              {config?.defaultGoal}
              %
            </span>
            )
          </span>
        </div>
      </div>
    );
  };

  const renderMetric = (value: number, isTir?: boolean) => {
    if (totalMin === undefined || totalMin === 0) {
      return 0;
    }
    const percentage = totalMin !== 0 ? (Math.round((value / totalMin) * 100)) : '0';
    return isDataValid && (
      <div className={`flex ${value === 0 ? 'text-gray-scale-3' : ''}`}>
        <div className={isTir ? 'CgmVitalSummaryComponent__tir-value' : 'b6'}>
          {percentage}
          %
        </div>
        <div className="text-gray-scale-2">
          {formatTime(value)}
        </div>
      </div>
    );
  };

  const renderCgmLegendGroup = (labelSrc: string) => (
    <div className="flex mx32">
      <div className="mt5 mx5">
        <Image src={labelSrc} preview={false} width={20} height={86} />
      </div>
      <div className="mt5 mr12">
        <Image src={CgmBarChartItem} preview={false} width={60} height={86} />
      </div>
      <div>
        {[
          { label: CgmMetricEnum.TAR_LEVEL_2 },
          { label: CgmMetricEnum.TAR_LEVEL_1 },
          { label: CgmMetricEnum.TIR },
          { label: CgmMetricEnum.TBR_LEVEL_1 },
          { label: CgmMetricEnum.TBR_LEVEL_2 },
        ].map(({ label }) => renderLegend(label, true))}
      </div>
    </div>
  );

  const cgmBarChartTitle = () => (
    <div className="flex ai-bl">
      <strong>Time In Range</strong>
      {!showGoals && (
        <Tooltip
          placement="top"
          overlayStyle={{ minWidth: '565px' }}
          title={(
            <>
              <div className="mb12">
                <span className="bold">TIR (Time In Range)</span>
                {' '}
                is the percentage of time an individual's glucose levels from a CGM device remain
                within a target range. It provides real-time insight into daily glucose fluctuations
                and complements traditional metrics like A1C by offering a more dynamic view of
                glucose control. Every 5% increase in TIR is clinically beneficial.
              </div>
              <span className="bold">CGM Default Target Ranges</span>
              <div className="flex my5">
                {renderCgmLegendGroup(CgmBarChartLabelDm2)}
                {renderCgmLegendGroup(CgmBarChartLabelPreDm)}
              </div>
              <div className="flex CgmVitalSummaryComponent__tooltip mb12">
                <div>DM1/DM2</div>
                <div>Pre-DM</div>
              </div>
            </>
          )}
        >
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </div>
  );

  const renderGoals = () => {
    if (!showGoals) {
      return null;
    }
    return (
      <div className="CgmGoalsComponent bg-gray5 px20 py10 default-border-radius w200">
        <TextComponent className="bold mb5">Targets</TextComponent>
        <div>
          Very High (&lt; 5%)
        </div>
        <div>
          High (&le; 25%)
        </div>
        <div>
          Target (&ge; 70%)
        </div>
        <div>
          Low (&le; 4%)
        </div>
        <div>
          Very Low (&le; 1%)
        </div>
      </div>
    );
  };

  return (
    <Card className="CgmBarChartComponent">
      <div className="flex cmg-bar-chart gap4">
        <div className="flex fd-c">
          {cgmBarChartTitle()}
          {!isDataValid ? (<CgmEmptySummaryComponent />) : (
            <div className="flex gap3">
              <CgmBarChartImageComponent
                tbrLevel2Min={tbrLevel2Min}
                tbrLevel1Min={tbrLevel1Min}
                tirMin={tirMin}
                tarLevel1Min={tarLevel1Min}
                tarLevel2Min={tarLevel2Min}
                percentGoals={percentGoals || undefined}
              />
              <div className="CgmVitalSummaryComponent__summary-box">
                <div className="flex">
                  {renderLegend(CgmMetricEnum.TAR_LEVEL_2)}
                  {renderMetric(tarLevel2Min || 0)}
                </div>
                <div className="flex">
                  {renderLegend(CgmMetricEnum.TAR_LEVEL_1)}
                  {renderMetric(tarLevel1Min || 0)}
                </div>
                <div className="flex">
                  {renderLegend(CgmMetricEnum.TIR)}
                  {renderMetric(tirMin || 0, true)}
                </div>
                <div className="flex">
                  {renderLegend(CgmMetricEnum.TBR_LEVEL_1)}
                  {renderMetric(tbrLevel1Min || 0)}
                </div>
                <div className="flex">
                  {renderLegend(CgmMetricEnum.TBR_LEVEL_2)}
                  {renderMetric(tbrLevel2Min || 0)}
                </div>
              </div>
            </div>
          )}
        </div>
        {renderGoals()}
      </div>
      {showGoals && <div className="text-gray-scale-2 mt10 fs1">Each 5% increase in time in range is clinically beneficial.</div>}
    </Card>
  );
};
