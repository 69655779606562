export enum MixpanelEvents {
  ClinicBilling = 'ClinicBilling',
  CrashEvent = 'CrashEvent',
  Unknown = 'Unknown',
  Home = 'Home',
  Patients = 'Patients',
  PatientCare = 'PatientCare',
  Tasks = 'Tasks',
  Utilities = 'Utilities',
  Insight = 'Insight',
  MedicalAlerts = 'MedicalAlerts',
  HeaderMessage = 'HeaderMessage',
  HeaderCalendar = 'HeaderCalendar',
  BigCalendar = 'BigCalendar',
  HeaderAWS = 'HeaderAWS',
  HeaderAWSOnline = 'HeaderAWSOnline',
  HeaderAWSOffline = 'HeaderAWSOffline',
  HeaderAWSControlPanel = 'HeaderAWSControlPanel',
  HomeTodoTask = 'HomeTodoTask',
  HomeTodoPatientCare = 'HomeTodoPatientCare',
  HomeTodoTranscribing = 'HomeTodoTranscribing',
  HomeTodoBillableTimeReview = 'HomeTodoBillableTimeReview',
  TasksAssignedToMe = 'TasksAssignedToMe',
  TasksCreatedByMe = 'TasksCreatedByMe',
  TasksMembersTasks = 'TasksMembersTasks',
  TasksCreateTaskButton = 'TasksCreateTaskButton',
  TasksCreateTaskSubmit = 'TasksCreateTaskSubmit',
  TasksOnCallCoverageToggleOn = 'TasksOnCallCoverageToggleOn',
  TasksOnCallCoverageToggleOff = 'TasksOnCallCoverageToggleOff',
  TasksResolveTask = 'TasksResolveTask',
  TasksResolveTaskStart = 'TasksResolveTaskStart',
  TasksResolveTaskEnd = 'TasksResolveTaskEnd',
  TasksCommentTask = 'TasksCommentTask',
  TasksEditTask = 'TasksEditTask',
  TasksReopenTask = 'TasksReopenTask',
  TasksDeleteTask = 'TasksDeleteTask',
  TasksCommentOrResolveStart = 'TasksCommentOrResolveStart',
  TasksCommentOrResolveEnd = 'TasksCommentOrResolveEnd',
  TasksCreateTaskStart = 'TasksCreateTaskStart',
  TasksCreateTaskEnd = 'TasksCreateTaskEnd',
  TasksEditTaskStart = 'TasksEditTaskStart',
  TasksEditTaskEnd = 'TasksEditTaskEnd',
  PatientCareStartWorkModeBtn = 'PatientCareStartWorkModeBtn',
  PatientCareClinicFilterChange = 'PatientCareClinicFilterChange',
  PatientCareWorkTypeFilterChange = 'PatientCareWorkTypeFilterChange',
  PatientCareOnCallCoverageToggleOn = 'PatientCareOnCallCoverageToggleOn',
  PatientCareOnCallCoverageToggleOff = 'PatientCareOnCallCoverageToggleOff',
  TranscribingStartA1CBtn = 'TranscribingStartA1CBtn',
  TranscribingStartVisitDueBtn = 'TranscribingStartVisitDueBtn',
  TranscribingAddA1C = 'TranscribingAddA1C',
  TranscribingComplete = 'TranscribingComplete',
  TranscribingSave = 'TranscribingSave',
  AddMedication = 'AddMedication',
  AddMedicationStart = 'AddMedicationStart',
  AddMedicationEnd = 'AddMedicationEnd',
  EditMedicationStart = 'EditMedicationStart',
  EditMedicationEnd = 'EditMedicationEnd',
  AddMedicationDrawerOpen = 'AddMedicationDrawerOpen',
  EditMedication = 'EditMedication',
  AddLabResultDrawerOpen = 'AddLabResultDrawerOpen',
  AddLabResult = 'AddLabResult',
  AddLabResultStart = 'AddLabResultStart',
  AddLabResultEnd = 'AddLabResultEnd',
  EditLabResult = 'EditLabResult',
  EditLabResultStart = 'EditLabResultStart',
  EditLabResultEnd = 'EditLabResultEnd',
  CreateVisit = 'CreateVisit',
  EditVisit = 'EditVisit',
  EditVisitParticipants = 'EditVisitParticipants',
  MedicalAlertResolve = 'MedicalAlertResolve',
  ComplianceAlertResolve = 'ComplianceAlertResolve',
  ComplianceAlertComment = 'ComplianceAlertComment',
  ComplianceAlertSnooze = 'ComplianceAlertSnooze',
  OutstandingInitialTranscribing = 'OutstandingInitialTranscribing',
  OutstandingFollowUpTranscribing = 'OutstandingFollowUpTranscribing',
  OutstandingA1CTranscribing = 'OutstandingA1CTranscribing',
  OutstandingMessages = 'OutstandingMessages',
  AddCareNote = 'AddCareNote',
  AddCareNoteStart = 'AddCareNoteStart',
  AddCareNoteEnd = 'AddCareNoteEnd',
  EditCareNoteStart = 'EditCareNoteStart',
  EditCareNoteEnd = 'EditCareNoteEnd',
  PatientDemographicsEditIcon = 'PatientDemographicsEditIcon',
  PatientVitalsMonitoringEditIcon = 'PatientVitalsMonitoringEditIcon',
  PatientProgramEditIcon = 'PatientProgramEditIcon',
  PatientInsuranceEditIcon = 'PatientInsuranceEditIcon',
  PatientHeaderCall = 'PatientHeaderCall',
  PatientHeaderChat = 'PatientHeaderChat',
  PatientHeaderPlusBtn = 'PatientHeaderPlusBtn',
  PatientHeaderCreateTask = 'PatientHeaderCreateTask',
  PatientHeaderPrintVital = 'PatientHeaderPrintVital',
  PatientHeaderCreateVisit = 'PatientHeaderCreateVisit',
  PatientHeaderPrintPhysicianReport = 'PatientHeaderPrintPhysicianReport',
  PatientHeaderCreateManualMTPR = 'PatientHeaderCreateManualMTPR',
  PatientHeaderBillableTag = 'PatientHeaderBillableTag',
  PatientHeaderMeasurementTag = 'PatientHeaderMeasurementTag',
  PatientEnrollmentDrawerOpen = 'PatientEnrollmentDrawerOpen',
  PatientEnroll = 'PatientEnroll',
  PatientConsentSubmit = 'PatientConsentSubmit',
  PatientEnrollmentSubmit = 'PatientEnrollmentSubmit',
  PatientEnrollmentDone = 'PatientEnrollmentDone',
  CreatePatientStart = 'CreatePatientStart',
  CreatePatientEnd = 'CreatePatientEnd',
  AssignDeviceSubmit = 'AssignDeviceSubmit',
  WaiveOnboarding = 'WaiveOnboarding',
  CreateOnboardingVisitDrawerOpen = 'CreateOnboardingVisitDrawerOpen',
  CreateInitialVisitDrawerOpen = 'CreateInitialVisitDrawerOpen',
  PatientCreateDrawerOpen = 'PatientCreateDrawerOpen',
  PatientCreate = 'PatientCreate',
  PatientFoodLogSubmitReview = 'PatientFoodLogSubmitReview',
  PatientFoodLogViewBtn = 'PatientFoodLogViewBtn',
  CareNoteTabSelect = 'CareNoteTabSelect',
  InterventionTabSelect = 'InterventionTabSelect',
  MedicalHistoryTabSelect = 'MedicalHistoryTabSelect',
  VisitTabSelect = 'VisitTabSelect',
  ProfileTabSelect = 'ProfileTabSelect',
  DeviceTabSelect = 'DeviceTabSelect',
  InterventionClinicalGoalEditIcon = 'InterventionClinicalGoalEditIcon',
  InterventionCgmGoalUpdate = 'InterventionCgmGoalUpdate',
  InterventionVitalsMonitoringGoalEditIcon = 'InterventionVitalsMonitoringGoalEditIcon',
  InterventionBehaviorGoalEditIcon = 'InterventionBehaviorGoalEditIcon',
  InterventionStageOfBehaviorChangeEditIcon = 'InterventionStageOfBehaviorChangeEditIcon',
  InterventionLifestyleAssessmentEditIcon = 'InterventionLifestyleAssessmentEditIcon',
  InterventionEducationSearch = 'InterventionEducationSearch',
  InterventionEducationAdd = 'InterventionEducationAdd',
  ICDCodeEditIcon = 'ICDCodeEditIcon',
  MedicationEditIcon = 'MedicationEditIcon',
  DiseaseHistoryEditIcon = 'DiseaseHistoryEditIcon',
  WorkVisitCheckInBtn = 'WorkVisitCheckInBtn',
  WorkVisitOpenVideoAndCheckInBtn = 'WorkVisitOpenVideoAndCheckInBtn',
  WorkVisitCheckOutBtn = 'WorkVisitCheckOutBtn',
  WorkVisitEndVideoAndCheckOutBtn = 'WorkVisitEndVideoAndCheckOutBtn',
  CallLogSubmit = 'CallLogSubmit',
  CallLogTopicDiscussedSelect = 'CallLogTopicDiscussedSelect',
  Visits = 'Visits',
  InsuranceEligibilityEffectConfirm = 'InsuranceEligibilityEffectConfirm',
  UserSessionStart = 'UserSessionStart',
  UserSessionEnd = 'UserSessionEnd',
  UserSessionActive = 'UserSessionActive',
  UserSessionInactive = 'UserSessionInactive',
  UserRefreshTokenStart = 'UserRefreshTokenStart',
  UserRefreshTokenEnd = 'UserRefreshTokenEnd',
  UserLogout = 'UserLogout',
  UserSessionInvalid = 'UserSessionInvalid',
  ChatStart = 'ChatStart',
  ChatEnd = 'ChatEnd',
  MTPRStart = 'MTPRStart',
  MTPREnd = 'MTPREnd',
  CGMReviewStart = 'CGMReviewStart',
  CGMReviewEnd = 'CGMReviewEnd',
  MedicalAlertsStart = 'MedicalAlertsStart',
  MedicalAlertsEnd = 'MedicalAlertsEnd',
  ComplianceAlertsStart = 'ComplianceAlertsStart',
  ComplianceAlertsEnd = 'ComplianceAlertsEnd',
  FoodLogStart = 'FoodLogStart',
  FoodLogEnd = 'FoodLogEnd',
  VisitTranscribingStart = 'VisitTranscribingStart',
  VisitTranscribingEnd = 'VisitTranscribingEnd',
  A1cTranscribingStart = 'A1cTranscribingStart',
  A1cTranscribingEnd = 'A1cTranscribingEnd',
  AddSymptom = 'AddSymptom',
  UpdateSymptom = 'UpdateSymptom',
  DeleteSymptom = 'DeleteSymptom',
  LifeStyleAssessmentStart = 'LifeStyleAssessmentStart',
  LifeStyleAssessmentEnd = 'LifeStyleAssessmentEnd',

  // Call Center
  StartACall = 'StartACall',
  CallCenterError = 'CallCenterError',
  CallCenterMetric = 'CallCenterMetric',

  // Notification Client events
  NotificationClientStatusChange = 'NotificationClientStatusChange',
  NotificationClientIsReady = 'NotificationClientIsReady',
  NotificationClientGetUnreadOnConnected = 'NotificationClientGetUnreadOnConnected',

  // Message
  MessageError = 'MessageError',
  MessageChannelCleanup = 'MessageChannelCleanup',
}

export enum MixpanelEventsParents {
  PatientCare = 'PatientCare',
  Tasks = 'Tasks',
  PatientProfile = 'PatientProfile',
  Outstanding = 'Outstanding',
  PatientHeader = 'PatientHeader',
  PatientList = 'PatientList',
  PatientEnrollment = 'PatientEnrollment',
  Transcribing = 'Transcribing',
  CalendarFull = 'CalendarFull',
  CalendarPopup = 'CalendarPopup',
  PortalLogin = 'PortalLogin',
  MessagePage = 'MessagePage',
  MedicalAlertDashboard = 'MedicalAlertDashboard',
  CareNotesTab = 'CareNotesTab'
}

export enum MixpanelDurationEvents {
  RenderPatientProfilePage = 'RenderPatientProfilePage'
}
