import { Tag, TagProps, Tooltip } from 'antd';
import './CgmPotentialTagComponent.scss';

export interface CgmPotentialTagComponentProps extends TagProps {
  reason?: string;
}

export const CgmPotentialTagComponent = ({
  reason,
  ...props
}: CgmPotentialTagComponentProps) => (
  <Tooltip title={reason}>
    <Tag
      color="#E9F1FA"
      className="CgmPotentialTagComponent"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      CGM Potential
    </Tag>
  </Tooltip>
);
