import { Select } from 'antd';
import { map } from 'lodash';
import { nutritionUnderstandings } from './options';

export interface NutritionUnderstandingSelectComponentProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const NutritionUnderstandingSelectComponent = ({
  value,
  onChange,
}: NutritionUnderstandingSelectComponentProps) => (
  <Select
    value={value}
    onChange={onChange}
    listItemHeight={10}
    popupClassName="nutrition-component"
    options={map(nutritionUnderstandings, (value) => ({
      value,
      label: value,
    }))}
    placeholder="Select"
    allowClear
  />
);
