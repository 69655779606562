import { TimeSelectComponent, TimeSelectComponentProps } from '../../features/visit/component/CreateVisitFormComponent/TimeSelectComponent';

export interface TimeSelectAllDayComponent extends TimeSelectComponentProps { }

export const TimeSelectAllDayComponent = ({
  ...props
}: TimeSelectAllDayComponent) => (
  <TimeSelectComponent
    minValue={0}
    maxValue={24}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
