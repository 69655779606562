import {
  filter,
  find
} from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  EnrollmentActionEnum,
  LoanDevice,
  usePatientGetPatientCgmDevice,
  usePatientUpdateEnrollment,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { CGMFormSubmitValue } from '../../../CGMDevice/component/CGMDeviceFormItemComponent/useCGMDeviceForm';
import { normalizeCGMDeviceData } from '../../../CGMDevice/helper/cgmHelper';
import { useCgmUpdateHelper } from '../../../CGMDevice/helper/useCgmUpdateHelper';
import { SubmitValue, useAssignDeviceForm } from '../../../patient/hook/useAssignDeviceForm';
import { AssignDeviceFormComponent, AssignDeviceFormComponentProps } from '../../component/AssignDeviceFormComponent/AssignDeviceFormComponent';
import { ConsentFormMissingInfoComponent } from '../../component/ConsentFormMissingInfoComponent/ConsentFormMissingInfoComponent';
import { EnrollmentWrapperComponent } from '../../component/EnrollmentWrapperComponent/EnrollmentWrapperComponent';
import { useEnrollmentProgressContext } from '../../context/EnrollmentProgressContext/EnrollmentProgressContext';
import { EnrollmentSectionsEnum } from '../../context/EnrollmentProgressContext/type';
import { isMissingConsent } from '../../helper/consentHelper';
import { EnrollmentReq } from '../../type';

export interface AssignDevicesFormContainerProps {
  patientInfo: PatientInfo;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const AssignDevicesFormContainer = ({
  patientInfo,
  onSubmit,
  onClose,
}: AssignDevicesFormContainerProps) => {
  const [currValue, setCurrValue] = useState<SubmitValue>();
  const programEnrollmentUpdate = usePatientUpdateEnrollment({});
  const { onSectionDone } = useEnrollmentProgressContext();
  const { send: sendMixpanel } = useMixpanelContext();
  const deviceUpdateHook = useUpdate('DEVICE_MONITOR_UPDATED');
  const deviceAssignUpdateHook = useUpdate('DEVICE_ASSIGNED');
  const vitals = patientInfo.enrollmentInfo?.programEnrollment?.vitalTypes || [];
  const assignDeviceForm = useAssignDeviceForm();
  const patientCGMDeviceInfo = usePatientGetPatientCgmDevice({
    params: { memberId: patientInfo.id },
  });
  const patientCGMDevice = patientCGMDeviceInfo.data?.data;
  const {
    updatePatientCGMDevice
  } = useCgmUpdateHelper(
    patientInfo.id,
  );

  const sendReq = (value: EnrollmentReq<SubmitValue>) => {
    const memberId = patientInfo.id;
    const loanDevices: LoanDevice[] | undefined = (
      value?.loanDevice?.deviceId
        ? [{ ...value.loanDevice, memberId }]
        : []
    );
    sendMixpanel({ event: MixpanelEvents.AssignDeviceSubmit });

    const cgmDeviceValue = find(
      value.deviceList,
      { vitalType: VitalEnumType.CGM }
    );
    const updatedDeviceList = filter(
      value.deviceList,
      (v) => ![VitalEnumType.CGM].includes(v.vitalType as VitalEnumType)
    );

    updatePatientCGMDevice(cgmDeviceValue as CGMFormSubmitValue);

    const req = programEnrollmentUpdate.send({
      params: {
        memberId,
        request: {
          action: value.action,
          patientDevice: {
            patientTechLevel: value.techLevel,
            deviceDeliveryMethod: value.deliveryMethod,
            deviceList: updatedDeviceList,
            shippingAddress: value.shippingAddress,
            // if want to save shipping address to patient profile address, uncomment this
            // saveAddress: true,
          },
          loanDeviceRequest: loanDevices,
        },
      },
    });
    return ApiRequestHelper.tryCatch(req, 'Failed to update devices!');
  };

  const initialValues = useMemo<AssignDeviceFormComponentProps['initialValues']>(() => {
    if (patientCGMDevice === undefined) {
      return undefined;
    }

    let deviceList = patientInfo.enrollmentInfo?.patientDevice?.deviceList ?? undefined;
    const cgmDevice = normalizeCGMDeviceData(patientCGMDevice);
    if (cgmDevice) {
      deviceList = [
        ...(deviceList || []),
        { vitalType: VitalEnumType.CGM, devices: [cgmDevice] }
      ];
    }

    return {
      techLevel: patientInfo.enrollmentInfo?.patientDevice?.patientTechLevel
        ?? undefined,
      deliveryMethod: patientInfo.enrollmentInfo?.patientDevice?.deviceDeliveryMethod
        ?? undefined,
      deviceList,
      shippingAddress: patientInfo.enrollmentInfo?.patientDevice?.shippingAddress
        ?? undefined,
      loanDevice: patientInfo.enrollmentInfo?.loanDeviceRequest?.[0]
        ?? undefined,
    };
  }, [patientInfo, patientCGMDevice]);

  const handleSubmit = async (v: SubmitValue) => {
    const res = await sendReq({ ...v, action: EnrollmentActionEnum.ASSIGN_DEVICE });
    // if success
    if (res !== undefined) {
      deviceUpdateHook.updateValue();
      deviceAssignUpdateHook.updateValue();
      onSectionDone(EnrollmentSectionsEnum.Devices);
      onSubmit?.();
    }
  };

  const handleValuesChange: AssignDeviceFormComponentProps['onValuesChange'] = (_, allValues) => {
    setCurrValue(allValues);
  };

  const handleClose = () => {
    if (currValue) {
      sendReq(assignDeviceForm.parseSubmitValue(currValue));
    }
    onClose?.();
  };

  const { consentService } = patientInfo;

  useEffect(() => {
    let interval: unknown | undefined;

    if (!consentService.isSigned()) {
      interval = setInterval(() => {
        patientInfo.refetch(['consentRefetch']);
      }, 10000);
    } else {
      // refetch only what assignedDevices form needs
      patientInfo.refetch([
        'patientRefetch',
        'enrollmentInfoRefetch',
      ]);
    }

    return () => {
      if (interval) clearInterval(interval as number);
    };
  }, [consentService.isSigned()]);

  return (
    <EnrollmentWrapperComponent
      onClose={handleClose}
    >
      {
        isMissingConsent(consentService)
          ? (
            <ConsentFormMissingInfoComponent
              description={(
                <div className="w400">
                  You need to enroll patient and collect consent before assign devices.
                  This view will refresh automatically after patient signs
                  the consent form through the app.
                </div>
              )}
            />
          ) : (
            <LoadingOverlayComponent
              isLoading={initialValues === undefined}
            >
              <AssignDeviceFormComponent
                initialValues={initialValues}
                isLoading={programEnrollmentUpdate.isLoading}
                vitals={vitals}
                hasSmartDevice={!!patientInfo.enrollmentInfo?.programEnrollment?.hasSmartDevice}
                onSubmit={handleSubmit}
                onValuesChange={handleValuesChange}
                onTechLevelSelect={() => onSectionDone(EnrollmentSectionsEnum.TechLevel)}
                onDeliveryMethodSelect={() => onSectionDone(EnrollmentSectionsEnum.DeliveryMethod)}
                onDevicesSelect={() => onSectionDone(EnrollmentSectionsEnum.Devices)}
              />
            </LoadingOverlayComponent>
          )
      }
    </EnrollmentWrapperComponent>
  );
};
