import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import { useIterableFormHook } from '../../../../hooks/useIterableFormListHook/useIterableFormHook';

export const usePhysicalActivityForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('physicalActivities', {
    type: {
      name: 'type',
      label: 'Exercise type'
    },
    startTime: {
      name: 'startTime',
      label: 'Usual exercise time'
    },
    endTime: {
      name: 'endTime',
      label: 'Usual exercise time'
    },
    intensity: {
      name: 'intensity',
      label: 'Intensity'
    }
  }, options);

  const getInfo = (
    key: keyof typeof factory.formInput,
    field: number
  ) => ({
    name: factory.makeListNamePath(key, field),
    label: factory.getLabel(key) as string
  });

  return useGetContextValue({
    ...factory,
    getInfo
  });
};
