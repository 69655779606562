import Paragraph from 'antd/lib/typography/Paragraph';
import { AccountHelper } from '../../../../../helpers/account';
import { CareNote } from '../../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';

export interface CgmReportCareNoteComponentProps {
  careNote?: CareNote;
}

export const CgmReportCareNoteComponent = ({
  careNote
}: CgmReportCareNoteComponentProps) => (
  <div className="CgmReportCareNoteComponent">
    <TextComponent className="pop-text bold mb10">
      Care Team Notes
    </TextComponent>
    <div className="flex mb10">
      <div>
        {`${AccountHelper.getFullName(
          careNote?.createdByUser || {}
        ) || ''}, Generated on `}
      </div>
      <DisplayDateComponent
        format="MMM_DD_YYYY"
        value={careNote?.updatedAt}
      />
    </div>
    <div className="bg-gray5 p10 default-br pre-wrap mb5">
      <Paragraph>
        {careNote?.content}
      </Paragraph>
    </div>
    <div className="fs8 text-gray-scale-2">
      *Suggested considerations do not replace the opinion or advice of the healthcare provider.
    </div>
  </div>
);
