import { ReactNode } from 'react';
import { useInterval } from 'usehooks-ts';
import WarningImage from '../../../../assets/images/warning.png';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

import './ConsentFormPendingCheckContainer.scss';
import { usePatientGetConsentForm } from '../../../../uc-api-sdk';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';

const CONSENT_FORM_CHECK_INTERVAL = 5000;

export interface ConsentFormPendingCheckContainerProps {
  patientId?: string;
  message?: ReactNode;
  onConsentFormSigned?: () => void;
  onDifferentMethodClick?: () => void;
}

export const ConsentFormPendingCheckContainer = ({
  patientId,
  message = 'The page will automatically refresh.',
  onConsentFormSigned,
  onDifferentMethodClick,
}: ConsentFormPendingCheckContainerProps) => {
  const consentFormInfo = usePatientGetConsentForm({
    params: { memberId: patientId || '' },
    options: { sendOnMount: false }
  });
  const isSigned = consentFormInfo.data?.data?.status;
  useInterval(() => {
    if (!isSigned) {
      consentFormInfo.refetch();
      return;
    }
    onConsentFormSigned?.();
  }, patientId ? CONSENT_FORM_CHECK_INTERVAL : null);

  if (!patientId) {
    return (
      <MissingErrorComponent>
        Something went wrong. Please close and reopen to try again.
      </MissingErrorComponent>
    );
  }

  return (
    <div className="consent-form-pending-check">
      <img
        src={WarningImage}
        alt="warning"
      />
      <TextComponent
        bold
        size="x-large"
      >
        Consent form has been sent
      </TextComponent>
      <div className="ta-c my10">
        {message}
      </div>
      {
        onDifferentMethodClick
        && (
          <LinkButton
            useBlueColor
            underlineOnHover
            onClick={onDifferentMethodClick}
          >
            Choose another way to collect consent
          </LinkButton>
        )
      }
    </div>
  );
};
