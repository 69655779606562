import { get } from 'lodash';
import { CgmMetricEnum, CgmPatientContext, CgmThresholdRange } from '../../../uc-api-sdk';
import { TagType } from '../../../uiComponent/TagComponent/TagComponent';

export const CGM_BAR_CHART_CONFIG: Partial<Record<CgmMetricEnum, {
  name: string;
  tagType: TagType;
  defaultGoal: number;
}>> = {
  [CgmMetricEnum.TAR_LEVEL_2]: { name: 'Very High', tagType: 'error', defaultGoal: 5 },
  [CgmMetricEnum.TAR_LEVEL_1]: { name: 'High', tagType: 'warning', defaultGoal: 25 },
  [CgmMetricEnum.TIR]: { name: 'In Range', tagType: 'success', defaultGoal: 70 },
  [CgmMetricEnum.TBR_LEVEL_1]: { name: 'Low', tagType: 'processing', defaultGoal: 4 },
  [CgmMetricEnum.TBR_LEVEL_2]: { name: 'Very Low', tagType: 'default', defaultGoal: 1 },
};

export class CgmHelper {
  public static getDefaultThresholdValue(t: CgmMetricEnum) {
    return CGM_BAR_CHART_CONFIG[t]?.defaultGoal || 0;
  }

  public static getDefaultThreshold(t: CgmMetricEnum, thresholds?: CgmPatientContext['thresholds']) {
    const th = get(thresholds, t) as unknown as CgmThresholdRange;
    switch (t) {
      case CgmMetricEnum.TBR_LEVEL_2:
        return (
          <span>
            &le;
            {th?.upperBound || 53}
            {' '}
            mg/dL
          </span>
        );
      case CgmMetricEnum.TBR_LEVEL_1:
        return <span>{`${th?.lowerBound || 54}-${th?.upperBound || 69} mg/dL`}</span>;
      case CgmMetricEnum.TIR:
        return <span>{`${th?.lowerBound || 70}-${th?.upperBound || 139} mg/dL`}</span>;
      case CgmMetricEnum.TAR_LEVEL_1:
        return <span>{`${th?.lowerBound || 140}-${th?.upperBound || 199} mg/dL`}</span>;
      case CgmMetricEnum.TAR_LEVEL_2:
        return (
          <span>
            &ge;
            {th?.lowerBound || 200}
            {' '}
            mg/dL
          </span>
        );
      default:
        return null;
    }
  }

  public static getDefaultThresholdPercentString(t: CgmMetricEnum) {
    switch (t) {
      case CgmMetricEnum.TBR_LEVEL_2:
        return (
          <span>
            (&lt;
            {' '}
            {CgmHelper.getDefaultThresholdValue(t)}
            %)
          </span>
        );
      case CgmMetricEnum.TBR_LEVEL_1:
        return (
          <span>
            (&lt;
            {' '}
            {CgmHelper.getDefaultThresholdValue(t)}
            %)
          </span>
        );
      case CgmMetricEnum.TIR:
        return (
          <span>
            (&ge;
            {' '}
            {CgmHelper.getDefaultThresholdValue(t)}
            %)
          </span>
        );
      case CgmMetricEnum.TAR_LEVEL_1:
        return (
          <span>
            (&lt;
            {' '}
            {CgmHelper.getDefaultThresholdValue(t)}
            %)
          </span>
        );
      case CgmMetricEnum.TAR_LEVEL_2:
        return (
          <span>
            (&lt;
            {' '}
            {CgmHelper.getDefaultThresholdValue(t)}
            %)
          </span>
        );
      default:
        return null;
    }
  }
}
