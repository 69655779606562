import { map } from 'lodash';
import { useMemo } from 'react';
import { SimplifiedWorklistDiseaseHistoryComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistDiseaseHistoryComponent';
import { SimplifiedWorklistGoalsSetupComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistGoalsSetupComponent';
import { SimplifiedWorklistSocialSupportComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistSocialSupportComponent';
import { SimplifiedWorklistMedReminderConsentComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistMedReminderConsentComponent';
import { SimplifiedWorklistNextVisitComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistNextVisitComponent';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { SimplifiedWorklistDiagnosesComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistDiagnosesComponent';

export interface InitialWorklistSectionComponentProps {
  patientId: string;
  clinicId?: string;
}

export const InitialWorklistSectionComponent = ({
  patientId,
  clinicId
}: InitialWorklistSectionComponentProps) => {
  const items = useMemo(() => ([
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistDiagnosesComponent
          patientId={patientId}
          itemNumber={itemNumber}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistDiseaseHistoryComponent
          patientId={patientId}
          itemNumber={itemNumber}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistGoalsSetupComponent
          patientId={patientId}
          itemNumber={itemNumber}
          clinicId={clinicId}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistSocialSupportComponent
          patientId={patientId}
          itemNumber={itemNumber}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistMedReminderConsentComponent
          patientId={patientId}
          itemNumber={itemNumber}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistNextVisitComponent
          itemNumber={itemNumber}
          visitType={VisitTypeEnum.INITIAL}
        />
      )
    }
  ]), []);

  return (
    <>
      {
        map(items, (item, index) => (
          <div key={index}>
            {item.render(index + 1)}
          </div>
        ))
      }
    </>
  );
};
