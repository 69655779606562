import { Divider } from 'antd';
import dayjs from 'dayjs';
import { UNIFIEDCARE_PILOT_LOGO } from '../../assets/logoIconInfo';
import { PatientInfo } from '../../contexts/PatientInfoContext/PatientInfoContext';
import { GenderEnumComponent } from '../../enumComponent/GenderEnumComponent/GenderEnumComponent';
import { ProgramCategoryEnumListComponent } from '../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { VitalEnumTypeListComponent } from '../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { Icon } from '../../icons/Icon';
import { GenderEnum, Height, Weight } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';
import { HeightComponent } from '../HeightComponent/HeightComponent';
import { TextComponent } from '../TextComponent/TextComponent';
import { Title } from '../Title/Title';
import { WeightComponent } from '../WeightComponent/WeightComponent';

export interface CommonReportHeaderComponentProps {
  patientInfo?: PatientInfo;
  title: string;
}

export const CommonReportHeaderComponent = ({
  patientInfo,
  title,
}: CommonReportHeaderComponentProps) => {
  const { patientInfoService, enrolledProgramService } = patientInfo || {};

  return (
    <>
      <div className="CommonReportHeaderComponent">
        <div className="flex jc-sb">
          <Title noMargin>
            {title}
          </Title>
          <Icon
            className="unified-care-logo"
            info={UNIFIEDCARE_PILOT_LOGO}
          />
        </div>
        <div className="">
          <TextComponent className="bold mb10">
            {patientInfo?.patientInfoService?.getFullName()}
          </TextComponent>
          <div className="flex gap2 fs12">
            <span>
              {dayjs().endOf('day').diff(dayjs(patientInfoService?.getBirthday()), 'years')}
              {' y/o'}
            </span>
            <GenderEnumComponent value={patientInfoService?.getGender() as GenderEnum} />
            <span>
              MRN:
              {' '}
              <DisplayOrEmptyComponent value={patientInfoService?.getMedicalId()} />
            </span>
            <HeightComponent
              label="Ht:"
              height={patientInfoService?.getHeightWithUnit() as Height}
            />
            <WeightComponent
              label="Wt:"
              weight={patientInfoService?.getWeightWithUnit() as Weight}
              className="flex"
            />
            <span>
              Enrolled programs:
              {' '}
              <ProgramCategoryEnumListComponent
                value={enrolledProgramService?.getPrograms()}
                type="short"
              />
            </span>
            <span>
              Vitals:
              {' '}
              <VitalEnumTypeListComponent
                value={enrolledProgramService?.getVitals() || []}
                type="short"
                splitter=", "
              />
            </span>
          </div>
        </div>
      </div>
      <Divider className="print-vital-divider" />
    </>
  );
};
