import { ClinicGoalEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export const getText = (value?: ClinicGoalEnum) => {
  switch (value) {
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_1:
      return 'Maintain or improve Hgb A1C by 0.5% over the next 3 months without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_2:
      return 'Maintain or improve Hgb A1C to less than 7% over the next 3 months without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_3:
      return 'Maintain a stable A1C between 6.5-7% over the next 3 months without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_4:
      return 'Maintain or improve A1C between 7-7.5% over the next 3 months, due to the patient\'s significant advanced risks due to other complicating conditions, without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_5:
      return 'Pre-diabetes A1C will not worsen over the next 6 months without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_6:
      return 'Pre-diabetes A1C will be maintained or improved to below 5.7% in the next 6 months without symptoms of hypoglycemia';

    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_1:
      return 'Reduce and maintain blood pressure to <130/80 mmHg over the next 6 months without symptoms associated with hypotension';
    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_2:
      return 'Reduce and maintain blood pressure to <140/90 mmHg over the next 6 months without symptoms associated with hypotension';
    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_3:
      return 'Reduce and maintain blood pressure to between 130/80-140/90 mmHg over the next 6 months without symptoms associated with hypotension, given advanced age and/or associated risks';
    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_4:
      return 'Maintain blood pressure at or below 160/90 mmHg due to age and other associated risks without symptoms associated with hypotension over the next 6 months';
    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_5:
      return 'Minimize hypotensive risk by maintaining blood pressure above 90/60 mmHg without symptoms associated with hypotension over the next 6 months';

    case ClinicGoalEnum.WEIGHT_REDUCTION_1:
      return 'Achieve and maintain a healthful weight at a BMI below 30 over the next 6 months';
    case ClinicGoalEnum.WEIGHT_REDUCTION_2:
      return 'Prevent weight gain over 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.WEIGHT_REDUCTION_3:
      return 'Aim to lose up to avg 0.5lb/wk with gradual lifestyle changes over the next 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.WEIGHT_REDUCTION_4:
      return 'Aim to lose up to avg 1lb/wk for the next 3 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.WEIGHT_REDUCTION_5:
      return 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week';
    case ClinicGoalEnum.WEIGHT_REDUCTION_6:
      return 'Weight will either be maintained at current BMI or improve to lower BMI within the next 6 months with nutrition interventions';
    case ClinicGoalEnum.WEIGHT_REDUCTION_7:
      return 'Maintain weight within the target range, avoiding significant fluctuations, at 1 month (+/-5%), 3 months (+/-7.5%), and 6 months (+/-10%) to ensure stability and timely adjustments if necessary. (* side note: this is for elderly patients, per CMS) ';

    case ClinicGoalEnum.WEIGHT_REDUCTION_GLP_1:
      return '1% weight reduction from baseline per month';
    case ClinicGoalEnum.WEIGHT_REDUCTION_GLP_2:
      return '2% weight reduction from baseline per month';
    case ClinicGoalEnum.WEIGHT_REDUCTION_GLP_3:
      return '5% weight reduction from baseline after 3 months';
    case ClinicGoalEnum.WEIGHT_REDUCTION_GLP_4:
      return '5% weight reduction from baseline after 6 months';
    case ClinicGoalEnum.WEIGHT_REDUCTION_GLP_5:
      return '10% weight reduction from baseline after 6 months';
    case ClinicGoalEnum.WEIGHT_REDUCTION_GLP_6:
      return '10% weight reduction from baseline after 6 months';
    case ClinicGoalEnum.WEIGHT_REDUCTION_GLP_7:
      return '15% weight reduction from baseline after 6 months';

    case ClinicGoalEnum.CANCER_1:
      return 'Maintain current weight in the next 3 months';
    case ClinicGoalEnum.CANCER_2:
      return 'Gain at least 0.5 lbs weekly in the next 3 months';
    case ClinicGoalEnum.CANCER_3:
      return 'No further muscle wasting in the next 3 months';

    case ClinicGoalEnum.UNDERWEIGHT_1:
      return 'Maintain current weight in the next 3 months';
    case ClinicGoalEnum.UNDERWEIGHT_2:
      return 'Gain at least 0.5 lbs weekly in the next 3 months';
    case ClinicGoalEnum.UNDERWEIGHT_3:
      return 'No further muscle wasting in the next 3 months';

    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_1:
      return 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week, and without symptoms of exacerbated CHF';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_2:
      return 'Maintain or improve total cholesterol to <200 mg/dl over the next 3-6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_3:
      return 'Maintain or improve LDL to <100 mg/dL over the next 3-6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_4:
      return 'Maintain or improve HDL to >40 mg/dL over the next 3-6 months (men)';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_5:
      return 'Maintain or improve HDL to >50 mg/dL over the next 3-6 months (women)';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_6:
      return 'Maintain or improve triglycerides to <150 mg/dL over the next 3-6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_7:
      return 'Lipid profile will not worsen in the next 12 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_8:
      return 'LDL will be reduced to normal range in the next 12 months with nutrition interventions and medication management as needed';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_9:
      return 'HDL will meet AHA standards (>40 for men, >50 for women) in the next 12 months with nutrition interventions and medication management as needed';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_10:
      return 'Maintain or improve lipid profile to normal levels within the next 12 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_11:
      return 'Reduce and maintain blood pressure to <130/80 mmHg over the next 6 months without symptoms associated with hypotension';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_12:
      return 'Reduce and maintain blood pressure to <140/90 mmHg over the next 6 months without symptoms associated with hypotension';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_13:
      return 'Reduce and maintain blood pressure to between 130/80-140/90 mmHg over the next 6 months without symptoms associated with hypotension, given advanced age and/or associated risks';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_14:
      return 'Maintain blood pressure at or below 160/90 mmHg due to age and other associated risks without symptoms associated with hypotension over the next 6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_15:
      return 'Minimize hypotensive risk by maintaining blood pressure above 90/60 mmHg without symptoms associated with hypotension over the next 6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_16:
      return 'Achieve and maintain a healthful weight at a BMI below 30 over the next 6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_17:
      return 'Prevent weight gain over 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_18:
      return 'Aim to lose up to 0.5lb/wk with gradual lifestyle changes over the next 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_19:
      return 'Aim to lose up to 1lb/wk for the next 3 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_20:
      return 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week';

    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_1:
      return 'Maintain oxygen saturation level within or above 88-92% without any symptoms of COPD exacerbation in the next 6 months';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_2:
      return 'Maintain current weight over the next 3-6 months with no significant weight change';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_3:
      return 'Aim for weight gain of 1-3 lbs over the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_4:
      return 'Aim for weight loss of 1-3 lbs over the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_5:
      return 'No further muscle wasting over the next 3-6 months';

    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_1:
      return 'Maintain eGFR at or above current level for the next 3-6 months.';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_2:
      return 'Maintain or improve renal panel with in normal range in the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_3:
      return 'Maintain or improve serum albumin level to 3.4-5.4 g/dL in the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_4:
      return 'Maintain or improve serum potassium level to 3.5-5.0 mmol/L in the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_5:
      return 'Maintain or improve serum phosphorus level to 2.5-4.5 mg/dL in the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_6:
      return 'Maintain current dry weight in the next 3-6 months';

    case ClinicGoalEnum.LIPID_MANAGEMENT_1:
      return 'Maintain or improve total cholesterol to <200 mg/dl over the next 3-6 months';
    case ClinicGoalEnum.LIPID_MANAGEMENT_2:
      return 'Maintain or improve LDL to <100 mg/dL over the next 3-6 months';
    case ClinicGoalEnum.LIPID_MANAGEMENT_3:
      return 'Maintain or improve HDL to >40 mg/dL over the next 3-6 months (men)';
    case ClinicGoalEnum.LIPID_MANAGEMENT_4:
      return 'Maintain or improve HDL to >50 mg/dL over the next 3-6 months (women)';
    case ClinicGoalEnum.LIPID_MANAGEMENT_5:
      return 'Maintain or improve triglycerides to <150 mg/dL over the next 3-6 months';
    case ClinicGoalEnum.LIPID_MANAGEMENT_6:
      return 'Lipid profile will not worsen in the next 12 months';
    case ClinicGoalEnum.LIPID_MANAGEMENT_7:
      return 'LDL will be reduced to normal range in the next 12 months with nutrition interventions and medication management as needed';
    case ClinicGoalEnum.LIPID_MANAGEMENT_8:
      return 'HDL will meet AHA standards (>40 for men, >50 for women) in the next 12 months with nutrition interventions and medication management as needed';
    case ClinicGoalEnum.LIPID_MANAGEMENT_9:
      return 'Maintain or improve lipid profile to normal levels within the next 12 months';

    case ClinicGoalEnum.COVID_1:
      return 'The temperature and/or blood oxygen for the duration of the acute COVID illness will be maintained at or near normal levels, and the patient will notify the provider if symptoms worsen.';

    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_99:
      return 'The goal is to reduce and maintain BP to ideal goal of <130/80 or minimal goal of 140/90 by following medication regimen and improved lifestyle changes through diet and exercise.';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_99:
      return 'The goal is to improve HgbA1C < 9, complete annual eye exam, complete kidney evaluation labs such as eGFR and uACR, complete diabetic foot exams and LDL cholesterol goal < 70.';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_99:
      return 'The goal is to improve or maintain functional mobility for daily living activities. Patient is to follow medication regimen, improve nutritional value and include instructed exercises.';
    case ClinicGoalEnum.CANCER_99:
      return 'The goal is to keep regularly scheduled visits with oncologists and other specialists as required. Follow medication regimen per care team recommendation and contact care team if conditions worsen to determine if higher level of care is needed.';
    case ClinicGoalEnum.DEPRESSION_99:
      return 'The goal is to adhere to medication and review behavioral therapy options.';
    case ClinicGoalEnum.CHRONIC_PAIN_99:
      return 'The goal is to follow medication regimen by prescribing physician and to maintain or improve daily activities of life to be completed with minimal or no assistance.';
    default:
      return null;
  }
};

export interface ClinicalGoalEnumComponentComponentProps {
  value: ClinicGoalEnum;
}

export const ClinicalGoalEnumComponent = ({
  value,
}: ClinicalGoalEnumComponentComponentProps) => (
  <DisplayOrEmptyComponent value={getText(value)} />
);
