import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  EnrollmentActionEnum, ProgramCategoryEnum,
  SignatureCollectionMethodEnum, usePatientUpdateEnrollment, VitalEnumType
} from '../../../../uc-api-sdk';
import { SubmitValue } from '../../../consentForm/component/ConsentFormComponent/ConsentFormComponent';
import { showConsentFormSignedComponent } from '../../../consentForm/component/ConsentFormSignedComponent/ConsentFormSignedComponent';
import { ConsentFormContainer, ConsentFormContainerProps } from '../../../consentForm/container/ConsentFormContainer/ConsentFormContainer';

export interface EnrollmentConsentFormContainerProps extends Omit<ConsentFormContainerProps, 'patientId'> {
  patientInfo: PatientInfo;
  programs?: ProgramCategoryEnum[];
  vitalsToMonitor?: VitalEnumType[];
  showSentModal?: boolean;
  showConsentAlert?: boolean;
  onOk?: () => void;

}

export const EnrollmentConsentFormContainer = ({
  patientInfo,
  onSubmit,
  programs,
  vitalsToMonitor,
  showSentModal = false,
  onOk,
  showConsentAlert
}: EnrollmentConsentFormContainerProps) => {
  const { send: sendMixpanel } = useMixpanelContext();
  const programEnrollmentUpdate = usePatientUpdateEnrollment({});

  const handleOnSubmit = (v?: SubmitValue) => {
    sendMixpanel({ event: MixpanelEvents.PatientConsentSubmit });
    const res = programEnrollmentUpdate.send({
      params: {
        memberId: patientInfo.id,
        request: {
          action: EnrollmentActionEnum.CONSENT_SUBMIT,
          consentRequest: {
            consentType: v?.signatureMethod,
          },
        },
      },
    });

    ApiRequestHelper.tryCatch(res, {
      success: undefined,
      error: 'Failed to submit consent!',
      onSuccess: () => {
        if (showSentModal && v?.signatureMethod !== SignatureCollectionMethodEnum.APP) {
          showConsentFormSignedComponent({
            programs,
            vitalsToMonitor,
            onOk
          });
        }
        onSubmit?.(v);
      }
    });
  };

  return (
    <ConsentFormContainer
      patientId={patientInfo?.id}
      initialValues={patientInfo?.consentService}
      onSubmit={handleOnSubmit}
      onOk={onOk}
      showConsentAlert={showConsentAlert}
      extraInfo={(
        <div>
          A text message containing the App download link will be sent to the patient
        </div>
      )}
    />
  );
};
