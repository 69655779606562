import { Card } from 'antd';
import { FC } from 'react';
import { useBoolean } from 'usehooks-ts';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { CareNoteTagEnumComponent } from '../../../../enumComponent/CareNoteTagEnumComponent/CareNoteTagEnumComponent';
import { CareNote, CareNoteTagEnum } from '../../../../uc-api-sdk';
import { CardHeaderComponent } from '../../../../uiComponent/CardHeaderComponent/CardHeaderComponent';
import { CardHeaderExtraComponent } from '../../../../uiComponent/CardHeaderExtraComponent/CardHeaderExtraComponent';
import { CareNoteDurationInfoComponent } from '../../../../uiComponent/CareNoteDurationInfoComponent/CareNoteDurationInfoComponent';
import { CareNotePopupCardComponent } from '../../../../uiComponent/CareNotePopupCardComponent/CareNotePopupCardComponent';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import { CareNoteCardMessageComponent } from '../CareNoteCardMessageComponent/CareNoteCardMessageComponent';
import { CareNoteCardSettingsComponent } from '../CareNoteCardSettingsComponent/CareNoteCardSettingsComponent';
import './CareNoteCardComponent.scss';

export interface CareNoteCardComponentProps {
  note: CareNote;
  isEditable?: boolean;
  showHistories?: boolean;
}

export const CareNoteCardComponent: FC<CareNoteCardComponentProps> = ({
  note,
  isEditable = true,
  showHistories = true,
}) => {
  const { value: hide, setTrue, setFalse } = useBoolean(true);
  const { info } = usePatientContext();
  const timezone = info?.patientInfoService?.getTimezone();
  return (
    <div className="relative CareNoteCardComponent">
      <Card className="secondary-br">
        <CardHeaderComponent>
          <div className="flex flex-wrap mr10">
            {note.tags?.filter(t => t !== null).map(
              (tag: CareNoteTagEnum) => (
                <TagComponent
                  type={tag?.toLowerCase() === 'visit' ? 'warning' : 'processing'}
                  iconType="none"
                  key={tag}
                >
                  <CareNoteTagEnumComponent value={tag} />
                </TagComponent>
              ),
            )}
          </div>
          <CardHeaderExtraComponent>
            <CareNoteCardSettingsComponent
              note={note}
              isEditable={isEditable}
              showHistories={showHistories}
            />
          </CardHeaderExtraComponent>
        </CardHeaderComponent>
        <CareNoteDurationInfoComponent
          durationText={note.tags?.includes(CareNoteTagEnum.CALL_LOG) ? 'Call Duration' : undefined}
          timezone={timezone}
          tags={note.tags}
          startTime={note.startTime || ''}
          endTime={note.endTime || ''}
        />
        <CareNoteCardMessageComponent
          message={note.content || ''}
          showPopup={setFalse}
        />
      </Card>
      {!hide
        && (
        <CareNotePopupCardComponent
          timezone={timezone}
          note={note}
          hideCard={setTrue}
        />
        )}
    </div>
  );
};
