import { useMonthlyMeasurementContext } from '../../../../contexts/MonthlyMeasurementContext/MonthlyMeasurementContext';
import { ConditionEnum } from '../../../../uc-api-sdk';
import { MTPRBloodGlucoseComponent } from '../MTPRBloodGlucoseComponent/MTPRBloodGlucoseComponent';
import { MTPRBloodPressureComponent } from '../MTPRBloodPressureComponent/MTPRBloodPressureComponent';
import { MTPRMeasurementSummaryTitleComponent } from '../MTPRMeasurementSummaryTitleComponent/MTPRMeasurementSummaryTitleComponent';

export interface MeasurementSummaryComponentProps {
  condition: ConditionEnum;
  customCategory?: string;
}

export const MeasurementSummaryComponent = ({
  condition,
  customCategory,
}: MeasurementSummaryComponentProps) => {
  const { monthlyMeasurement } = useMonthlyMeasurementContext() || {};
  if (!monthlyMeasurement) return null;

  const renderSummary = () => {
    switch (condition) {
      case ConditionEnum.BLOOD_PRESSURE_MANAGEMENT:
        return (
          <MTPRBloodPressureComponent
            monthlyMeasurement={monthlyMeasurement.BLOOD_PRESSURE_MANAGEMENT || {}}
          />
        );
      case ConditionEnum.GLUCOSE_MANAGEMENT:
        return (
          <MTPRBloodGlucoseComponent
            monthlyMeasurement={monthlyMeasurement.GLUCOSE_MANAGEMENT || {}}
          />
        );
      default:
        return (
          <MTPRMeasurementSummaryTitleComponent
            key={condition || customCategory}
            condition={condition}
            customCategory={customCategory}
            lastMeasurementDate={(
              monthlyMeasurement[condition as ConditionEnum]?.lastMeasurementDate
            )}
          />
        );
    }
  };

  return (
    <div className="my10">
      {renderSummary()}
    </div>
  );
};
