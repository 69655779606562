import { isBoolean } from 'lodash';
import { FreqEnumComponent } from '../../../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import { getAlcoholContainerTypeText } from '../../../../enumComponent/SmokeDrinkEnumComponent/AlcoholContainerTypeEnumComponent';
import { AlcoholTypeEnumComponent } from '../../../../enumComponent/SmokeDrinkEnumComponent/AlcoholTypeEnumComponent';
import { SmokeDrinkRecord } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { PluralComponent } from '../../../../uiComponent/PluralComponent/PluralComponent';
import { LifestyleAssessmentDisplayLayout } from './layout/LifestyleAssessmentDisplayLayout';

export interface DrinkingInfoComponentProps {
  info?: SmokeDrinkRecord | null;
}

export const DrinkingFreqInfoComponent = ({
  info,
}: DrinkingInfoComponentProps) => (
  <div className="flex f-w ai-c">
    <PluralComponent
      value={info?.drinkingAmount ?? undefined}
      unit={getAlcoholContainerTypeText(info?.containerType, true)}
      emptyUnit={<EmptyComponent />}
    />
    <span>of</span>
    <div>
      {
        info?.alcoholType
          ? <AlcoholTypeEnumComponent value={info?.alcoholType} />
          : <EmptyComponent />
      }
    </div>
    <span>/</span>
    <div>
      {
        info?.drinkingFrequency
          ? <FreqEnumComponent value={info?.drinkingFrequency} type="short" />
          : <EmptyComponent />
      }
    </div>
  </div>
);

export const DrinkingInfoComponent = ({
  info
}: DrinkingInfoComponentProps) => {
  const isYes = info?.isDrinking;

  if (!isBoolean(isYes)) {
    return null;
  }
  return isYes
    ? (
      <LifestyleAssessmentDisplayLayout
        label="Frequency"
        content={<DrinkingFreqInfoComponent info={info} />}
      />
    ) : (
      <LifestyleAssessmentDisplayLayout
        label="Quit year"
        content={(
          <DisplayOrEmptyComponent value={info?.quitDrinkingYear} />
        )}
      />
    );
};
