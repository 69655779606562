import { useCallback } from 'react';
import { BlockUnmountContextProvider } from '../../../../contexts/BlockUnmountContext/BlockUnmountContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { AddOnServiceEnum, EnrolledProgramStatusEnum, ProgramCategoryEnum } from '../../../../uc-api-sdk';
import { ConsentFormDrawerComponent } from '../../../consentForm/component/ConsentFormDrawerComponent/ConsentFormDrawerComponent';
import { useVerifyConsentStatus } from '../../../consentForm/hook/useVerifyConsentStatus';
import { AssignDevicesFormContainer } from '../../container/AssignDevicesFormContainer/AssignDevicesFormContainer';
import { EnrollmentConsentFormContainer } from '../../container/EnrollmentConsentFormContainer/EnrollmentConsentFormContainer';
import { ProgramEnrollmentFormContainer } from '../../container/ProgramEnrollmentFormContainer/ProgramEnrollmentFormContainer';
import { Step, useEnrollmentStepContext } from '../../context/EnrollmentStepContext/EnrollmentStepContext';
import { OnboardingComponent } from '../OnboardingComponent/OnboardingComponent';
import { SubmitValue } from '../ProgramEnrollmentFormComponent/ProgramEnrollmentFormComponent';
import './EnrollmentComponent.scss';

export interface EnrollmentComponentProps {
  patientInfo: PatientInfo;
  onClose?: () => void;
}

export const EnrollmentComponent = ({
  patientInfo,
  onClose,
}: EnrollmentComponentProps) => {
  const { currStep: step, goTo: setStep, onDone: onStepDone } = useEnrollmentStepContext();
  const {
    patientInfoService, enrollmentInfo,
    enrolledProgramService, consentService
  } = patientInfo;
  const hasConsentForm = consentService.hasConsent();
  const isEnrolled = enrolledProgramService.isEnrolled();
  const { vitalTypes, programCategories } = enrollmentInfo?.programEnrollment || {};
  const { medicalOrganizationService } = usePatientMedicalOrganizationContext();
  const patientInfoUpdateHook = useUpdate('PATIENT_INFO_UPDATED');
  const { send: sendMixpanel } = useMixpanelContext();
  const verify = useVerifyConsentStatus({});

  const fetchVerify = useCallback((
    selectedPrograms?: ProgramCategoryEnum[],
    selectedAddOnServices?: AddOnServiceEnum[]
  ) => (
    verify.send({
      params: {
        request: {
          memberId: patientInfo?.id || '',
          organizationId: patientInfoService?.getClinicId(),
          status: EnrolledProgramStatusEnum.ENROLLED,
          selectedPrograms,
          selectedAddOnServices,
        }
      }
    })
  ), [
    verify.send,
    patientInfo?.id,
    patientInfoService?.getClinicId()
  ]);

  const refetchVerify = useDebounce(() => {
    fetchVerify(
      enrolledProgramService?.getPrograms(),
      enrolledProgramService?.addOnServicesList
    );
  }, 500, [
    fetchVerify,
    enrolledProgramService?.getPrograms(),
    enrolledProgramService?.addOnServicesList
  ]);

  const handleClose = () => {
    patientInfoUpdateHook.updateValue();
    onClose?.();
  };

  const handleProgramEnrollmentSubmit = async (value: SubmitValue) => {
    patientInfo.refetch();
    const verifiedResponse = await fetchVerify(
      value.programs,
      value.addOnServices,
    );
    onStepDone(Step.ProgramEnrollment);
    if (isEnrolled || hasConsentForm) {
      if (medicalOrganizationService?.isRiverBend()) {
        handleClose?.();
        return;
      }

      if (!verifiedResponse?.data) {
        setStep(Step.Consent);
      } else {
        setStep(Step.AssignDevices);
      }
    } else {
      setStep(Step.Consent);
    }
  };

  const handleCloseConsent = () => {
    setStep(Step.ProgramEnrollment);
  };

  const handleAssignDevicesSubmit = () => {
    patientInfo.refetch();
    onStepDone(Step.AssignDevices);
    if (medicalOrganizationService?.isStThomas()) {
      handleClose?.();
      return;
    }
    setStep(Step.OnboardingVisits);
  };

  const onConsentSigned = async () => {
    await patientInfo.refetch();
    refetchVerify();
    sendMixpanel({ event: MixpanelEvents.PatientEnrollmentDone });
    onStepDone(Step.Consent);
    setStep(Step.AssignDevices);
  };

  const onOk = async () => {
    await patientInfo.refetch();
    refetchVerify();
    setStep(Step.AssignDevices);
  };

  return (
    <div className="enrollment-comp">
      {step === Step.ProgramEnrollment && (
        <BlockUnmountContextProvider>
          <ProgramEnrollmentFormContainer
            patientInfo={patientInfo}
            onSubmit={handleProgramEnrollmentSubmit}
            onClose={handleClose}
          />
        </BlockUnmountContextProvider>
      )}
      {step === Step.Consent && (
        <ConsentFormDrawerComponent
          open
          onClose={handleCloseConsent}
        >
          <EnrollmentConsentFormContainer
            patientInfo={patientInfo}
            onSubmit={onConsentSigned}
            onOk={onOk}
            showSentModal
            programs={programCategories || []}
            vitalsToMonitor={vitalTypes || []}
            showConsentAlert={(
              !verify.isLoading
              && !verify.data?.data
              && consentService.isSigned()
            )}
          />
        </ConsentFormDrawerComponent>
      )}
      {step === Step.AssignDevices && (
        <AssignDevicesFormContainer
          patientInfo={patientInfo}
          onSubmit={handleAssignDevicesSubmit}
          onClose={handleClose}
        />
      )}
      {step === Step.OnboardingVisits && (
        <OnboardingComponent
          patientInfo={patientInfo}
          onClose={handleClose}
        />
      )}
    </div>
  );
};
