import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { FutureFollowUpScheduleComponent } from '../FutureFollowUpScheduleComponent/FutureFollowUpScheduleComponent';
import { FollowUpScheduleFormComponent, FollowUpScheduleFormComponentProps } from '../FollowUpScheduleFormComponent/FollowUpScheduleFormComponent';

import './FollowUpScheduleWithFutureClinicEventsComponent.scss';

export interface FollowUpScheduleWithFutureClinicEventsComponentProps
  extends FollowUpScheduleFormComponentProps {
  futureClinicEventServices: ClinicEventsService[];
}

export const FollowUpScheduleWithFutureClinicEventsComponent = ({
  visitId,
  futureClinicEventServices,
  cgmOptOut,
}: FollowUpScheduleWithFutureClinicEventsComponentProps) => (
  <div className="follow-up-schedule-with-existing-clinic-events">
    <FutureFollowUpScheduleComponent clinicEvents={futureClinicEventServices} />
    <FollowUpScheduleFormComponent
      showWantScheduleAnother
      visitId={visitId}
      canSkipSchedule={false}
      cgmOptOut={cgmOptOut}
    />
  </div>
);
