import { TextComponent } from '../TextComponent/TextComponent';

export const CgmReviewTipComponent = () => (
  <TextComponent className="mb10 text-gray-scale-2">
    Review patient's vitals, goals and CGM data from the past
    {' '}
    <span className="bold">30 days</span>
    {' '}
    and generate report.
    {' '}
    <span className="text-red">*</span>
    Your review will be added to the report and saved as a Care Note.
  </TextComponent>
);
