import {
  Divider, Form
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FormType } from '../../../Input/types';
import { SubmitValue, useLifestyleAssessmentForm } from '../../hook/useILifestyleAssessmentForm/useLifestyleAssessmentForm';
import { MealAndSleepRoutineTableComponent } from '../MealAndSleepRoutineTableComponent/MealAndSleepRoutineTableComponent';
import { IAPhysicalActivityFormListComponent } from '../LifestyleAssessmentFormListComponent/components/PhysicalActivity/PhysicalActivityFormListComponent';
import { NutritionUnderstandingSelectComponent } from '../NutritionUnderstandingSelectComponent/NutritionUnderstandingSelectComponent';
import { BeverageFormItemComponent } from '../LifestyleAssessmentFormItemComponent/BeverageFormItemComponent';
import { CaloriesManagementFormItemComponent } from '../LifestyleAssessmentFormItemComponent/CaloriesManagementFormItemComponent';
import { DrinkFormItemComponent } from '../LifestyleAssessmentFormItemComponent/DrinkFormItemComponent';
import { FastFoodFormItemComponent } from '../LifestyleAssessmentFormItemComponent/FastFoodFormItemComponent';
import { SmokeFormItemComponent } from '../LifestyleAssessmentFormItemComponent/SmokeFormItemComponent';
import { StressManagementFormItemComponent } from '../LifestyleAssessmentFormItemComponent/StressManagementFormItemComponent';
import { InterventionSubmitCancelButtons } from '../InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { TextareaComponent } from '../../../../uiComponent/TextareaComponent/TextareaComponent';
import { LifestyleAssessmentTitleLayout } from './layout/LifestyleAssessmentTitleLayout';
import { LifestyleAssessment } from '../../../../uc-api-sdk';

import './LifestyleAssessmentFormComponent.scss';

export interface LifestyleAssessmentFormComponentProps extends FormType<SubmitValue> { }

export const LifestyleAssessmentFormComponent = ({
  initialValues,
  isLoading,
  onValuesChange,
  onSubmit,
  onCancel,
  formButtons = (
    <InterventionSubmitCancelButtons
      onCancel={onCancel}
    />
  ),
}: LifestyleAssessmentFormComponentProps) => {
  const iaForm = useLifestyleAssessmentForm();

  const handleSubmit = (v: LifestyleAssessment) => {
    const fields = [] as string[];
    if (v.isEatingFastFood === false) {
      fields.push('fastFoodFreq');
    }
    if (v.smokeDrinkRecord?.isSmoking === true) {
      fields.push('smokeDrinkRecord.quitSmokingYear');
    }
    if (v.smokeDrinkRecord?.isSmoking === false) {
      fields.push('smokeDrinkRecord.smokingAmount');
      fields.push('smokeDrinkRecord.cigaretteUnit');
      fields.push('smokeDrinkRecord.smokingFrequency');
    }
    if (v.smokeDrinkRecord?.isDrinking === true) {
      fields.push('smokeDrinkRecord.quitDrinkingYear');
    }
    if (v.smokeDrinkRecord?.isDrinking === false) {
      fields.push('smokeDrinkRecord.alcoholType');
      fields.push('smokeDrinkRecord.containerType');
      fields.push('smokeDrinkRecord.drinkingAmount');
      fields.push('smokeDrinkRecord.drinkingFrequency');
    }
    onSubmit?.({
      ...v,
      $unset: { fields },
    });
  };

  return (
    <Form
      form={iaForm.form}
      onFinish={iaForm.handleSubmit(handleSubmit)}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      disabled={iaForm.formDisabled || isLoading}
      layout="vertical"
      colon={false}
      className="lifestyle-assessment-form"
    >
      <div>
        <LifestyleAssessmentTitleLayout
          title="Eating and Sleeping Routine"
        />
        <MealAndSleepRoutineTableComponent
          isEditable
        />
      </div>
      <Divider className="mt40" />
      <div>
        <LifestyleAssessmentTitleLayout
          title="Fast Food and Beverage Intake"
        />
        <FastFoodFormItemComponent />
        <div className="mt12" />
        <BeverageFormItemComponent />
      </div>
      <Divider className="mt40" />
      <div>
        <LifestyleAssessmentTitleLayout
          title="Calorie Management"
        />
        <CaloriesManagementFormItemComponent />
      </div>
      <Divider className="mt40" />
      <div>
        <LifestyleAssessmentTitleLayout
          title="Nutrition Understanding"
        />
        <FormItem
          name={iaForm.getName('nutritionUnderstanding')}
        >
          <NutritionUnderstandingSelectComponent />
        </FormItem>
      </div>
      <Divider className="mt40" />
      <div>
        <LifestyleAssessmentTitleLayout
          title="Smoking and Drinking"
        />
        <SmokeFormItemComponent />
        <div className="mt12" />
        <DrinkFormItemComponent />
      </div>
      <Divider className="mt40" />
      <div>
        <LifestyleAssessmentTitleLayout
          title="Stress Management"
        />
        <StressManagementFormItemComponent />
      </div>
      <Divider className="mt40" />
      <div>
        <LifestyleAssessmentTitleLayout
          title="Physical Activity"
        />
        <IAPhysicalActivityFormListComponent />
      </div>
      <Divider className="mt40" />
      <div>
        <LifestyleAssessmentTitleLayout
          title="Additional Comments"
        />
        <FormItem
          name={iaForm.getName('additionalComments')}
        >
          <TextareaComponent
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </FormItem>
      </div>
      {formButtons}
    </Form>
  );
};
