import { Form, FormItemProps as AntdFormItemProps } from 'antd';
import classNames from 'classnames';
import { createRequiredRule } from '../../helpers/form';
import './FormItem.scss';

export interface FormItemProps extends AntdFormItemProps {
  info?: {
    name: string;
    label: string | React.ReactNode;
  };
  tooltipOnLabel?: React.ReactNode;
  required?: boolean;
  hideRequiredMark?: boolean;
}

export const FormItem = ({
  info,
  tooltipOnLabel,
  name,
  label,
  children,
  className = '',
  required,
  rules,
  requiredMark = false,
  hideRequiredMark = false,
  ...props
}: FormItemProps) => (
  <Form.Item
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    label={tooltipOnLabel ?? (info?.label || label)}
    name={info?.name || name}
    data-name={info?.name || name}
    className={classNames({
      [className]: !!className,
      'hide-required-mark': !!hideRequiredMark,
      'no-children': !children,
    })}
    rules={rules || (required ? [createRequiredRule()] : undefined)}
    requiredMark={requiredMark}
  >
    {children}
  </Form.Item>
);

export default FormItem;
