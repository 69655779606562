import { Space } from 'antd';
import { useMemo } from 'react';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { PatientInfo, usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ArticlesMainContainer } from '../../../articles/containers/ArticlesMainContainer';
import { BehaviorGoalContainer } from '../../../intervention/container/BehaviorGoalContainer/BehaviorGoalContainer';
import { CgmGoalContainer } from '../../../intervention/container/CgmGoalContainer/CgmGoalContainer';
import { InterventionVitalsMonitoringContainer } from '../../../intervention/container/InterventionVitalsMonitoringContainer/InterventionVitalsMonitoringContainer';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { WorklistBehaviorChangeComponent } from '../WorklistBehaviorChangeComponent/WorklistBehaviorChangeComponent';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { WorklistMTPRComponent } from '../WorklistMTPRComponent/WorklistMTPRComponent';
import { WorklistTabProps } from '../WorklistTabsComponent/type';
import { WorklistTabsComponent } from '../WorklistTabsComponent/WorklistTabsComponent';

export enum GoalEducationTabKey {
  Goals = 'Goals',
  Education = 'Education',
}

export interface GoalEducationComponentProps
  extends WorklistTabProps<GoalEducationTabKey> {
  patientId: string;
  clinicId?: string;
  isEligibleForCGMReview?: boolean;
}

export const GoalEducationComponent = ({
  patientId,
  defaultActiveKey,
  clinicId,
  isEligibleForCGMReview
}: GoalEducationComponentProps) => {
  const { info } = usePatientContext();

  const items = useMemo(() => ([
    {
      key: GoalEducationTabKey.Goals,
      label: 'Goals',
      children: (
        <Space size="middle" direction="vertical" className="w100">
          {!isEligibleForCGMReview
            && (
            <NestedFormComponent>
              <WorklistMTPRComponent
                patientId={patientId}
                clinicId={clinicId}
              />
            </NestedFormComponent>
            )}
          <NestedFormComponent>
            <CgmGoalContainer
              patientId={patientId}
              isEditing
              showEdit={false}
              formButtons={null}
            />
          </NestedFormComponent>
          <NestedFormComponent>
            <WorklistContentComponent>
              <InterventionVitalsMonitoringContainer
                patientId={patientId}
                isEditing
                showEdit={false}
                formButtons={null}
              />
            </WorklistContentComponent>
          </NestedFormComponent>
          <NestedFormComponent>
            <WorklistBehaviorChangeComponent
              patientInfo={info as PatientInfo}
            />
          </NestedFormComponent>
          <NestedFormComponent>
            <WorklistContentComponent>
              <BehaviorGoalContainer
                patientId={patientId}
                isEditing
                showEdit={false}
                formButtons={null}
              />
            </WorklistContentComponent>
          </NestedFormComponent>
        </Space>
      ),
    },
    {
      key: GoalEducationTabKey.Education,
      label: 'Education',
      children: (
        <PatientCommonCardComponent
          title="Search Articles to Patient's App"
          content={null}
          updateInfo={undefined}
          formContent={<ArticlesMainContainer title={null} />}
          showHeaderOutside={false}
          isEditing
          showEdit={false}
        />
      ),
    }
  ]), []);

  return (
    <WorklistTabsComponent
      defaultActiveKey={defaultActiveKey}
      items={items}
    />
  );
};
