import {
  Checkbox, Col, Form, Input, Row, Space
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { Dayjs } from 'dayjs';
import { find, map } from 'lodash';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { PatientSpokenLanguageSelectComponent } from '../../../../selectComponents/PatientSpokenLanguageSelectComponent/PatientSpokenLanguageSelectComponent';
import USStateSelectComponent from '../../../../selectComponents/USStatesSelectComponent/USStatesSelectComponent';
import {
  BehaviorChangeEnum, EnrolledProgramStatusEnum, Nullable, RoleTypeEnum
} from '../../../../uc-api-sdk';
import { AddressAptInputComponent } from '../../../../uiComponent/Address/AddressAptInputComponent/AddressAptInputComponent';
import { AddressCityInputComponent } from '../../../../uiComponent/Address/AddressCityInputComponent/AddressCityInputComponent';
import { ChangePasswordModalComponent } from '../../../../uiComponent/ChangePasswordModalComponent/ChangePasswordModalComponent';
import { ClinicSelectComponent } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import EmailInputFormItemComponent from '../../../../uiComponent/EmailInputComponent/EmailInputFormItemComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PatientAssigneeSelectComponent } from '../../../../uiComponent/PatientBasicInfo/PatientAssigneeSelectComponent/PatientAssigneeSelectComponent';
import { PatientBehaviorChangeInputComponent } from '../../../../uiComponent/PatientBasicInfo/PatientBehaviorChangeInputComponent/PatientBehaviorChangeInputComponent';
import { PatientDobInputComponent } from '../../../../uiComponent/PatientBasicInfo/PatientDobInputComponent/PatientDobInputComponent';
import { PatientGenderIdentitySelectComponent } from '../../../../uiComponent/PatientBasicInfo/PatientGenderIdentitySelectComponent/PatientGenderIdentitySelectComponent';
import { PatientGenderInputComponent } from '../../../../uiComponent/PatientBasicInfo/PatientGenderInputComponent/PatientGenderInputComponent';
import { PatientIsTestUserCheckboxComponent } from '../../../../uiComponent/PatientBasicInfo/PatientIsTestUserCheckboxComponent/PatientIsTestUserCheckboxComponent';
import { PatientRaceInputComponent } from '../../../../uiComponent/PatientBasicInfo/PatientRaceInputComponent/PatientRaceInputComponent';
import { PatientTechLevelInputComponent } from '../../../../uiComponent/PatientBasicInfo/PatientTechLevelInputComponent/PatientTechLevelInputComponent';
import { PatientUsernameInputContainer } from '../../../../uiComponent/PatientUsernameInputContainer/PatientUsernameInputContainer';
import PhoneInput from '../../../../uiComponent/PhoneInput/PhoneInput';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { HeightInputComponent } from '../../../../uiComponent/Transcribing/component/HeightInputComponent/HeightInputComponent';
import { MedicalIdInputComponent } from '../../../../uiComponent/Transcribing/component/MedicalIdInputComponent/MedicalIdInputComponent';
import { ProviderSelectContainer, ProviderSelectContainerProps } from '../../../../uiComponent/Transcribing/component/ProviderSelectContainer/ProviderSelectContainer';
import { WeightInputFormItemComponent } from '../../../../uiComponent/Transcribing/component/WeightInputFormItemComponent/WeightInputFormItemComponent';
import ZipCodeInputComponent from '../../../../uiComponent/ZipCodeInputComponent/ZipCodeInputComponent';
import { OTPSwitchContainer } from '../../../../uiContainer/OTPSwitchContainer/OTPSwitchContainer';
import { InterventionSubmitCancelButtons } from '../../../intervention/component/InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { useDemographicsForm } from '../../hook/useDemographicsForm';
import { PotentialClinicSelectComponent } from '../PotentialClinicSelectComponent/PotentialClinicSelectComponent';
import { SocialSupportFormItemComponent } from '../SocialSupportFormComponent/SocialSupportFormItemComponent';

export interface DemographicsFormValue {
  firstName: Nullable<string> | undefined;
  lastName: Nullable<string> | undefined;
  nickName?: Nullable<string> | undefined;
  birthday: Dayjs | undefined;
  gender: Nullable<string> | undefined;
  genderIdentity?: Nullable<string> | undefined;
  race?: Nullable<string> | undefined;
  languages: Nullable<string[]> | undefined;
  behaviorChange?: Nullable<BehaviorChangeEnum> | undefined;
  height: number | undefined;
  weight?: number | undefined;
  techLevel?: Nullable<string> | undefined;
  isTestUser?: Nullable<boolean> | undefined;
  clinicId: Nullable<string> | undefined;
  doctorId: Nullable<string> | undefined;
  medicalRecordId: Nullable<string> | undefined;
  phone: Nullable<string> | undefined;
  phone2: Nullable<string> | undefined;
  smartPhone?: Nullable<boolean> | undefined;
  canUseForLogin?: Nullable<boolean> | undefined;
  loginId?: Nullable<string> | undefined;
  email?: Nullable<string> | undefined;
  address?: Nullable<string> | undefined;
  address2?: Nullable<string> | undefined;
  city?: Nullable<string> | undefined;
  state?: Nullable<string> | undefined;
  zipcode?: Nullable<string> | undefined;
  communitySupport?: Nullable<string> | undefined;
  contactInfo?: Nullable<string> | undefined;
  otherProviders?: Nullable<string> | undefined;
  assignedToCA?: Nullable<string> | undefined;
  assignedToRD?: Nullable<string> | undefined;
}
export interface DemographicsFormComponentProps {
  onCancel?: () => void;
  onSubmit?: (v: DemographicsFormValue) => void;
  initialValues?: DemographicsFormValue;
  isLoading?: boolean;
}

export const DemographicsFormComponent = ({
  onCancel,
  onSubmit,
  initialValues,
  isLoading,
}: DemographicsFormComponentProps) => {
  const [form] = Form.useForm();
  const hook = useDemographicsForm({ form });
  const { info } = usePatientContext();
  const associatedList = info?.patientInfoService?.patientAssociatedClinics;
  const { doesUserRoleIncludes } = useLoggedInUserFromContext();
  const { id = '' } = info || {};
  const {
    value: isValid,
    setTrue: setValid,
    setFalse: setInvalid
  } = useBoolean(true);
  const enrollmentStatus = info?.enrolledProgramService.getStatus();
  const unenrolled = !enrollmentStatus || enrollmentStatus === EnrolledProgramStatusEnum.UNENROLLED;
  const hasClinics = (info?.patientInfoService?.patientAssociatedClinics?.length || 0) > 0;
  const isInternalUser = doesUserRoleIncludes([RoleTypeEnum.RD, RoleTypeEnum.CA, RoleTypeEnum.HC]);
  const showPotentialClinic = unenrolled && hasClinics && isInternalUser;

  const gutter = 22;

  const handleOnProviderListLoaded: ProviderSelectContainerProps['onProviderListLoaded'] = (
    providerList,
  ) => {
    const clinicId = hook.getValue('clinicId', hook.form.getFieldValue);
    const currentProvider = hook.getValue('doctorId', hook.form.getFieldValue);
    const providerListIds = map(providerList, 'id');
    const associatedProviderId = find(associatedList, { clinicId })?.doctorId;
    // if provider list for new selected clinic includes the selected provider, then keep selection
    // else select provider from associated list, if applicable
    if (!providerListIds.includes(currentProvider)) {
      let newProviderId: string | undefined;
      if (associatedProviderId && providerListIds.includes(associatedProviderId)) {
        newProviderId = associatedProviderId;
      }
      hook.form.setFieldsValue({ [hook.getName('doctorId')]: newProviderId });
    }
  };

  return (
    <Form
      form={hook.form}
      onFinish={hook.handleSubmit(onSubmit)}
      layout="vertical"
      disabled={isLoading}
      scrollToFirstError
      initialValues={initialValues}
    >
      <Space
        direction="vertical"
        size={40}
        className="w100 breakword"
      >
        {/* Start Basic Info */}
        <div>
          <Title level={5}>Basic Information</Title>
          {/* Start basic info first row */}
          <Row gutter={gutter} align="bottom" className="mb10">
            <Col span={6}>
              <FormItem
                info={hook.getInfo('firstName')}
                required
              >
                <Input placeholder="First Name" />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('lastName')}
                required
              >
                <Input placeholder="Last Name" />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('nickName')}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('birthday')}
                required
              >
                <PatientDobInputComponent placeholder="DD/MM/YYY" />
              </FormItem>
            </Col>
          </Row>
          {/* End basic info first row */}
          {/* Start basic info second row */}
          <Row gutter={gutter} align="bottom" className="mb10">
            <Col span={6}>
              <div className="flex ai-e">
                <FormItem
                  info={hook.getInfo('gender')}
                  tooltipOnLabel={(
                    <TooltipComponent
                      type={hook.formInput.gender.tooltip.type}
                      title={hook.getTooltip('gender')?.tip}
                    >
                      {hook.getInfo('gender').label}
                    </TooltipComponent>
                  )}
                  required
                >
                  <PatientGenderInputComponent />
                </FormItem>
                <FormItem
                  info={hook.getInfo('genderIdentity')}
                  tooltipOnLabel={(
                    <TooltipComponent
                      type={hook.formInput.genderIdentity.tooltip.type}
                      title={hook.getTooltip('genderIdentity')?.tip}
                    >
                      {hook.getInfo('genderIdentity').label}
                    </TooltipComponent>
                  )}
                >
                  <PatientGenderIdentitySelectComponent />
                </FormItem>
              </div>
            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('race')}
              >
                <PatientRaceInputComponent />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('languages')}
                required
              >
                <PatientSpokenLanguageSelectComponent />
              </FormItem>

            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('behaviorChange')}
              >
                <PatientBehaviorChangeInputComponent />
              </FormItem>
            </Col>
          </Row>
          {/* End basic info second row */}
          {/* Start basic info third row */}
          <Row gutter={gutter} align="bottom" className="mb10">
            <Col span={6}>
              <FormItem
                info={hook.getInfo('height')}
              >
                <HeightInputComponent />
              </FormItem>

            </Col>
            <Col span={6}>
              <WeightInputFormItemComponent
                info={hook.getInfo('weight')}
                required
              />
            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('patientTechLevel')}
              >
                <PatientTechLevelInputComponent />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hook.getName('isTestUser')}
                valuePropName="checked"
              >
                <PatientIsTestUserCheckboxComponent />
              </FormItem>
            </Col>
          </Row>
          {/* End basic info third row */}
        </div>
        {/* End Basic Info */}

        {/* Start Care Team Info */}
        <div>
          <Title level={5}>Care Team Information</Title>
          <FormItem
            noStyle
            shouldUpdate
          >
            {
              ({ getFieldValue }) => {
                const clinicId = hook.getValue('clinicId', getFieldValue);
                return (
                  <>
                    {/* Start Care Team info first row */}
                    <Row gutter={gutter} className="mb10">
                      <Col span={6}>
                        <FormItem info={hook.getInfo('clinicId')}>
                          {
                            showPotentialClinic ? <PotentialClinicSelectComponent />
                              : (
                                <ClinicSelectComponent
                                  disabled
                                  className="no-border-i m0i p0i"
                                  filters={{ id: clinicId }}
                                  getAllClinics
                                />
                              )
                          }
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          info={hook.getInfo('doctorId')}
                          required
                        >
                          <ProviderSelectContainer
                            clinicIds={[clinicId]}
                            externalEmployeeForPatientId={id}
                            onProviderListLoaded={handleOnProviderListLoaded}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          info={hook.getInfo('medicalRecordId')}
                          required
                        >
                          <MedicalIdInputComponent />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={gutter} className="mb10">
                      <Col span={6}>
                        <FormItem
                          info={hook.getInfo('assignedToCA')}
                        >
                          <PatientAssigneeSelectComponent
                            roles={[RoleTypeEnum.CA]}
                            clinicIds={[clinicId]}
                            patientId={id}
                          />
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          info={hook.getInfo('assignedToRD')}
                        >
                          <PatientAssigneeSelectComponent
                            roles={[RoleTypeEnum.RD, RoleTypeEnum.HC]}
                            clinicIds={[clinicId]}
                            patientId={id}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </>
                );
              }
            }
          </FormItem>
        </div>
        {/* End Care Team info first row */}
        {/* End Care Team Info */}

        {/* Start Contact & Login Info */}
        <div>
          <Title level={5}>Contact & Login Information</Title>
          {/* Start Contact & Login info first row */}
          <Row gutter={gutter} align="bottom">
            <Col span={6}>
              <FormItem
                info={hook.getInfo('loginId')}
              >
                <PatientUsernameInputContainer
                  form={hook.form}
                  setInvalid={setInvalid}
                  setValid={setValid}
                />
              </FormItem>
            </Col>
            <Col span={6} className="pb10">
              <ChangePasswordModalComponent
                userId={id}
                userType="PATIENT"
                buttonText="Set Password"
                buttonBlock
              />
            </Col>
          </Row>
          <Row gutter={gutter} align="bottom" className="mb10">
            <Col span={6}>
              <FormItem
                info={hook.getInfo('phone')}
                required
              >
                <PhoneInput prefix="+1" />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('smartPhone')}
                valuePropName="checked"
              >
                <Checkbox>Smart Phone</Checkbox>
              </FormItem>
            </Col>
            <Col>
              <FormItem dependencies={['phone']}>

                {
                  ({ getFieldValue }) => {
                    const phone = getFieldValue('phone');
                    return (
                      <FormItem
                        info={hook.getInfo('canUseForLogin')}
                      >
                        <OTPSwitchContainer
                          phone={phone}
                          initialPhone={initialValues?.phone || undefined}
                        />
                      </FormItem>
                    );
                  }
                }
              </FormItem>
            </Col>
          </Row>

          {/* End Contact & Login info first row */}
          {/* Start Contact & Login info second row */}
          <Row gutter={gutter} align="bottom" className="mb10">
            <Col span={6}>
              <FormItem
                info={hook.getInfo('phone2')}
              >
                <PhoneInput prefix="+1" />
              </FormItem>
            </Col>
            <Col span={6}>
              <EmailInputFormItemComponent
                name={hook.getName('email')}
                label={hook.getLabel('email')}
              />
            </Col>
          </Row>
          {/* End Contact & Login info second row */}

          {/* Start Contact & Login info third row */}
          <Row gutter={gutter} align="bottom" className="mb10" wrap>
            <Col span={12}>
              <Space.Compact className="compact w100">
                <FormItem
                  info={hook.getInfo('address')}
                  className="w70"
                >
                  <Input />
                </FormItem>
                <FormItem
                  className="w30"
                  info={hook.getInfo('address2')}
                >
                  <AddressAptInputComponent />
                </FormItem>
              </Space.Compact>
            </Col>
            <Col span={6}>
              <FormItem
                info={hook.getInfo('city')}
              >
                <AddressCityInputComponent />
              </FormItem>
            </Col>
            <Col span={6}>
              <Space.Compact className="compact w100">
                <FormItem
                  info={hook.getInfo('state')}
                  className="w50"
                >
                  <USStateSelectComponent />
                </FormItem>
                <FormItem
                  className="w50"
                  info={hook.getInfo('zipcode')}
                >
                  <ZipCodeInputComponent />
                </FormItem>
              </Space.Compact>
            </Col>
          </Row>
        </div>
        <div>
          <Title level={5}>Social Support</Title>
          <SocialSupportFormItemComponent />
        </div>
        <div className="w100 flex flex-end jc-sb border-t py20">
          <InterventionSubmitCancelButtons
            onCancel={onCancel}
            disabled={!isValid}
          />
        </div>
      </Space>
    </Form>
  );
};
