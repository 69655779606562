import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { InputType } from '../../../Input/types';

export interface HaveSmartPhoneRadioComponentProps extends InputType<boolean> {

}

export const HaveSmartPhoneRadioComponent = ({
  value,
  onChange,
}: HaveSmartPhoneRadioComponentProps) => (
  <YesNoRadioComponent
    value={value}
    onChange={onChange}
  />
);
