import dayjs from 'dayjs';
import { omit, pick, some } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { BILLING_DATE } from '../../../../constants/timeFormat';
import { PatientInfo, PatientInfoRefetchObj, PatientProvider } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { AppFunctionsService } from '../../../../services/AppFunctionsService';
import { CgmService } from '../../../../services/CgmService';
import { ConsentService } from '../../../../services/ConsentService';
import { ControlLevelService } from '../../../../services/ControlLevelService';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { EnrollmentService } from '../../../../services/EnrollmentService';
import { FollowUpVisitWindowService } from '../../../../services/FollowUpVisitService';
import { MedicationManagementService } from '../../../../services/MedicationManagementService';
import { PatientAssigneesService } from '../../../../services/PatientAssigneesService';
import { PatientBillableTimeService } from '../../../../services/PatientBillableTimeService';
import { PatientDeviceService } from '../../../../services/PatientDeviceService';
import { PatientOutstandingService } from '../../../../services/PatientOutstandingService';
import { PatientService } from '../../../../services/PatientService';
import {
  useAppFunctionsGet,
  useBillableMonthlySummaryQueryCurrentMonth,
  useClinicEventGetFollowupDateRange,
  useMeasurementGetBpBaseline,
  useMeasurementGetMemberLastAllMeasurementTypeTime,
  usePatientGet,
  usePatientGetCgmGoalById,
  usePatientGetConsentForm,
  usePatientGetEnrolledPrograms,
  usePatientGetEnrollment,
  usePatientGetHealthCondition,
  usePatientGetOutstandingItemCount,
  usePatientGetPatientAssignees,
  usePatientGetPatientCgmDevice,
  usePatientGetPatientComplexityById,
  usePatientGetPatientControlLevelById,
  usePatientGetPatientDevice,
  usePatientGetUcMedicationManagement,
  usePhoneReportInfoGetAppInfoByPatientId,
  usePhoneReportInfoSearch
} from '../../../../uc-api-sdk';
import { BaseSortDirection } from '../../../../uc-api-sdk/staticFiles/useReqHook';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { ProviderRestrictedPatientViewComponent } from '../../../auth/component/RestrictedPatientPageComponent/RestrictedPatientPageComponent';
import { RestrictedAccess } from '../../../auth/helper/RestrictedAccess';
import { usePatientInsuranceGetAll } from '../../../Insurance/hook/insuranceApis';
import { PatientProfileComponent } from '../../component/PatientProfileComponent/PatientProfileComponent';
import { DeletedPatientProfilePageComponent } from '../../page/DeletedPatientProfilePageComponent/DeletedPatientProfilePageComponent';

export interface PatientProfileContainerProps {
  children?: ReactNode | ((value: PatientInfo | undefined, isLoading: boolean) => ReactNode);
  showLoadingOverlay?: boolean;
  patientId: string;
  showOnRefetch?: boolean;
  getPatient?: boolean;
  getEnrolledProgram?: boolean;
  getAssignees?: boolean;
  getComplexity?: boolean;
  getFollowUpVisitRange?: boolean;
  getInsurance?: boolean;
  getEnrollment?: boolean;
  getHealthCondition?: boolean;
  getConsent?: boolean;
  getMedicationManagement?: boolean;
  getDevices?: boolean;
  getBillableTime?: boolean;
  getOutstandingItemCount?: boolean;
  getAppVersion?: boolean;
  getLastMeasurementDate?: boolean;
  getControlLevel?: boolean;
  getBpBaseline?: boolean;
  getAppFunction?: boolean;
  getCgmDevice?: boolean;
  getPhoneReportInfoList?: boolean;
  getCgmGoal?: boolean;
}

export const PatientProfileContainer = ({
  children,
  showLoadingOverlay,
  patientId: id,
  showOnRefetch = true,
  getPatient = true,
  getEnrolledProgram = false,
  getAssignees = false,
  getComplexity = false,
  getFollowUpVisitRange = false,
  getInsurance = false,
  getEnrollment = false,
  getHealthCondition = false,
  getConsent = false,
  getMedicationManagement = false,
  getDevices = false,
  getBillableTime = false,
  getOutstandingItemCount = false,
  getAppVersion = false,
  getLastMeasurementDate = false,
  getControlLevel = false,
  getBpBaseline = false,
  getAppFunction = false,
  getCgmDevice = false,
  getPhoneReportInfoList = false,
  getCgmGoal = false,
}: PatientProfileContainerProps) => {
  const patientSearch = usePatientGet({
    options: { sendOnMount: getPatient && !!id },
    params: { memberId: id || '' },
  });

  const enrolledProgramReq = usePatientGetEnrolledPrograms({
    options: { sendOnMount: getEnrolledProgram && !!id },
    params: { memberId: id || '' },
  });

  const patientId = patientSearch.data?.data?.id || '';

  const devicesSearch = usePatientGetPatientDevice({
    params: { memberId: patientId },
    options: { sendOnMount: false }
  });

  const billableTimeSearch = useBillableMonthlySummaryQueryCurrentMonth({
    params: {
      param: {
        memberId: patientId,
        monthOfYear: Number(dayjs().format(BILLING_DATE)),
      }
    },
    options: { sendOnMount: false }
  });

  const patientAssignees = usePatientGetPatientAssignees({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const patientComplexity = usePatientGetPatientComplexityById({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const followUpVisitWindowReq = useClinicEventGetFollowupDateRange({
    params: { id },
    options: { sendOnMount: false },
  });

  const insuranceInfo = usePatientInsuranceGetAll({
    params: { id: patientId },
    options: { sendOnMount: false },
  });

  const enrollmentReq = usePatientGetEnrollment({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const healthConditionReq = usePatientGetHealthCondition({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const consentReq = usePatientGetConsentForm({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const medicationManagementReq = usePatientGetUcMedicationManagement({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const outstandingItemCountReq = usePatientGetOutstandingItemCount({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const controlLevel = usePatientGetPatientControlLevelById({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const bpBaseline = useMeasurementGetBpBaseline({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const cgmGoal = usePatientGetCgmGoalById({
    params: {
      memberId: patientId
    },
    options: {
      sendOnMount: false,
    }
  });

  const appVersionReq = usePhoneReportInfoSearch({
    params: {
      filter: { memberId: patientId },
      pageInfo: {
        page: 1,
        size: 1,
        pagination: true,
        sort: [{
          direction: BaseSortDirection.DESC,
          property: 'updatedAt',
        }],
      },
    },
    options: { sendOnMount: false },
  });

  const phoneReportInfoReq = usePhoneReportInfoGetAppInfoByPatientId({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const lastMeasurementDateReq = useMeasurementGetMemberLastAllMeasurementTypeTime({
    params: { memberId: patientId },
    options: { sendOnMount: false },
  });

  const appFunctionsReq = useAppFunctionsGet({
    params: {
      id: patientId
    },
    options: { sendOnMount: false },
  });

  const cgmDevice = usePatientGetPatientCgmDevice({
    params: {
      memberId: patientId,
    },
    options: {
      sendOnMount: false,
    },
  });

  const isLoadingObj = useMemo<PatientInfo['isLoadingObj']>(() => ({
    patientInfoLoading: patientSearch.isLoading,
    patientComplexityLoading: patientComplexity.isLoading,
    enrolledProgramLoading: enrolledProgramReq.isLoading,
    patientInsuranceLoading: insuranceInfo.isLoading,
    assigneesLoading: patientAssignees.isLoading,
    followUpVisitWindowLoading: followUpVisitWindowReq.isLoading,
    insuranceLoading: insuranceInfo.isLoading,
    enrollmentInfoLoading: enrollmentReq.isLoading,
    healthConditionsLoading: healthConditionReq.isLoading,
    consentLoading: consentReq.isLoading,
    medicationManagementLoading: medicationManagementReq.isLoading,
    deviceLoading: devicesSearch.isLoading,
    billableTimeLoading: billableTimeSearch.isLoading,
    appVersionLoading: appVersionReq.isLoading,
    lastMeasurementDateLoading: lastMeasurementDateReq.isLoading,
    controlLevelLoading: controlLevel.isLoading,
    bpBaselineLoading: bpBaseline.isLoading,
    appFunctionsLoading: appFunctionsReq.isLoading,
    cgmDeviceLoading: cgmDevice.isLoading,
    phoneReportInfoLoading: phoneReportInfoReq.isLoading,
    cgmGoalLoading: cgmGoal.isLoading,
  }), [
    patientSearch.isLoading,
    enrolledProgramReq.isLoading,
    patientComplexity.isLoading,
    insuranceInfo.data,
    patientAssignees.isLoading,
    followUpVisitWindowReq.isLoading,
    insuranceInfo.isLoading,
    enrollmentReq.isLoading,
    healthConditionReq.isLoading,
    consentReq.isLoading,
    medicationManagementReq.isLoading,
    devicesSearch.isLoading,
    billableTimeSearch.isLoading,
    appVersionReq.isLoading,
    lastMeasurementDateReq.isLoading,
    controlLevel.isLoading,
    bpBaseline.isLoading,
    appFunctionsReq.isLoading,
    cgmDevice.isLoading,
    phoneReportInfoReq.isLoading,
    cgmGoal.isLoading
  ]);

  const refetch: PatientInfo['refetch'] = (keys, excludedKeys) => {
    let refetchMap = {} as Partial<Record<keyof PatientInfoRefetchObj, () => Promise<unknown>>>;
    if (getPatient) {
      refetchMap.patientRefetch = patientSearch.refetch;
    }
    if (getEnrolledProgram) {
      refetchMap.enrolledProgramRefetch = enrolledProgramReq.refetch;
    }
    if (getAssignees) {
      refetchMap.assigneesRefetch = patientAssignees.refetch;
    }
    if (getComplexity) {
      refetchMap.patientComplexityRefetch = patientComplexity.refetch;
    }
    if (getFollowUpVisitRange) {
      refetchMap.followUpVisitWindowRefetch = followUpVisitWindowReq.refetch;
    }
    if (getInsurance) {
      refetchMap.patientInsuranceRefetch = insuranceInfo.refetch;
    }
    if (getEnrollment) {
      refetchMap.enrollmentInfoRefetch = enrollmentReq.refetch;
    }
    if (getHealthCondition) {
      refetchMap.healthConditionsRefetch = healthConditionReq.refetch;
    }
    if (getConsent) {
      refetchMap.consentRefetch = consentReq.refetch;
    }
    if (getMedicationManagement) {
      refetchMap.medicationManagementRefetch = medicationManagementReq.refetch;
    }
    if (getDevices) {
      refetchMap.devicesRefetch = devicesSearch.refetch;
    }
    if (getBillableTime) {
      refetchMap.billableTimeRefetch = billableTimeSearch.refetch;
    }
    if (getOutstandingItemCount) {
      refetchMap.outstandingItemCountRefetch = outstandingItemCountReq.refetch;
    }
    if (getAppVersion) {
      refetchMap.appVersionRefetch = appVersionReq.refetch;
    }
    if (getLastMeasurementDate) {
      refetchMap.lastMeasurementDateRefetch = lastMeasurementDateReq.refetch;
    }
    if (getControlLevel) {
      refetchMap.controlLevelRefetch = controlLevel.refetch;
    }
    if (getBpBaseline) {
      refetchMap.bpBaselineRefetch = bpBaseline.refetch;
    }
    if (getAppFunction) {
      refetchMap.appFunctionsRefetch = appFunctionsReq.refetch;
    }
    if (getCgmDevice) {
      refetchMap.cgmDeviceRefetch = cgmDevice.refetch;
    }
    if (getPhoneReportInfoList) {
      refetchMap.phoneReportInfoListRefetch = phoneReportInfoReq.refetch;
    }
    if (getCgmGoal) {
      refetchMap.cgmGoalRefetch = cgmGoal.refetch;
    }
    if (excludedKeys) {
      refetchMap = omit(refetchMap, excludedKeys);
    }
    if (keys) {
      refetchMap = pick(refetchMap, keys);
    }

    return Promise.all(Object.values(refetchMap).map((fn) => fn()));
  };

  useUpdateEffect(() => {
    // simulate initial fetch if the patientId is available
    if (patientSearch.data?.data?.id) {
      // only start the other requests if the patient is found
      // enrolled program request will start at the same time with patient request
      refetch(undefined, ['patientRefetch', 'enrolledProgramRefetch']);
    }
  }, [patientSearch.data?.data?.id]);

  const patientInfo = useMemo(() => {
    if (id) {
      const p = patientSearch.data?.data;
      const enrolledProgram = enrolledProgramReq.data?.data;
      const assignees = patientAssignees.data?.data;
      const complexity = patientComplexity.data?.data;
      const visitWindow = followUpVisitWindowReq.data?.data;
      const insurance = insuranceInfo.data?.data;
      const enrollment = enrollmentReq.data?.data;
      const healthCondition = healthConditionReq.data?.data;
      const consent = consentReq.data?.data;
      const medicationManagement = medicationManagementReq.data?.data;
      const devices = devicesSearch.data?.data;
      const monthlyBillableTime = billableTimeSearch.data?.data;
      const outstandingItemCount = outstandingItemCountReq.data?.data;
      const appVersion = appVersionReq.data?.data?.content?.at(0);
      const lastMeasurementDate = lastMeasurementDateReq.data?.data;
      const control = controlLevel.data?.data;
      const bpBaselineData = bpBaseline.data?.data;
      const appFunctions = appFunctionsReq.data?.data;
      const cgmDeviceData = cgmDevice.data?.data;
      const phoneReportInfoList = phoneReportInfoReq.data?.data;
      const cgmGoalData = cgmGoal.data?.data;

      const res: PatientInfo = {
        id,
        cgmService: new CgmService(cgmGoalData ?? undefined),
        patientInfo: p,
        patientComplexity: complexity,
        patientInsurance: insurance,
        patientHealthCondition: healthCondition,
        patientMedicationManagement: medicationManagement,
        patientInfoService: p ? new PatientService({
          patientInfo: p,
          insurance,
          patientDiagnoses: p.diagnoses,
          healthCondition,
          complexity,
        }) : undefined,
        assignees,
        controlLevelService: (control || bpBaselineData) ? new ControlLevelService(
          control ?? undefined,
          bpBaselineData ?? undefined
        ) : undefined,
        assigneesService: assignees ? new PatientAssigneesService(assignees) : undefined,
        patientAppVersion: appVersion,
        lastMeasurementDate: lastMeasurementDate ?? [],
        enrolledProgram,
        enrolledProgramService: new EnrolledProgramService(enrolledProgram ?? undefined),
        followUpVisitWindowService: visitWindow
          ? new FollowUpVisitWindowService(visitWindow)
          : undefined,
        enrollmentInfo: enrollment,
        enrollmentService: new EnrollmentService(enrollment ?? undefined),
        consentInfo: consent,
        consentService: new ConsentService(consent ?? undefined),
        medicationManagementService: medicationManagement !== undefined
          ? (
            new MedicationManagementService(medicationManagement ?? undefined)
          ) : undefined,
        devicesService: new PatientDeviceService(devices, cgmDeviceData),
        billableTimeService: new PatientBillableTimeService(monthlyBillableTime),
        outstandingService: new PatientOutstandingService(outstandingItemCount),
        appFunctionsService: new AppFunctionsService(appFunctions ?? undefined),
        phoneReportInfoList: phoneReportInfoList ?? undefined,
        isLoadingObj,
        isLoading: some(isLoadingObj, (v) => v),
        refetch,
      };
      return res;
    }
    return undefined;
  }, [
    id,
    lastMeasurementDateReq.data,
    appVersionReq.data,
    patientSearch.data,
    enrolledProgramReq.data,
    patientComplexity.data,
    patientAssignees.data,
    followUpVisitWindowReq.data,
    insuranceInfo.data,
    enrollmentReq.data,
    healthConditionReq.data,
    consentReq.data,
    medicationManagementReq.data,
    isLoadingObj,
    devicesSearch.data,
    billableTimeSearch.data,
    outstandingItemCountReq.data,
    controlLevel.data,
    bpBaseline.data,
    appFunctionsReq.data,
    cgmDevice.data,
    phoneReportInfoReq.data,
    cgmGoal.data
  ]);

  const renderChildren = () => {
    if (children) {
      if (typeof children === 'function') {
        return children(patientInfo, some(isLoadingObj, (v) => v));
      }
      return children;
    }
    return (
      <PatientProfileComponent />
    );
  };

  return (
    <FetchComponent
      info={patientSearch}
      showLoadingOverlay={showLoadingOverlay}
      alwaysShowChildren
      showOnRefetch={showOnRefetch}
    >
      {
        (value) => {
          const patientResponse = value?.rawValue;
          if (RestrictedAccess.isPatientRestricted(patientResponse)) {
            return <ProviderRestrictedPatientViewComponent />;
          }
          if (patientSearch.data?.data?.deleted) {
            return <DeletedPatientProfilePageComponent />;
          }
          return (
            <PatientProvider info={patientInfo}>
              {renderChildren()}
            </PatientProvider>
          );
        }
      }
    </FetchComponent>
  );
};
