import dayjs, { Dayjs } from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import {
  first,
  last,
  partialRight
} from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { useDeepCompareEffectWithInitialRender } from '../../../../hooks/useDeepCompareEffect';
import {
  CgmBrandEnum,
  CgmMetricEnum,
  CgmPercentGoal,
  useCgmGetPatientAgp,
  useCgmMobileGetPatientCgmReading
} from '../../../../uc-api-sdk';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { PluralComponent } from '../../../../uiComponent/PluralComponent/PluralComponent';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { CgmVitalSummaryComponent } from '../../component/CgmVitalSummaryComponent/CgmVitalSummaryComponent';
import { FromDateToDateDayjs } from '../../type';
import { CGMChartContainer } from '../CGMChartContainer/CGMChartContainer';
import { CGMDatePickerContainer } from '../CGMDatePickerContainer/CGMDatePickerContainer';

import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { GenerateCgmReportButtonContainer } from '../../../print/CgmReport/container/GenerateCgmReportButtonContainer/GenerateCgmReportButtonContainer';
import './CGMVitalContainer.scss';
import { CGMDeviceBrandLogoComponent } from '../../../CGMDevice/component/CGMDeviceBrandLogoComponent/CGMDeviceBrandLogoComponent';
import { Icons } from '../../../../icons/Icons';

dayjs.extend(dayjsTimezone);

export interface CGMVitalContainerProps {
  patientId: string;
  percentGoals?: CgmPercentGoal[];
  startDate?: Dayjs;
  endDate?: Dayjs;
  showGenerateReportButton?: boolean;
  isEligibleForCGMReview?: boolean;
}

export const CGMVitalContainer = ({
  patientId,
  percentGoals,
  startDate,
  endDate,
  showGenerateReportButton,
  isEligibleForCGMReview
}: CGMVitalContainerProps) => {
  const { info } = usePatientContext();
  const { devicesService } = info || {};
  const [fromToDate, setFromToDate] = useState<FromDateToDateDayjs>();
  const [availableDays, setAvailableDays] = useState<number>();
  const cgmReadingInfo = useCgmMobileGetPatientCgmReading({
    options: { sendOnMount: false }
  });
  const brand = info?.patientInfo?.patientCgmDevice?.brand;
  const isDexcom = !!(brand === CgmBrandEnum.DEXCOM);

  const agpInfo = useCgmGetPatientAgp({
    options: { sendOnMount: false }
  });

  const fetchCGMReadingAvailableDates = (
    fromDate?: Dayjs,
    toDate?: Dayjs,
    includeSummary?: boolean,
  ) => {
    const startTime = fromDate?.startOf('day')?.utc()?.toISOString();
    const endTime = toDate ? dayjs(toDate).endOf('day').toISOString() : undefined;
    cgmReadingInfo.send({
      params: {
        request: {
          patientId,
          includeAvailableDates: true,
          includeRawData: false,
          includeSummary,
          startTime: startTime || dayjs(endTime).subtract(1, 'year').startOf('day').toISOString(),
          endTime: endTime || dayjs().endOf('day').toISOString(),
        }
      }
    });
  };

  const handleSetFromToDate = (
    v?: FromDateToDateDayjs,
    includeSummary?: boolean,
  ) => {
    setFromToDate(v);
    fetchCGMReadingAvailableDates(v?.fromDate, v?.toDate, includeSummary);
  };

  useDeepCompareEffectWithInitialRender(() => {
    const availableDates = cgmReadingInfo?.data?.data?.availableDates;
    const timezone = cgmReadingInfo?.data?.data?.context?.patientContext?.timezone ?? undefined;
    const availableDays = availableDates?.length || 0;
    setAvailableDays(availableDays);
    if (isEligibleForCGMReview) {
      handleSetFromToDate({
        toDate: endDate || dayjs().endOf('days'),
        fromDate: startDate || dayjs().startOf('days').add(-29, 'days'),
      });
      return;
    }
    if (!fromToDate && availableDates) {
      // only for initial render

      // get last date with data
      const firstInAvailable = first(availableDates);
      const lastInAvailable = last(availableDates);
      const lastAvailable = (
        dayjs(firstInAvailable).isBefore(dayjs(lastInAvailable))
          ? lastInAvailable
          : firstInAvailable
      );

      // auto-select last 14 days from last date with data
      const firstAvailable = dayjs().startOf('day').subtract(13, 'day').toISOString();

      handleSetFromToDate({
        fromDate: dayjs(firstAvailable).tz(timezone).startOf('day'),
        toDate: dayjs(lastAvailable)?.tz(timezone).endOf('day'),
      }, true);
    }
  }, [
    fromToDate,
    cgmReadingInfo?.data?.data?.availableDates,
  ]);

  useEffect(() => {
    ApiRequestHelper.tryCatch(agpInfo.send({
      params: {
        request: { patientId }
      }
    }), {
      success: '',
      error: '',
      onSuccess: (res) => {
        const {
          startTime,
          endTime,
          isApplicable,
          context,
        } = res?.data || {};
        if (isApplicable && startTime && endTime) {
          const timezone = context?.patientContext?.timezone ?? undefined;
          handleSetFromToDate({
            fromDate: TimezoneService.calcDateTimeDayjs(startTime, timezone),
            toDate: TimezoneService.calcDateTimeDayjs(endTime, timezone),
          }, true);
        } else {
          // no agp data, check with cgmReadingInfo to get last 14 days
          handleSetFromToDate();
        }
      },
      onError: () => {
        // no agp data, check with cgmReadingInfo to get last 14 days
        handleSetFromToDate();
      }
    });
  }, [startDate, endDate]);

  const dataInHours = useMemo(() => {
    const { metrics } = cgmReadingInfo?.data?.data || {};
    const activeRate = metrics?.find(
      m => m.metricName === CgmMetricEnum.CGM_ACTIVE_PERCENTAGE
    )
      ?.values?.value || 0;
    const dataDays = metrics?.find(
      m => m.metricName === CgmMetricEnum.CGM_DATA_DAYS
    )?.values?.value || 0;
    return 24 * dataDays * (activeRate / 100);
  }, [cgmReadingInfo?.data?.data?.metrics]);
  return (
    <div className="mb60 cgm-vital-container">
      <FetchComponent
        info={cgmReadingInfo}
        alwaysShowChildren
      >
        {
        isDexcom ? (
          <div className="cgm-vital-container__header">
            <PatientCommonCardTitleComponent>
              Continuous Glucose Monitoring
            </PatientCommonCardTitleComponent>
            <div className="flex flex-wrap jc-sb">
              <div>
                Patient is listed as a
                <CGMDeviceBrandLogoComponent brand={brand} />
                user. To review patient's data, please login to
                {' '}
                <a className="clarity-dexcom-com-link" target="_blank" href="https://clarity.dexcom.com/professional/" rel="noreferrer">
                  clarity.dexcom.com
                  <Icons.LinkSymbolIcon />
                </a>
                {' '}
                and log your time spent on Clarity as “offline billable time”.
              </div>
              <GenerateCgmReportButtonContainer
                startDate={fromToDate?.fromDate || ''}
                endDate={fromToDate?.toDate || ''}
                isDexcom
              />
            </div>
          </div>
        ) : (
          <>
            <div className="cgm-vital-container__header">
              <div className="flex ai-s jc-sb">
                <PatientCommonCardTitleComponent>
                  Continuous Glucose Monitoring
                </PatientCommonCardTitleComponent>

                <div className="flex gap3">
                  <CGMDatePickerContainer
                    patientId={patientId}
                    value={fromToDate ?? undefined}
                    onChange={partialRight(handleSetFromToDate, true)}
                  />
                  {showGenerateReportButton && (
                    <GenerateCgmReportButtonContainer
                      startDate={fromToDate?.fromDate || ''}
                      endDate={fromToDate?.toDate || ''}
                      disabled={dataInHours < 72}
                      isDexcom={false}
                    />
                  )}
                </div>

              </div>

              <div className="flex ai-c">
                Based on your selection,
                {
                    availableDays !== undefined
                      ? (
                        <PluralComponent
                          value={availableDays || 0}
                          unit="day"
                        />
                      ) : <EmptyComponent />
                  }
                of CGM data are available
              </div>
            </div>
            <LoadingOverlayComponent isLoading={!fromToDate}>
              <CgmVitalSummaryComponent
                metricData={cgmReadingInfo?.data?.data?.metrics || undefined}
                cgmDeviceInfo={devicesService?.getCgmDevice()}
                percentGoals={percentGoals}
                startDate={fromToDate?.fromDate}
                endDate={fromToDate?.toDate}
              />
              <CGMChartContainer
                patientId={patientId}
                startDate={fromToDate?.fromDate || ''}
                endDate={fromToDate?.toDate || ''}
              />
            </LoadingOverlayComponent>
          </>
        )
        }
      </FetchComponent>
    </div>
  );
};
