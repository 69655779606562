import { map } from 'lodash';
import { HealthConditionItem } from '../../../../uc-api-sdk';

import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import './PrintHealthConditionsComponent.scss';

export interface PrintHealthConditionsComponentProps {
  healthConditions?: HealthConditionItem[];
}

export const PrintHealthConditionsComponent = ({
  healthConditions,
}: PrintHealthConditionsComponentProps) => (
  <div className="print-health-conditions">
    {
        map(healthConditions, (hc, i) => (
          <div className="print-health-conditions__item" key={i}>
            <DisplayOrEmptyComponent value={hc.code} />
            <DisplayOrEmptyComponent value={hc.condition} />
          </div>
        ))
      }
  </div>
);
