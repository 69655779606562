import { ConditionEnum } from '../../uc-api-sdk';

export const getText = (value?: ConditionEnum) => {
  switch (value) {
    case ConditionEnum.BLOOD_PRESSURE_MANAGEMENT: return 'Blood Pressure Management';
    case ConditionEnum.CANCER: return 'Cancer';
    case ConditionEnum.CHRONIC_KIDNEY_DISEASE: return 'Chronic Kidney Disease';
    case ConditionEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE: return 'Chronic Obstructive Pulmonary Disease';
    case ConditionEnum.CONGESTIVE_HEART_FAILURE: return 'Congestive Heart Failure';
    case ConditionEnum.GLUCOSE_MANAGEMENT: return 'Glucose Management';
    case ConditionEnum.LIPID_MANAGEMENT: return 'Lipid Management';
    case ConditionEnum.UNDERWEIGHT: return 'Underweight';
    case ConditionEnum.UNMAPPED: return 'Unmapped';
    case ConditionEnum.WEIGHT_REDUCTION: return 'Weight Reduction';
    case ConditionEnum.WEIGHT_REDUCTION_GLP: return 'Weight Reduction GLP-1';
    case ConditionEnum.COVID: return 'Covid';
    case ConditionEnum.DEPRESSION: return 'Depression';
    case ConditionEnum.CHRONIC_PAIN: return 'Chronic Pain';
    default: return null;
  }
};

export interface ConditionEumComponentProps {
  value?: ConditionEnum;
}

export const ConditionEumComponent = ({
  value,
}: ConditionEumComponentProps) => (
  <span>{getText(value)}</span>
);
