import { message } from 'antd';
import { isEqual, map } from 'lodash';
import { useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { useBlockUnmountContext } from '../../../../contexts/BlockUnmountContext/BlockUnmountContext';
import { ErrorContextProvider } from '../../../../contexts/ErrorContext/ErrorContext';
import { useFutureClinicEventContext } from '../../../../contexts/FutureClinicEventContext/FutureClinicEventContext';
import { NestedFormContextSubmitCallback, useNestedFormControlContext } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import { useWorklistMTPRContext } from '../../../../contexts/WorklistMTPRContext/WorklistMTPRContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import {
  ClinicEvent,
  VisitMethodEnum,
  VisitTypeEnum,
  CGMOptOut
} from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { Step, StepsComponent } from '../../../../uiComponent/StepsComponent/StepsComponent';
import { WorklistCgmReviewComponent } from '../../../../uiComponent/WorklistCgmReviewComponent/WorklistCgmReviewComponent';
import { useWorklistActions } from '../../hook/useWorklistActions';
import { WorklistComponentCommonProps } from '../../types';
import { VisitSummaryInputComponent } from '../VisitSummaryInputComponent/VisitSummaryInputComponent';
import { WorklistButtonComponent } from '../WorklistButtonComponent/WorklistButtonComponent';
import { WorklistStepEnum } from '../WorklistDrawerComponent/WorklistDrawerComponent';
import { WorklistPatientInfoComponent } from '../WorklistPatientInfoComponent/WorklistPatientInfoComponent';
import { WorklistSectionComponent } from '../WorklistSectionComponent/WorklistSectionComponent';
import { WorklistStepTitleComponent } from '../WorklistStepTitleComponent/WorklistStepTitleComponent';
import { WorklistVisitInfoComponent } from '../WorklistVisitInfoComponent/WorklistVisitInfoComponent';

export type NonTechWorklistRequiredField = 'FOLLOW_UP_SCHEDULE' | 'MTPR_NOTE';

export interface NonTechWorklistComponentProps extends WorklistComponentCommonProps {
  followupVisitSkipped: boolean;
  cgmOptOut?: CGMOptOut;
  onClose?: (hasUpdate?: boolean) => void;
}

export const NonTechWorklistComponent = ({
  clinicEvent,
  isLoading,
  onClose,
  onCheckOut,
  onClinicEventUpdate,
  onCompleteCharting,
  followupVisitSkipped,
  isEligibleForCGMReview,
  cgmReportCareNote,
  onGenerateCgmReport,
  cgmOptOut,
}: NonTechWorklistComponentProps) => {
  const {
    hasFutureClinicEvent,
  } = useFutureClinicEventContext();
  const [
    step,
    setStep,
  ] = useState<WorklistStepEnum | undefined>(map(WorklistStepEnum)[0]);
  const {
    getVisitSummary,
    isVisitSummaryChanged,
    setVisitSummary,
    validateVisitSummary,
    errors,
    handleSetError,
  } = useWorklistActions<NonTechWorklistRequiredField>(clinicEvent);
  const {
    handleOnSubmit,
    isSubmitting,
    disabled,
  } = useNestedFormControlContext();
  const {
    shouldShowMTPR,
    isMTPRValid,
    completeMTPR,
    shouldCompleteMTPR
  } = useWorklistMTPRContext();
  const {
    blockPrompt,
  } = useBlockUnmountContext();

  const getClinicEventValues = (): ClinicEvent => ({
    visitSummary: getVisitSummary(),
    followupVisitSkipped,
    cgmOptOut,
  });

  const handleSubmit = async (
    cb?: NestedFormContextSubmitCallback,
  ) => {
    blockPrompt({
      next: () => {
        if (disabled) cb?.(false, '');
        if (step === WorklistStepEnum.FOLLOW_UP_SCHEDULE) {
          cb?.();
          return;
        }
        handleOnSubmit({
          onSubmit: cb,
        });
      }
    });
  };

  const handleOnChangeStep = (newStep?: WorklistStepEnum) => {
    handleSubmit((submitSent, error) => {
      if (!submitSent && error) {
        if (error === 'Failed to submit') {
          message.error(`${error}. Please check for required fields`);
        }
        return;
      }
      setStep(newStep);
    });
  };

  const debouncedHandleOnChangeStep = useDebounce(
    handleOnChangeStep,
    250,
    [handleOnChangeStep]
  );

  const handleCheckOut = () => {
    onCheckOut?.(getClinicEventValues());
  };

  const handleCompleteCharting = () => {
    let hasError = !validateVisitSummary();
    if (!followupVisitSkipped && !hasFutureClinicEvent) {
      hasError = true;
      handleSetError('FOLLOW_UP_SCHEDULE', true);
    }
    if (shouldShowMTPR && !isMTPRValid) {
      hasError = true;
      handleSetError('MTPR_NOTE', true);
    }
    if (hasError) return;

    handleSubmit(() => {
      if (shouldCompleteMTPR) {
        completeMTPR({}, false);
      }
      onCompleteCharting?.(getClinicEventValues());
    });
  };

  const handleClose = () => {
    handleSubmit((submitSent) => {
      const shouldUpdateEvent = (
        submitSent
        || isVisitSummaryChanged
        || (
          followupVisitSkipped !== undefined
          && followupVisitSkipped !== clinicEvent.followupVisitSkipped
        )
        || (
          cgmOptOut !== undefined
          && !isEqual(cgmOptOut, clinicEvent.cgmOptOut)
        )
      ); // prevent unnecessary request
      if (shouldUpdateEvent) {
        onClinicEventUpdate?.(getClinicEventValues());
      }
      onClose?.(shouldUpdateEvent);
    });
  };

  useUpdateEffect(() => {
    if (hasFutureClinicEvent || followupVisitSkipped) {
      // remove error when follow up is scheduled or skipped
      handleSetError('FOLLOW_UP_SCHEDULE', false);
    }
  }, [hasFutureClinicEvent, followupVisitSkipped]);

  useUpdateEffect(() => {
    if (isMTPRValid) {
      handleSetError('MTPR_NOTE', false);
    }
  }, [isMTPRValid]);

  const renderStepTitle = (stepEnum: WorklistStepEnum) => {
    let showRequiredMark = false;
    switch (stepEnum) {
      case WorklistStepEnum.FOLLOW_UP_SCHEDULE:
        showRequiredMark = !!errors.FOLLOW_UP_SCHEDULE;
        break;
      case WorklistStepEnum.GOALS_EDUCATION_SETUP:
        showRequiredMark = !!errors.MTPR_NOTE;
        break;
      default:
    }
    return (
      <WorklistStepTitleComponent
        key={stepEnum}
        step={stepEnum}
        showRequiredMark={showRequiredMark}
      />
    );
  };

  return (
    <div className="flex fr-reverse">
      <FixedComponent className="worklist-navigation">
        <FixedComponent.Child isFixed>
          <WorklistVisitInfoComponent
            visitType={clinicEvent.visitType as VisitTypeEnum}
            visitMethod={clinicEvent.visitMethod as VisitMethodEnum}
            description={clinicEvent.description || ''}
            startTime={clinicEvent.checkInTime || ''}
            endTime={clinicEvent.checkOutTime || ''}
            onClose={handleClose}
          />
        </FixedComponent.Child>
        <FixedComponent.Child className="worklist-drawer-content">
          <WorklistPatientInfoComponent />
          <StepsComponent
            className="my15"
            onChange={debouncedHandleOnChangeStep}
            value={step}
            steps={map(WorklistStepEnum, (e): Step<WorklistStepEnum> => ({
              value: e,
              title: renderStepTitle(e),
              status: step === e ? 'process' : 'wait'
            }))}
          />
          {
            isEligibleForCGMReview && clinicEvent.visitType === VisitTypeEnum.ADDITIONAL
              ? (
                <WorklistCgmReviewComponent
                  cgmReportCareNote={cgmReportCareNote}
                  onSubmit={onGenerateCgmReport}
                  showRequiredMark={errors.VISIT_SUMMARY}
                />
              )
              : (
                <VisitSummaryInputComponent
                  defaultValue={getVisitSummary() ?? undefined}
                  onChange={setVisitSummary}
                  disabled={disabled}
                  showRequiredMark={errors.VISIT_SUMMARY}
                />
              )
          }
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <WorklistButtonComponent
            clinicEvent={clinicEvent}
            onCheckout={handleCheckOut}
            onCompleteCharting={handleCompleteCharting}
            onClose={handleClose}
          />
        </FixedComponent.Child>
      </FixedComponent>
      <ErrorContextProvider value={errors}>
        {
          step
          && (
            <div className="worklist-section">
              <OverlayLoading loading={isLoading || isSubmitting} />
              <WorklistSectionComponent
                step={step}
                clinicEvent={clinicEvent}
                followupVisitSkipped={followupVisitSkipped}
                isEligibleForCGMReview={isEligibleForCGMReview
                  && clinicEvent.visitType === VisitTypeEnum.ADDITIONAL}
                cgmOptOut={cgmOptOut}
              />
            </div>
          )
        }
      </ErrorContextProvider>
    </div>
  );
};
