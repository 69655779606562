import { CareNoteTagEnum } from '../../uc-api-sdk';
import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';

export interface CareNoteTagEnumComponentProps {
  value: CareNoteTagEnum;
}

export const CareNoteTagEnumComponent = ({
  value,
}: CareNoteTagEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case CareNoteTagEnum.MONTHLY_REVIEW_RD_HC: return 'Monthly Review (RD/HC)';
      case CareNoteTagEnum.MONTHLY_REVIEW_CA: return 'Monthly Review (CM/CA)';
      case CareNoteTagEnum.GOAL_UPDATE: return 'Customized A1C Goal';
      case CareNoteTagEnum.OFFLINE_BILLABLE_TIME: return 'Add Offline Billable Time';
      case CareNoteTagEnum.CGM_REPORT: return 'CGM Review';
      default:
        return <BasicEnumComponent value={value} />;
    }
  };

  return (<span>{getText()}</span>);
};
