import { Form, FormListFieldData, FormListOperation } from 'antd';
import { ReactNode } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { LifestyleAssessmentInputCardLayout } from '../LifestyleAssessmentComponent/layout/LifestyleAssessmentInputCardLayout';

interface LifestyleAssessmentFormListChildrenProps {
  fields: FormListFieldData[];
  remove: (idx: number) => void;
}

export interface LifestyleAssessmentFormListComponentProps {
  formListName: string;
  className?: string;
  children: (props: LifestyleAssessmentFormListChildrenProps) => ReactNode;
  onAdd?: () => void;
  addItemTemplate?: unknown;
}

export const LifestyleAssessmentFormListComponent = ({
  formListName,
  className = '',
  children,
  onAdd,
  addItemTemplate,
}: LifestyleAssessmentFormListComponentProps) => {
  const handleOnAdd = (
    add: FormListOperation['add']
  ) => () => {
    add(addItemTemplate || {});
    onAdd?.();
  };

  return (
    <LifestyleAssessmentInputCardLayout
      className={className}
    >
      <Form.List name={formListName}>
        {
          (fields, { add, remove }) => (
            <div>
              {
                children({
                  fields,
                  remove,
                })
              }
              <div>
                <LinkButton
                  className="mt10"
                  onClick={handleOnAdd(add)}
                  useBlueColor
                >
                  <div className="flex ai-c">
                    <PlusOutlined />
                    <span>{`Add${fields?.length > 0 ? ' another' : ''}`}</span>
                  </div>
                </LinkButton>
              </div>
            </div>
          )
        }
      </Form.List>
    </LifestyleAssessmentInputCardLayout>
  );
};
