import { useCallback, useMemo } from 'react';
import { FormProps } from 'antd';
import { FormSubmitValue, ShouldShow, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import {
  Address,
  AssignDeviceInfo,
  DeviceAssignment,
  DeviceDeliveryMethodEnum,
  DeviceModelEnum,
  LoanDevice,
  PatientCgmDevice,
  PatientTechLevelEnum,
  VitalEnumType,
} from '../../../uc-api-sdk';
import { DeviceHelper } from '../../device/helper';
import { AddressSubmitValue, useAddressForm } from '../../../hooks/formHook/useAddressForm';

export interface SubmitValue {
  techLevel: PatientTechLevelEnum;
  deliveryMethod: DeviceDeliveryMethodEnum;
  deviceList: DeviceAssignment[];
  cgmDevice?: PatientCgmDevice;
  shippingAddress?: Address;
  loanDevice?: LoanDevice;
}

export const useAssignDeviceForm = () => {
  const addressForm = useAddressForm();

  const addressFormInputs = useMemo(() => ({
    ...Object.assign(
      {},
      ...(
        (Object.keys(addressForm.formInput) as (keyof typeof addressForm.formInput)[]).map((
          k,
        ) => ({
          [k]: {
            ...addressForm.formInput[k],
            name: ['shippingAddress', k],
          }
        }))
      )
    ) as typeof addressForm.formInput,
  }), [addressForm.formInput]);

  const factory = useFormHookFactory({
    techLevel: {
      name: 'techLevel',
      label: 'Evaluate patient\'s tech-level',
    },
    deliveryMethod: {
      name: 'deliveryMethod',
      label: 'How will patient get device?',
    },
    deviceList: {
      name: 'deviceList',
      label: '',
    },
    loanDevice: {
      name: 'loanDevice',
      label: '',
    },
    ...addressFormInputs
  });

  const shouldShowDeliveryMethod: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('techLevel')) !== undefined
  ), []);

  const shouldShowDevice: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('deliveryMethod')) !== undefined
  ), []);

  const shouldShowConfirmButton: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('loanDevice'))
    || getFieldValue(factory.getName('deviceList'))?.length
  ), []);

  const shouldShowAddressInput: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('deviceList'))?.length
    && getFieldValue(factory.getName('deliveryMethod')) === DeviceDeliveryMethodEnum.SHIP
  ), []);

  const parseSubmitValue = (values: SubmitValue) => {
    const { deviceList, shippingAddress, ...otherValues } = values;
    const parsedDeviceList = [] as DeviceAssignment[];
    deviceList?.forEach((device: AssignDeviceInfo) => {
      const { deviceModel, deviceMethod, cuffSize } = device;
      if (!deviceModel) return;
      if (deviceModel === DeviceModelEnum.CGM) {
        if (device) {
          parsedDeviceList.push({
            ...device,
            vitalType: VitalEnumType.CGM,
          });
        }
        return;
      }
      const vitalType = DeviceHelper.getVitalTypeByModel(deviceModel);
      const shouldSaveCuffSize = vitalType === VitalEnumType.BP && cuffSize;
      const shouldSaveDevice = device?.deviceId;
      parsedDeviceList.push({
        vitalType,
        monitorMethod: deviceMethod,
        cuffSize: shouldSaveCuffSize ? cuffSize : undefined,
        devices: shouldSaveDevice ? [device] : undefined,
      }) as DeviceAssignment;
    });

    const {
      address,
      address2,
      city,
      state,
      zipcode,
    } = (shippingAddress || {}) as AddressSubmitValue;
    let parsedShippingAddress: Address | undefined;
    if (address && city && state && zipcode) {
      parsedShippingAddress = {
        streetName: address,
        unit: address2,
        city,
        state,
        postCode: zipcode,
      };
    }

    return {
      ...otherValues as SubmitValue,
      deviceList: parsedDeviceList,
      shippingAddress: parsedShippingAddress,
    };
  };

  const handleSubmit: (onSubmit?: (values: SubmitValue) => Promise<void> | void) => FormProps['onFinish'] = (
    useCallback((onSubmit) => (values) => {
      const submitValue = parseSubmitValue(values);
      onSubmit?.(submitValue);
    }, [])
  );

  const response = useMemo(() => ({
    ...factory,
    shouldShowDeliveryMethod,
    shouldShowDevice,
    shouldShowConfirmButton,
    shouldShowAddressInput,
    parseSubmitValue,
    handleSubmit,
  }), [
    factory,
    shouldShowDeliveryMethod,
    shouldShowDevice,
    shouldShowConfirmButton,
    shouldShowAddressInput,
    parseSubmitValue,
    handleSubmit,
  ]);

  return response;
};

export type UseAssignDeviceFormSubmitValue = FormSubmitValue<typeof useAssignDeviceForm>;
