import { Button } from 'antd';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import './OldAppVersionNoteComponent.scss';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { UcTransitionGuideComponent } from '../UcTransitionGuideComponent/UcTransitionGuideComponent';
import { EnrolledProgramStatusEnum, MonitorMethodEnum } from '../../../../uc-api-sdk';

export interface OldAppVersionNoteComponentProps {
  patientInfo?: PatientInfo;
}

export const OldAppVersionNoteComponent = ({
  patientInfo
}: OldAppVersionNoteComponentProps) => {
  // Only show Old App Banner for enrolled patients with App monitoring and not converted
  if (
    patientInfo?.enrolledProgram?.enrolledStatus !== EnrolledProgramStatusEnum.ENROLLED
  || !patientInfo?.devicesService?.getDeviceList()?.some(
    d => d.monitorMethod === MonitorMethodEnum.APP_MONITOR
  )
  || patientInfo?.phoneReportInfoList?.icPhoneReportInfo?.mobileInfo?.isNewUC
  ) {
    return null;
  }

  const {
    isOpen: isTooltipOpen,
    open: setOpenTooltip,
    close: setCloseTooltip,
  } = useOpen();

  const ucTransitionGuideBtn = (
    <Button type="text" onClick={setOpenTooltip}>
      <div className="text-blue b5 bg-transparent">
        UC+ Transition Guide
      </div>
    </Button>
  );

  return (
    <AlertCardComponent
      className="OldAppVersionNoteComponent"
      type="info"
      align="left"
      message={(
        <div className="OldAppVersionNoteComponent__banner">
          <div className="OldAppVersionNoteComponent__note">
            <strong>Unified Care+ Conversion</strong>
            {' '}
            This patient has not logged in to the new “Unified Care+” app.
            Please encourage and assist the patient with downloading the new app.
          </div>
          <div>
            {ucTransitionGuideBtn}
            <UcTransitionGuideComponent
              patientId={patientInfo?.id}
              isOpen={isTooltipOpen}
              close={setCloseTooltip}
            />
          </div>
        </div>
      )}
    />
  );
};
