import { sum } from 'lodash';
import { CgmMetricEnum, CgmPercentGoal } from '../../../../uc-api-sdk';
import YAxis from '../CgmVitalSummaryComponent/CgmBarChartYAxisComponent';
import { CGM_BAR_CHART_HEIGHT } from '../../constant/cgmConstant';
import './CgmBarChartImageComponent.scss';

export interface CgmBarChartComponentImageProps {
  tbrLevel2Min?: number;
  tbrLevel1Min?: number;
  tirMin?: number;
  tarLevel1Min?: number;
  tarLevel2Min?: number;
  percentGoals?: CgmPercentGoal[];
}

export const CgmBarChartImageComponent = ({
  tbrLevel2Min,
  tbrLevel1Min,
  tirMin,
  tarLevel1Min,
  tarLevel2Min,
  percentGoals,
}: CgmBarChartComponentImageProps) => {
  const totalMin = sum([tbrLevel2Min, tbrLevel1Min, tirMin, tarLevel1Min, tarLevel2Min]);

  const tarLevel1Bound = percentGoals?.find(
    i => i.metric === CgmMetricEnum.TAR_LEVEL_1
  )?.thresholdRange?.lowerBound ?? 140;
  const tarLevel2Bound = percentGoals?.find(
    i => i.metric === CgmMetricEnum.TAR_LEVEL_2
  )?.thresholdRange?.lowerBound ?? 200;

  if (totalMin === undefined || totalMin === 0) {
    return null;
  }

  const tarLevel2Height = tarLevel2Min && tarLevel2Min > 0 ? Math.max((
    tarLevel2Min / totalMin) * CGM_BAR_CHART_HEIGHT, 0.1) : 0;
  const tarLevel1Height = tarLevel1Min && tarLevel1Min > 0 ? Math.max((
    tarLevel1Min / totalMin) * CGM_BAR_CHART_HEIGHT, 0.1) : 0;
  const tirHeight = tirMin && tirMin > 0 ? Math.max((
    tirMin / totalMin) * CGM_BAR_CHART_HEIGHT, 0.1) : 0;
  const tbrLevel1Height = tbrLevel1Min && tbrLevel1Min > 0 ? Math.max((
    tbrLevel1Min / totalMin) * CGM_BAR_CHART_HEIGHT, 0.1) : 0;
  const tbrLevel2Height = tbrLevel2Min && tbrLevel2Min > 0 ? Math.max((
    tbrLevel2Min / totalMin) * CGM_BAR_CHART_HEIGHT, 0.1) : 0;

  return (
    <div className="flex">
      <div className="CgmBarChartImageComponent__label ml5">
        {/* Y axis labels for CGM bar chart */}
        <YAxis
          tarLevel2Bound={tarLevel2Bound}
          tarLevel1Bound={tarLevel1Bound}
          tarLevel2Height={tarLevel2Height}
          tarLevel1Height={tarLevel1Height}
          tirHeight={tirHeight}
          tbrLevel1Height={tbrLevel1Height}
          tbrLevel2Height={tbrLevel2Height}
          barChartHeight={CGM_BAR_CHART_HEIGHT}
        />
      </div>
      <div className="flex CgmBarChartImageComponent__bar-chart">
        <div className="CgmBarChartImageComponent__tar-level-2-bar" style={{ height: `${tarLevel2Height}px` }} />
        <div className="CgmBarChartImageComponent__tar-level-1-bar" style={{ height: `${tarLevel1Height}px` }} />
        <div className="CgmBarChartImageComponent__tir-bar" style={{ height: `${tirHeight}px` }} />
        <div className="CgmBarChartImageComponent__tbr-level-1-bar" style={{ height: `${tbrLevel1Height}px` }} />
        <div className="CgmBarChartImageComponent__tbr-level-2-bar" style={{ height: `${tbrLevel2Height}px` }} />
      </div>
    </div>
  );
};
