import { ReactNode } from 'react';
import { Checkbox, Form } from 'antd';
import { CGMServiceNextScheduleSubmitValue, useCGMServiceNextScheduleForm } from '../../hook/useCGMServiceNextScheduleForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { FormType } from '../../../Input/types';
import { CGMServiceNotWearDropdownComponent } from './CGMServiceNotWearDropdownComponent';
import './CGMServiceNextVisitFormComponent.scss';

export interface CGMServiceNextVisitFormComponent extends
  FormType<CGMServiceNextScheduleSubmitValue> {
}

export const CGMServiceNextVisitFormComponent = ({
  onSubmit,
  onValuesChange,
  initialValues,
  isLoading,
  disabled,
}: CGMServiceNextVisitFormComponent) => {
  const formHook = useCGMServiceNextScheduleForm();

  return (
    <Form
      className="CGMServiceNextVisitFormComponent"
      form={formHook.form}
      initialValues={initialValues}
      onFinish={formHook.handleSubmit(onSubmit)}
      onValuesChange={onValuesChange}
      disabled={disabled || isLoading}
    >
      <FormItem
        name={formHook.getName('cgmOptOut')}
        valuePropName="checked"
        initialValue={false}
      >
        <Checkbox>
          {formHook.getLabel('cgmOptOut') as ReactNode}
        </Checkbox>
      </FormItem>
      <FormItem noStyle shouldUpdate={formHook.shouldUpdate(['cgmOptOut'])}>
        {({ getFieldValue }) => (
          formHook.shouldShowReason(getFieldValue)
            ? (
              <FormItem
                name={formHook.getName('cgmOptOutReason')}
                className="cgmOptOutReason"
              >
                <CGMServiceNotWearDropdownComponent />
              </FormItem>
            ) : null
        )}
      </FormItem>
    </Form>
  );
};
