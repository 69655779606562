import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useLifestyleAssessmentForm } from '../../hook/useILifestyleAssessmentForm/useLifestyleAssessmentForm';
import { CalorieManagementComponent } from '../CalorieManagementComponent/CalorieManagementComponent';

export interface CaloriesManagementFormItemComponentProps { }

export const CaloriesManagementFormItemComponent = () => {
  const laForm = useLifestyleAssessmentForm();
  return (
    <FormItem
      info={laForm.getInfo('previousDiets')}
    >
      <CalorieManagementComponent />
    </FormItem>
  );
};
