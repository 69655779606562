import {
  Col, Collapse, Divider, Row, Typography
} from 'antd';
import { omit } from 'lodash';
import { Icon, IconProps } from '../../../../icons/Icon';
import { TitleContentTextComponent } from '../../../../uiComponent/TitleContentTextComponent/TitleContentTextComponent';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import {
  AppInfoResponse, AuthorityInfo, PhoneReportInfo, PhoneReportInfoPermissionStatusEnum
} from '../../../../uc-api-sdk';
import {
  permissionKeyLabelMapping, unifiedCareAppOldTitle, unifiedCareAppTitle, unifiedCarePlusAppTitle
} from '../../constant';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { SendDownloadAppMsgBtnContainer } from '../../../unifiedCareApp/container/SendDownloadAppMsgBtnContainer/SendDownloadAppMsgBtnContainer';
import './AppInfoComponent.scss';
import { UNIFIEDCARE_LOGO, UNIFIEDCARE_PLUS_LOGO } from '../../../../assets/logoIconInfo';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';

const { Panel } = Collapse;
const { Text } = Typography;

export interface AppInfoComponentProp {
  patientId: string,
  appInfoResponse?: AppInfoResponse;
}

export const AppInfoComponent = ({
  patientId,
  appInfoResponse,
}: AppInfoComponentProp) => {
  const oldAppVersion = appInfoResponse?.ucPhoneReportInfo ?? undefined;
  const newAppVersion = appInfoResponse?.icPhoneReportInfo ?? undefined;
  const dataSource = newAppVersion ?? oldAppVersion;

  const renderCommonAppInfo = (
    title: string,
    icon: { src: string; alt?: string; size?: IconProps['size'] },
    dataSource?: PhoneReportInfo,
    isOldVersion?: boolean
  ) => (
    <Row>
      <Col span={5}>
        <Row>
          <Text>{title}</Text>
        </Row>
        <Row>
          <Text type="secondary">
            App Version:
            <DisplayOrEmptyComponent value={dataSource?.mobileInfo?.appVersion} />
          </Text>
        </Row>
      </Col>
      <Col span={3}>
        <div>
          <Icon info={icon} size={50} />
        </div>
      </Col>
      <Col span={4}>
        <TitleContentTextComponent
          title="Last used"
          content={(
            <DisplayDateComponent
              value={dataSource?.updatedAt}
              format="USA_DATE"
              fallbackText={<DisplayOrEmptyComponent />}
            />
          )}
        />
      </Col>
      <Col span={4}>
        <TitleContentTextComponent
          title="Initial Used"
          content={(
            <DisplayDateComponent
              value={!isOldVersion && newAppVersion
                ? appInfoResponse?.icInitialUsed : appInfoResponse?.ucInitialUsed}
              format="USA_DATE"
              fallbackText={<DisplayOrEmptyComponent />}
            />
          )}
        />
      </Col>

      {!isOldVersion && (
        <>
          <Col span={4}>
            <TitleContentTextComponent
              title="Device Name"
              content={<DisplayOrEmptyComponent value={dataSource?.mobileInfo?.phoneModel} />}
            />
          </Col>
          <Col span={4}>
            <TitleContentTextComponent
              title="Operation Version"
              content={<DisplayOrEmptyComponent value={dataSource?.mobileInfo?.phoneOSVersion} />}
            />
          </Col>
        </>
      )}
    </Row>
  );

  const {
    labelsGrantedArray,
    labelsOtherArray,
  } = useDeepCompareMemo(() => {
    const authorityInfo: Partial<Record<keyof AuthorityInfo, PhoneReportInfoPermissionStatusEnum | null>> = omit(dataSource?.authorityInfo ?? {}, ['openAppCountsAfterLogin']);
    const keys = Object.keys(authorityInfo) as (keyof AuthorityInfo)[];
    const grantedStatusArray = keys
      .filter((key) => (authorityInfo[key] === PhoneReportInfoPermissionStatusEnum.granted));
    const statusArray = keys
      .filter((key) => (authorityInfo[key] !== PhoneReportInfoPermissionStatusEnum.granted));
    const labelsGrantedArray = grantedStatusArray.map((key) => permissionKeyLabelMapping[key]);
    const labelsOtherArray = statusArray.map((key) => permissionKeyLabelMapping[key]);

    return {
      labelsGrantedArray,
      labelsOtherArray,
    };
  }, [dataSource?.authorityInfo]);

  return (
    <div className="AppInfoComponent">
      {renderCommonAppInfo(
        newAppVersion ? unifiedCarePlusAppTitle : unifiedCareAppTitle,
        newAppVersion ? UNIFIEDCARE_PLUS_LOGO : UNIFIEDCARE_LOGO,
        dataSource || undefined,
        false
      )}

      <Row>
        <Col span={8}>
          <SendDownloadAppMsgBtnContainer
            patientId={patientId}
            title="Send Unified Care+ App Download SMS"
          />
        </Col>
        <Col span={12}>
          <TitleContentTextComponent
            title="App Authority"
            content={(
              <div className="AppInfoComponent__app-authority">
                <TagComponent type="success" iconType="halfSolid" background="none" iconColor="blue">
                  <div className="breakword">
                    {labelsGrantedArray.length > 0 ? labelsGrantedArray.join(', ') : <DisplayOrEmptyComponent />}
                  </div>
                </TagComponent>
                <TagComponent type="error" iconType="halfSolid" background="none">
                  <div className="breakword">
                    {labelsOtherArray.length > 0 ? labelsOtherArray.join(', ') : <DisplayOrEmptyComponent />}
                  </div>
                </TagComponent>
              </div>
            )}
          />
        </Col>
        <Col span={4}>
          <TitleContentTextComponent
            title="Reward Points"
            content={<DisplayOrEmptyComponent value={appInfoResponse?.rewardPoints ? new Intl.NumberFormat('en-US').format(appInfoResponse?.rewardPoints ?? 0) : undefined} />}
          />
        </Col>
      </Row>

      {(oldAppVersion && newAppVersion) && (
        <>
          <Divider />
          <Collapse ghost>
            <Panel className="text-gray-scale-2" header="Old App History" key="snooze">
              {renderCommonAppInfo(
                unifiedCareAppOldTitle,
                UNIFIEDCARE_LOGO,
                oldAppVersion,
                true
              )}
            </Panel>
          </Collapse>
        </>
      )}
    </div>
  );
};
