import { Drawer } from 'antd';
import { usePatientGetLifestyleById, usePatientGetNutritionById } from '../../../uc-api-sdk';
import { LinkButton } from '../../../uiComponent/LinkButton/LinkButton';
import { useOpen } from '../../../hooks/useBoolean/useOpen';
import { VersionedLifestyleAndNutritionComponent } from './VersionedLifestyleAndNutritionComponent';
import { LoadingOverlayComponent } from '../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { LargeDrawerWidth } from '../../../constants/drawer';

export interface VersionedLifestyleAndNutritionModalButtonComponentProps {
  patientId: string;
}

const Container = ({
  patientId,
}: VersionedLifestyleAndNutritionModalButtonComponentProps) => {
  const lifestyleInfo = usePatientGetLifestyleById({
    params: { memberId: patientId },
  });
  const nutritionInfo = usePatientGetNutritionById({
    params: { memberId: patientId },
  });

  const lifeStyleData = lifestyleInfo?.data?.data;
  const nutritionData = nutritionInfo?.data?.data;

  if (lifeStyleData === undefined || nutritionData === undefined) {
    return <LoadingOverlayComponent isLoading />;
  }

  return (
    <VersionedLifestyleAndNutritionComponent
      lifeStyle={lifestyleInfo?.data?.data}
      nutrition={nutritionInfo?.data?.data}
    />
  );
};

export const VersionedLifestyleAndNutritionModalButtonComponent = ({
  patientId
}: VersionedLifestyleAndNutritionModalButtonComponentProps) => {
  const {
    isOpen,
    open,
    close
  } = useOpen();

  return (
    <>
      <LinkButton
        useBlueColor
        onClick={open}
      >
        Old notes
      </LinkButton>
      <Drawer
        title="Lifestyle and Nutrition (Old)"
        open={isOpen}
        onClose={close}
        width={LargeDrawerWidth}
        footer={null}
        destroyOnClose
      >
        <Container
          patientId={patientId}
        />
      </Drawer>
    </>
  );
};
