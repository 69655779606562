import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import { useIterableFormHook } from '../../../../hooks/useIterableFormListHook/useIterableFormHook';

export const useStressManagementForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('stressManagement', {
    stressLevel: {
      name: 'stressLevel',
      label: 'Stress level',
    },
    stressCopingStrategy: {
      name: 'strategy',
      label: 'Stress coping strategy',
    },
    recentDepressionAnxiety: {
      name: 'recentDepression',
      label: 'Recent depression/anxiety',
    },
  }, options);

  const getInfo = (
    key: keyof typeof factory.formInput,
  ) => ({
    name: factory.makeListNamePath(key, factory.getMainFormItemName()),
    label: factory.getLabel(key) as string
  });

  return useGetContextValue({
    ...factory,
    getInfo
  });
};
