import { InfoCircleFilled } from '@ant-design/icons';
import { message } from 'antd';
import { partial } from 'lodash';
import { useEffect, useState } from 'react';
import { LargeDrawerWidth } from '../../../../constants/drawer';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { PatientService } from '../../../../services/PatientService';
import {
  AddOnServiceEnum,
  SignatureCollectionMethodEnum,
  usePatientVerifyLatestConsent
} from '../../../../uc-api-sdk';
import { IconTextBoxComponent } from '../../../../uiComponent/IconTextBoxComponent/IconTextBoxComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { ConsentFormContainer, ConsentFormContainerProps } from '../../../consentForm/container/ConsentFormContainer/ConsentFormContainer';
import { ConsentFormPendingCheckContainer } from '../../../consentForm/container/ConsentFormPendingCheckContainer/ConsentFormPendingCheckContainer';
import { ConsentService } from '../../../../services/ConsentService';

import './CGMConsentFormContainer.scss';

export interface CGMConsentFormContainerProps {
  patientService: PatientService;
  patientConsentService: ConsentService;
  enrolledProgramService: EnrolledProgramService;
  onSigned?: () => void;
  onCancel?: () => void;
}

export const CGMConsentFormContainer = ({
  patientService,
  patientConsentService,
  enrolledProgramService,
  onSigned,
  onCancel,
}: CGMConsentFormContainerProps) => {
  const verifyConsentForm = usePatientVerifyLatestConsent({});
  const [sent, setSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const consentFormForCGMSigned = verifyConsentForm.data?.data;

  const handleConsentFormOnSigned = () => {
    onSigned?.();
  };

  const handleOnSubmit: ConsentFormContainerProps['onSubmit'] = (v) => {
    if (v?.signatureMethod === SignatureCollectionMethodEnum.APP) {
      setSent(true);
      return;
    }
    handleConsentFormOnSigned();
  };

  useEffect(() => {
    if (!patientService.id || !patientService.getClinicId()) {
      message.error('Failed to verify consent form');
      onCancel?.();
      return;
    }
    verifyConsentForm.send({
      params: {
        request: {
          memberId: patientService.id,
          organizationId: patientService.getClinicId(),
          status: enrolledProgramService.getStatus(),
          // don't need to check for programs to prevent wrong flag
          selectedPrograms: [],
          // always specifically check for CGM services
          selectedAddOnServices: [AddOnServiceEnum.CGM]
        }
      }
    });
  }, []);

  useEffect(() => {
    if (
      consentFormForCGMSigned === undefined
      || patientConsentService?.patientConsent === undefined
    ) {
      return;
    }
    if (
      consentFormForCGMSigned === true
      && patientConsentService?.isSigned()
    ) {
      handleConsentFormOnSigned();
      return;
    }
    setVerified(true);
  }, [
    consentFormForCGMSigned,
    patientConsentService?.patientConsent
  ]);

  return (
    <LoadingOverlayComponent
      isLoading={!verified}
    >
      <div
        className="cgm-consent-form-container"
        style={{ width: LargeDrawerWidth }}
      >
        {
          sent
            ? (
              <ConsentFormPendingCheckContainer
                patientId={patientService.id || ''}
                message="The page will automatically refresh and redirect you to the patient device setup once the consent form is signed."
                onConsentFormSigned={handleConsentFormOnSigned}
                onDifferentMethodClick={partial(setSent, false)}
              />
            ) : (
              <>
                <IconTextBoxComponent
                  icon={<InfoCircleFilled />}
                  text="A new consent form is required to enroll in
                the Continuous Glucose Monitoring Program."
                  backgroundFill="primary-blue-tint"
                  className="cgm-consent-form-container__banner"
                />
                <div className="cgm-consent-form-container__consent-form">
                  <ConsentFormContainer
                    patientId={patientService.id || ''}
                    title={null}
                    onSubmit={handleOnSubmit}
                  />
                </div>
              </>
            )
        }
      </div>
    </LoadingOverlayComponent>
  );
};
