import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import { v4 } from 'uuid';
import EnvConfig from '../../../configs/envConfig/envConfig';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import {
  ClientStatusEnum,
  ConnectionConfig,
  PONG_TASK_INTERVAL,
  WebSocketClient
} from '../../../lib/notificationClient';
import useDebounce from '../../../hooks/useDebounce/useDebounce';
import { useMessageSubscription } from './useMessageSubscription';
import { StorageKeyEnum, useSessionStorage } from '../../../hooks';
import { useMessageServicesContext } from '../../../contexts/MessageContext/MessageServicesContext';
import { useEffectWithPrevValue } from '../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';
import { useDeepCompareEffect } from '../../../hooks/useDeepCompareEffect';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';
import { useUserActiveContext } from '../../../contexts/UserActiveContext/UserActiveContext';
import { useRefState } from '../../../hooks/useRefState/useRefState';

dayjs.extend(dayjsTimezone);
interface NotificationMetric {
  type: string;
  receivedAt: number;
}

export enum NotificationStorageKey {
  CONNECTION_ID = 'notificationClientConnectionId',
  CLIENT_STATUS = 'notificationClientStatus',
  DEBUG = 'notificationClientDebug', // read-only value
  ON_READY = 'notificationClientOnReady', // read-only value
  CLIENT_ID = 'notificationClientId', // read-only value
}

export const useNotificationClient = () => {
  const { token } = useLoggedInUserFromContext();
  const [connectionId, setConnectionId] = useSessionStorage<string>(
    NotificationStorageKey.CONNECTION_ID as string as StorageKeyEnum,
    ''
  );
  const [debug] = useSessionStorage<boolean | undefined>(
    NotificationStorageKey.DEBUG as string as StorageKeyEnum,
  );
  const [
    getUnreadPaginatedChannels,
    setGetUnreadPaginatedChannels
  ] = useState(false);
  const clientRef = useRef<WebSocketClient | undefined>(undefined);
  const clientStatusRef = useRef<ClientStatusEnum | undefined>(undefined);
  const {
    handleMessageEvent,
  } = useMessageSubscription();
  const {
    handleGetUnreadPaginatedChannels,
  } = useMessageServicesContext();
  const [
    clientStatusChangeTime,
    setClientStatusChangeTime,
  ] = useState<number | undefined>();
  const [
    getLastMessageReceived,
    setLastMessageReceived,
  ] = useRefState<NotificationMetric>();
  const [
    getLastFetchUnread,
    setLastFetchUnread,
  ] = useRefState<number>(0);
  const {
    send
  } = useMixpanelContext();
  const [
    messagePayload,
    setMessagePayload,
  ] = useState<string[] | undefined>();
  const { isActive } = useUserActiveContext();

  const hasToken = () => {
    const token = sessionStorage.getItem('token');
    return !!token;
  };

  const handleSaveSession = (
    key: string,
    value: string,
    manualTrigger?: boolean,
  ) => {
    if (!hasToken()) return;
    sessionStorage.setItem(key, value);
    if (manualTrigger) {
      const storageEvent = new StorageEvent('session-storage', {
        key,
      });
      dispatchEvent(storageEvent);
    }
  };

  const startClient = (client: WebSocketClient) => {
    if (clientRef.current) {
      return;
    }
    clientRef.current = client;
    try {
      clientRef.current.start();
    } catch (error) {
      console.error(error);
    }
  };

  const getLastMessageMixpanelProperties = () => {
    const lastMessageReceived = getLastMessageReceived();
    return lastMessageReceived
      ? {
        type: lastMessageReceived.type,
        receivedAt: dayjs(lastMessageReceived.receivedAt).utc().toISOString(),
        timezone: dayjs.tz.guess(),
      }
      : null;
  };

  const createClient = (
    token: string,
    connectionId: string,
  ) => {
    const config: ConnectionConfig = {
      token,
      baseUrl: EnvConfig.notificationClientUrl, // notification server address
      connectionId: connectionId as string,
      ssl: true, // ssl certificate
      operation: {
        onReady: (timestamp) => {
          send({
            event: MixpanelEvents.NotificationClientIsReady,
            properties: {
              lastMessageReceived: getLastMessageMixpanelProperties(),
            }
          });
          handleSaveSession(
            NotificationStorageKey.ON_READY,
            new Date(timestamp).toISOString()
          );
        },
        getClientId: (clientId) => {
          handleSaveSession(
            NotificationStorageKey.CLIENT_ID,
            clientId
          );
        },
        onMessage: (message): boolean => {
          setMessagePayload(message);
          return true;
        },
        onLastMessageReceived: (type, receivedAt) => {
          setLastMessageReceived({ type, receivedAt });
        },
        onTerminated: () => {
          clientRef.current = undefined;
          if (!hasToken()) return;
          setConnectionId('');
        },
        onStatusChange: (status) => {
          const timestamp = new Date().getTime();
          send({
            event: MixpanelEvents.NotificationClientStatusChange,
            properties: {
              clientStatus: status,
              clientStatusChangeTime: timestamp,
              lastMessageReceived: getLastMessageMixpanelProperties(),
            },
          });
          clientStatusRef.current = status;
          handleSaveSession(
            NotificationStorageKey.CLIENT_STATUS,
            JSON.stringify(status || '')
          );
          // time client status is updated, even value is not changed
          setClientStatusChangeTime(timestamp);
        },
      },
      timeout: {
        terminate: EnvConfig.afkTimerThreshold
      },
      debug,
    };
    return new WebSocketClient(config);
  };

  const debouncedGetUnreadPaginatedChannels = useDebounce(() => {
    const lastFetchAt = getLastFetchUnread();
    handleGetUnreadPaginatedChannels({
      fromTimestamp: String(lastFetchAt),
    });
    send({
      event: MixpanelEvents.NotificationClientGetUnreadOnConnected,
      properties: {
        lastFetchAt,
        lastMessageReceived: getLastMessageMixpanelProperties(),
      }
    });
    setLastFetchUnread(new Date().getTime());
  }, 2500, [handleGetUnreadPaginatedChannels]);

  const initConnection = useDebounce(async (
    token?: string,
    connectionId?: string,
  ) => {
    if (
      ![
        ClientStatusEnum.CONNECTING,
        ClientStatusEnum.CONNECTED
      ].includes(clientStatusRef.current || '' as ClientStatusEnum)
      && token
      && connectionId
    ) {
      startClient(createClient(token, connectionId));
    }
  }, 500);

  useUpdateEffect(() => {
    if (isActive && getUnreadPaginatedChannels) {
      debouncedGetUnreadPaginatedChannels();
      setGetUnreadPaginatedChannels(false);
    }
  }, [getUnreadPaginatedChannels, isActive]);

  const terminateConnection = useDebounce((
    restartClient = true,
  ) => {
    // should terminate the connection when token is changed
    if (clientRef.current) {
      clientRef.current.terminate();
      clientRef.current = undefined;
    }
    if (restartClient) {
      if (!hasToken()) return;
      setConnectionId('');
    }
  });

  useEffectOnce(() => {
    // set new connection id when page is loaded
    setConnectionId(v4());

    return () => {
      terminateConnection(false);
    };
  });

  useUpdateEffect(() => {
    if (!connectionId) {
      // make sure connection id is always available
      const newConnectionId = v4();
      setConnectionId(newConnectionId);
    } else {
      initConnection(token, connectionId);
    }
  }, [connectionId]);

  useEffectWithPrevValue(token, (prevToken) => {
    if (prevToken && token) {
      const shouldRestart = !!token;
      terminateConnection(shouldRestart);
    }
  });

  useEffect(() => {
    if (messagePayload?.length) {
      handleMessageEvent(messagePayload);
      setMessagePayload(undefined);
    }
  }, [messagePayload]);

  const checkDisconnectedStatus = useDebounce(() => {
    if (clientStatusRef.current !== ClientStatusEnum.DISCONNECTED) {
      return;
    }
    // ignore connecting, terminated status
    if (clientStatusRef.current === ClientStatusEnum.DISCONNECTED) {
      setTimeout(() => {
        /**
        * Help to reconnect.
        * Note: ping-pong task should automatically try to reconnect,
        * status then should be "connecting" after pong interval time.
        * if status is not changed,
        * pong task could have been closed by browser.
        */
        if (clientStatusRef.current === ClientStatusEnum.DISCONNECTED) {
          if (!hasToken()) return;
          setConnectionId('');
        }
      }, PONG_TASK_INTERVAL + 1000);
    }
  }, 1000, [], { leading: true });

  useDeepCompareEffect(() => {
    const clientStatus = clientStatusRef.current;
    if (clientStatus === ClientStatusEnum.CONNECTED) {
      setGetUnreadPaginatedChannels(true);
    }
    if (
      clientStatus === ClientStatusEnum.DISCONNECTED
    ) {
      checkDisconnectedStatus();
    }
    handleSaveSession(
      NotificationStorageKey.CLIENT_STATUS,
      JSON.stringify(clientStatus || ''),
      true
    );
  }, [clientStatusChangeTime]);

  useEffect(() => {
    if (isActive) {
      checkDisconnectedStatus();
    }
  }, [isActive]);

  return null;
};
