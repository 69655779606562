import { InputNumber } from 'antd';
import { map } from 'lodash';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { useSmokeDrinkForm } from '../../hook/useILifestyleAssessmentForm/useSmokeDrinkForm';
import { LifestyleAssessmentInputCardLayout } from '../LifestyleAssessmentComponent/layout/LifestyleAssessmentInputCardLayout';
import { FreqSelectComponent } from '../../../../uiComponent/FreqSelectComponent/FreqSelectComponent';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { FrequencyUnitEnum, SmokeDrinkRecord$CigaretteUnitEnum } from '../../../../uc-api-sdk';
import { SmokeDrinkQuitYearComponent } from './SmokeDrinkQuitYearComponent';

import './SmokeDrinkFormItemComponent.scss';
import { CigaretteUnitEnumComponent } from '../../../../enumComponent/SmokeDrinkEnumComponent/CigaretteUnitEnumComponent';

export const SmokeFormItemComponent = () => {
  const smokeForm = useSmokeDrinkForm();

  return (
    <>
      <FormItem
        info={smokeForm.getInfo('isSmoking')}
      >
        <YesNoRadioComponent />
      </FormItem>

      <div className="mt12">
        <FormItem
          noStyle
          shouldUpdate={smokeForm.shouldUpdate(['isSmoking'])}
        >
          {
            ({ getFieldValue }) => {
              const isSmoking = smokeForm.getObjectValue('isSmoking', getFieldValue);
              if (typeof isSmoking !== 'boolean') {
                return null;
              }
              return (
                <LifestyleAssessmentInputCardLayout>
                  {
                    isSmoking ? (
                      <div className="smoke-drink__freq">
                        <FormItem
                          info={smokeForm.getInfo('smokingAmount')}
                        >
                          <InputNumber
                            min={0}
                            precision={0}
                            placeholder="Amt."
                            addonAfter={(
                              <FormItem
                                name={smokeForm.getInfo('cigaretteUnit').name}
                                initialValue={SmokeDrinkRecord$CigaretteUnitEnum.PACKS}
                              >
                                <EnumSelectComponent
                                  options={map(SmokeDrinkRecord$CigaretteUnitEnum)}
                                  optionRenderer={CigaretteUnitEnumComponent}
                                  placeholder="Select"
                                />
                              </FormItem>
                            )}
                          />
                        </FormItem>
                        <div className="smoke-drink__freq__text">/</div>
                        <FormItem
                          info={smokeForm.getInfo('smokingFrequency')}
                          initialValue={FrequencyUnitEnum.WEEKLY}
                        >
                          <FreqSelectComponent type="shortCap" />
                        </FormItem>
                      </div>
                    ) : (
                      <FormItem
                        info={smokeForm.getInfo('quitSmokingYear')}
                      >
                        <SmokeDrinkQuitYearComponent />
                      </FormItem>
                    )
                  }
                </LifestyleAssessmentInputCardLayout>
              );
            }
          }
        </FormItem>
      </div>
    </>
  );
};
