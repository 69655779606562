import { Col, Row, Table } from 'antd';
import { map } from 'lodash';
import { MealIntake, Nullable } from '../../../../uc-api-sdk';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';

import './OldIntakeTableComponent.scss';
import { oldUseIntakeTableColumns } from './oldUseIntakeTableColumns';

export interface MealType {
  name: string;
  time: string;
}

export interface DataType {
  key: string;
  meal: MealType;
  foodTypeAmount: string;
  mealFreq: string;
  skipFreq: string;
}

export interface OldIntakeTableComponentProps {
  intake?: Nullable<MealIntake[]>;
}

// ---
// WARNING: OLD UI for version view only, don't use this component
// ---
export const OldIntakeTableComponent = ({
  intake,
}: OldIntakeTableComponentProps) => {
  const columns = useTableColumns(
    oldUseIntakeTableColumns,
    ['meal', 'foodTypeAmount', 'mealFreq', 'skipFreq'],
  );
  const data: DataType[] = map(intake, (mealIntake, index) => ({
    key: `${index}-${mealIntake.meal}`,
    meal: {
      name: `${mealIntake.meal}`,
      time: `${mealIntake.timeRange ? mealIntake.timeRange : 'N/A'}`,
    },
    foodTypeAmount: `${mealIntake.foodTypeAmount ? mealIntake.foodTypeAmount : 'N/A'}`,
    mealFreq: `${mealIntake.mealFreq ? mealIntake.mealFreq : 'N/A'}`,
    skipFreq: `${mealIntake.skipFreq ? mealIntake.skipFreq : 'N/A'}`,
  }));
  return (
    <div className="old-intake-table-component">
      <div className="text-title">
        24-hour Recall or Typical intake
      </div>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};
