import { Row, Col } from 'antd';
import { CgmGoalStatusEnum, CgmMetricEnum } from '../../../../uc-api-sdk';
import { MeetingStatusSelectComponent } from '../../../patient/component/MeetingStatusSelectComponent/MeetingStatusSelectComponent';
import './CgmGoalCardComponent.scss';
import { GoalFormTimeComponent } from '../ClinicalGoalComponent/GoalFormTimeComponent/GoalFormTimeComponent';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import { CgmGoalFormValue } from '../CgmGoalFormComponent/CgmGoalFormComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { CgmGoalTooltipComponent } from '../CgmGoalTooltipComponent/CgmGoalTooltipComponent';
import { CgmGoalThresholdComponent } from '../CgmGoalThresholdComponent/CgmGoalThresholdComponent';
import { CGM_BAR_CHART_CONFIG } from '../../../vitals/constant/cgmConstant';

interface CgmGoalCardComponentProps {
  cgmGoal: CgmGoalFormValue;
}

export const CgmGoalCardComponent = ({
  cgmGoal,
}: CgmGoalCardComponentProps) => {
  const metricConfig: Partial<Record<CgmMetricEnum, number>> = {
    [CgmMetricEnum.TBR_LEVEL_2]: cgmGoal.tbrLevel2,
    [CgmMetricEnum.TBR_LEVEL_1]: cgmGoal.tbrLevel1,
    [CgmMetricEnum.TIR]: cgmGoal.tir,
    [CgmMetricEnum.TAR_LEVEL_1]: cgmGoal.tarLevel1,
    [CgmMetricEnum.TAR_LEVEL_2]: cgmGoal.tarLevel2,
  };

  const renderCgmGoalValue = (metricName: CgmMetricEnum) => (
    <Col className="CgmGoalCardComponent__cgm-goal">
      {metricName === CgmMetricEnum.TIR ? '≥ ' : '< '}
      {metricConfig[metricName] ?? CGM_BAR_CHART_CONFIG[metricName]?.defaultGoal}
      %
    </Col>
  );

  return (
    <div className="CgmGoalCardComponent">
      <div className="flex text-gray-scale-2 fs12 mb12">
        <div>
          CGM Percent in Range
        </div>
        <TooltipComponent
          title={<CgmGoalTooltipComponent />}
          placement="topLeft"
          type="info-icon"
          color="white"
          overlayStyle={{ minWidth: '615px' }}
        />
      </div>
      <div className="ml12">
        <Row gutter={10} className="jc-sb">
          <div>
            <CgmGoalThresholdComponent cgmGoal={cgmGoal} />
            <div className="flex mb12">
              {[
                CgmMetricEnum.TBR_LEVEL_2,
                CgmMetricEnum.TBR_LEVEL_1,
                CgmMetricEnum.TIR,
                CgmMetricEnum.TAR_LEVEL_1,
                CgmMetricEnum.TAR_LEVEL_2
              ].map(renderCgmGoalValue)}
            </div>
          </div>
          <div className="flex">
            <div className="meeting-status">
              <p>Status:</p>
              <MeetingStatusSelectComponent
                bordered={false}
                disabled
                value={cgmGoal?.status as CgmGoalStatusEnum}
                useExtendedStatus
              />
              <GoalFormTimeComponent value={cgmGoal?.updatedTime as string} />
            </div>
          </div>
        </Row>
      </div>
      <div className="text-gray-scale-2 fs12 my5">
        Additional Comments
      </div>
      {cgmGoal?.latestNote || 'N/A'}
      {
        cgmGoal?.notes && cgmGoal?.notes.length !== 0 && (
          <HistoryDrawerComponent history={cgmGoal?.notes} />
        )
      }
    </div>
  );
};
