import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Card, Col, Row, Tooltip
} from 'antd';
import dayjs from 'dayjs';
import { MMM_DD_YYYY } from '../../../../constants/timeFormat';
import { DateTimeType } from '../../../../types/common';
import { CgmMetricEnum, DeviceModelEnum, PatientCgmDevice } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { DeviceLabelComponent } from '../../../device/component/DeviceLabelComponent/DeviceLabelComponent';
import './CgmVitalSummaryComponent.scss';

export interface CgmStatisticsComponentProps {
  activeTime?: number;
  avgCgm?: number;
  gv?: number;
  gmi?: number;
  cgmDeviceInfo?: PatientCgmDevice;
  startDate?: DateTimeType;
  endDate?: DateTimeType;
  isForReport?: boolean;
}

export const CgmStatisticsComponent = ({
  activeTime,
  avgCgm,
  gv,
  gmi,
  cgmDeviceInfo,
  startDate,
  endDate,
  isForReport
}: CgmStatisticsComponentProps) => {
  const cgmStatisticsTitle = () => (
    <div className="flex cgm-statistics-title">
      <strong>Glucose Statistics</strong>
      <Tooltip
        placement="topRight"
        className="cgm-tooltip"
        overlayStyle={{ minWidth: '580px' }}
        title={(
          <div>
            <div>
              <span className="bold">GV (Glycemic Variability)</span>
              {' '}
              refers to fluctuations in blood glucose levels and is defined
              as the ratio of the standard deviation to the average glucose
              level (in %), also termed as % coefficient of variation.
            </div>
            <div>
              <span className="bold">GMI (Glucose Management Indicator)</span>
              {' '}
              is a calculated metric that reflects the average glucose values
              from a CGM device translated into an HbA1C equivalent value (in %).
            </div>
          </div>
        )}
      >
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );

  const displayMetricValue = (metricName: CgmMetricEnum, value?: number) => {
    if (value !== undefined) {
      return (
        <>
          {Math.floor(value * 10) / 10}
          {metricName === CgmMetricEnum.AVERAGE_GLUCOSE ? ' mg/dL' : '%'}
        </>
      );
    }
    return <EmptyComponent />;
  };

  const selectedDateRange = startDate && endDate
    ? `${dayjs(startDate).format(MMM_DD_YYYY)} - ${dayjs(endDate).format(MMM_DD_YYYY)}` : 'Day Range';

  const cgmStatisticsItems = () => (
    <div className="w100">
      <Row gutter={20}>
        <Col span={isForReport ? 12 : 24}>
          <Row gutter={20}>
            <Col span={14} className="text-gray-scale-2">Device Information</Col>
            <Col span={10} className="bold cgm-stats-value">
              <DeviceLabelComponent
                device={{
                  deviceModel: DeviceModelEnum.CGM,
                  brand: cgmDeviceInfo?.brand,
                  model: cgmDeviceInfo?.model,
                }}
                showImage={false}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={14} className="text-gray-scale-2">{selectedDateRange}</Col>
            <Col span={10} className="bold cgm-stats-value"><DisplayOrEmptyComponent value={dayjs(endDate).diff(dayjs(startDate), 'day') + 1} /></Col>
          </Row>
          <Row gutter={20}>
            <Col span={14} className="text-gray-scale-2">Active Time (≥70%)</Col>
            <Col span={10} className="bold cgm-stats-value">{displayMetricValue(CgmMetricEnum.CGM_ACTIVE_MINUTES, activeTime)}</Col>
          </Row>
          <Row gutter={20}>
            <Col span={14} className="text-gray-scale-2">Average Glucose (≤154 mg/dL)</Col>
            <Col span={10} className="bold cgm-stats-value">{displayMetricValue(CgmMetricEnum.AVERAGE_GLUCOSE, avgCgm)}</Col>
          </Row>
        </Col>
        <Col span={isForReport ? 12 : 24}>
          <Row gutter={20}>
            <Col span={14} className="text-gray-scale-2">Glycemic Variability (≤36%)</Col>
            <Col span={10} className="bold cgm-stats-value">{displayMetricValue(CgmMetricEnum.GV, gv)}</Col>
          </Row>
          <Row gutter={20}>
            <Col span={14} className="text-gray-scale-2">GMI/Estimated A1C (≤7%)</Col>
            <Col span={10} className="bold cgm-stats-value">{displayMetricValue(CgmMetricEnum.GMI, gmi)}</Col>
          </Row>
          <div className="text-gray-scale-2 mt10 fs1">Defined as percent coefficient of variation </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <Card className="CgmStatisticsComponent mb0">
      <div className="flex cgm-statistics flex-wrap">
        <div className="w-mc mr12">
          {cgmStatisticsTitle()}
        </div>
        {cgmStatisticsItems()}
      </div>
    </Card>
  );
};
