import { Col, Row } from 'antd';
import { PatientInfo } from '../../../../../contexts/PatientInfoContext/PatientInfoContext';
import { AccountHelper } from '../../../../../helpers/account';
import { LimitedUser } from '../../../../../uc-api-sdk';
import { ClinicNameDisplayComponent } from '../../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';

export interface CgmReportClinicInfoComponentProps {
  patientInfo?: PatientInfo;
  careNoteWriter?: LimitedUser;
}

export const CgmReportClinicInfoComponent = ({
  patientInfo,
  careNoteWriter
}: CgmReportClinicInfoComponentProps) => {
  const careNoteWriterName = AccountHelper.getFullNameWithTitle(careNoteWriter);
  const assigneeIds = patientInfo?.assigneesService?.getAllAssigneesId() || [];
  const careTeamMembers = [
    AccountHelper.getFullNameWithTitle(patientInfo?.assignees?.assignedToCAUser),
    AccountHelper.getFullNameWithTitle(patientInfo?.assignees?.assignedToRDUser),
  ];
  if (careNoteWriter?.id && !assigneeIds.includes(careNoteWriter.id)) {
    careTeamMembers.push(careNoteWriterName);
  }

  return (
    <div className="CgmReportClinicInfoComponent">
      <TextComponent className="bold mb10">
        Clinic Information
      </TextComponent>
      <Row className="mb10">
        <Col span={12}>
          <span className="bold">Clinic Name: </span>
          <span>
            <ClinicNameDisplayComponent clinic={patientInfo?.patientInfoService?.getClinic()} />
          </span>
        </Col>
        <Col span={12}>
          <span className="bold">Provider Name: </span>
          <span>{patientInfo?.patientInfoService?.getProviderName()}</span>
        </Col>
      </Row>

      <TextComponent className="bold">
        Care Team
      </TextComponent>
      <div>{careTeamMembers.join('; ')}</div>
    </div>
  );
};
