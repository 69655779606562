import { Col, Row } from 'antd';
import { ReactNode } from 'react';

import './LifestyleAssessmentDisplayLayout.scss';

export interface LifestyleAssessmentDisplayLayoutProps {
  label: ReactNode;
  content: ReactNode;
}

export const LifestyleAssessmentDisplayLayout = ({
  label,
  content
}: LifestyleAssessmentDisplayLayoutProps) => (
  <Row
    className="lifestyle-assessment-layout mb5"
  >
    <Col
      span={6}
      className="lifestyle-assessment-layout__label"
    >
      {label}
    </Col>
    <Col
      offset={1}
      className="lifestyle-assessment-layout__content"
    >
      {content}
    </Col>
  </Row>
);
