import { Button, Divider } from 'antd';
import { useEffectOnce } from 'usehooks-ts';
import { useMessagePopupContext } from '../../../../contexts/MessageContext/MessagePopupContext';

export interface OutstandingMessageComponentProps {
  onResolve: () => void;
  isSubmitting?: boolean;
  autoOpenChat?: boolean;
}

export const OutstandingMessageComponent = ({
  onResolve,
  isSubmitting,
  autoOpenChat,
}: OutstandingMessageComponentProps) => {
  const { handleOpenChat } = useMessagePopupContext() || {};

  useEffectOnce(() => {
    if (autoOpenChat) {
      handleOpenChat?.();
    }
  });
  return (
    <div className="flex fd-c jc-sb h100">
      <div>
        This patient has unread message in chat channel, please review.
      </div>
      <div>
        <div className="w100">
          <Divider />
          <div className="flex jc-e">
            <Button
              type="primary"
              onClick={onResolve}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Mark as Resolved
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
