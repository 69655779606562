import { isEmpty } from 'lodash';
import { MealAndSleepRoutine } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { FromToTimeDisplay } from '../../../../../uiComponent/FromToTimeDisplay/FromToTimeDisplay';
import { getTimeOfDayByMinutes } from '../../../../../helpers/time';
import { MealRoutineTypeEnumComponent } from '../../../../../enumComponent/MealRoutineTypeEnumComponent/MealRoutineTypeEnumComponent';

export interface PrintCarePlanMealAndSleepRoutineProps {
  mealAndSleepRoutine?: MealAndSleepRoutine | null;
}

export const PrintCarePlanMealAndSleepRoutineComponent = ({
  mealAndSleepRoutine,
}: PrintCarePlanMealAndSleepRoutineProps) => {
  if (isEmpty(mealAndSleepRoutine)) {
    return null;
  }
  return (
    <div className="mb12">
      <TextComponent size="medium" bold>
        <MealRoutineTypeEnumComponent value={mealAndSleepRoutine.type} />
      </TextComponent>
      <PrintCarePlanSimpleItemComponent
        label="Time Range:"
        content={(
          <div className="inline-flex-i">
            <FromToTimeDisplay
              startTime={getTimeOfDayByMinutes(mealAndSleepRoutine.startTime)}
              endTime={getTimeOfDayByMinutes(mealAndSleepRoutine.endTime)}
              format="hhmm_a"
            />
          </div>
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Type/Amount/Quality:"
        content={(
          <DisplayOrEmptyComponent value={mealAndSleepRoutine.foodTypeAmount} />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Frequency:"
        content={(
          <DisplayOrEmptyComponent value={mealAndSleepRoutine.frequency} />
        )}
      />
    </div>
  );
};
