import { useMemo } from 'react';
import { SocialSupportFormComponent, SocialSupportFormComponentProps } from '../../../patient/component/SocialSupportFormComponent/SocialSupportFormComponent';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { SubmitValue } from '../../../patient/hook/useSocialSupportForm';
import { PatientService } from '../../../../services/PatientService';
import { usePatientUpdate } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';

export interface WorklistSocialSupportFormContainerProps
  extends NestedFormComponentChildProps<SubmitValue> {
  patientId: string;
  patientInfoService: PatientService;
}

export const WorklistSocialSupportFormContainer = ({
  patientId,
  patientInfoService,
  onSubmit,
  onError,
  onValuesChange
}: WorklistSocialSupportFormContainerProps) => {
  const patientUpdateInfo = usePatientUpdate({});
  const { clinicEvent } = useWorklistContext();

  const initialValues = useMemo(() => ({
    communitySupport: patientInfoService?.patient?.profile?.communitySupport ?? undefined,
    contactInfo: patientInfoService?.patient?.profile?.contactInfo ?? undefined,
    otherProviders: patientInfoService?.patient?.profile?.otherProviders ?? undefined,
    easeOfFoodAccess: patientInfoService?.patient?.profile?.easeOfFoodAccess ?? undefined,
  }), [
    patientInfoService?.patient?.profile,
  ]);

  const handleSubmit: SocialSupportFormComponentProps['onSubmit'] = (
    values
  ) => {
    ApiRequestHelper.tryCatch(
      patientUpdateInfo.send({
        params: {
          memberId: patientId,
          request: {
            profile: {
              communitySupport: values.communitySupport,
              contactInfo: values.contactInfo,
              otherProviders: values.otherProviders,
              easeOfFoodAccess: values.easeOfFoodAccess,
            },
            visitId: clinicEvent?.id,
          },
        }
      }),
      {
        success: 'Updated social support successfully.',
        error: 'Failed to update social support.',
        onSuccess: () => onSubmit?.(values),
        onError,
      }
    );
  };

  return (
    <SocialSupportFormComponent
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onValuesChange={onValuesChange}
    />
  );
};
