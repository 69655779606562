import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { usePatientContext } from '../../contexts/PatientInfoContext/PatientInfoContext';
import { CgmReportDownloadButton } from '../../features/print/CgmReport/component/CgmReportDownloadButton/CgmReportDownloadButton';
import { VisitSummaryInputComponent } from '../../features/worklist/component/VisitSummaryInputComponent/VisitSummaryInputComponent';
import TimezoneService from '../../helpers/timezone/timezoneService';
import { CareNote } from '../../uc-api-sdk';
import { CgmReviewTipComponent } from '../CgmReviewTipComponent/CgmReviewTipComponent';
import { Title } from '../Title/Title';

export interface CgmReviewValues {
  reportStartDate: string;
  reportEndDate: string;
  note: string;
}

export interface WorklistCgmReviewComponentProps {
  onSubmit?: (v: CgmReviewValues) => void;
  cgmReportCareNote?: CareNote;
  showRequiredMark?: boolean;
}

export const WorklistCgmReviewComponent = ({
  onSubmit,
  cgmReportCareNote,
  showRequiredMark
}: WorklistCgmReviewComponentProps) => {
  const { info } = usePatientContext();
  const reportEndDate = dayjs().endOf('d');
  const reportStartDate = reportEndDate.subtract(30, 'd');
  const [cgmNote, setCgmNote] = useState<string | undefined>('');

  const onNoteChange = (v: string | undefined) => {
    setCgmNote(v);
  };

  const handleSubmit = () => {
    onSubmit?.({
      note: cgmNote || '',
      reportStartDate: TimezoneService.getUTC(reportStartDate),
      reportEndDate: TimezoneService.getUTC(reportEndDate)
    });
  };

  return (
    <div className="CgmReviewComponent">
      <Title type="secondary">
        CGM Review
        {' '}
        <Tooltip
          title="The system prompts a CGM review when at least one wear session's worth
           of data with active time above 70% has been collected since the last report generation time.
           Your CGM review note will be added to the CGM report.
           Providers review the CGM report and use it as proof of &quot;CGM Data Review&quot; for billing."
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Title>
      <CgmReviewTipComponent />

      <div className="cgm-review-care-note mb20">
        <VisitSummaryInputComponent
          value={cgmNote}
          onChange={onNoteChange}
          showRequiredMark={showRequiredMark}
        />
      </div>
      <div className="flex jc-sb">
        <div>
          {!cgmReportCareNote
            && (
              <Button
                type="primary"
                ghost
                onClick={handleSubmit}
                disabled={!cgmNote?.trim()}
              >
                Generate CGM Report
              </Button>
            )}
        </div>
        <div>
          {cgmReportCareNote
            && (
              <CgmReportDownloadButton
                startDate={reportStartDate}
                endDate={reportEndDate}
                patientInfo={info}
                careNote={cgmReportCareNote}
                buttonText="Download Report"
              />
            )}
        </div>
      </div>
    </div>
  );
};
