import { SmokeDrinkRecord$ContainerTypeEnum } from '../../uc-api-sdk';

export const getAlcoholContainerTypeText = (
  value?: SmokeDrinkRecord$ContainerTypeEnum | null,
  singular?: boolean,
) => {
  let text = '';
  switch (value) {
    case SmokeDrinkRecord$ContainerTypeEnum.BOTTLES:
      text = 'bottles';
      break;
    case SmokeDrinkRecord$ContainerTypeEnum.CANS:
      text = 'cans';
      break;
    case SmokeDrinkRecord$ContainerTypeEnum.GLASSES:
      text = 'glasses';
      break;
    default:
  }
  if (singular) {
    text = text.slice(0, -1);
  }
  return text;
};

export interface AlcoholContainerTypeEnumComponentProps {
  value?: SmokeDrinkRecord$ContainerTypeEnum | null;
}

export const AlcoholContainerTypeEnumComponent = ({
  value,
}: AlcoholContainerTypeEnumComponentProps) => (
  <>{getAlcoholContainerTypeText(value)}</>
);
