import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useLoggedInUserFromContext } from '../../../../../contexts/loggedInUserContext';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import { CareNote, useCareNoteInsert } from '../../../../../uc-api-sdk';
import { GenerateCgmReportDrawerComponent, GenerateCgmReportDrawerComponentProps } from '../../component/GenerateCgmReportDrawerComponent/GenerateCgmReportDrawerComponent';

export interface GenerateCgmReportButtonContainerProps extends
  GenerateCgmReportDrawerComponentProps {
  disabled?: boolean;
}

export const GenerateCgmReportButtonContainer = ({
  ...props
}: GenerateCgmReportButtonContainerProps) => {
  const createCareNote = useCareNoteInsert({});
  const [note, setNote] = useState<string>();
  const { userInfo } = useLoggedInUserFromContext();
  const onSubmit = useDebounce((value: CareNote, onSuccess?: () => void) => {
    ApiRequestHelper.tryCatch(
      createCareNote.send({
        params: {
          document: {
            ...value
          }
        }
      }),
      {
        success: undefined,
        error: 'Failed to add care note.',
        onSuccess
      }
    );
  });

  const careNote = useMemo(() => ({
    content: note,
    createdByUser: userInfo?.profile,
    updatedAt: dayjs().toISOString(),
  } as CareNote), [note, userInfo?.id]);

  return (
    <div className="GenerateCgmReportButtonContainer mt5">
      <GenerateCgmReportDrawerComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        note={note}
        onNoteChange={setNote}
        careNote={careNote}
        isLoading={createCareNote.isLoading}
        onSubmit={onSubmit}
      />
    </div>
  );
};
