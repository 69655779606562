import dayjs from 'dayjs';
import moment from 'moment';
import { DATE_RANGE_START, DATE_RANGE_END } from '../../constants/timeFormat';
import { DateTimeType } from '../../types/common';

// eslint-disable-next-line import/prefer-default-export
export const utcStrToMoment = (timeStr: string) => moment(`${timeStr}Z`);

export const getLastMonthDateRange = () => ({
  startDate: moment().subtract(1, 'months').startOf('month').format(DATE_RANGE_START),
  endDate: moment().subtract(1, 'months').endOf('month').format(DATE_RANGE_END),
});
export const getThisMonthDateRange = () => ({
  startDate: moment().startOf('month').format(DATE_RANGE_START),
  endDate: moment().endOf('month').format(DATE_RANGE_END),
});
export const getLastWeekDateRange = () => ({
  startDate: moment().subtract(1, 'weeks').startOf('week').format(DATE_RANGE_START),
  endDate: moment().subtract(1, 'weeks').endOf('week').format(DATE_RANGE_END),
});
export const getThisWeekDateRange = () => ({
  startDate: moment().startOf('week').format(DATE_RANGE_START),
  endDate: moment().endOf('week').format(DATE_RANGE_END),
});
export const getRecent3MonthsDateRange = () => ({
  startDate: moment().subtract(2, 'months').startOf('month').format(DATE_RANGE_START),
  endDate: moment().endOf('month').format(DATE_RANGE_END),
});
export const getTimeOfDayByMinutes = (time?: DateTimeType | null) => {
  // time in minutes of day
  if (typeof time !== 'number') {
    return undefined;
  }
  const today = dayjs().startOf('day');
  return today.add(time, 'minutes');
};
