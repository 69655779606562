import { Input, InputProps } from 'antd';
import { ReactNode } from 'react';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export interface SocialSupportInputComponentProps extends InputProps {
  id?: string;
  tooltip?: ReactNode;
}

export const SocialSupportInputComponent = ({
  id,
  value,
  onChange,
  tooltip,
  ...props
}: SocialSupportInputComponentProps) => (
  <div className="flex ai-c">
    <Input
      id={id}
      value={value}
      onChange={onChange}
      placeholder="Please enter"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
    {
      tooltip && (
        <TooltipComponent
          type="info-icon"
          title={tooltip}
        />
      )
    }
  </div>
);
