import { map } from 'lodash';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ProgramCategoryEnum, useClinicGet } from '../../../../uc-api-sdk';
import { ConsentFormStatusBarComponent } from '../../../consentForm/component/ConsentFormStatusBarComponent/ConsentFormStatusBarComponent';
import { PatientCommonCardComponent } from '../../component/PatientCardComponent/PatientCommonCardComponent';
import { PatientProfileBillingProgramsComponent } from '../../component/PatientProfileBillingProgramsComponent/PatientProfileBillingProgramsComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export const PatientProfileProgramsContainer = () => {
  const { info } = usePatientContext();
  const { patientInfo, enrolledProgramService } = info || {};
  const clinicInfo = useClinicGet({
    params: { id: patientInfo?.clinicId || '' },
    options: { sendOnMount: !!patientInfo?.clinicId },
  });

  const clinicProgramParticipation = (
    map(clinicInfo.data?.data?.programParticipation, 'name') as ProgramCategoryEnum[]
  );

  const handleOnSubmitConsent = useDebounce(
    () => info?.refetch(['enrolledProgramRefetch', 'consentRefetch']),
    500,
    [info?.refetch]
  );

  useUpdateListener('CONSENT_FORM_SIGNED', handleOnSubmitConsent);

  return (
    <PatientCommonCardComponent
      updateInfo={enrolledProgramService?.enrolledProgram}
      title="Billing Programs"
      showEdit={false}
      content={(
        <div className="flex fd-c gap2">
          <ConsentFormStatusBarComponent
            patientInfo={info}
            onSubmit={handleOnSubmitConsent}
            showSignedStatus={false}
          />

          <PatientProfileBillingProgramsComponent
            patientInfo={info}
            enrolledProgramService={enrolledProgramService}
            patientClinicProgramParticipation={clinicProgramParticipation}
          />
        </div>
      )}
    />
  );
};
