import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import './Title.scss';

export interface SubTitleProps {
  children: ReactNode;
  className?: string;
  showMarginBot?: boolean;
}

export const SubTitle: FC<SubTitleProps> = ({
  children,
  className,
  showMarginBot,
}) => (
  <div
    className={classNames({
      'sub-title': true,
      [className || '']: true,
      mb32: showMarginBot,
    })}
  >
    {children}
  </div>
);
