import { ColumnType } from 'antd/lib/table';
import { capitalize } from 'lodash';
import { DataType } from './OldIntakeTableComponent';

export const oldUseIntakeTableColumns = ({
  meal: (): ColumnType<DataType> => ({
    title: 'Meal',
    dataIndex: 'meal',
    render: (obj) => (
      <div>
        <div className="default">
          {capitalize(obj?.name || '')}
        </div>
        <div className="second">
          {obj.time}
        </div>
      </div>
    ),
  }),
  foodTypeAmount: (): ColumnType<DataType> => ({
    title: 'Food Type & Amount',
    dataIndex: 'foodTypeAmount',
  }),
  mealFreq: (): ColumnType<DataType> => ({
    title: 'Frequency',
    dataIndex: 'mealFreq',
    align: 'center',
  }),
  skipFreq: (): ColumnType<DataType> => ({
    title: 'Skip',
    dataIndex: 'skipFreq',
    align: 'center',
  }),
});
