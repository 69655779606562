import { ReactNode } from 'react';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';

export interface LifestyleAssessmentTitleLayoutProps {
  title: ReactNode;
}

export const LifestyleAssessmentTitleLayout = ({
  title,
}: LifestyleAssessmentTitleLayoutProps) => (
  <TextComponent bold className="mb12">
    {title}
  </TextComponent>
);
