import { RedoOutlined } from '@ant-design/icons';
import {
  Button,
  Col, Form, FormProps, Row,
  Tooltip
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CgmGoalStatusEnum, CgmMetricEnum, Note } from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { FormType } from '../../../Input/types';
import { MeetingStatusSelectComponent } from '../../../patient/component/MeetingStatusSelectComponent/MeetingStatusSelectComponent';
import { CgmHelper } from '../../../vitals/helper/CgmHelper';
import { CgmThresholdInputComponent } from '../CgmGoalThresholdComponent/CgmThresholdInputComponent';
import { CgmGoalTooltipComponent } from '../CgmGoalTooltipComponent/CgmGoalTooltipComponent';
import { GoalFormTimeComponent } from '../ClinicalGoalComponent/GoalFormTimeComponent/GoalFormTimeComponent';
import { InterventionSubmitCancelButtons } from '../InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import './CgmGoalFormComponent.scss';
import { useCgmGoalForm } from './useCgmGoalForm';

export interface CgmGoalFormValue {
  tbrLevel2?: number;
  tbrLevel2UpperBound?: number;
  tbrLevel1?: number;
  tbrLevel1LowerBound?: number;
  tbrLevel1UpperBound?: number;
  tir?: number;
  tirLowerBound?: number;
  tirUpperBound?: number;
  tarLevel1?: number;
  tarLevel1LowerBound?: number;
  tarLevel1UpperBound?: number;
  tarLevel2?: number;
  tarLevel2LowerBound?: number;
  status?: CgmGoalStatusEnum;
  latestNote?: string;
  notes?: Note[];
  updatedTime?: string;
}

export type CgmMetricItems = keyof CgmGoalFormValue;

export interface CgmGoalFormComponentProps extends FormType<CgmGoalFormValue> {
  onSubmit?: (v: CgmGoalFormValue) => void;
  isLoading?: boolean;
  initialValues?: CgmGoalFormValue;
  includeNote?: boolean;
}

export const CgmGoalFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
  onCancel,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />,
  onValuesChange,
  includeNote = true
}: CgmGoalFormComponentProps) => {
  const cgmGoalFormInfo = useCgmGoalForm();
  const { form } = cgmGoalFormInfo;

  const defaultGoals = {
    tbrLevel2: CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TBR_LEVEL_2),
    tbrLevel1: CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TBR_LEVEL_1),
    tir: CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TIR),
    tarLevel1: CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TAR_LEVEL_1),
    tarLevel2: CgmHelper.getDefaultThresholdValue(CgmMetricEnum.TAR_LEVEL_2),
  };

  const goalKeys = Object.keys(defaultGoals) as Array<keyof typeof defaultGoals>;
  const isInitChanged = (): boolean => goalKeys.some(
    key => initialValues?.[key] !== defaultGoals[key]
  );

  const isPercentGoalsChanged = (): boolean => goalKeys.some(
    key => Number(form.getFieldValue(key)) !== defaultGoals[key]
  );

  const [isDefault, setIsDefault] = useState(!isInitChanged());

  const resetPercentGoals = (goalValues = defaultGoals): void => {
    form.resetFields(Object.keys(goalValues)); // Clear validation errors
    form.setFieldsValue(goalValues); // Reset to provided goal values
    setIsDefault(true);
  };

  const onFormSubmit = (v: CgmGoalFormValue) => {
    onSubmit?.({
      ...v,
    });
  };
  const [currentDate] = useState(dayjs());

  const handleValuesChange: FormProps['onValuesChange'] = (changedValues, allValues) => {
    onValuesChange?.(changedValues, allValues);
    setIsDefault(!isPercentGoalsChanged());
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, []);

  return (
    <Form
      name="cgmPercentGoals"
      form={form}
      layout="vertical"
      disabled={isLoading}
      onFinish={onFormSubmit}
      onValuesChange={handleValuesChange}
      initialValues={initialValues}
    >
      <FixedComponent>
        {/* title */}
        <div className="flex text-gray-scale-2 mb12 fs12">
          <div>
            CGM Percent in Range
          </div>
          <TooltipComponent
            title={<CgmGoalTooltipComponent />}
            placement="topLeft"
            type="info-icon"
            color="white"
            overlayStyle={{ minWidth: '620px' }}
          />
        </div>

        {/* CGM percent goal */}
        <Row gutter={10} wrap justify="space-between">
          <Col xs={24} sm={24}>
            <div className="flex gap2 ai-c flex-wrap">
              <CgmThresholdInputComponent
                upperBound={initialValues?.tbrLevel2UpperBound}
                metric={CgmMetricEnum.TBR_LEVEL_2}
                sign="≤"
                name={cgmGoalFormInfo.getName('tbrLevel2')}
              />
              <CgmThresholdInputComponent
                upperBound={initialValues?.tbrLevel1UpperBound}
                lowerBound={initialValues?.tbrLevel1LowerBound}
                metric={CgmMetricEnum.TBR_LEVEL_1}
                name={cgmGoalFormInfo.getName('tbrLevel1')}
              />
              <CgmThresholdInputComponent
                upperBound={initialValues?.tirUpperBound}
                lowerBound={initialValues?.tirLowerBound}
                metric={CgmMetricEnum.TIR}
                name={cgmGoalFormInfo.getName('tir')}
              />
              <CgmThresholdInputComponent
                upperBound={initialValues?.tarLevel1UpperBound}
                lowerBound={initialValues?.tarLevel1LowerBound}
                metric={CgmMetricEnum.TAR_LEVEL_1}
                name={cgmGoalFormInfo.getName('tarLevel1')}
              />
              <CgmThresholdInputComponent
                lowerBound={initialValues?.tarLevel2LowerBound}
                metric={CgmMetricEnum.TAR_LEVEL_2}
                name={cgmGoalFormInfo.getName('tarLevel2')}
                sign="≥"
              />
              <Tooltip
                className="CgmGoalFormComponent__reset-bottom"
                placement="top"
                title="Reset to default"
              >
                <Button
                  disabled={isDefault}
                  type="text"
                  icon={(
                    <span className={!isDefault ? 'CgmGoalFormComponent__reset-button-icon' : ''}>
                      <RedoOutlined />
                    </span>
                  )}
                  onClick={() => resetPercentGoals(defaultGoals)}
                />
              </Tooltip>
            </div>
          </Col>

          {/* CGM status and updated time */}
          <Col xs={24} sm={24}>
            <div className="flex CgmGoalFormComponent__status jc-e">
              <p className="text-gray-scale-2 mx5 mt5"> Status </p>
              <FormItem
                info={cgmGoalFormInfo.getInfo('status')}
                className="goal-form-meeting-status"
              >
                <MeetingStatusSelectComponent useExtendedStatus />
              </FormItem>
              <div className="mt5">
                <GoalFormTimeComponent
                  value={initialValues?.updatedTime ?? currentDate.toISOString()}
                />
              </div>
            </div>
          </Col>
        </Row>
        {includeNote && (
          <>
            <p className="text-gray-scale-2 fs12 mt32"> Additional Comments</p>
            <FormItem name="latestNote">
              <TextArea placeholder="Enter comment" />
            </FormItem>
          </>
        )}
        {
          initialValues?.notes?.length !== undefined && initialValues?.notes?.length !== 0 && (
            <HistoryDrawerComponent history={initialValues?.notes} />
          )
        }
        {formButtons}
      </FixedComponent>
    </Form>
  );
};
