import {
  Drawer,
  DrawerProps
} from 'antd';
import { Title } from '../../../../uiComponent/Title/Title';
import { CGMConsentFormContainer } from '../../container/CGMConsentFormContainer/CGMConsentFormContainer';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';

import './CGMConsentFormDrawerComponent.scss';

export interface CGMConsentFormDrawerComponentProps extends Omit<DrawerProps, 'onClose'> {
  patientId: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const CGMConsentFormDrawerComponent = ({
  patientId,
  open,
  title = (<Title>Sign Consent Form</Title>),
  onSubmit,
  onCancel,
}: CGMConsentFormDrawerComponentProps) => {
  const handleOnSigned = () => {
    onSubmit?.();
  };

  return (
    <Drawer
      title={title}
      width="fit-content"
      open={open}
      onClose={onCancel}
      destroyOnClose
    >
      <div className="cgm-consent-form-drawer__body">
        <PatientProfileContainer
          patientId={patientId}
          getEnrolledProgram
          getConsent
        >
          {
            (info) => (
              (info?.patientInfoService === undefined
                || info.enrolledProgramService === undefined
                || info.consentService === undefined
              )
                ? (
                  <LoadingOverlayComponent isLoading />
                ) : (
                  <CGMConsentFormContainer
                    patientService={info?.patientInfoService}
                    patientConsentService={info?.consentService}
                    enrolledProgramService={info.enrolledProgramService}
                    onSigned={handleOnSigned}
                    onCancel={onCancel}
                  />
                )
            )
          }
        </PatientProfileContainer>
      </div>
    </Drawer>
  );
};
