import { DeleteOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { ReactNode } from 'react';

export interface FastFoodBeverageInputCardLayoutProps {
  type: ReactNode;
  frequency: ReactNode;
  removable?: boolean;
  removeAction?: () => void;
}

export const FastFoodBeverageInputCardLayout = ({
  type,
  frequency,
  removable = true,
  removeAction,
}: FastFoodBeverageInputCardLayoutProps) => (
  <Row className="mb5">
    <Col span={6}>
      {type}
    </Col>
    <Col offset={1}>
      <div className="flex f-w ai-bl">
        {frequency}
        {
          (removable && removeAction)
          && (
            <DeleteOutlined
              onClick={removeAction}
            />
          )
        }
      </div>
    </Col>
  </Row>
);
