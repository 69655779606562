import { useState } from 'react';
import { Checkbox } from 'antd';

import './FollowUpScheduleFormComponent.scss';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { SkipFollowUpScheduleFormContainer, SkipFollowUpScheduleFormContainerProps } from '../../container/SkipFollowUpScheduleFormContainer/SkipFollowUpScheduleFormContainer';
import { FollowUpScheduleButtonComponent } from '../FollowUpScheduleButtonComponent/FollowUpScheduleButtonComponent';
import { CGMServiceNextVisitFormComponent } from '../CGMServiceNextVisitFormComponent/CGMServiceNextVisitFormComponent';
import { RequiredAsteriskComponent } from '../../../../uiComponent/RequiredAsterisk/RequiredAsteriskComponent';
import { AddOnServiceEnum, CGMOptOut, VisitTypeEnum } from '../../../../uc-api-sdk';
import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface FollowUpScheduleFormComponentProps {
  visitId?: string;
  canSkipSchedule?: boolean;
  followupVisitSkipped?: boolean;
  cgmOptOut?: CGMOptOut;
  showWantScheduleAnother?: boolean;
}

export const FollowUpScheduleFormComponent = ({
  visitId,
  canSkipSchedule,
  followupVisitSkipped: _followupVisitSkipped = false,
  cgmOptOut,
  showWantScheduleAnother = false,
}: FollowUpScheduleFormComponentProps) => {
  const [startNewSchedule, setStartNewSchedule] = useState(false);
  const [followupVisitSkipped, setSkipFollowUpSchedule] = useState(_followupVisitSkipped);
  const { patientInfo, clinicEvent } = useWorklistContext();
  const hasCGMService = !!patientInfo?.enrolledProgramService.hasAddOnService(AddOnServiceEnum.CGM);
  const isAdditionalVisit = clinicEvent?.visitType === VisitTypeEnum.ADDITIONAL;
  const showCGMRelatedInfo = hasCGMService && isAdditionalVisit;

  useUpdateListener('VISIT_CREATED', () => setStartNewSchedule(false));

  const handleSkipScheduleChange: SkipFollowUpScheduleFormContainerProps['onValuesChange'] = (
    changedValues,
  ) => {
    const newIgnoreFollowUp = changedValues.followupVisitSkipped;
    if (newIgnoreFollowUp !== undefined) {
      setSkipFollowUpSchedule(newIgnoreFollowUp);
    }
  };

  const renderCGMServiceNote = () => (
    showCGMRelatedInfo ? (
      <p>
        <div className="inline-block">
          <RequiredAsteriskComponent />
        </div>
        This patient is currently on
        {' '}
        <b>CGM service</b>
        {'. '}
        Schedule an
        {' '}
        <b>additional visit</b>
        {' '}
        based on the estimated sensor end time to preview
        patients's CGM data.
        {' ('}
        Libre: 14-25 days from start date,
        Dexcom: 10 days from start date)
      </p>
    ) : null
  );

  const renderScheduleAnotherOne = () => (
    showWantScheduleAnother ? (
      <Checkbox
        checked={startNewSchedule}
        onChange={(e) => setStartNewSchedule(e.target.checked)}
      >
        Still want to schedule another one?
      </Checkbox>
    ) : null
  );

  return (
    <div className="follow-up-schedule">
      {renderCGMServiceNote()}
      {renderScheduleAnotherOne()}
      {(!showWantScheduleAnother || startNewSchedule) && (
        <>
          <div className="follow-up-schedule-button my20">
            <FollowUpScheduleButtonComponent
              disabled={followupVisitSkipped}
            />
          </div>
          {
            canSkipSchedule
            && (
              <NestedFormComponent>
                <SkipFollowUpScheduleFormContainer
                  visitId={visitId}
                  initialValues={{ followupVisitSkipped }}
                  onValuesChange={handleSkipScheduleChange}
                />
              </NestedFormComponent>
            )
          }
        </>
      )}
      <NestedFormComponent>
        {showCGMRelatedInfo && (
          <CGMServiceNextVisitFormComponent
            initialValues={{
              cgmOptOut: cgmOptOut?.hasOptOut || false,
              cgmOptOutReason: {
                associatedEnum: cgmOptOut?.reasonEnum ?? undefined,
                value: cgmOptOut?.reason ?? undefined,
              }
            }}
          />
        )}
      </NestedFormComponent>
    </div>
  );
};
