import { Form } from 'antd';
import { FormType } from '../../../Input/types';
import { useSocialSupportForm, SubmitValue } from '../../hook/useSocialSupportForm';
import { SocialSupportFormItemComponent } from './SocialSupportFormItemComponent';

export interface SocialSupportFormComponentProps extends FormType<SubmitValue> { }

export const SocialSupportFormComponent = ({
  isLoading,
  initialValues,
  onSubmit,
  onValuesChange
}: SocialSupportFormComponentProps) => {
  const form = useSocialSupportForm();
  return (
    <Form
      form={form.form}
      onFinish={form.handleSubmit(onSubmit)}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      disabled={form.formDisabled || isLoading}
      layout="vertical"
      colon={false}
    >
      <SocialSupportFormItemComponent />
    </Form>
  );
};
