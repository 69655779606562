import { Col } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { SocialSupportInputComponent } from './SocialSupportInputComponent';
import { useSocialSupportForm } from '../../hook/useSocialSupportForm';

export const SocialSupportFormItemComponent = () => {
  const form = useSocialSupportForm();

  return (
    <>
      <div className="flex gap3">
        <Col span={7}>
          <FormItem
            info={form.getInfo('communitySupport')}
          >
            <SocialSupportInputComponent
              tooltip="Note whether the patient lives alone or with others, along with any support they receive for ADLs, transportation, and other needs."
            />
          </FormItem>
        </Col>
        <Col span={7}>
          <FormItem info={form.getInfo('contactInfo')}>
            <SocialSupportInputComponent
              tooltip="Record contact information for the patient's support people and caregivers"
            />
          </FormItem>
        </Col>
        <Col span={7}>
          <FormItem info={form.getInfo('otherProviders')}>
            <SocialSupportInputComponent
              tooltip="Record the patient's specialty providers and include office phone numbers if available."
            />
          </FormItem>
        </Col>
      </div>
      <div>
        <Col span={20}>
          <FormItem info={form.getInfo('easeOfFoodAccess')}>
            <SocialSupportInputComponent />
          </FormItem>
        </Col>
      </div>
    </>
  );
};
