import { Modal } from 'antd';
import { Icons } from '../../../../icons/Icons';

export const showConsentFormSentModal = (
  onOk?: () => void,
) => {
  Modal.info({
    centered: true,
    icon: null,
    title: <div className="flex jc-c ai-c"><Icons.PaperPlane hexColor="#3BA767" /></div>,
    content: "The consent form has been sent to the patient's app! Please help the patient complete the signing if possible.",
    okText: 'Got it',
    onOk
  });
};
