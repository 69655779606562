import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input
} from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { MDYYYY } from '../../../../constants/timeFormat';
import { InsuranceEligibilityRequestSubmitValue, useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { FormType } from '../../../Input/types';
import { InquiringProviderNameFormItemComponent } from '../InquiringProviderNameFormItemComponent/InquiringProviderNameFormItemComponent';
import { InsuranceOrderOfBenefitFormItemComponent } from '../InsuranceCardFormComponent/InsuranceCardFormItemComponent/InsuranceOrderOfBenefitFormItemComponent';
import { InsurancePlanFormItemComponent } from '../InsuranceCardFormComponent/InsuranceCardFormItemComponent/InsurancePlanFormItemComponent';
import { InsurancePolicyNumberFormItemComponent } from '../InsuranceCardFormComponent/InsuranceCardFormItemComponent/InsurancePolicyNumberFormItemComponent';
import { InsuranceRelationshipToInsuredFormItemComponent } from '../InsuranceCardFormComponent/InsuranceCardFormItemComponent/InsuranceRelationshipToInsuredFormItemComponent';
import { InsuranceItemComponent } from '../InsuranceItemComponent/InsuranceItemComponent';

import './InsuranceEligibilityVerifyFormComponent.scss';

export interface InsuranceEligibilityVerifyFormComponentProps
  extends FormType<InsuranceEligibilityRequestSubmitValue> {
}

export const InsuranceEligibilityVerifyFormComponent = ({
  onSubmit,
  initialValues,
}: InsuranceEligibilityVerifyFormComponentProps) => {
  const insuranceForm = useInsuranceForm();

  const handleSubmit = (values: InsuranceEligibilityRequestSubmitValue) => {
    onSubmit?.(values);
  };

  const renderSectionTitle = (
    title: string,
    className = '',
  ) => (
    <TextComponent
      className={classNames({
        'eligibility-verify-section-title mt20 mb5': true,
        [className]: !!className,
      })}
      size="large"
      bold
    >
      {title}
    </TextComponent>
  );

  const renderWithManualInput = (
    children: ReactNode,
    emptyChildren: ReactNode = null,
  ) => (
    <FormItem noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const isManualInput = insuranceForm.isManualInput(getFieldValue);
        if (isManualInput) {
          return emptyChildren;
        }
        return children;
      }}
    </FormItem>
  );

  return (
    <Form
      initialValues={initialValues}
      onFinish={insuranceForm.handleSubmit(handleSubmit)}
      className="insurance-eligibility-verify-form"
    >
      <div>
        Please fill in the required fields below for an eligibility verification query:
      </div>

      <div>
        {renderSectionTitle('Basic info', 'mt30')}

        <div className="flex">
          <InsuranceItemComponent
            label={insuranceForm.getLabel('firstName')}
            content={(
              <FormItem
                name={insuranceForm.getName('firstName')}
                required
              >
                <Input />
              </FormItem>
            )}
            span={12}
            required
          />
          <InsuranceItemComponent
            label={insuranceForm.getLabel('lastName')}
            content={(
              <FormItem
                name={insuranceForm.getName('lastName')}
                required
              >
                <Input />
              </FormItem>
            )}
            span={12}
            required
          />
        </div>
        <div>
          <InsuranceItemComponent
            label={insuranceForm.getLabel('birthday')}
            content={(
              <FormItem
                name={insuranceForm.getName('birthday')}
                required
              >
                <DatePicker
                  className="w100"
                  format={MDYYYY}
                />
              </FormItem>
            )}
            span={24}
            required
          />
        </div>

        {renderSectionTitle('Insurance info')}

        <div>
          <InsurancePlanFormItemComponent
            span={14}
          />
        </div>
        <div className="flex mt10">
          <InsuranceOrderOfBenefitFormItemComponent
            span={8}
          />
          <InsurancePolicyNumberFormItemComponent
            span={8}
            required
            shouldValidate
          />
          <InsuranceRelationshipToInsuredFormItemComponent
            span={8}
            required
          />
        </div>
        <div className="flex mt10">
          <InquiringProviderNameFormItemComponent
            span={24}
            required
          />
        </div>
      </div>
      <div className="mt60 mb20">
        {
          renderWithManualInput(
            (
              <div>
                I understand that each query
                {' '}
                <b>incurs a cost</b>
                {', '}
                and I have double-checked the information needed before making a query.
              </div>
            ),
            (
              <div className="flex ai-bl text-red b5">
                <ExclamationCircleFilled
                  className="mr5"
                />
                <div>
                  Please be aware that the eligibility of manually entered insurance plan
                  can't be verified.
                </div>
              </div>
            )
          )
        }
      </div>
      <FormItem
        noStyle
        shouldUpdate
      >
        {
          ({ getFieldValue }) => {
            const isVerifyDisabled = insuranceForm.shouldDisableVerify(getFieldValue);
            return (
              <Button
                type="primary"
                htmlType="submit"
                block
                disabled={isVerifyDisabled}
              >
                <div className="flex ai-c jc-c insurance-eligibility-verify-form__submit-btn">
                  Verify
                </div>
              </Button>
            );
          }
        }
      </FormItem>
    </Form>
  );
};
