import { ColumnType } from 'antd/lib/table';
import { Input } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useMealSleepRoutineForm, SubmitValue } from '../../hook/useILifestyleAssessmentForm/useMealSleepRoutineForm';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { MealRoutineTypeEnum } from '../../../../uc-api-sdk';
import { FromToTimeDisplay } from '../../../../uiComponent/FromToTimeDisplay/FromToTimeDisplay';
import { getTimeOfDayByMinutes } from '../../../../helpers/time';
import { MealRoutineTypeEnumComponent } from '../../../../enumComponent/MealRoutineTypeEnumComponent/MealRoutineTypeEnumComponent';
import { TimeSelectAllDayComponent } from '../../../../uiComponent/TimeSelectAllDayComponent/TimeSelectAllDayComponent';

export const useMealAndSleepRoutineTableColumns = (isEdit?: boolean) => {
  const niForm = useMealSleepRoutineForm();

  const getFoodTypeAmountPlaceholder = (record: SubmitValue) => {
    switch (record?.type) {
      case MealRoutineTypeEnum.WAKEUP:
        return 'Enter details about wake up routine';
      case MealRoutineTypeEnum.SLEEP:
        return 'Enter details about bedtime routine';
      default:
        return niForm.getInfo('foodTypeAmount').placeholder;
    }
  };

  return {
    type: (): ColumnType<SubmitValue> => ({
      title: niForm.getLabel('type'),
      width: 100,
      render: (text, record, index) => (
        isEdit ? (
          <FormItem
            name={niForm.makeObjectNamePath('type', index)}
            initialValue={record.type}
          >
            <MealRoutineTypeEnumComponent />
          </FormItem>
        ) : (
          <MealRoutineTypeEnumComponent value={record.type} />
        )
      ),
      fixed: 'left',
    }),
    timeRange: (): ColumnType<SubmitValue> => ({
      title: niForm.getLabel('startTime'),
      width: isEdit ? 240 : 200,
      render: (text, record, index) => (
        <div className="flex ai-bl gap2">
          {
            isEdit
              ? (
                <>
                  <FormItem
                    name={niForm.makeObjectNamePath('startTime', index)}
                  >
                    <TimeSelectAllDayComponent
                      placeholder={niForm.getInfo('startTime').placeholder}
                      allowClear
                    />
                  </FormItem>
                  <div>to</div>
                  <FormItem
                    name={niForm.makeObjectNamePath('endTime', index)}
                  >
                    <TimeSelectAllDayComponent
                      placeholder={niForm.getInfo('endTime').placeholder}
                      allowClear
                    />
                  </FormItem>

                </>
              ) : (
                <FromToTimeDisplay
                  startTime={getTimeOfDayByMinutes(record.startTime)}
                  endTime={getTimeOfDayByMinutes(record.endTime)}
                  format="hhmm_a"
                  separator="to"
                />
              )
          }
        </div>
      ),
    }),
    foodTypeAmount: (): ColumnType<SubmitValue> => ({
      title: niForm.getLabel('foodTypeAmount'),
      width: 600,
      render: (text, record, index) => (
        isEdit ? (
          <FormItem name={niForm.makeObjectNamePath('foodTypeAmount', index)}>
            <Input.TextArea
              placeholder={getFoodTypeAmountPlaceholder(record)}
              autoSize={{ minRows: 2 }}
            />
          </FormItem>
        ) : (
          <DisplayOrEmptyComponent
            value={record.foodTypeAmount}
          />
        )
      ),
    }),
    freq: (): ColumnType<SubmitValue> => ({
      title: niForm.getLabel('frequency'),
      width: 210,
      render: (text, record, index) => (
        isEdit ? (
          <FormItem
            name={niForm.makeObjectNamePath('frequency', index)}
          >
            <Input.TextArea
              placeholder={niForm.getInfo('frequency').placeholder}
              autoSize={{ minRows: 2 }}
            />
          </FormItem>
        ) : (
          <DisplayOrEmptyComponent
            value={record.frequency}
          />
        )
      ),
    }),
  };
};
