import { useMemo } from 'react';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { AddOnServiceEnum, useClinicGet, usePatientUpsertPatientEnrolledProgram } from '../../../../uc-api-sdk';
import { PatientProfileVitalsMonitoringFormComponent, PatientProfileVitalsMonitoringFormComponentProps } from '../../component/PatientProfileVitalsMonitoringFormComponent/PatientProfileVitalsMonitoringFormComponent';
import { AddRemoveVitalsMonitoringSubmitValue } from '../../hook/useAddRemoveVitalsMonitoringForm';
import { FormType } from '../../../Input/types';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';

export interface PatientProfileVitalsMonitoringFormContainerProps
  extends FormType<AddRemoveVitalsMonitoringSubmitValue> {
  patientInfo: PatientInfo;
  enrolledProgramService: EnrolledProgramService;
}

export const PatientProfileVitalsMonitoringFormContainer = ({
  patientInfo,
  enrolledProgramService,
  isLoading,
  onSubmit,
  onCancel,
  onError,
  onValuesChange,
  formButtons,
}: PatientProfileVitalsMonitoringFormContainerProps) => {
  const patientClinicId = patientInfo.patientInfoService?.getClinicId();
  const clinicInfo = useClinicGet({
    params: { id: patientClinicId || '' },
    options: { sendOnMount: !!patientClinicId },
  });
  const isClinicLoading = !clinicInfo.data?.data === undefined || clinicInfo.isLoading;
  const isCGMEnabled = clinicInfo?.data?.data?.addOnServices?.includes(AddOnServiceEnum.CGM);

  const vitalsUpdateInfo = usePatientUpsertPatientEnrolledProgram({});
  const handleOnSubmit: PatientProfileVitalsMonitoringFormComponentProps['onSubmit'] = (
    values,
  ) => {
    onSubmit?.(values);
  };

  const debouncedHandleSubmit = useDebounce(handleOnSubmit, 250, [handleOnSubmit]);
  const initialValues = useMemo((): AddRemoveVitalsMonitoringSubmitValue => ({
    vitals: enrolledProgramService.getMonitoringVitals(),
  }), []);

  return (
    <LoadingOverlayComponent
      isLoading={isClinicLoading}
    >
      <PatientProfileVitalsMonitoringFormComponent
        patientId={patientInfo?.id}
        initialValues={initialValues}
        isCGMEnabled={isCGMEnabled}
        isLoading={isLoading || vitalsUpdateInfo.isLoading}
        onSubmit={debouncedHandleSubmit}
        onCancel={onCancel}
        onError={onError}
        onValuesChange={onValuesChange}
        formButtons={formButtons}
      />
    </LoadingOverlayComponent>
  );
};
