import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { FileUploadMessagePayload } from '../../../../services/CHSServices/types/data';

import { BucketsTypeEnum, useFileManagerDownloadUrl } from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import './MessageAudioComponent.scss';
import { Icons } from '../../../../icons/Icons';

export interface MessageAudioComponentProps extends Pick<FileUploadMessagePayload, 'fileKey'> {
}

export const MessageAudioComponent = ({ fileKey }: MessageAudioComponentProps) => {
  const fileDownloadInfo = useFileManagerDownloadUrl({
    params: {
      fileDownloadUrlInput: {
        bucket: BucketsTypeEnum.AUDIO_UPLOAD,
        fileKey,
      },
    },
    options: {
      sendOnMount: true,
      retry: 1,
      cache: true,
    },
  });
  const audioSrc = fileDownloadInfo?.data?.data?.url;

  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const timerRef = useRef<number | null>(null);

  const handleError = () => {
    const audio = audioRef.current;
    if (!audio) return;
    console.error('Error loading audio file. Check the URL:', audio.src);
  };

  const handleLoadedMetadata = () => {
    const audio = audioRef.current;
    if (!audio) return;
    if (!Number.isNaN(audio.duration) && audio.duration > 0) {
      setDuration(audio.duration);
      setRemainingTime(audio.duration);
    } else {
      console.warn('Duration is NaN, retrying...');
      audio.load();
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio || !audioSrc) {
      return () => {
        console.log('[Audio Effect Cleanup] Skipped — no valid audio or src.');
      };
    }

    const handleMetadata = () => {
      handleLoadedMetadata();
    };

    const handleErrorEvent = () => {
      handleError();
    };

    audio.src = audioSrc;
    audio.load();

    audio.addEventListener('loadedmetadata', handleMetadata);
    audio.addEventListener('error', handleErrorEvent);

    return () => {
      audio.removeEventListener('loadedmetadata', handleMetadata);
      audio.removeEventListener('error', handleErrorEvent);
    };
  }, [audioSrc]);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying) {
      audio.pause();
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    } else {
      audio.play().catch((e) => console.error('Playback Error:', e));

      timerRef.current = window.setInterval(() => {
        setRemainingTime((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }

    setIsPlaying(!isPlaying);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setRemainingTime(duration);
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  if (!audioSrc) {
    return <LoadingOverlayComponent isLoading showSkeleton />;
  }

  return (
    <>
      <audio ref={audioRef} src={audioSrc} preload="metadata" onEnded={handleEnded}>
        <track kind="captions" src="" label="No captions available" />
      </audio>
      <div>
        <Button
          onClick={togglePlayPause}
          className={classNames({
            'audio-bar': !isPlaying,
            'audio-bar-play': isPlaying,
            flex: true,
            'ai-c': true,
          })}
        >
          <span className="play-icon mr5">
            {isPlaying ? <Icons.AudioPauseIcon /> : <Icons.AudioPlayIcon />}
          </span>
          <span className="text-white b5 mr5">
            {(() => {
              const minutes = Math.floor(Math.max(0, remainingTime) / 60);
              const seconds = Math.floor(Math.max(0, remainingTime) % 60);
              return `${minutes}:${seconds.toString().padStart(2, '0')}`;
            })()}
          </span>
          <span className="wave-icon">
            {isPlaying ? <Icons.AudioWaveAnimatedIcon /> : <Icons.AudioWaveStaticIcon /> }
          </span>
        </Button>
      </div>
    </>
  );
};
