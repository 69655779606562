import { Col, Row } from 'antd';
import { DietEnumListComponent } from '../../../../enumComponent/DietEnumComponent/DietEnumListComponent';
import { FreqComponent } from '../../../../uiComponent/FreqComponent/FreqComponent';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import { HistoryInfo } from '../../../../uiComponent/HistoryComponent/HistoryItemComponent';
import { TitleWithContent } from '../../../../uiComponent/TitleWithContent/TitleWithContent';
import { OldNutrition } from './oldType';

import './VersionedNutritionComponent.scss';
import { OldIntakeTableComponent } from './OldIntakeTableComponent';

export interface VersionedNutritionComponentProps {
  nutrition?: OldNutrition | null;
  history?: HistoryInfo[];
}

// ---
// WARNING: OLD UI for version view only, don't use this component
// ---
export const VersionedNutritionComponent = ({
  nutrition,
  history,
}: VersionedNutritionComponentProps) => (
  <div className="old-nutrition-component">
    <OldIntakeTableComponent
      intake={nutrition?.intake}
    />
    <Row className="pt20" gutter={[8, 8]}>
      <Col span={6}>
        <TitleWithContent
          title="Fast Food Frequency"
          content={(
            <FreqComponent
              value={nutrition?.fastFoodFreq}
              unit={nutrition?.fastFoodFreqUnit}
            />
          )}
        />
      </Col>
      <Col span={6}>
        <TitleWithContent
          title="Sweet Beverage Frequency"
          content={(
            <FreqComponent
              value={nutrition?.sweetBeverageFreq}
              unit={nutrition?.sweetBeverageFreqUnit}
            />
          )}
        />
      </Col>
    </Row>
    <Row className="pt20">
      <Col span={24}>
        <TitleWithContent
          title="Previous Calorie Management Regimens"
          content={(
            <DietEnumListComponent
              value={nutrition?.previousDiets ? nutrition?.previousDiets : []}
              splitter=", "
            />
          )}
        />
      </Col>
    </Row>
    <Row className="pt20">
      <Col span={24}>
        <TitleWithContent
          title="Nutrition Understanding"
          content={nutrition?.nutritionUnderstanding}
        />
      </Col>
    </Row>
    <Row className="pt20">
      <Col span={24}>
        <TitleWithContent
          title="Additional Comments"
          content={`${nutrition?.additionalComments ? nutrition?.additionalComments : 'N/A'}`}
        />
      </Col>
    </Row>
    <HistoryDrawerComponent history={history} />
  </div>
);
