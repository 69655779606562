import { message } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { MonthlyMeasurementContextProvider } from '../../../../contexts/MonthlyMeasurementContext/MonthlyMeasurementContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import {
  CareNote,
  CareNoteTagEnum, CgmReportStatusEnum, useCareNoteInsert, useCgmUpdateCgmReport
} from '../../../../uc-api-sdk';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import CgmReportPreviewComponent from '../../../print/CgmReport/component/CgmReportPreviewComponent/CgmReportPreviewComponent';
import { CGMReviewSubmitValue, OutstandingCGMReviewFormComponent } from '../../component/OutstandingCGMReviewFormComponent/OutstandingCGMReviewFormComponent';

export interface OutstandingCGMReviewContainerProps {
  cgmReportId?: string;
  patientId: string;
}

export const OutstandingCGMReviewContainer = ({
  patientId,
  cgmReportId,
}: OutstandingCGMReviewContainerProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const insertCareNote = useCareNoteInsert({});
  const [careNote, setCareNote] = useState<CareNote | undefined>();
  const { userId } = useLoggedInUserFromContext();
  const updateCgmReport = useCgmUpdateCgmReport({});
  const { send } = useBillableTimeInterventionComp();
  const completeCgmReview = useUpdate('CGM_REVIEW_FINISHED');
  const { info } = usePatientContext();
  const { startEvent, endEvent } = useMpTrackingHelper(
    {
      eventStart: MixpanelEvents.CGMReviewStart,
      eventEnd: MixpanelEvents.CGMReviewEnd,
      parent: MixpanelEventsParents.Outstanding,
      patientId,
      properties: {
        cgmReportId
      },
    }
  );

  useEffect(startEvent, []);

  const updateCgmReportStatus = async (v: CGMReviewSubmitValue) => {
    await ApiRequestHelper.tryCatch(
      updateCgmReport.send({
        params: {
          reportId: cgmReportId || '',
          update: {
            status: CgmReportStatusEnum.DONE,
            note: v.cgmReviewNote,
          }
        }
      }),
      {
        success: 'Updated CGM Monthly Review successfully!',
        error: 'Failed to update CGM Monthly Review.',
        onSuccess: () => {
          endEvent();
        }
      }
    );
  };

  const handleSubmit = async (v: CGMReviewSubmitValue) => {
    setIsSubmitting(true);
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.ConductCGMReview,
      detail: BillableTimeInterventionDetailEnum.OutstandingCGMReviewSubmit,
    });
    ApiRequestHelper.tryCatch(
      insertCareNote.send({
        params: {
          document: {
            memberId: patientId,
            content: v.cgmReviewNote,
            tags: [CareNoteTagEnum.CGM_REPORT, CareNoteTagEnum.MONTHLY_REVIEW],
            createdBy: userId,
            reportStartTime: v.startTime ? TimezoneService.getUTC(v.startTime) : undefined,
            reportEndTime: v.endTime ? TimezoneService.getUTC(v.endTime) : undefined,
          }
        },
      }),
      {
        success: 'Updated CGM Review successfully!',
        error: 'Failed to update CGM Review.',
        onSuccess: async (data) => {
          message.success('CGM Care Note created successfully.', 3);
          setCareNote(data?.data || undefined);
          await updateCgmReportStatus(v);
        },
        onError: () => {
          setIsSubmitting(false);
        }
      }
    );
  };

  const onPrint = () => {
    completeCgmReview.updateValue();
    info?.refetch(['outstandingItemCountRefetch']);
  };

  const renderCgmReport = useCallback(() => {
    if (careNote) {
      return (
        <div className="display-none">
          <CgmReportPreviewComponent
            patientInfo={info}
            startDate={dayjs(careNote.reportStartTime).subtract(30, 'D')}
            endDate={dayjs(careNote.reportEndTime)}
            careNote={careNote}
            print
            onPrint={onPrint}
          />
        </div>
      );
    }

    return null;
  }, [careNote]);

  return (
    <div className="OutstandingCGMReviewContainer">
      <MonthlyMeasurementContextProvider patientId={patientId}>
        {
          ({ startTime, endTime }) => (
            <OutstandingCGMReviewFormComponent
              patientId={patientId}
              onSubmit={handleSubmit}
              isLoading={isSubmitting}
              startTime={startTime}
              endTime={endTime}
            />
          )
        }
      </MonthlyMeasurementContextProvider>
      {renderCgmReport()}
    </div>
  );
};
