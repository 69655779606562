import { ReactNode } from 'react';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { InterventionContainerProps } from '../../type';
import {
  LifestyleAssessment,
  usePatientGetLifestyleAssessmentById
} from '../../../../uc-api-sdk';
import { LifestyleAssessmentFormContainer } from '../../container/LifestyleAssessmentFormContainer/LifestyleAssessmentFormContainer';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { LifestyleAssessmentComponent } from '../LifestyleAssessmentComponent/LifestyleAssessmentComponent';
import { VersionedLifestyleAndNutritionModalButtonComponent } from '../../VersionedLifestyleAndNutrition/VersionedLifestyleAndNutritionModalComponent';

export interface PatientProfileLifestyleAssessmentComponentProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps<LifestyleAssessment> {
  title?: ReactNode;
  parent?: MixpanelEventsParents;
}

export const PatientProfileLifestyleAssessmentComponent = ({
  patientId,
  visitId,
  version,
  title = 'Lifestyle and Nutrition',
  isEditing,
  showEdit,
  onEdit,
  onSubmit,
  onError,
  onValuesChange,
  onCancel,
  formButtons,
  parent,
}: PatientProfileLifestyleAssessmentComponentProps) => {
  const iaInfo = usePatientGetLifestyleAssessmentById({
    params: {
      memberId: patientId,
      version,
    },
  });

  const initialLoading = iaInfo?.data?.data === undefined;
  const iaData = iaInfo?.data?.data;
  useUpdateListener('WORKLIST_UPDATED', iaInfo.refetch);

  const handleSubmit = () => {
    onSubmit?.();
    iaInfo.refetch();
  };

  if (initialLoading) {
    return <LoadingOverlayComponent isLoading />;
  }

  return (
    <PatientCommonCardComponent
      title={(
        <>
          {title}
          <div className="ml10">
            <VersionedLifestyleAndNutritionModalButtonComponent
              patientId={patientId}
            />
          </div>
        </>
      )}
      isEditing={isEditing}
      showEdit={showEdit}
      onEdit={onEdit}
      updateInfo={iaData}
      content={(
        <LifestyleAssessmentComponent
          data={iaInfo.data?.data}
        />
      )}
      formContent={(
        <LifestyleAssessmentFormContainer
          patientId={patientId}
          visitId={visitId}
          initialValues={iaInfo.data?.data ?? undefined}
          isLoading={iaInfo.isLoading}
          onSubmit={handleSubmit}
          onError={onError}
          onValuesChange={onValuesChange}
          onCancel={onCancel}
          formButtons={formButtons}
          parent={parent}
        />
      )}
    />
  );
};
