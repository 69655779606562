import { useUpdateEffect } from 'usehooks-ts';
import { useMessageServicesContext } from '../../../../contexts/MessageContext/MessageServicesContext';
import { PatientInfo, usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientPageControllerContext } from '../../../patient/context/PatientPageControllerContext/PatientPageControllerContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useEffectWithPrevValue } from '../../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';
import { VitalEnumType } from '../../../../uc-api-sdk';

export interface PatientOutstandingItemsOptions {
  manualMTPROpen?: boolean;
  supportManualMTPR?: boolean;
}

export const usePatientOutstandingItems = (
  patientInfo?: PatientInfo,
  options?: PatientOutstandingItemsOptions,
) => {
  const {
    supportManualMTPR = true,
    manualMTPROpen,
  } = options || {};
  const {
    id: patientId,
    outstandingService,
    enrolledProgramService,
    refetch
  } = patientInfo || usePatientContext()?.info || {};
  const {
    getChannel
  } = useMessageServicesContext() || {};
  const {
    // default: 0 is important, to prevent unnecessary initial refetch
    unreadMessages: realTimeUnreadMessages = 0,
    isUnread,
  } = getChannel?.(patientId) || {};
  const {
    isManualMTPROpen = manualMTPROpen
  } = usePatientPageControllerContext() || {};

  if (!patientId) {
    throw new Error('Missing patient context');
  }

  if (supportManualMTPR && isManualMTPROpen === undefined) {
    throw new Error('Missing patient page controller context');
  }

  const refetchOutstandingItems = useDebounce(() => (
    refetch?.(['outstandingItemCountRefetch'])
  ), 500, [refetch]);

  const outstandingItems = outstandingService?.outstandingItemCount || {};

  const totalOutstandingItemCount = (
    (outstandingService?.getTotalCount() || 0)
    + Number(isManualMTPROpen)
  );

  useUpdateEffect(() => {
    refetchOutstandingItems();
  }, [realTimeUnreadMessages, isUnread]);

  useEffectWithPrevValue(enrolledProgramService?.getVitals(), (prev) => {
    const curVitalTypes = enrolledProgramService?.getVitals();
    if (prev?.includes(VitalEnumType.CGM) && !curVitalTypes?.includes(VitalEnumType.CGM)) {
      // cgm alert is auto-closed when CGM is removed
      refetchOutstandingItems();
    }
  });

  return {
    outstandingItems,
    refetchOutstandingItems,
    totalOutstandingItemCount,
  };
};
