import { isBoolean } from 'lodash';
import { FreqEnumComponent } from '../../../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import { getCigaretteUnitText } from '../../../../enumComponent/SmokeDrinkEnumComponent/CigaretteUnitEnumComponent';
import { SmokeDrinkRecord } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { PluralComponent } from '../../../../uiComponent/PluralComponent/PluralComponent';
import { LifestyleAssessmentDisplayLayout } from './layout/LifestyleAssessmentDisplayLayout';

export interface SmokingInfoComponentProps {
  info?: SmokeDrinkRecord | null,
}

export const SmokingFreqInfoComponent = ({
  info,
}: SmokingInfoComponentProps) => (
  <div className="flex f-w ai-c">
    <PluralComponent
      value={info?.smokingAmount ?? undefined}
      unit={getCigaretteUnitText(info?.cigaretteUnit, true)}
      emptyUnit={<EmptyComponent />}
    />
    <span>/</span>
    {
      info?.smokingFrequency
        ? <FreqEnumComponent value={info?.smokingFrequency} type="short" />
        : <EmptyComponent />
    }
  </div>
);

export const SmokingInfoComponent = ({
  info,
}: SmokingInfoComponentProps) => {
  const isYes = info?.isSmoking;

  if (!isBoolean(isYes)) {
    return null;
  }
  return isYes
    ? (
      <LifestyleAssessmentDisplayLayout
        label="Frequency"
        content={<SmokingFreqInfoComponent info={info} />}
      />
    ) : (
      <LifestyleAssessmentDisplayLayout
        label="Quit year"
        content={(
          <DisplayOrEmptyComponent value={info?.quitSmokingYear} />
        )}
      />
    );
};
