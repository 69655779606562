import { Popover } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { ComplianceAlert } from '../../../../uc-api-sdk';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { CGMDeviceDrawerComponent } from '../../../CGMDevice/component/CGMDeviceDrawerComponent/CGMDeviceDrawerComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { CGMAlertStatus } from './cgmAlertType';

import './CGMComplianceAlertContentComponent.scss';

export interface CGMComplianceAlertContentComponentProps {
  alert?: ComplianceAlert | null;
  value?: CGMAlertStatus | null;
  onLinkAccountClick?: () => void;
}

export const CGMComplianceAlertContentComponent = ({
  alert,
  value,
  onLinkAccountClick,
}: CGMComplianceAlertContentComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const handleOnLinkAccountClick = () => {
    open();
    onLinkAccountClick?.();
  };

  const renderContent = () => {
    switch (value) {
      case CGMAlertStatus.MISSING_LINKING:
        return (
          <>
            <div>
              Patient's CGM account linking is not yet complete.
              Reach out to the patient to complete the account linking remotely
              or schedule an in-clinic visit.
              <LinkButton
                useBlueColor
                className="ml5 no-style-btn"
                onClick={handleOnLinkAccountClick}
              >
                Link Account
                <RightOutlined />
              </LinkButton>
            </div>
            <CGMDeviceDrawerComponent
              patientId={alert?.memberId || ''}
              open={isOpen}
              onClose={close}
            />
          </>
        );
      case CGMAlertStatus.MISSING_DATA:
        return (
          <div>
            The patient's data has not yet synced with the UC system.
            Verify with the patient: Confirm if they are still wearing the device
            and can see their data on the CGM app (Libre/Dexcom).
            <Popover
              overlayClassName="cgm-compliance-alert-tooltip"
              trigger={['click']}
              placement="bottom"
              content={(
                <ol>
                  <li>
                    If data is not available on the CGM app, guide the patient to contact
                    the manufacturer for assistance. They may be able to obtain a replacement.
                  </li>
                  <li>
                    If data is available on the CGM app but not visible in the portal/UC app,
                    this is an engineering issue—please report it.
                  </li>
                </ol>
              )}
            >
              <LinkButton
                useBlueColor
                className="ml5 no-style-btn"
              >
                Troubleshooting Steps
              </LinkButton>
            </Popover>
          </div>
        );
      case CGMAlertStatus.JUST_ENGAGE:
        return (
          <div>
            Patient recently enrolled in the CGM service.
            Please engage with them and address any questions or concerns.
          </div>
        );
      default:
        return null;
    }
  };

  return renderContent();
};
