import { useCallback } from 'react';
import {
  Table
} from 'antd';
import classNames from 'classnames';
import { find, map } from 'lodash';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { useMealAndSleepRoutineTableColumns } from './useMealAndSleepRoutineColumns';
import { SubmitValue } from '../../hook/useILifestyleAssessmentForm/useMealSleepRoutineForm';
import { MealRoutineTypeEnum } from '../../../../uc-api-sdk';

import './MealAndSleepRoutineTableComponent.scss';

export type MealAndSleepRoutineTableComponentProps = {
  isEditable: true;
  dataSource?: never;
} | {
  isEditable?: false;
  dataSource: SubmitValue[];
}

export const MealAndSleepRoutineTableComponent = ({
  isEditable,
  dataSource,
}: MealAndSleepRoutineTableComponentProps) => {
  const columns = useTableColumns(
    useMealAndSleepRoutineTableColumns(isEditable),
    ['type', 'timeRange', 'foodTypeAmount', 'freq'],
  );

  const parseDataSource = useCallback((dataSource?: SubmitValue[]) => (
    // populate types for static view
    map(MealRoutineTypeEnum, (e) => {
      const availData = find(dataSource, { type: e });
      return availData || { type: e };
    })
  ), []);

  return (
    <div className="meal-sleep-routine-table-component">
      <Table
        columns={columns}
        dataSource={parseDataSource(dataSource)}
        pagination={false}
        className={classNames({
          'meal-sleep-routine-table': true,
          isEditable,
        })}
        scroll={{ x: 'max-content' }}
        bordered
      />
    </div>
  );
};
