import { EnrolledProgramStatusEnum, usePatientVerifyLatestConsent, VerifyConsentUpdateRequest } from '../../../uc-api-sdk';

export const useVerifyConsentStatus = ({
  memberId,
  organizationId,
  status,
  selectedPrograms,
  selectedAddOnServices,
}: VerifyConsentUpdateRequest) => {
  const verify = usePatientVerifyLatestConsent({
    params: {
      request: {
        memberId,
        organizationId,
        status,
        selectedPrograms,
        selectedAddOnServices,
      },
    },
    options: {
      sendOnMount: status === EnrolledProgramStatusEnum.ENROLLED,
    }
  });

  return verify;
};
