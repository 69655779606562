import { ReactNode, useCallback } from 'react';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { usePatientGetClinicalGoalById, usePatientGetHealthCondition, usePatientUpdateClinicalGoal } from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { ClinicalGoalCardListComponent } from '../../component/ClinicalGoalComponent/ClinicalGoalCardListComponent/ClinicalGoalCardListComponent';
import { ClinicalGoalCardFormComponentProps, SubmitValue } from '../../component/ClinicalGoalComponent/ClinicalGoalFormComponent/ClinicalGoalCardFormComponent';
import { InterventionContainerProps } from '../../type';
import { ClinicalGoalTemplateFetchContainer } from './ClinicalGoalTemplateFetchContainer';

export interface ClinicalGoalContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps<SubmitValue> {
    title?: ReactNode;
  patientId: string;
  requireClinicalGoal?: boolean;
  clinicId?: string;
  showTitle?: boolean;
  showUpdateInfo?: boolean;
}

export const ClinicalGoalContainer = ({
  title = 'Clinical Goal',
  version,
  visitId,
  showEdit,
  onEdit,
  isEditing,
  onSubmit,
  onError,
  onCancel,
  patientId,
  onValuesChange,
  formButtons,
  requireClinicalGoal,
  clinicId,
  showTitle,
  showUpdateInfo
}: ClinicalGoalContainerProps) => {
  const fetchClinicalGoalHook = usePatientGetClinicalGoalById({
    params: {
      memberId: patientId,
      version,
    },
  });

  const fetchHealthConditionHook = usePatientGetHealthCondition({
    params: {
      memberId: patientId
    }
  });

  const codes = fetchHealthConditionHook?.data?.data?.healthConditions?.map(
    h => h.code
  )?.filter((code): code is string => !!code);

  const debouncedClinicalGoalRefetch = useDebounce(fetchClinicalGoalHook.refetch, 500);
  useUpdateListener('ICD_UPDATED', debouncedClinicalGoalRefetch);
  useUpdateListener('WORKLIST_UPDATED', debouncedClinicalGoalRefetch);
  useUpdateListener('MTPR_SAVED', debouncedClinicalGoalRefetch);
  useUpdateListener('MTPR_FINISHED', debouncedClinicalGoalRefetch);
  useUpdateListener('CLINICAL_GOAL_UPDATED', debouncedClinicalGoalRefetch);
  const clinicalGoalRefetch = useUpdate('CLINICAL_GOAL_UPDATED');

  const updateHook = usePatientUpdateClinicalGoal({});
  const updateClinicalGoal = useCallback(async (value: SubmitValue) => {
    const request = await updateHook.send({
      params:
      {
        memberId: patientId,
        request: {
          clinicalGoals: value.clinicalGoals,
          visitId,
        },
      },
    });
    return request;
  }, [patientId]);

  const handleSuccess = (v: SubmitValue) => {
    onSubmit?.(v);
    clinicalGoalRefetch.updateValue();
  };

  const handleSubmit: ClinicalGoalCardFormComponentProps['onSubmit'] = (v) => {
    const req = updateClinicalGoal(v);
    ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Updated clinical goals!',
        error: 'Fail to update clinical goals!',
        onSuccess: () => handleSuccess(v),
        onError,
      },
    );
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const { send } = useBillableTimeInterventionComp();

  const handleOnEdit = () => {
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.UpdateCarePlan,
      detail: BillableTimeInterventionDetailEnum.InterventionClinicalGoalEditIcon,
    });
    onEdit?.();
  };

  const isLoading = (
    fetchClinicalGoalHook.data?.data === undefined || fetchClinicalGoalHook.isLoading
  );
  const clinicalGoalInfo = fetchClinicalGoalHook.data?.data?.clinicalGoals || [];

  const renderContent = () => (
    clinicalGoalInfo.length
      ? (
        <ClinicalGoalCardListComponent
          value={clinicalGoalInfo ?? []}
        />
      ) : (
        <NoDataWithActionComponent
          description="No record at this time."
          actionLinkText="Add Clinical Goals"
          actionLinkOnClick={onEdit}
        />
      )
  );

  return (
    <PatientCommonCardComponent
      title={title}
      showHeaderOutside={false}
      isEditing={isEditing}
      showTitle={showTitle}
      showEdit={showEdit}
      onEdit={handleOnEdit}
      showUpdateInfo={showUpdateInfo}
      updateInfo={fetchClinicalGoalHook.data?.data}
      content={(
        <LoadingOverlayComponent isLoading={isLoading} showSkeleton>
          {renderContent()}
        </LoadingOverlayComponent>
      )}
      formContent={(
        <LoadingOverlayComponent
          isLoading={isLoading}
          showSkeleton
        >
          <ClinicalGoalTemplateFetchContainer
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            isLoading={updateHook.isLoading}
            initialValues={{
              clinicalGoals: clinicalGoalInfo,
            }}
            onValuesChange={onValuesChange}
            formButtons={formButtons}
            requireClinicalGoal={requireClinicalGoal}
            clinicId={clinicId}
            codes={codes}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
