export enum ActivityTypeEnum {
  CALL = 'Called patient offline',
  CHECK_EMR = 'Check EMR about this patient',
  EDUCATION = 'Translated education/communication',
  REVIEW_VITALS = 'Reviewed vitals and/or treatment goals',
  REVIEW_CGM_DATA = 'Reviewed CGM data',
  COORDINATE_CARE = 'Coordinated care with team or caregiver',
  RESEARCHED_CONDITION = 'Researched patient condition',
  OTHER = 'Other',
}
