import classNames from 'classnames';
import { ReactNode } from 'react';

import './IconTextBoxComponent.scss';

type BackgroundFill =
  'primary-blue-tint'
  | 'secondary-yellow';

export interface IconTextBoxComponentProps {
  icon?: ReactNode;
  text?: ReactNode;
  backgroundFill?: BackgroundFill;
  className?: string;
}

export const IconTextBoxComponent = ({
  icon,
  text = 'Icon text box',
  backgroundFill,
  className = '',
}: IconTextBoxComponentProps) => (
  <div
    className={classNames({
      'icon-text-box': true,
      'flex ai-c border default-br': true,
      [backgroundFill || '']: !!backgroundFill,
      [className]: !!className
    })}
  >
    <span className="icon-text-box__icon">
      {icon}
    </span>
    <span className="icon-text-box__text">
      {text}
    </span>
  </div>
);
