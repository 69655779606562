import { DateTimeType } from '../../types/common';
import { DisplayDateComponent, DisplayDateComponentProps } from '../DisplayComponent/DisplayDateComponent';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface FromToTimeDisplayProps {
  startTime?: DateTimeType | null;
  endTime?: DateTimeType | null;
  format: DisplayDateComponentProps['format'];
  separator?: string;
  fallbackText?: React.ReactNode;
}

export const FromToTimeDisplay = ({
  startTime,
  endTime,
  format,
  separator = '-',
  fallbackText = <EmptyComponent />,
}: FromToTimeDisplayProps) => (
  <>
    <DisplayDateComponent
      value={startTime}
      format={format}
      fallbackText={fallbackText}
    />
    <div>{separator}</div>
    <DisplayDateComponent
      value={endTime}
      format={format}
      fallbackText={fallbackText}
    />
  </>
);
