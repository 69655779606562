import { findLast } from 'lodash';
import {
  EnrollmentRequest,
  EnrollmentStatus,
  EnrollmentStatusEnum,
  PatientDiagnosesEnum
} from '../uc-api-sdk';

export interface EnrollmentServiceArg {
  enrollment?: EnrollmentRequest;
}

export class EnrollmentService {
  private enrollment?: EnrollmentRequest;

  constructor(enrollment?: EnrollmentRequest) {
    this.enrollment = enrollment;
  }

  get waivedTechOnboarding() {
    return this.enrollment?.waiveTechOnboarding;
  }

  private findLastStatusOf(status: EnrollmentStatusEnum) {
    return !!findLast(this.enrollment?.statuses || [], (v: EnrollmentStatus) => (
      v?.status === status
    ));
  }

  public isEnrolled() {
    return !!this.findLastStatusOf(EnrollmentStatusEnum.ENROLLED);
  }

  public isRejected() {
    return this.enrollment?.programEnrollment?.ableToJoinProgram === false;
  }

  public hasConsent() {
    return this.findLastStatusOf(EnrollmentStatusEnum.CONSENT_SUBMIT);
  }

  public hasDevices() {
    return this.findLastStatusOf(EnrollmentStatusEnum.DEVICE_ASSIGNED);
  }

  public getPatientTechLevel() {
    return this.enrollment?.patientDevice?.patientTechLevel;
  }

  public getPatientDevices() {
    return this.enrollment?.patientDevice?.deviceList;
  }

  public getPatientDiagnoses() {
    return (
      this.enrollment?.programEnrollment?.patientDiagnoses || []
    ) as PatientDiagnosesEnum[];
  }
}
