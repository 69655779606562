import { useEffect } from 'react';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { usePatientUpdateLifestyleAssessment } from '../../../../uc-api-sdk';
import { LifestyleAssessmentFormComponent, LifestyleAssessmentFormComponentProps } from '../../component/LifestyleAssessmentComponent/LifestyleAssessmentFormComponent';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';

export interface LifestyleAssessmentFormContainerProps
  extends LifestyleAssessmentFormComponentProps {
  patientId: string;
  visitId?: string;
  parent?: MixpanelEventsParents;
}

export const LifestyleAssessmentFormContainer = ({
  patientId,
  visitId,
  initialValues,
  isLoading,
  onSubmit,
  onError,
  onValuesChange,
  onCancel,
  formButtons,
  parent,
}: LifestyleAssessmentFormContainerProps) => {
  const iaUpdateInfo = usePatientUpdateLifestyleAssessment({});

  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.LifeStyleAssessmentStart,
    eventEnd: MixpanelEvents.LifeStyleAssessmentEnd,
    parent,
    patientId,
  });

  const handleSubmit: LifestyleAssessmentFormComponentProps['onSubmit'] = (values) => {
    ApiRequestHelper.tryCatch(
      iaUpdateInfo.send({
        params: {
          memberId: patientId,
          request: {
            visitId,
            ...values,
          }
        }
      }),
      {
        success: 'Updated Lifestyle and Nutrition successfully',
        error: 'Failed to update Lifestyle and Nutrition',
        onError,
        onSuccess: () => {
          onSubmit?.(values);
        }
      }
    );
  };

  useEffect(() => {
    startEvent();
    return endEvent;
  }, []);

  return (
    <LifestyleAssessmentFormComponent
      initialValues={initialValues}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onError={onError}
      onValuesChange={onValuesChange}
      onCancel={onCancel}
      formButtons={formButtons}
    />
  );
};
