import { FreqEnumComponent, FreqEnumComponentProps } from '../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import { FrequencyUnitEnum } from '../../uc-api-sdk';
import { EnumSelectComponent, EnumSelectComponentProps } from '../EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../features/Input/types';

export interface FreqSelectComponentProps extends
  InputType<FrequencyUnitEnum | string> {
  type?: FreqEnumComponentProps['type']
}

export const FreqSelectComponent = ({
  value,
  onChange,
  type,
}: FreqSelectComponentProps) => {
  const optionRenderer: EnumSelectComponentProps<string>['optionRenderer'] = (option) => (
    <FreqEnumComponent value={option?.value} type={type} />
  );

  return (
    <EnumSelectComponent
      options={FrequencyUnitEnum}
      optionRenderer={optionRenderer}
      value={value}
      onChange={onChange}
      placeholder="Select"
    />
  );
};
