import { Button, SelectProps } from 'antd';
import { useMemo, useState } from 'react';
import { includes, map } from 'lodash';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import { ConditionEnum } from '../../../../../uc-api-sdk';
import { ClinicalGoalConditionSearchSelectComponent } from '../ClinicalGoalConditionSearchSelectComponent/ClinicalGoalConditionSearchSelectComponent';

export interface AddGoalConditionComponentProps {
   onAdd: (goalCondition: string) => void;
   onCancel: () => void;
   onSelect: (condition: string) => void;
   isLoading: boolean | undefined;
   options: SelectProps['options'];
   conditionAdded: string[];
   isCustomizedClinic: boolean;
 }

export const AddGoalConditionComponent = ({
  onAdd,
  onCancel,
  onSelect,
  isLoading,
  options,
  conditionAdded,
  isCustomizedClinic,
}: AddGoalConditionComponentProps) => {
  const [value, setValue] = useState<string | ConditionEnum>();

  const handleOnAdd = useDebounce(() => {
    if (value) onAdd(value);
  }, 50, [value]);

  const handleOnSelect = (v: string) => {
    onSelect(v);
  };

  const processedOptions = useMemo(() => (
    map(
      options?.filter(opt => isCustomizedClinic || (
        opt.value !== ConditionEnum.DEPRESSION && opt.value !== ConditionEnum.CHRONIC_PAIN
      )),
      (opt) => ({
        ...opt,
        disabled: includes(conditionAdded, opt.value),
      })
    )
  ), [options, conditionAdded, isCustomizedClinic]);

  return (
    <div>
      <span>
        Name of condition
      </span>
      <div className="flex">
        <ClinicalGoalConditionSearchSelectComponent
          loading={isLoading}
          options={processedOptions}
          onSearch={(value) => setValue(value)}
          searchValue={value}
          onChange={handleOnSelect}
          value={value}
        />
        <Button
          disabled={!value}
          onClick={handleOnAdd}
        >
          Add
        </Button>
        <Button
          type="text"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
