import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import { LifestyleAssessment } from '../../../../uc-api-sdk';
import { useFastFoodBeverageForm } from './useFastFoodBeverageForm';
import { usePhysicalActivityForm } from './usePhysicalActivityForm';
import { useSmokeDrinkForm } from './useSmokeDrinkForm';
import { useStressManagementForm } from './useStressManagementForm';

export interface SubmitValue extends LifestyleAssessment {}

export const useLifestyleAssessmentForm = (options?: FormOptions) => {
  const fastFoodBeverageForm = useFastFoodBeverageForm(options);
  const smokeDrinkForm = useSmokeDrinkForm(options);
  const physicalActivityForm = usePhysicalActivityForm(options);
  const stressManagementForm = useStressManagementForm(options);

  const factory = useFormHookFactory({
    previousDiets: {
      name: 'previousDiets',
      label: 'Previous calorie management regimen',
    },
    nutritionUnderstanding: {
      name: 'nutritionUnderstanding',
      label: 'Nutrition understanding',
    },
    additionalComments: {
      name: 'additionalComments',
      label: 'Additional Comments',
    }
  }, options);

  return useGetContextValue({
    ...factory,
    fastFoodBeverageForm,
    smokeDrinkForm,
    physicalActivityForm,
    stressManagementForm,
  });
};
