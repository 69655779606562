import {
  Button,
  Dropdown,
  Input,
  InputProps,
  MenuProps,
  Select,
  SelectProps,
} from 'antd';
import { escapeRegExp, map } from 'lodash';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { DefaultOptionType } from 'antd/lib/select';
import { InputType } from '../../../Input/types';
import {
  InsurancePayer,
  InsuranceTypeEnum,
  Nullable,
  useInsuranceProviderSearchPayers
} from '../../../../uc-api-sdk';
import { useScrollPagination } from '../../../../hooks/useScrollPagination/useScrollPagination';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useEffectWithPrevValue } from '../../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';

import './WaystarProviderSelectContainer.scss';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export const InsuranceValueDelimiter = ' --- ';

export const makeInsuranceSelectedValue = (
  value?: string | null,
  name?: string | null,
  isAlias?: boolean,
) => {
  if (!value && !name) {
    return 'Search and select an insurance plan';
  }
  if (isAlias) { return `${value}${InsuranceValueDelimiter}${name}`; }
  return `${value}`;
};

export interface WaystarProviderValue {
  name?: string;
  id?: string;
}

export enum WaystarProviderInputType {
  SEARCH = 'search',
  MANUAL = 'manual',
}

export interface WaystarProviderSelectContainerProps extends InputType<string> {
  initialProviderId?: string | null;
  providerAlias?: string | null;
  onChangeProviderId?: (providerId?: string) => void;
  onChangeProviderAlias?: (providerAlias?: string) => void;
  onChangeInsuranceType?: (insuranceType?: InsuranceTypeEnum[]) => void;
  disableManualInput?: boolean;
}
export interface Option {
  key: Nullable<string> | undefined;
  label: JSX.Element;
  value: Nullable<string> | undefined;
  payerInfo: InsurancePayer;
}
const DEFAULT_PAGE_SIZE = 15;

export const WaystarProviderSelectContainer = ({
  value,
  onChange,
  initialProviderId,
  providerAlias,
  onChangeProviderId,
  onChangeProviderAlias,
  onChangeInsuranceType,
  disableManualInput,
}: WaystarProviderSelectContainerProps) => {
  const ref = useRef(null);
  const [
    searchStr,
    setSearchStr,
  ] = useState(value ?? undefined);
  const [
    inputType,
    setInputType,
  ] = useState<WaystarProviderInputType>(
    (value && !initialProviderId)
      ? WaystarProviderInputType.MANUAL
      : WaystarProviderInputType.SEARCH
  );

  const parsedValue = makeInsuranceSelectedValue(value, providerAlias, !!providerAlias);

  const handleChangeProviderId = (providerId?: string) => {
    onChangeProviderId?.(providerId);
  };

  const handleOnChange = (value?: string) => {
    const [selectedProvider, alias] = value?.split(InsuranceValueDelimiter) || [];
    onChangeProviderAlias?.(alias);
    onChange?.(selectedProvider);
  };

  const insuranceProviderSearch = useInsuranceProviderSearchPayers({});
  const {
    allData,
    handleOnScroll,
  } = useScrollPagination({
    requestInfo: insuranceProviderSearch,
    fetchParams: (page = 1) => ({
      request: {
        filter: { request: searchStr },
        pageInfo: { page, size: DEFAULT_PAGE_SIZE }
      },
    }),
  });

  const sendRequest = useDebounce((searchStr?: string) => {
    insuranceProviderSearch.send({
      params: {
        request: {
          filter: { request: searchStr || '' },
          pageInfo: { page: 1, size: DEFAULT_PAGE_SIZE }
        }
      }
    });
  }, 500);

  useEffect(() => {
    if (searchStr === '') {
      return; // when user clear the search, do not send request
    }
    const newSearchStr = searchStr || value;
    if (searchStr === undefined) {
      // initial render
      setSearchStr(value);
    }
    sendRequest(newSearchStr);
  }, [searchStr]);

  useEffect(() => {
    if (disableManualInput) {
      setInputType(WaystarProviderInputType.SEARCH);
    }
  }, [disableManualInput, inputType]);

  useEffectWithPrevValue(inputType, (prevInputType) => {
    if (prevInputType === undefined) return;
    // reset value when switch input type
    handleOnChange?.(undefined);
    handleChangeProviderId?.(undefined);
  });

  const handleHighlightSearchStr = (text: string) => {
    if (!searchStr?.trim()) return text;
    const searchKey = new RegExp(`(${escapeRegExp(searchStr)})`, 'gi');
    const parts = text.split(searchKey);
    return (
      <>
        {
          map(parts, (part, idx) => (
            <pre
              key={idx}
              className={(
                part.toLowerCase() === searchStr.toLowerCase()
                  ? 'bold'
                  : ''
              )}
            >
              {part}
            </pre>
          ))
        }
      </>
    );
  };

  const dropDownOption = (text: string, isAlias: boolean, payerInfo: InsurancePayer) => ({
    key: `${payerInfo.id}_${text}`,
    label: (
      <div className="flex">
        <div
          className={isAlias ? 'insurance-search-highlight ml20' : 'insurance-search-highlight'}
        >
          {handleHighlightSearchStr(text as string)}
        </div>
        {
          !payerInfo.wayStarPayerId
          && (
            <TooltipComponent
              type="info-icon"
              title="The insurance is manually added to the list and might not be accurate"
            />
          )
        }
      </div>
    ),
    value: makeInsuranceSelectedValue(payerInfo.payerName, text, isAlias),
    payerInfo,
    fakeLabel: makeInsuranceSelectedValue(payerInfo.payerName, text, isAlias),
  });

  const options = useDeepCompareMemo(() => {
    const optionsData: Option[] = [];
    map(allData as InsurancePayer[], (payerInfo) => {
      // sort to make sure payername is display on the top
      optionsData.push(dropDownOption(payerInfo.payerName as string, false, payerInfo));
      if (payerInfo.payerAliases) {
        optionsData.push(
          ...payerInfo.payerAliases.map((alias) => dropDownOption(alias, true, payerInfo))
        );
      }
    });
    return optionsData;
  }, [allData, searchStr]);

  const goToPayerListTool = () => {
    window.open('https://login.zirmed.com/ui/Payers', '_blank');
  };

  const dropdownRender = useCallback<NonNullable<SelectProps['dropdownRender']>>((menu) => (
    <>
      <div>
        {menu}
      </div>
      <div className="p12">
        <div className="pt12 border-t text-gray-scale-1">
          Can't find the payer?
          Please use the
          {' '}
          <LinkButton
            showUnderline
            onClick={goToPayerListTool}
          >
            payer list tool
          </LinkButton>
          {' '}
          for help or
          {' '}
          <LinkButton
            useBlueColor
            onClick={() => setInputType(WaystarProviderInputType.MANUAL)}
            disabled={disableManualInput}
          >
            enter manually
          </LinkButton>
        </div>
      </div>
    </>
  ), []);

  const items = useDeepCompareMemo(() => {
    const getClassName = (key: WaystarProviderInputType) => classNames({
      'waystar-provider-select__option': true,
      'waystar-provider-select__option-selected': inputType === key,
    });
    return [
      {
        key: WaystarProviderInputType.SEARCH,
        label: (
          <>
            <SearchOutlined />
            {' '}
            Search
          </>
        ),
        className: getClassName(WaystarProviderInputType.SEARCH),
      },
      {
        key: WaystarProviderInputType.MANUAL,
        label: (
          <>
            <EditOutlined />
            {' '}
            Manual input
          </>
        ),
        className: getClassName(WaystarProviderInputType.MANUAL),
        disabled: disableManualInput,
      },
    ] as MenuProps['items'];
  }, [inputType, disableManualInput]);

  const handleOnChangeSelect: SelectProps['onChange'] = (value, option) => {
    const { payerInfo } = option as DefaultOptionType;
    handleOnChange?.(value);
    handleChangeProviderId?.(payerInfo?.wayStarPayerId);
    onChangeInsuranceType?.(payerInfo.insuranceType);
  };

  const handleOnChangeInput: InputProps['onChange'] = (e) => {
    const { value } = e.target;
    if (value === '') {
      // it means whitespace=true for form item rule,
      // this is to trigger form validate fields
      handleOnChange?.(undefined);
      return;
    }
    handleOnChange?.(value);
  };

  return (
    <div className="flex ai-c gap0 mw100">
      {
        inputType === WaystarProviderInputType.MANUAL
          ? (
            <Input
              value={value}
              onChange={handleOnChangeInput}
              autoFocus
            />
          ) : (
            <Select
              ref={ref}
              value={parsedValue}
              onChange={handleOnChangeSelect}
              className="waystar-provider-select"
              options={inputType === WaystarProviderInputType.SEARCH ? options : undefined}
              dropdownMatchSelectWidth={false}
              onSearch={setSearchStr}
              searchValue={searchStr}
              showSearch
              autoClearSearchValue={false}
              onPopupScroll={handleOnScroll}
              loading={insuranceProviderSearch.isLoading}
              showArrow={false}
              dropdownRender={dropdownRender}
              optionLabelProp="fakeLabel"
            />
          )
      }
      <Dropdown
        placement="bottomLeft"
        trigger={['click']}
        onOpenChange={() => {
          (ref.current as unknown as HTMLInputElement)?.blur();
        }}
        menu={{
          items,
          onClick: ({ domEvent, key }) => {
            domEvent.stopPropagation();
            setInputType(key as WaystarProviderInputType);
          },
        }}
        arrow={false}
        overlayClassName="waystar-provider-select__popup"
      >
        <Button className="waystar-provider-select__input-type-btn">
          {
            inputType === WaystarProviderInputType.SEARCH
              ? <SearchOutlined />
              : <EditOutlined />
          }
        </Button>
      </Dropdown>
    </div>
  );
};
