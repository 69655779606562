import { map } from 'lodash';
import { ReactNode } from 'react';
import EmptyComponent from '../../../../../uiComponent/EmptyComponent/EmptyComponent';

export interface LifestyleAssessmentListDisplayLayoutProps<T> {
  data: T[];
  renderItem: (item: T) => ReactNode;
}

export const LifestyleAssessmentListDisplayLayout = <T, >({
  data,
  renderItem,
}: LifestyleAssessmentListDisplayLayoutProps<T>) => (
  <div className="lifestyle-assessment-list">
    {
      data?.length
        ? (
          map(data, (item, idx) => (
            <div
              key={idx}
              className="lifestyle-assessment-list__item"
            >
              {renderItem(item)}
            </div>
          ))
        ) : <EmptyComponent />
    }
  </div>
  );
