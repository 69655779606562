import { OutstandingEnum } from '../../features/outstanding/constant/outstandingOrder';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface OutstandingItemEnumComponent {
  value: OutstandingEnum | undefined | null;
}

export const OutstandingItemEnumComponent = ({
  value,
}: OutstandingItemEnumComponent) => {
  const getName = () => {
    switch (value) {
      case OutstandingEnum.ALERTS: return 'Alerts';
      case OutstandingEnum.ENROLLMENT_PREPARATION: return 'Enrollment Prep';
      case OutstandingEnum.FOOD_DAIRY: return 'Food Diary';
      case OutstandingEnum.MESSAGES: return 'Messages';
      case OutstandingEnum.MONTHLY_REVIEW: return 'MTPR';
      case OutstandingEnum.ONBOARDING_TODOS: return 'Onboarding To-dos';
      case OutstandingEnum.REFERRAL: return 'Referral';
      case OutstandingEnum.TASKS: return 'Tasks';
      case OutstandingEnum.TRANSCRIBING: return 'Transcribing';
      case OutstandingEnum.VISITS: return 'Visits';
      case OutstandingEnum.CGM_REVIEW: return 'CGM Review';
      default: return <EmptyComponent />;
    }
  };

  return (
    <span>{getName()}</span>
  );
};
