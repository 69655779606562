/* eslint-disable */
// https://github.com/iHealthLab/notification-client/blob/main/src/index.ts
interface Frame {
  type: string;
}

interface TransmissionFrame extends Frame {
  type: 'TRANSMISSION';
  seq: number;
  payload: string[];
  timestamp: number;
}

interface PingFrame extends Frame {
  type: 'PING';
}

interface ByeFrame extends Frame {
  type: 'BYE';
  byeTime: number;
}

interface PongFrame extends Frame {
  type: 'PONG';
}

interface ReadyFrame extends Frame {
  type: 'READY';
  connectTime: number;
  initAckSeq: number;
}

interface AckFrame extends Frame {
  type: 'ACK';
  ackSeq: number;
}

interface TerminateFrame extends Frame {
  type: 'TERMINATE';
}

export type NotificationMessageType = 'start' | 'ready' | 'connected' | 'payload' | 'pong';

export enum ClientStatusEnum {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  TERMINATED = 'terminated',
}

export interface ConnectionConfig {
  token: string;
  baseUrl: string;
  connectionId: string;
  ssl: boolean;
  operation: ConnectionEventOperation;
  timeout: TimeoutConfig;
  debug?: boolean;
}

/**
 *  try to reconnect or terminate  without receiving a pong message during a period of time
 * timeunit : seconds
 */
export interface TimeoutConfig {
  terminate?: number;

  reconnect?: number;
  /**
   * seconds
   */
  heartbeatCycle?: number;
}

export interface ConnectionEventOperation {
  onReady: (timestamp: number) => void;
  onMessage: (message: string[]) => boolean;
  onLastMessageReceived: (type: NotificationMessageType, receivedAt: number) => void;
  onTerminated: () => void;
  onStatusChange?: (status: ClientStatusEnum) => void;
  getClientId?: (clientId: string) => void;
}

export const PONG_TASK_INTERVAL = 5000; // ms

export class WebSocketClient {
  private readonly baseUrl: string;

  private readonly authPath = '/connection/auth';

  private readonly wsPath = '/connection/ws';

  private readonly authUrl: string;

  private readonly wsUrl: string;

  private readonly authProtocol: 'http://' | 'https://';

  private readonly wsProtocol: 'ws://' | 'wss://';

  // operations
  private readonly operation: ConnectionEventOperation;

  private readonly timeout: TimeoutConfig;

  // ws
  private readonly connectionId: string;

  private readonly token: string;

  // if ws client is ready
  private clientId: string | undefined;

  private ws: WebSocket | undefined;

  private signature: string | undefined;

  private lastConnecting: number | undefined;

  // status
  private ready: boolean;

  private state: ClientStatusEnum;

  // context
  private lastMessageReceived: number; // last message received

  private lastReceivedSeq: number; // next expected message seq

  private nextSendSeq: number; // next send seq

  // health check
  private sendPingTask?: NodeJS.Timer;

  private checkPongTask?: NodeJS.Timer;

  private debug?: boolean;

  private info(...log: any[]): void {
    if(process.env.NODE_ENV === 'production') return;
    if(!this.debug) return;
    const now = new Date().toLocaleString();
    console.trace(now, '---[WS]', log);
  }

  constructor(config: ConnectionConfig) {
    // set up url
    this.baseUrl = config.baseUrl;
    this.authProtocol = config.ssl ? 'https://' : 'http://';
    this.wsProtocol = config.ssl ? 'wss://' : 'ws://';
    this.authUrl = this.authProtocol + this.baseUrl + this.authPath;
    this.wsUrl = this.wsProtocol + this.baseUrl + this.wsPath;
    this.operation = config.operation;
    // timeout configuration
    this.timeout = {
      terminate: config.timeout.terminate ? config.timeout.terminate : 300,
      reconnect: config.timeout.reconnect ? config.timeout.reconnect : 60,
      heartbeatCycle: config.timeout.heartbeatCycle ? config.timeout.heartbeatCycle : 10,
    };
    config.timeout;
    this.token = config.token;
    // set up connection status
    this.state = ClientStatusEnum.DISCONNECTED;
    this.connectionId = config.connectionId;
    this.lastMessageReceived = 0;
    this.lastReceivedSeq = 0;
    this.ready = false;
    // context
    this.nextSendSeq = 1;
    this.debug = config.debug;
  }

  public handleStatusChange(status: ClientStatusEnum) {
    this.state = status;
    this.operation.onStatusChange?.(status);
    if (this.clientId) {
      this.operation?.getClientId?.(this.clientId);
    }
  }

  public handleLastMessageReceived(type: `${NotificationMessageType}`) {
    const receivedAt = this.now();
    this.lastMessageReceived = receivedAt;
    this.operation?.onLastMessageReceived(type, receivedAt);
  }

  public start(): boolean {
    const valid = this.auth();
    if (valid) {
      this.handleLastMessageReceived('start');
      this.initCheckPongTask();
      this.connect();
      return true;
    }
    return false;
  }

  public terminate() {
    if (this.state == ClientStatusEnum.TERMINATED) {
      this.info('websocket client has been terminated already');
      return;
    }

    this.info('terminate websocket client');
    if (this.checkPongTask) {
      clearInterval(this.checkPongTask);
    }

    if (this.sendPingTask) {
      clearInterval(this.sendPingTask);
    }

    if (this.ws != null && this.ws.readyState == WebSocket.OPEN) {
      // close connection
      const terminateMsg = this.terminateMsg();
      const textMsg = JSON.stringify(terminateMsg);
      this.ws.send(textMsg);
    }

    if (
      this.ws != null
      && [WebSocket.OPEN, WebSocket.CONNECTING].includes(this.ws.readyState as (0 | 1))
    ) {
      try {
        this.ws.close();
      } catch (e) {
        console.error(`close ws error,${e}`);
      }
    }

    this.handleStatusChange(ClientStatusEnum.TERMINATED);
    this.operation?.onTerminated?.();
  }

  public sendMessage(message: string): boolean {
    if (!this.ws && !this.ready) {
      return false;
    }

    const messages = [message];
    const msg = this.transmissionMsg(this.nextSendSeq, messages);
    this.nextSendSeq++;
    const text = JSON.stringify(msg);
    this.ws?.send(text);
    return true;
  }

  private buildWsUrl(clientId: string): string {
    const now = new Date().getTime();
    const nonce = this.generateNonce();
    return `${this.wsUrl}?token=${this.signature}&clientId=${clientId}&ts=${now}&nonce=${nonce}`;
  }

  private generateNonce(): number {
    return Math.round(Math.random() * 1000000);
  }

  private auth(): boolean {
    // get auth token
    const authReq = new XMLHttpRequest();
    const nonce = this.generateNonce();
    authReq.open('POST', this.authUrl, false);
    authReq.setRequestHeader('x-session-token', this.token);
    authReq.setRequestHeader('connectionId', this.connectionId);
    authReq.setRequestHeader('timestamp', new Date().getTime().toString());
    authReq.setRequestHeader('nonce', nonce.toString());
    authReq.send();
    if (authReq.status === 200) {
      const response = JSON.parse(authReq.responseText);
      this.signature = response.data;
      return true;
    }
    return false;
  }

  private connect(): void {
    this.info('connect, state:', this.state, 'connectionId: ', this.connectionId);
    if (this.state != ClientStatusEnum.DISCONNECTED) {
      return;
    }

    this.handleStatusChange(ClientStatusEnum.CONNECTING);
    this.lastConnecting = this.now();
    this.clientId = this.createClientId();
    const wsUrl = this.buildWsUrl(this.clientId);
    this.info(`websocket connecting, ${wsUrl}`);
    this.ws = new WebSocket(wsUrl);
    const self = this;
    this.ws.onerror = function (event) {
      console.error('websocket error:', event);
    };
    // register open event listener
    this.ws.onopen = function (event) {
      if (event.currentTarget != self.ws) {
        console.error('invalid websocket open');
        return;
      }

      self.handleStatusChange(ClientStatusEnum.CONNECTED);
      self.info(`ws connected:${wsUrl}`);
      self.handleLastMessageReceived('connected');
    };
    // register close event listener
    this.ws.onclose = function (event) {
      if (event.currentTarget != self.ws) {
        console.error('invalid websocket close, ignore');
        return;
      }

      self.info(`ws closed:${wsUrl}code:${event.code}reason:${event.reason}`);
      self.handleStatusChange(ClientStatusEnum.DISCONNECTED);
      if (event.code === 4001) {
        // auth error,should terminate
        self.terminate();
      }
    };
    // register receive message
    this.ws.onmessage = function (event) {
      const receivedMsg = JSON.parse(event.data);
      const { type } = receivedMsg;
      if (type == 'BYE') {
        self.info('received bye message');
      }

      if (event.currentTarget != self.ws) {
        self.error('invalid websocket message, ignore');
        return;
      }

      self.info(`ws message received:${event.data}`);
      if (type == 'PONG') {
        self.handleLastMessageReceived('pong');
        return;
      }

      if (type == 'READY') {
        if (self.ready) {
          self.error("connection context doesn't match server, need to be terminated and refresh");
          self.terminate();
          return;
        }
        self.lastReceivedSeq = receivedMsg.initAckSeq;
        self.ready = true;
        self.handleLastMessageReceived('ready');
        self.initSendPingTask();
        if (self.operation.onReady) {
          const timestamp = receivedMsg.connectTime;
          self.info('notification ready time', new Date(timestamp).toLocaleString());
          self.operation.onReady(timestamp);
        }
      }

      if (type == 'TERMINATE') {
        self.error('receive server terminate cmd, need to be terminated and refresh');
        self.terminate();
        return;
      }

      if (type == 'ACK') {
        return;
      }

      if (type == 'TRANSMISSION') {
        const msgSeq = receivedMsg.seq;
        const preSeq = receivedMsg.pre;
        self.handleLastMessageReceived('payload');
        if (msgSeq < self.lastReceivedSeq) {
          self.info(`received duplicate message,ignore msgSeq=${msgSeq}`);
          const ackMsg = self.ackMsg(self.lastReceivedSeq);
          const ackText = JSON.stringify(ackMsg);
          self.ws.send(ackText);
        } else if (preSeq == self.lastReceivedSeq) {
          const ackText = JSON.stringify(self.ackMsg(msgSeq));
          let processed = false;
          if (self.operation.onMessage) {
            const list = JSON.parse(receivedMsg.payload);
            processed = self.operation.onMessage(list);
          } else {
            processed = true;
          }

          if (processed) {
            self.ws.send(ackText);
            self.lastReceivedSeq = msgSeq;
          }
        } else {
          self.info(`received future message,ignore msgSeq=${msgSeq}, lastSeq=${self.lastReceivedSeq}`);
        }
      }
    };
  }

  private initCheckPongTask() {
    if (this.checkPongTask) {
      return;
    }

    this.checkPongTask = setInterval(() => {
      const self = this;
      const now = self.now();
      self.info(`${now} - ${this.lastMessageReceived}, ${((now - self.lastMessageReceived) / 1000)}s, ${self.state}`);
      self.info(`${now - this.lastMessageReceived} > ${(self.timeout.terminate || 0) * 1000}? ${now - this.lastMessageReceived > (self.timeout.terminate || 0) * 1000}`);
      // reconnect immediately
      if (this.state == ClientStatusEnum.DISCONNECTED) {
        self.info('detect closed ws , try to reconnect');
        self.connect();
        return;
      }

      if (now - self.lastMessageReceived > (self.timeout.terminate || 0) * 1000) {
        this.error("too long can't connect to server, please establish a new connection");
        // close the connection
        try {
          if (self.ws?.readyState === WebSocket.OPEN || self.ws?.readyState == WebSocket.CONNECTING) {
            self.ws.close();
          }
        } catch (error) {
          self.info('websocket close error: ', error);
        }

        if (self.operation.onTerminated) {
          self.operation.onTerminated();
        }

        if (self.checkPongTask) {
          clearInterval(self.checkPongTask);
        }

        if (self.sendPingTask) {
          clearInterval(self.sendPingTask);
        }

        self.handleStatusChange(ClientStatusEnum.DISCONNECTED);
      } else if (now - self.lastMessageReceived > (self.timeout.reconnect || 0) * 1000) {
        if (now - (self.lastConnecting || 0) < 5000) {
          // last ws may is connecting, wait for next trigger
          self.info('last ws is connecting, waiting for next trigger');
          return;
        }

        try {
          if (self.ws?.readyState === WebSocket.OPEN || self.ws?.readyState == WebSocket.CONNECTING) {
            self.ws.close();
          }
        } catch (error) {
          self.error('websocket close error: ', error);
          self.info("Disconnect then try to reconnect websocket, cause didn't receive enough pong");
          self.handleStatusChange(ClientStatusEnum.DISCONNECTED);
        }
      }
    }, PONG_TASK_INTERVAL);
  }

  private initSendPingTask() {
    if (this.sendPingTask) {
      return;
    }

    this.sendPingTask = setInterval(() => {
      if (this.ws?.readyState == WebSocket.OPEN) {
        try {
          const msg = this.pingMsg();
          const text = JSON.stringify(msg);
          this.ws.send(text);
        } catch (e) {
          this.error(`send ping message error:${e}`);
        }
      }
    }, (this.timeout.heartbeatCycle || 0) * 1000);
  }

  private now(): number {
    return new Date().getTime();
  }

  private pingMsg(): PingFrame {
    return {
      type: 'PING',
    };
  }

  private ackMsg(seq: number): AckFrame {
    return {
      type: 'ACK',
      ackSeq: seq,
    };
  }

  private transmissionMsg(seq: number, messages: string[]): TransmissionFrame {
    return {
      type: 'TRANSMISSION',
      seq,
      payload: messages,
      timestamp: new Date().getTime(),
    };
  }

  private terminateMsg(): TerminateFrame {
    return {
      type: 'TERMINATE',
    };
  }

  private parseClientId(event: any): string {
    const { url } = event.currentTarget;
    const urlParams = url.split('?')[1];
    const params = urlParams.split('&');
    const paramMap = new Map();
    params.forEach((pair: any) => {
      const pairs = pair.split('=');
      const key = pairs[0];
      const value = pairs[1];
      paramMap.set(key, value);
    });
    return params.get('clientId');
  }

  private createClientId(): string {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }

  private error(...log: any[]): void {
    const now = new Date().toLocaleString();
    console.error(now, '---[WS]', log);
  }
}
