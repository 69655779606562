import { isBoolean, isEmpty, map } from 'lodash';
import { Divider } from 'antd';
import { ReactNode } from 'react';
import { ExerciseDetail, JunkFoodFreq, LifestyleAssessment } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintCarePlanMealAndSleepRoutineComponent } from './PrintCarePlanMealAndSleepRoutineComponent';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';
import { DietEnumListComponent } from '../../../../../enumComponent/DietEnumComponent/DietEnumListComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import EmptyComponent from '../../../../../uiComponent/EmptyComponent/EmptyComponent';
import { FoodBeverageInfoComponent } from '../../../../intervention/component/LifestyleAssessmentComponent/FoodBeverageInfoComponent';
import { YesNoDisplayComponent } from '../../../../../uiComponent/YesNoDisplayComponent/YesNoDisplayComponent';
import { DelimitedListComponent } from '../../../../../uiComponent/DelimitedListComponent/DelimitedListComponent';
import { PhysicalActivityInfoComponent } from '../../../../intervention/component/LifestyleAssessmentComponent/PhysicalActivityInfoComponent';
import { SmokingFreqInfoComponent } from '../../../../intervention/component/LifestyleAssessmentComponent/SmokingInfoComponent';
import { DrinkingFreqInfoComponent } from '../../../../intervention/component/LifestyleAssessmentComponent/DrinkingInfoComponent';

import './PrintCarePlanLifestyleAssessmentComponent.scss';

export interface LifestyleAssessmentComponentProps {
  lifeStyleAssessment?: LifestyleAssessment | null;
}

export const LifestyleAssessmentComponent = ({
  lifeStyleAssessment,
}: LifestyleAssessmentComponentProps) => {
  if (isEmpty(lifeStyleAssessment)) {
    return null;
  }

  const isSmoking = lifeStyleAssessment?.smokeDrinkRecord?.isSmoking;
  const isDrinking = lifeStyleAssessment?.smokeDrinkRecord?.isDrinking;

  const renderTitle = (title: ReactNode) => (
    <TextComponent
      size="medium"
      bold
      className="mb5"
    >
      {title}
    </TextComponent>
  );

  const renderYesNo = (value: boolean | null | undefined) => (
    <YesNoDisplayComponent
      value={value}
      yesDisplay="Yes"
      noDisplay="No"
      nilDisplay={<EmptyComponent />}
    />
  );

  const renderList = <T, >(list: T[], render: (i: T) => ReactNode) => (
    <DelimitedListComponent
      list={list}
      className="flex f-w"
      itemClassName="flex gap0"
      render={render}
      delimiter=";"
    />
  );

  const renderFoodBeverageInfo = (info: JunkFoodFreq) => (
    <FoodBeverageInfoComponent info={info} />
  );

  const renderPhysicalActivityInfo = (info: ExerciseDetail) => (
    <PhysicalActivityInfoComponent info={info} />
  );

  const renderSmokeDrinkInfo = (type: 'smoke' | 'drink') => {
    let isYes;
    let FreqInfoComponent = null;
    let quitYear = null;
    switch (type) {
      case 'smoke':
        isYes = isSmoking;
        FreqInfoComponent = SmokingFreqInfoComponent;
        quitYear = lifeStyleAssessment?.smokeDrinkRecord?.quitSmokingYear;
        break;
      default: // drink
        isYes = isDrinking;
        FreqInfoComponent = DrinkingFreqInfoComponent;
        quitYear = lifeStyleAssessment?.smokeDrinkRecord?.quitDrinkingYear;
        break;
    }

    if (!isBoolean(isYes)) {
      return null;
    }

    if (isYes && FreqInfoComponent) {
      return (
        <div className="print-lifestyle-assessment__info-list">
          <PrintCarePlanSimpleItemComponent
            label="Frequency:"
            content={(
              <FreqInfoComponent info={lifeStyleAssessment?.smokeDrinkRecord} />
            )}
          />
        </div>
      );
    }

    return (
      <PrintCarePlanSimpleItemComponent
        label="Quit year:"
        content={(
          <DisplayOrEmptyComponent
            value={quitYear}
          />
        )}
      />
    );
  };

  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Lifestyle and Nutrition
      </TextComponent>

      {renderTitle('Eating and Sleeping Routine')}
      <div className="white-card">
        {
          map(lifeStyleAssessment?.mealAndSleepRoutines, (mealAndSleepRoutine) => (
            <PrintCarePlanMealAndSleepRoutineComponent
              key={mealAndSleepRoutine.type}
              mealAndSleepRoutine={mealAndSleepRoutine}
            />
          ))
        }
      </div>

      <div className="mb20" />
      {renderTitle('Fast Food and Beverage Intake')}
      <PrintCarePlanSimpleItemComponent
        label="Does the patient eat fast food:"
        content={renderYesNo(lifeStyleAssessment?.isEatingFastFood)}
      />
      {
        lifeStyleAssessment?.isEatingFastFood && (
          <div className="print-lifestyle-assessment__info-list">
            <PrintCarePlanSimpleItemComponent
              label="Food type and frequency:"
              content={(
                lifeStyleAssessment.fastFoodFreq?.length
                  ? renderList(
                    lifeStyleAssessment.fastFoodFreq,
                    renderFoodBeverageInfo
                  ) : <EmptyComponent />
              )}
            />
          </div>
        )
      }
      <div className="print-lifestyle-assessment__info-list">
        <PrintCarePlanSimpleItemComponent
          label="Beverage the patient consumes:"
          content={(
            lifeStyleAssessment.beverageFreq?.length
              ? renderList(
                lifeStyleAssessment.beverageFreq,
                renderFoodBeverageInfo
              ) : <EmptyComponent />
          )}
        />
      </div>

      <div className="mb20" />
      {renderTitle('Calorie Management')}
      <PrintCarePlanSimpleItemComponent
        label="Previous Calorie Management Regimens:"
        content={(
          lifeStyleAssessment?.previousDiets?.length
            ? (
              <DietEnumListComponent
                value={lifeStyleAssessment.previousDiets}
                splitter=", "
              />
            ) : <EmptyComponent />
        )}
      />

      <div className="mb30" />
      {renderTitle('Nutrition Understanding')}
      <PrintCarePlanSimpleItemComponent
        content={(
          <DisplayOrEmptyComponent
            value={lifeStyleAssessment?.nutritionUnderstanding ?? undefined}
          />
        )}
      />

      <div className="mb20" />
      {renderTitle('Smoking and Drinking')}
      <PrintCarePlanSimpleItemComponent
        label="Does the patient currently smoke:"
        content={renderYesNo(isSmoking)}
      />
      {renderSmokeDrinkInfo('smoke')}
      <PrintCarePlanSimpleItemComponent
        label="Does the patient currently drink:"
        content={renderYesNo(isSmoking)}
      />
      {renderSmokeDrinkInfo('drink')}

      <div className="mb20" />
      {renderTitle('Stress Management')}
      <PrintCarePlanSimpleItemComponent
        label="Stress level:"
        content={(
          <DisplayOrEmptyComponent
            value={lifeStyleAssessment?.stressManagement?.stressLevel}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Stress coping strategy:"
        content={(
          <DisplayOrEmptyComponent
            value={lifeStyleAssessment?.stressManagement?.strategy}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Recent depression/anxiety:"
        content={(
          <DisplayOrEmptyComponent
            value={lifeStyleAssessment?.stressManagement?.recentDepression}
          />
        )}
      />

      <div className="mb20" />
      {renderTitle('Physical Activity')}
      <div className="print-lifestyle-assessment__info-list">
        <PrintCarePlanSimpleItemComponent
          label="Exercise type, time and intensity:"
          content={(
            lifeStyleAssessment.physicalActivities?.length
              ? renderList(
                lifeStyleAssessment.physicalActivities,
                renderPhysicalActivityInfo
              ) : <EmptyComponent />
          )}
        />
      </div>

      <div className="mb20" />
      {renderTitle('Additional Comment')}
      <PrintCarePlanSimpleItemComponent
        content={(
          <DisplayOrEmptyComponent value={lifeStyleAssessment?.additionalComments} />
        )}
      />

      <Divider />
    </>
  );
};
