import { isEmpty } from 'lodash';
import { HealthConditionItem } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintHealthConditionsComponent } from '../../../../vitals/component/PrintVitalComponent/PrintHealthConditionsComponent';

import './PrintCarePlanDiagnosisHealthConditionsComponent.scss';

export interface PrintCarePlanDiagnosisHealthConditionsComponentProps {
  // diagnosis?: PatientDiagnosesEnum[] | null;
  healthConditions?: HealthConditionItem[] | null;
}

export const PrintCarePlanDiagnosisHealthConditionsComponent = ({
  // diagnosis,
  healthConditions,
}: PrintCarePlanDiagnosisHealthConditionsComponentProps) => {
  if (isEmpty(healthConditions)) {
    return null;
  }
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text bold mb10">
        Diagnosis and Health Conditions
      </TextComponent>
      <PrintHealthConditionsComponent
        healthConditions={healthConditions || []}
      />
    </>
  );
};
