import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { CgmGoalContainer } from '../../../intervention/container/CgmGoalContainer/CgmGoalContainer';
import { InterventionVitalsMonitoringContainer } from '../../../intervention/container/InterventionVitalsMonitoringContainer/InterventionVitalsMonitoringContainer';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { WorklistMTPRComponent } from '../WorklistMTPRComponent/WorklistMTPRComponent';
import { WorklistSectionHeaderComponent } from '../WorklistSectionHeaderComponent/WorklistSectionHeaderComponent';

export interface SimplifiedWorklistGoalsSetupComponentProps {
  patientId: string;
  itemNumber?: number;
  clinicId?: string;
}

export const SimplifiedWorklistGoalsSetupComponent = ({
  patientId,
  itemNumber = -1,
  clinicId,
}: SimplifiedWorklistGoalsSetupComponentProps) => (
  <div className="simplified-worklist-section">
    <WorklistSectionHeaderComponent
      number={itemNumber}
      title="Goals Setup"
    />

    <NestedFormComponent>
      <WorklistMTPRComponent
        patientId={patientId}
        requireClinicalGoal
        clinicId={clinicId}
      />
    </NestedFormComponent>
    <NestedFormComponent>
      <CgmGoalContainer
        patientId={patientId}
        isEditing
        showEdit={false}
        formButtons={null}
      />
    </NestedFormComponent>

    <NestedFormComponent>
      <WorklistContentComponent>
        <InterventionVitalsMonitoringContainer
          patientId={patientId}
          isEditing
          showEdit={false}
          formButtons={null}
        />
      </WorklistContentComponent>
    </NestedFormComponent>
  </div>
);
