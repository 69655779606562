import React from 'react';

interface YAxisProps {
  tarLevel2Bound: number;
  tarLevel1Bound: number;
  tarLevel2Height: number;
  tarLevel1Height: number;
  tirHeight: number;
  tbrLevel2Height: number;
  tbrLevel1Height: number;
  barChartHeight: number;
}

export const YAxis: React.FC<YAxisProps> = ({
  tarLevel2Bound,
  tarLevel1Bound,
  tarLevel2Height,
  tarLevel1Height,
  tirHeight,
  tbrLevel2Height,
  tbrLevel1Height,
  barChartHeight,
}) => {
  const MIN_GAP = 10;
  const PADDING_TOP = 10;
  const PADDING_BOTTOM = 10;
  const BAR_CHART_HEIGHT = 110;

  if (!barChartHeight || barChartHeight === 0) {
    return null;
  }

  const adjustTickPositions = (ticks: number[], totalHeight: number): number[] => {
    const adjustedTicks = [...ticks];

    adjustedTicks[0] = Math.max(adjustedTicks[0], PADDING_TOP);

    adjustedTicks[adjustedTicks.length - 1] = Math.min(
      adjustedTicks[adjustedTicks.length - 1],
      totalHeight - PADDING_BOTTOM
    );

    for (let i = 1; i < adjustedTicks.length; i += 1) {
      if (adjustedTicks[i] - adjustedTicks[i - 1] < MIN_GAP) {
        adjustedTicks[i] = adjustedTicks[i - 1] + MIN_GAP;
      }
    }
    return adjustedTicks;
  };

  const rawTicks = [
    tarLevel2Height,
    tarLevel2Height + tarLevel1Height,
    tarLevel2Height + tarLevel1Height + tirHeight,
    BAR_CHART_HEIGHT - tbrLevel2Height,
  ];

  const [tick1, tick2, tick3, tick4] = adjustTickPositions(rawTicks, BAR_CHART_HEIGHT);

  return (
    <svg width="30" height={120} className="y-axis">
      <text x="-1" y={tick1} fontSize="12" fill="gray">
        {tarLevel2Height || tarLevel1Height ? tarLevel2Bound : ''}
      </text>
      <text x="-1" y={tick2} fontSize="12" fill="gray">
        {tarLevel1Height || tirHeight ? tarLevel1Bound : ''}
      </text>
      <text x="3" y={tick3} fontSize="12" fill="gray">
        {tirHeight || tbrLevel1Height ? 70 : ''}
      </text>
      <text x="3" y={tick4} fontSize="12" fill="gray">
        {tbrLevel1Height || tbrLevel2Height ? 54 : ''}
      </text>
    </svg>
  );
};

export default YAxis;
