import { useCallback } from 'react';
import { FormListFieldData, FormProps } from 'antd';
import { get, map, split } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { FormOptions } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../../types/form';
import { DiseaseHistoryItem, FrequencyUnitEnum } from '../../../uc-api-sdk';
import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';

export interface DiseaseHistorySubmitValue {
  diseaseHistoryList: DiseaseHistoryItem[];
  diseaseHistoryNote?: string | null;
}

export const useDiseaseHistoryForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('diseaseHistoryList', {
    disease: {
      name: 'disease',
      label: '',
      placeholder: '',
    },
    customDisease: {
      name: 'customDisease',
      label: '',
      placeholder: '',
    },
    diagnosedYear: {
      name: 'diagnosedYear',
      label: 'Year of diagnosis',
      placeholder: '',
    },
    yearsWithCondition: {
      name: 'yearsWithCondition',
      label: 'Years with condition',
      placeholder: 'If not sure, put N/A',
    },
    hasSeenDoctor: {
      name: 'hasSeenDoctor',
      label: 'Seen doctor in past 6 months?',
      placeholder: '',
    },
    hasSeenSpecialist: {
      name: 'hasSeenSpecialist',
      label: 'See specialist for this condition?',
      placeholder: '',
    },
    hasTestedBefore: {
      name: 'hasTestedBefore',
      label: '',
      placeholder: '',
    },
    treatmentTypes: {
      name: 'treatmentTypes',
      label: 'Current treatment',
      placeholder: '',
    },
    otherTreatmentAndNotes: {
      name: 'otherTreatmentAndNotes',
      label: 'Other Treatments and notes',
      placeholder: '',
    },
    isTakingMedication: {
      name: 'isTakingMedication',
      label: 'Taking Medication?',
      placeholder: '',
    },
    diseaseHistoryNote: {
      name: 'diseaseHistoryNote',
      label: '',
      placeholder: '',
    },
    measureFreqTimes: {
      name: 'measureFrequency.times',
      label: 'Measure Frequency',
      placeholder: '',
    },
    measureFreqUnit: {
      name: 'measureFrequency.frequency',
      label: '',
      placeholder: '',
    },
    nameAndContact: {
      name: 'nameAndContact',
      label: '',
      placeholder: '',
    },
    isManualInput: {
      name: 'isManualInput',
      label: '',
      placeholder: '',
    }
  }, options);

  const isConditionRemovable = useCallback((
    getFieldValue: GetFieldValue,
    listFieldName: FormListFieldData['name'],
  ) => {
    const isManualInput = factory.getListValue('isManualInput', getFieldValue, listFieldName);
    return !!isManualInput;
  }, []);

  const shouldShowNameAndContact = useCallback((
    getFieldValue: GetFieldValue,
    listFieldName: FormListFieldData['name'],
  ) => (
    factory.getListValue('hasSeenSpecialist', getFieldValue, listFieldName) === true
  ), []);

  const getDiseaseHistoryList = (formValues: DiseaseHistorySubmitValue) => {
    const { diseaseHistoryList } = formValues;
    // - remove specialist's Name and contact
    // - parse measure frequency
    const processedDiseaseHistoryList = map(diseaseHistoryList, (diseaseHistory) => {
      const nameAndContact = (
        diseaseHistory.hasSeenSpecialist
          ? diseaseHistory.nameAndContact
          : undefined
      );
      const processed = {
        ...diseaseHistory,
        nameAndContact,
      } as DiseaseHistoryItem;

      const diagnosedYears = get(
        diseaseHistory,
        factory.formInput.diagnosedYear?.name
      ) as Dayjs;

      if (diagnosedYears) {
        processed.diagnosedYear = parseInt(dayjs(diagnosedYears).format('YYYY'), 10);
      }

      const measureFrequencyTimes = get(diseaseHistory, factory.formInput.measureFreqTimes?.name);
      const measureFrequencyUnit = (
        get(
          diseaseHistory,
          factory.formInput.measureFreqUnit?.name
        ) as FrequencyUnitEnum | undefined
      );
      if (measureFrequencyUnit) {
        // measure frequency exists in the form
        processed.measureFrequency = measureFrequencyTimes
          ? {
            times: measureFrequencyTimes,
            frequency: measureFrequencyUnit
          } : null;
      }

      return processed;
    });
    return {
      ...formValues,
      diseaseHistoryList: processedDiseaseHistoryList,
    } as DiseaseHistorySubmitValue;
  };

  const handleSubmit: (onSubmit?: (submitValue: DiseaseHistorySubmitValue) => Promise<void> | void) => FormProps['onFinish'] = useCallback((onSubmit) => (formValues: DiseaseHistorySubmitValue) => {
    onSubmit?.(getDiseaseHistoryList(formValues));
  }, []);

  const makeListNamePath: typeof factory.makeListNamePath = (
    inputKey,
    fieldName,
  ) => {
    // to handle nested form items
    const name = factory.getName(inputKey);
    return [
      fieldName,
      ...split(name, '.')
    ];
  };

  return {
    ...factory,
    isConditionRemovable,
    shouldShowNameAndContact,
    handleSubmit,
    makeListNamePath,
  };
};
