import React, { ReactNode } from 'react';
import {
  CLINICAL_ORG_ICON, UC_MANAGEMENT_ICON, AVATAR_ICON, SECURITY_ICON,
} from '../../assets/commonIconInfo';
import { Icon } from '../../icons/Icon';
import { adminPortalUrl } from '../../hooks/useChangePage/useChangePage';

export interface MenuInfo {
  url: string;
  id: string;
  title: string | React.ReactNode;
  icon: ReactNode;
}
export interface PageInfo {
  url: string;
  id: string;
  title: string;
  menu?: MenuInfo[];
}

export const PAGE_INFO_MAP: { [key: string]: PageInfo } = {
  ADMIN_CONSOLE: {
    url: '/admin-console',
    id: 'adminConsole',
    title: 'Admin Console',
    menu: [
      {
        url: '/admin-console/uc-management',
        id: 'ucManagement',
        title: 'UC management',
        icon: <Icon info={UC_MANAGEMENT_ICON} />,
      },
      {
        url: '/admin-console/clinical-orgs',
        id: 'clinicalOrgs',
        title: 'Clinical orgs',
        icon: <Icon info={CLINICAL_ORG_ICON} />,
      },
    ],
  },
  NEW_ADMIN_PORTAL: {
    url: adminPortalUrl,
    id: 'newAdminPortal',
    title: 'New Admin Portal',
  },
  BILLER_PORTAL: {
    url: '/biller-portal',
    id: 'billerPortal',
    title: 'Biller Portal',
    menu: [],
  },
  CALL_CENTER_PORTAL: {
    url: '/call-center-portal',
    id: 'callCenterPortal',
    title: 'Call Center Portal',
    menu: [],
  },
  CARE_PORTAL: {
    url: '/care-portal',
    id: 'carePortal',
    title: 'Care Portal',
    menu: [],
  },
  ON_CALL_PORTAL: {
    url: '/on-call-portal',
    id: 'onCallPortal',
    title: 'On-call Portal',
    menu: [],
  },
  ACCOUNT: {
    url: '/manage-account',
    id: 'manageAccount',
    title: 'Manage Account',
    menu: [
      {
        url: '/manage-account/general',
        id: 'maGeneral',
        title: 'General',
        icon: <Icon info={AVATAR_ICON} />,
      },
      {
        url: '/manage-account/security',
        id: 'maSecurity',
        title: 'Security',
        icon: <Icon info={SECURITY_ICON} />,
      },
    ],
  },
};

export interface UrlTitleMap {
  [key: string]: PageInfo
}
