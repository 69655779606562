import { Space } from 'antd';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useSingleEditModeHook } from '../../../../hooks/useSingleEditModeHook/useSingleEditModeHook';
import { AnchorComponent } from '../../../../uiComponent/AnchorComponent/AnchorComponent';
import { AnchorLinkComponent } from '../../../../uiComponent/AnchorComponent/AnchorLinkComponent';
import { AlertInCardComponent } from '../../../alert/component/AlertInCardComponent/AlertInCardComponent';
import { InterventionSubmitCancelButtons } from '../../../intervention/component/InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { MedicationCommonCardComponent } from '../../../medication/component/MedicationCommonCardComponent/MedicationCommonCardComponent';
import { ProblemDiseaseHistoryContainer } from '../../../problems/container/ProblemDiseaseHistoryContainer/ProblemDiseaseHistoryContainer';
import { ProblemsICDCodeContainer } from '../../../problems/container/ProblemsICDCodeContainer/ProblemsICDCodeContainer';
import { SymptomCardContainer } from '../../../symptom/container/SymptomCardContainer/SymptomCardContainer';
import { LabResultInCardContainer } from '../../../transcribing/container/LabResultInCardContainer/LabResultInCardContainer';
import { VitalsComponent } from '../../../vitals/component/VitalsComponent/VitalsComponent';

export const MedicalTabAnchors = {
  vitals: {
    label: 'Vitals',
    href: '#vitals',
  },
  alertHistory: {
    label: 'Alerts',
    href: '#alertHistory',
  },
  medications: {
    label: 'Medication List',
    href: '#medications',
  },
  symptom: {
    label: 'Symptom Logs',
    href: '#symptom'
  },
  labResult: {
    label: 'Lab Results',
    href: '#labResult',
  },
  icdCode: {
    label: 'Diagnosis / ICD Code',
    href: '#icdCode',
  },
  diseaseHistory: {
    label: 'Disease History',
    href: '#diseaseHistory',
  },
};

export interface MedicalHistoryComponentProps {

}

enum MedicalHistoryEnumType {
  Medication = 'Medication',
  ICDCode = 'ICDCode',
  DiseaseHistory = 'DiseaseHistory',
}

export const MedicalHistoryComponent = () => {
  const { isEditing, handleEdit, closeEditMode } = useSingleEditModeHook<MedicalHistoryEnumType>();
  const { info: { id = '' } = {} } = usePatientContext();
  const { send: sendMixpanel } = useMixpanelContext();

  const handleEditMode = (type: MedicalHistoryEnumType) => () => {
    const getMixpanelEvent = () => {
      switch (type) {
        case MedicalHistoryEnumType.Medication:
          return MixpanelEvents.MedicationEditIcon;
        case MedicalHistoryEnumType.ICDCode:
          return MixpanelEvents.ICDCodeEditIcon;
        case MedicalHistoryEnumType.DiseaseHistory:
          return MixpanelEvents.DiseaseHistoryEditIcon;
        default:
          return MixpanelEvents.Unknown;
      }
    };

    sendMixpanel({
      event: getMixpanelEvent(),
      patientId: id,
    });
    handleEdit(type)();
  };
  return (
    <AnchorComponent hideAnchorLinks>
      <Space direction="vertical" size={40} className="w100">
        <AnchorLinkComponent
          id="vitals"
          title={MedicalTabAnchors.vitals.label}
          href={MedicalTabAnchors.vitals.href}
        >
          <VitalsComponent showGenerateReportButton />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          id="alertHistory"
          title={MedicalTabAnchors.alertHistory.label}
          href={MedicalTabAnchors.alertHistory.href}
        >
          <AlertInCardComponent
            patientId={id}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          id="medications"
          title={MedicalTabAnchors.medications.label}
          href={MedicalTabAnchors.medications.href}
        >
          <MedicationCommonCardComponent
            patientId={id}
            isEditing={isEditing(MedicalHistoryEnumType.Medication)}
            onEdit={handleEditMode(MedicalHistoryEnumType.Medication)}
            onSubmit={closeEditMode}
            onMedicationSubmit={closeEditMode}
            onCancel={closeEditMode}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          id="symptom"
          title={MedicalTabAnchors.symptom.label}
          href={MedicalTabAnchors.symptom.href}
        >
          <SymptomCardContainer
            patientId={id}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          id="labResult"
          title={MedicalTabAnchors.labResult.label}
          href={MedicalTabAnchors.labResult.href}
        >
          <LabResultInCardContainer
            patientId={id}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          id="icdCode"
          title={MedicalTabAnchors.icdCode.label}
          href={MedicalTabAnchors.icdCode.href}
        >
          <ProblemsICDCodeContainer
            patientId={id}
            isEditing={isEditing(MedicalHistoryEnumType.ICDCode)}
            onEdit={handleEditMode(MedicalHistoryEnumType.ICDCode)}
            onSubmit={closeEditMode}
            onCancel={closeEditMode}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          id="diseaseHistory"
          title={MedicalTabAnchors.diseaseHistory.label}
          href={MedicalTabAnchors.diseaseHistory.href}
        >
          <ProblemDiseaseHistoryContainer
            patientId={id}
            isEditing={isEditing(MedicalHistoryEnumType.DiseaseHistory)}
            onEdit={handleEditMode(MedicalHistoryEnumType.DiseaseHistory)}
            onSubmit={closeEditMode}
            onCancel={closeEditMode}
            formButtons={(
              <InterventionSubmitCancelButtons
                onCancel={closeEditMode}
              />
            )}
          />
        </AnchorLinkComponent>
      </Space>
    </AnchorComponent>
  );
};
