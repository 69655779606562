import { Select, Tooltip } from 'antd';
import { ClinicalGoalEnumComponent } from '../../../../../enumComponent/ClinicalGoalEnumComponent/ClinicalGoalEnumComponent';
import { ClinicGoalEnum } from '../../../../../uc-api-sdk';

import './GoalDescriptionSelectComponent.scss';

export interface GoalDescriptionSelectComponentProps {
  value?: ClinicGoalEnum;
  onChange?: (value: ClinicGoalEnum) => void;
  options?: ClinicGoalEnum[];
  isCustomizedCondition: boolean;
}

export const GoalDescriptionSelectComponent = ({
  options,
  value,
  onChange,
  isCustomizedCondition
}: GoalDescriptionSelectComponentProps) => (
  <span>
    <Tooltip
      className="goal-description-selector"
      title={isCustomizedCondition ? "This clinical goal cannot be modified due to the patient's clinic requirements." : ''}
    >
      <Select
        value={value}
        onChange={onChange}
        disabled={isCustomizedCondition}
      >
        {options?.map((opt) => (
          <Select.Option value={opt} key={opt}>
            <ClinicalGoalEnumComponent value={opt} />
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  </span>
);
