import { AutoComplete } from 'antd';
import { AutoCompleteInputType } from '../../../Input/types';
import { AutoCompleteOptions, useAutoCompleteWorkAround } from '../../../../uiHooks/useAutoCompleteWorkAround/useAutoCompleteWorkAround';
import { CGMOptOutReasonEnumComponent, CGMOptOutReasonEnum } from '../../../../enumComponent/CGMServiceNotWearReasonEnumComponent/CGMServiceNotWearReasonEnumComponent';

export interface CGMServiceNotWearDropdownComponentProps extends
  AutoCompleteInputType<CGMOptOutReasonEnum> {
}

export const CGMServiceNotWearDropdownComponent = ({
  value,
  onChange,
  disabled,
}: CGMServiceNotWearDropdownComponentProps) => {
  const defaultOptions: AutoCompleteOptions<CGMOptOutReasonEnum>[] = [
    {
      associatedEnum: CGMOptOutReasonEnum.EXPENSIVE,
    },
    {
      associatedEnum: CGMOptOutReasonEnum.NOT_USEFUL,
    },
    {
      associatedEnum: CGMOptOutReasonEnum.NO_LONGER_USEFUL,
    },
  ];

  const { options, handleChange } = useAutoCompleteWorkAround({
    defaultOptions,
    optionRenderer: CGMOptOutReasonEnumComponent,
    onChange,
  });

  return (
    <AutoComplete
      placeholder="Type/select a reason"
      className="select-component"
      options={options}
      value={value}
      showSearch
      showArrow={false}
      onChange={handleChange}
      dropdownMatchSelectWidth={500}
      notFoundContent={null}
      disabled={disabled}
    />
  );
};
