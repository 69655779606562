import {
  Button, Divider, Drawer, Tooltip
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TextAreaProps } from 'antd/lib/input';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { lazy, useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { usePatientContext } from '../../../../../contexts/PatientInfoContext/PatientInfoContext';
import TimezoneService from '../../../../../helpers/timezone/timezoneService';
import { useOpen } from '../../../../../hooks/useBoolean/useOpen';
import { DateTimeType } from '../../../../../types/common';
import { CareNote, CareNoteTagEnum } from '../../../../../uc-api-sdk';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import './GenerateCgmReportDrawerComponent.scss';
import { Icons } from '../../../../../icons/Icons';

const CgmReportPreviewComponent = lazy(() => import('../CgmReportPreviewComponent/CgmReportPreviewComponent'));

export interface GenerateCgmReportDrawerComponentProps {
  careNote?: CareNote;
  onNoteChange?: (value: string) => void;
  note?: string;
  startDate: DateTimeType;
  endDate: DateTimeType;
  onSubmit?: (v: CareNote, onSuccess?: () => void) => void;
  isLoading?: boolean;
  disabled?: boolean;
  isDexcom: boolean;
}

export const GenerateCgmReportDrawerComponent = ({
  startDate,
  endDate,
  careNote,
  onNoteChange,
  note,
  onSubmit,
  isLoading,
  disabled,
  isDexcom
}: GenerateCgmReportDrawerComponentProps) => {
  const {
    isOpen: isPreviewOpen,
    open: openPreview,
    close: closePreview,
  } = useOpen();
  const componentRef = useRef(null);

  const { info } = usePatientContext();

  const reactToPrintContent = useCallback(() => componentRef.current, [
    componentRef.current
  ]);

  const handleNoteChange: TextAreaProps['onChange'] = (e) => {
    if (onNoteChange) {
      onNoteChange(e.target.value);
    }
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'CGM Report',
    removeAfterPrint: true,
  });

  const handleSubmit = () => {
    onSubmit?.(
      {
        memberId: info?.id,
        content: note,
        tags: [CareNoteTagEnum.CGM_REPORT],
        reportStartTime: TimezoneService.getUTC(dayjs(startDate)),
        reportEndTime: TimezoneService.getUTC(dayjs(endDate)),
      } as CareNote,
      () => {
        closePreview();
        handlePrint();
      }
    );
  };

  return (
    <div className="GenerateCgmReportDrawerComponent">
      <Tooltip
        title="Unable to generate report due to insufficient CGM data (less than 72 hours validated)."
      >
        <Button
          onClick={openPreview}
          type="primary"
          ghost
          loading={isLoading}
          disabled={isLoading || disabled}
        >
          Generate CGM Report
        </Button>
      </Tooltip>
      <Drawer
        destroyOnClose
        size="large"
        open={isPreviewOpen}
        onClose={closePreview}
        width={879}
        maskClosable={false}
        className="CgmReportPreviewDrawerContainer"
      >
        <div className="flex gap2 overflow-h">
          <div className="overflow-a h100">
            <div
              ref={componentRef}
            >
              <CgmReportPreviewComponent
                patientInfo={info}
                startDate={startDate}
                endDate={endDate}
                careNote={careNote}
              />
            </div>
          </div>

        </div>
      </Drawer>
      <Drawer
        title="CGM Review Care Note"
        placement="right"
        open={isPreviewOpen}
        onClose={closePreview}
        width={400}
        maskClosable={false}
        destroyOnClose
        className="CgmReportPreviewCareNoteContainer"
      >
        <FixedComponent>
          {isDexcom && (
            <div className="cgm-clarity-tooltips p15 mb20">
              <div className="flex">
                <div className="clarity-tooltips-circle">
                  <InfoCircleOutlined />
                </div>
                Before reviewing, confirm the patient's CGM data in is at least 72 hours in Clarity.
              </div>
              <div className="ml10">
                <a className="clarity-dexcom-com-link" target="_blank" href="https://clarity.dexcom.com/professional/" rel="noreferrer">
                  clarity.dexcom.com
                  <Icons.LinkSymbolIcon />
                </a>
              </div>
            </div>
          )}

          <FixedComponent.Child>
            <div className="mb20">
              Your review will be included in the CGM report once generated.
              You can view your review notes later under the "Care Notes" tab.
            </div>
            <TextArea
              rows={10}
              value={note}
              onChange={handleNoteChange}
              placeholder="Add CGM review note"
            />

          </FixedComponent.Child>
          <FixedComponent.Child isFixed>
            <Divider />
            <div className="flex jc-e">
              <Button
                type="primary"
                disabled={!note || isLoading}
                loading={isLoading}
                onClick={handleSubmit}
                className="b5"
              >
                Generate Report
              </Button>
            </div>
          </FixedComponent.Child>
        </FixedComponent>
      </Drawer>
    </div>
  );
};
