import { useCallback } from 'react';
import { InterventionContainerProps } from '../../type';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { BehaviorGoalCardListComponent } from '../../component/BehaviorGoalComponent/BehaviorGoalCardListComponent/BehaviorGoalCardListComponent';
import { usePatientGetBehaviorGoalById, usePatientUpdateBehaviorGoal } from '../../../../uc-api-sdk';
import { BehaviorGoalCardFormComponent, BehaviorGoalCardFormComponentProps, SubmitValue } from '../../component/BehaviorGoalComponent/BehaviorGoalCardFormComponent/BehaviorGoalCardFormComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext/LoggedInUserContext';

export interface BehaviorGoalContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps<SubmitValue> {
  patientId: string;
}

export const BehaviorGoalContainer = ({
  visitId,
  showEdit,
  onEdit,
  isEditing,
  onSubmit,
  onError,
  onCancel,
  patientId,
  version,
  onValuesChange,
  formButtons,
}: BehaviorGoalContainerProps) => {
  const fetchBehaviorGoalHook = usePatientGetBehaviorGoalById({
    params: {
      memberId: patientId,
      version,
    },
  });

  useUpdateListener('ICD_UPDATED', fetchBehaviorGoalHook.refetch);
  useUpdateListener('WORKLIST_UPDATED', fetchBehaviorGoalHook.refetch);

  const updateHook = usePatientUpdateBehaviorGoal({});
  const { userInfo } = useLoggedInUserFromContext();

  const updateBehaviorGoal = useCallback(async (value: SubmitValue) => {
    const req = await updateHook.send({
      params:
      {
        memberId: patientId,
        request: {
          behaviorGoals: value.behaviorGoals,
          visitId,
          updatedBy: userInfo?.id
        },
      },
    });
    fetchBehaviorGoalHook.refetch();
    return req;
  }, [patientId]);

  const { send } = useBillableTimeInterventionComp();

  const handleOnEdit = () => {
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.UpdateCarePlan,
      detail: BillableTimeInterventionDetailEnum.InterventionClinicalGoalEditIcon,
    });
    onEdit?.();
  };

  const handleSubmit: BehaviorGoalCardFormComponentProps['onSubmit'] = (v) => {
    const req = updateBehaviorGoal(v);
    ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Updated behavior goals!',
        error: 'Fail to update behavior goals!',
        onSuccess: () => onSubmit?.(v),
        onError,
      },
    );
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const isLoading = (
    fetchBehaviorGoalHook.data?.data === undefined || fetchBehaviorGoalHook.isLoading
  );
  const behaviorGoalInfo = fetchBehaviorGoalHook.data?.data?.behaviorGoals || [];
  const hasBehaviorGoal = behaviorGoalInfo.length > 0;

  const renderContent = () => (
    hasBehaviorGoal
      ? (
        <BehaviorGoalCardListComponent
          value={behaviorGoalInfo ?? []}
        />
      ) : (
        <NoDataWithActionComponent
          description="No record at this time."
          actionLinkText="Add Behavioral Goals"
          actionLinkOnClick={onEdit}
        />
      )
  );

  if (fetchBehaviorGoalHook.data?.data === undefined) {
    return <LoadingOverlayComponent isLoading />;
  }

  return (
    <PatientCommonCardComponent
      title="Behavior Goal"
      showHeaderOutside={false}
      showEdit={hasBehaviorGoal && showEdit}
      isEditing={isEditing}
      onEdit={handleOnEdit}
      updateInfo={fetchBehaviorGoalHook.data?.data}
      content={renderContent()}
      formContent={(
        <LoadingOverlayComponent
          isLoading={isLoading}
          showSkeleton
        >
          <BehaviorGoalCardFormComponent
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            isLoading={updateHook.isLoading}
            initialValues={{
              behaviorGoals: behaviorGoalInfo,
            }}
            onValuesChange={onValuesChange}
            formButtons={formButtons}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
