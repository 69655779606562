import { map } from 'lodash';
import { Form, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { InterventionSubmitCancelButtons } from '../../InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { InterventionFormComponentProps } from '../../../type';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { BehaviorGoalCategoryEnum, BehaviorGoalItem } from '../../../../../uc-api-sdk';
import { useBehaviorGoalForm } from '../../../hook/useBehaviorGoalForm';

import { BehaviorGoalSearchSelectContainer } from '../../../container/BehaviorGoalSearchSelectContainer/BehaviorGoalSearchSelectContainer';

import { BehaviorGoalAddNewComponent } from '../BehaviorGoalAddNewComponent/BehaviorGoalAddNewComponent';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';
import { BehaviorGoalCategoryLabelComponent } from '../BehaviorGoalCategoryLabelComponent/BehaviorGoalCategoryLabelComponent';
import { useBehaviorGoalHelpers } from '../../../hook/useBehaviorGoalHelpers';
import { MeetingStatusSelectComponent } from '../../../../patient/component/MeetingStatusSelectComponent/MeetingStatusSelectComponent';
import { GoalFormTimeComponent } from '../../ClinicalGoalComponent/GoalFormTimeComponent/GoalFormTimeComponent';

export interface SubmitValue {
  behaviorGoals: BehaviorGoalItem[]
}

export type Status = string;
export type Description = string;

export interface BehaviorGoalCardFormComponentProps extends
  InterventionFormComponentProps<SubmitValue> { }

export const BehaviorGoalCardFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
  onCancel,
  onValuesChange,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />,
}: BehaviorGoalCardFormComponentProps) => {
  const behaviorGoalForm = useBehaviorGoalForm();
  const {
    form,
    formDisabled,
    getMainFormItemName,
    makeListNamePath,
    makeObjectNamePathFromMain,
    handleAddCategory,
  } = behaviorGoalForm;
  const {
    groupBehaviorGoalsByCategory
  } = useBehaviorGoalHelpers();

  const processedInitialValues = useDeepCompareMemo(() => ({
    behaviorGoals: groupBehaviorGoalsByCategory(initialValues?.behaviorGoals),
  }), []);

  return (
    <Form
      form={form}
      onFinish={behaviorGoalForm.handleSubmit(onSubmit)}
      initialValues={processedInitialValues}
      disabled={formDisabled || isLoading}
      onValuesChange={behaviorGoalForm.onValuesChange(onValuesChange)}
    >
      <div className="behavior-goal-items">
        <FormItem name={getMainFormItemName()}>
          <BehaviorGoalSearchSelectContainer />
        </FormItem>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const goalsByCategory = getFieldValue(getMainFormItemName());
            return (
              <div>
                {map(goalsByCategory, (
                  goals: BehaviorGoalItem[],
                  category: BehaviorGoalCategoryEnum
                ) => (goals.length > 0 ? (
                  <div key={category}>
                    <div>
                      <BehaviorGoalCategoryLabelComponent
                        category={goals[0].category}
                        customCategory={goals[0].customCategory}
                      />
                    </div>

                    <Form.List key={category} name={makeObjectNamePathFromMain(category)}>
                      {(fields, { remove }) => (
                        <div>
                          {map(fields, (field) => (
                            <div key={field.key} className="flex jc-sb">
                              <div className="w100 pr24">
                                <FormItem
                                  key={field.key}
                                  name={makeListNamePath('behaviorGoalValue', field.name)}
                                  required
                                >
                                  <Input.TextArea />
                                </FormItem>
                              </div>
                              <div className="flex ai-c">
                                <FormItem
                                  key={field.key}
                                  name={behaviorGoalForm.makeListNamePath('goalStatus', field.name)}
                                  label="Status"
                                  className="goal-form-meeting-status"
                                >
                                  <MeetingStatusSelectComponent useExtendedStatus />
                                </FormItem>
                                <FormItem
                                  key={field.key}
                                  name={makeListNamePath('updatedDate', field.name)}
                                >
                                  <GoalFormTimeComponent />
                                </FormItem>
                                <DeleteOutlined
                                  onClick={() => remove(field.name)}
                                  className="mb10"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Form.List>
                  </div>
                ) : null))}
              </div>
            );
          }}
        </FormItem>
        <BehaviorGoalAddNewComponent onAdd={handleAddCategory} />
      </div>
      {formButtons}
    </Form>
  );
};
