import {
  Col,
  Row,
  Checkbox,
} from 'antd';
import { CheckboxGroupProps, CheckboxValueType } from 'antd/lib/checkbox/Group';
import {
  filter,
  last,
  map,
  omit,
  sortBy
} from 'lodash';
import { useMemo } from 'react';
import { DietEnum } from '../../../../uc-api-sdk';
import { DietEnumComponent } from '../../../../enumComponent/DietEnumComponent/DietEnumComponent';

import './CalorieManagementComponent.scss';

export interface CalorieManagementComponentProps extends CheckboxGroupProps {
  value?: CheckboxValueType[];
  onChange?: CheckboxGroupProps['onChange'];
}

export const CalorieManagementComponent = ({
  value = [],
  onChange,
}: CalorieManagementComponentProps) => {
  const options = useMemo(() => ({
    ...sortBy(omit(DietEnum, DietEnum.NONE)),
    [DietEnum.NONE]: DietEnum.NONE,
  }), []);

  const onGroupChange = (list: CheckboxValueType[]) => {
    if (last(list) === DietEnum.NONE) {
      onChange?.([DietEnum.NONE]);
    } else {
      const noNone = filter(list, value => value !== DietEnum.NONE);
      onChange?.(noNone);
    }
  };

  return (
    <Checkbox.Group
      value={value}
      onChange={onGroupChange}
    >
      <Row>
        {
          map(options).map(value => (
            <Col
              key={`${value}`}
              span={5}
              className="diet-list__item"
            >
              <Checkbox value={value}>
                <DietEnumComponent value={value as DietEnum} />
              </Checkbox>
            </Col>
          ))
        }
      </Row>
    </Checkbox.Group>
  );
};
