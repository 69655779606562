import { DatePicker, DatePickerProps } from 'antd';
import moment from 'moment';
import { InputType } from '../../../Input/types';
import { YEAR_DATE } from '../../../../constants/timeFormat';

export interface SmokeDrinkQuitYearComponentProps extends InputType<number> { }

export const SmokeDrinkQuitYearComponent = ({
  value,
  onChange,
}: SmokeDrinkQuitYearComponentProps) => {
  const dateValue = value ? moment().set('year', value) : undefined;

  const handleChange: DatePickerProps['onChange'] = (date) => {
    const numValue = date ? date.year() : undefined;
    onChange?.(numValue);
  };

  return (
    <DatePicker
      value={dateValue}
      onChange={handleChange}
      format={YEAR_DATE}
      picker="year"
      disabledDate={(date) => date.isAfter(new Date())}
    />
  );
};
