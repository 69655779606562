import { DefaultOptionType } from 'antd/lib/select';
import { map } from 'lodash';
import { ReactNode, useCallback, useMemo } from 'react';
import { AutoCompleteProps } from 'antd';
import { EnumComponent } from '../../enumComponent';

export interface AutoCompleteOptions<T extends string>
  extends Omit<DefaultOptionType, 'label'> {
  associatedEnum?: T;
  label?: ReactNode | string | undefined | null;
}

export interface UseAutoCompleteWorkAroundParams<T extends string, K extends string> {
  defaultOptions: AutoCompleteOptions<T>[];
  optionRenderer?: EnumComponent<T, K>;
  onChange?: (v: { value?: string, associatedEnum?: T }) => void;
}

export const useAutoCompleteWorkAround = <T extends string, K extends string>({
  defaultOptions,
  optionRenderer,
  onChange,
}: UseAutoCompleteWorkAroundParams<T, K>) => {
  const processedOptions = useMemo(() => (
    map(defaultOptions, (o) => {
      const option: DefaultOptionType = {
        ...o,
        value: optionRenderer?.getText({ value: o.associatedEnum }) || o.value,
        label: optionRenderer?.({ value: o.associatedEnum }) || o.label,
        associatedEnum: o?.associatedEnum,
      };

      return option;
    })
  ), [defaultOptions]);

  const handleChange: Exclude<AutoCompleteProps['onChange'], undefined> = useCallback((
    value,
    option
  ) => {
    const associatedEnum = (option as AutoCompleteOptions<T>)?.associatedEnum;
    onChange?.({ value, associatedEnum });
  }, [onChange]);

  return {
    options: processedOptions,
    handleChange,
  };
};
