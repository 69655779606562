import { FreqEnumComponent } from '../../../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import { JunkFoodFreq } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';

import './FoodBeverageInfoComponent.scss';

export interface FoodBeverageInfoComponentProps {
  info?: JunkFoodFreq | null;
}

export const FoodBeverageInfoComponent = ({
  info
}: FoodBeverageInfoComponentProps) => (
  <div className="food-beverage-info">
    <div>
      <DisplayOrEmptyComponent value={info?.type} />
      <span>,</span>
    </div>
    <DisplayOrEmptyComponent value={info?.value} />
    <span>/</span>
    {
      info?.unit
        ? <FreqEnumComponent value={info?.unit} type="short" />
        : <EmptyComponent />
    }
  </div>
);
