import {
  keyBy, lowerCase, map, startCase
} from 'lodash';
import { useMemo } from 'react';
import { ConditionEumComponent } from '../../../../enumComponent/ConditionEumComponent/ConditionEumComponent';
import { orderByArray } from '../../../../helpers/order/orderByArray';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { ClinicGoalTemplate, ConditionEnum, useTemplatesGetClinicGoalTemplate } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { ClinicalGoalCardFormComponent, ClinicalGoalCardFormComponentProps } from '../../component/ClinicalGoalComponent/ClinicalGoalFormComponent/ClinicalGoalCardFormComponent';
import { clinicGoalConditionOrder } from '../../constant/clinicGoal';

export interface ClinicalGoalTemplateFetchContainerProps extends Omit<ClinicalGoalCardFormComponentProps, 'templates'> {
  onSubmit: ClinicalGoalCardFormComponentProps['onSubmit'];
  onCancel: ClinicalGoalCardFormComponentProps['onCancel'];
  isLoading: boolean;
  disabled?: boolean;
  requireClinicalGoal?: boolean;
  clinicId?: string;
  codes?: string[];
}

export const ClinicalGoalTemplateFetchContainer = ({
  onSubmit,
  onCancel,
  isLoading,
  initialValues,
  onValuesChange,
  formButtons,
  disabled,
  requireClinicalGoal,
  clinicId,
  codes,
}: ClinicalGoalTemplateFetchContainerProps) => {
  const fetchHook = useTemplatesGetClinicGoalTemplate({});
  const clinicGoalTemplateList : ClinicGoalTemplate[] = fetchHook.data?.data ?? [];

  const getFilteredClinicGoalList = (
    clinicGoalTemplateList: ClinicGoalTemplate[],
    clinicGoalConditionOrder: ConditionEnum[],
    clinicId?: string
  ) => {
    const conditionMap = new Map<ConditionEnum, ClinicGoalTemplate>();

    clinicGoalTemplateList.forEach((item) => {
      const { condition, clinicIds = [] } = item;

      if (!condition) return;

      if (conditionMap.has(condition)) {
        const existingItem = conditionMap.get(condition);
        if (!existingItem) {
          return;
        }
        if (clinicId) {
          if (!existingItem.clinicIds?.includes(clinicId) && clinicIds?.includes(clinicId)) {
            conditionMap.set(condition, item);
          } else {
            if (!existingItem.clinicIds?.length) {
              return;
            }
            if (!clinicIds?.length) {
              conditionMap.set(condition, item);
            }
          }
        }
      } else {
        conditionMap.set(condition, item);
      }
    });

    const filteredList = Array.from(conditionMap.values());
    return orderByArray(filteredList, clinicGoalConditionOrder, 'condition');
  };

  const orderedList = useDeepCompareMemo(
    () => getFilteredClinicGoalList(clinicGoalTemplateList, clinicGoalConditionOrder, clinicId),
    [clinicGoalTemplateList, clinicGoalConditionOrder, clinicId]
  );

  const templates = keyBy(orderedList, 'condition') || {};

  const defaultConditions = useMemo(() => {
    const result = map(templates, (item) => ({
      value: item.condition,
      label: (<ConditionEumComponent value={item.condition as ConditionEnum} />),
      searchableLabel: startCase(lowerCase(item.condition as ConditionEnum)),
      disabled: false
    }));
    return result;
  }, [templates]);

  return (
    <FetchComponent
      info={fetchHook}
    >
      {
        (value) => {
          if (value?.rawValue?.data === undefined) {
            return <LoadingOverlayComponent isLoading />;
          }
          return (
            <ClinicalGoalCardFormComponent
              defaultConditions={defaultConditions}
              onSubmit={onSubmit}
              onCancel={onCancel}
              initialValues={initialValues}
              isLoading={isLoading}
              templates={templates}
              onValuesChange={onValuesChange}
              formButtons={formButtons}
              disabled={disabled}
              requireClinicalGoal={requireClinicalGoal}
              clinicId={clinicId}
              codes={codes}
            />
          );
        }
      }
    </FetchComponent>
  );
};
