import { ActivityTypeEnum } from '../../uiComponent/OfflineTimeComponent/type';

export interface OfflineActivityTypeEnumComponentProps {
  value: ActivityTypeEnum;
}

export const OfflineActivityTypeEnumComponent = ({
  value,
}: OfflineActivityTypeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case ActivityTypeEnum.CALL:
        return 'Called patient offline';
      case ActivityTypeEnum.CHECK_EMR:
        return 'Check EMR about this patient';
      case ActivityTypeEnum.EDUCATION:
        return 'Translated education/communication';
      case ActivityTypeEnum.REVIEW_CGM_DATA:
        return 'Reviewed CGM data';
      case ActivityTypeEnum.REVIEW_VITALS:
        return 'Reviewed vitals and/or treatment goals';
      case ActivityTypeEnum.COORDINATE_CARE:
        return 'Coordinated care with team or caregiver';
      case ActivityTypeEnum.RESEARCHED_CONDITION:
        return 'Researched patient condition';
      case ActivityTypeEnum.OTHER:
        return 'Other';
      default: return null;
    }
  };

  return (
    <>{getText()}</>
  );
};
