import { SmokeDrinkRecord$CigaretteUnitEnum } from '../../uc-api-sdk';

export const getCigaretteUnitText = (
  value?: SmokeDrinkRecord$CigaretteUnitEnum | null,
  singular?: boolean,
) => {
  let text = '';
  switch (value) {
    case SmokeDrinkRecord$CigaretteUnitEnum.PACKS:
      text = 'packs';
      break;
    case SmokeDrinkRecord$CigaretteUnitEnum.CIGARETTES:
      text = 'cigarettes';
      break;
    default:
  }
  if (singular) {
    text = text.slice(0, -1);
  }
  return text;
};

export interface CigaretteUnitEnumComponentProps {
  value?: SmokeDrinkRecord$CigaretteUnitEnum | null;
}

export const CigaretteUnitEnumComponent = ({
  value,
}: CigaretteUnitEnumComponentProps) => (
  <>{getCigaretteUnitText(value)}</>
);
