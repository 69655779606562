import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { useFastFoodBeverageForm } from '../../hook/useILifestyleAssessmentForm/useFastFoodBeverageForm';
import { FastFoodInputCardComponent } from '../LifestyleAssessmentFormListComponent/components/FastFoodBeverage/FastFoodInputCardComponent';

export const FastFoodFormItemComponent = () => {
  const fastFoodForm = useFastFoodBeverageForm();

  return (
    <>
      <FormItem
        info={fastFoodForm.getInfo('isEatingFastFood')}
      >
        <YesNoRadioComponent />
      </FormItem>

      <div className="mt12">
        <FormItem
          noStyle
          shouldUpdate={fastFoodForm.shouldUpdate(['isEatingFastFood'])}
        >
          {
            ({ getFieldValue }) => {
              const isEatingFastFood = fastFoodForm.getValue('isEatingFastFood', getFieldValue);
              if (!isEatingFastFood) {
                return null;
              }
              return <FastFoodInputCardComponent />;
            }
          }
        </FormItem>
      </div>
    </>
  );
};
