import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export interface SubmitValue {
  communitySupport?: string;
  contactInfo?: string;
  otherProviders?: string;
  easeOfFoodAccess?: string;
}

export const useSocialSupportForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    communitySupport: {
      name: 'communitySupport',
      label: 'Community/Social Support',
    },
    contactInfo: {
      name: 'contactInfo',
      label: 'Contact Information',
    },
    otherProviders: {
      name: 'otherProviders',
      label: 'Other Providers',
    },
    easeOfFoodAccess: {
      name: 'easeOfFoodAccess',
      label: 'Ease of access to food',
    }
  }, options);

  return factory;
};
