import { Checkbox, Col, Row } from 'antd';
import { map } from 'lodash';
import { AddOnServiceEnumComponent } from '../../../../enumComponent/AddOnServiceEnumComponent/AddOnServiceEnumComponent';
import { AddOnServiceEnum, VitalEnumType } from '../../../../uc-api-sdk';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import { InputType } from '../../../Input/types';

export interface AddOnServicesCheckboxComponentProps
  extends InputType<AddOnServiceEnum[] | undefined> {
  selectedVitals?: VitalEnumType[];
}

export const DependentVitals = {
  [AddOnServiceEnum.CGM]: VitalEnumType.CGM,
};

export const AddOnServicesCheckboxComponent = ({
  value,
  onChange,
  selectedVitals = [],
}: AddOnServicesCheckboxComponentProps) => {
  useDeepCompareEffect(() => {
    if (
      selectedVitals?.includes(VitalEnumType.CGM)
      && !value?.includes(AddOnServiceEnum.CGM)
    ) {
      // auto-select CGM service
      const newValue = [...(value || []), AddOnServiceEnum.CGM];
      onChange?.(newValue);
    }
  }, [selectedVitals, value]);

  return (
    <Checkbox.Group
      className="checkbox-group-btn-style programs-checkbox-group w100"
      value={value}
      onChange={(v) => onChange?.(v as AddOnServiceEnum[])}
    >
      <Row gutter={10}>
        {
          map(AddOnServiceEnum, (v: AddOnServiceEnum) => (
            <Col span={12}>
              <Checkbox
                key={v}
                value={v}
                className="block"
                disabled={!selectedVitals.includes(DependentVitals[v])}
              >
                <AddOnServiceEnumComponent
                  value={v}
                  type="service"
                />
              </Checkbox>
            </Col>
          ))
        }
      </Row>
    </Checkbox.Group>
  );
};
