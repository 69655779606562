import dayjs from 'dayjs';
import { useCallback, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import useBoolean from '../../../../../hooks/useBoolean/useBoolean';
import {
  CgmBrandEnum,
  useCgmGetPatientAgp,
  useCgmMobileGetPatientCgmReading,
  usePatientGetCgmGoalById,
  usePatientSearchUcMedicationManagement
} from '../../../../../uc-api-sdk';
import { CommonReportHeaderComponent } from '../../../../../uiComponent/CommonReportHeaderComponent/CommonReportHeaderComponent';
import { LoadingOverlayComponent } from '../../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { AGPChartComponent } from '../../../../vitals/component/AGPChartComponent/AGPChartComponent';
import { CGMChartMainComponent } from '../../../../vitals/component/CGMChartComponent/CGMChartMainComponent';
import { CgmVitalSummaryComponent } from '../../../../vitals/component/CgmVitalSummaryComponent/CgmVitalSummaryComponent';
import { CGMChartHeight, CGM_DAYS_LIMIT } from '../../../../vitals/constant/cgmConstant';
import { PrintCarePlanDiagnosisHealthConditionsComponent } from '../../../PrintCarePlan/component/PrintCarePlanDiagnosisHealthConditionsComponent/PrintCarePlanDiagnosisHealthConditionsComponent';
import { PrintCarePlanMedicationComponent } from '../../../PrintCarePlan/component/PrintCarePlanMedicationComponent/PrintCarePlanMedicationComponent';
import { CgmReportProps } from '../../type';
import { CgmReportCareNoteComponent } from '../CgmReportCareNoteComponent/CgmReportCareNoteComponent';
import { CgmReportClinicInfoComponent } from '../CgmReportClinicInfoComponent/CgmReportClinicInfoComponent';
import './CgmReportPreviewComponent.scss';

export interface CgmReportPreviewComponentProps extends CgmReportProps {
  print?: boolean;
  onPrint?: () => void;
}

export const CgmReportPreviewComponent = ({
  patientInfo,
  startDate,
  endDate,
  careNote,
  print,
  onPrint
}: CgmReportPreviewComponentProps) => {
  const dataStartTime = startDate ? dayjs(startDate).toISOString()
    : dayjs(endDate || undefined).subtract(1, 'year').startOf('day').toISOString();
  const dataEndTime = endDate ? dayjs(endDate).toISOString() : dayjs().endOf('day').toISOString();

  const componentRef = useRef(null);
  const {
    value: agpChartReady,
    setTrue: setAgpReady,
  } = useBoolean(false);
  const {
    value: dailyChartReady,
    setTrue: setDailyChartReady,
  } = useBoolean(false);

  const reactToPrintContent = useCallback(() => componentRef.current, [
    componentRef.current,
    print,
    agpChartReady,
    dailyChartReady,
  ]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'CGM Report',
    removeAfterPrint: true,
  });

  const medInfo = usePatientSearchUcMedicationManagement({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {
          prescribedMedication: {
            memberId: patientInfo?.id,
            active: true,
            isEnded: false,
          },
          discontinuedMedication: {
            memberId: patientInfo?.id,
            active: true,
            isEnded: true,
          },
        },
      },
    },
  });

  const cgmGoal = patientInfo?.id
    ? usePatientGetCgmGoalById({
      params: { memberId: patientInfo.id },
      options: { sendOnMount: true }
    })
    : undefined;

  const cgmReadingInfo = useCgmMobileGetPatientCgmReading({
    params: {
      request: {
        patientId: patientInfo?.id || '',
        includeAvailableDates: true,
        includeRawData: true,
        includeSummary: true,
        startTime: dataStartTime,
        endTime: dataEndTime,
      }
    },
    options: { sendOnMount: true }
  });

  const agpInfo = useCgmGetPatientAgp({
    params: {
      request: {
        patientId: patientInfo?.id || '',
        startTime: dataStartTime,
        endTime: dataEndTime,
      }
    },
    options: {
      sendOnMount: true,
    }
  });

  const daysInRange = dayjs(endDate).diff(dayjs(startDate), 'day') + 1; // inclusive
  const isOneDayRange = dayjs(startDate).isSame(endDate, 'day');
  const agpData = agpInfo.data?.data;
  const agpPatientContext = agpInfo.data?.data?.context?.patientContext || {};
  const isAGPApplicable = agpInfo.data?.data?.isApplicable;

  const cgmData = cgmReadingInfo.data?.data?.rawData;
  const cgmPatientContext = cgmReadingInfo.data?.data?.context?.patientContext || {};
  const cgmAvailableDays = cgmReadingInfo.data?.data?.availableDates?.length || 0;
  const isDexcom = cgmReadingInfo.data?.data?.context?.deviceContext?.brand === CgmBrandEnum.DEXCOM;

  const medicationList = medInfo.data?.data?.prescribedMedications || [];

  useEffect(() => {
    if (print
      && (agpChartReady || !agpData?.isApplicable)
      && dailyChartReady
      && !cgmReadingInfo.isLoading
      && !medInfo.isLoading
      && !agpInfo.isLoading
      && !cgmGoal?.isLoading
    ) {
      handlePrint();
      onPrint?.();
    }
  }, [print, cgmReadingInfo.data?.data, medInfo.data?.data,
    agpInfo.data?.data, cgmGoal?.data?.data, agpChartReady, dailyChartReady,
    agpData?.isApplicable
  ]);

  return (
    <div className="CgmReportPreviewComponent fs12 flex fd-c gap2" ref={componentRef}>
      <div className="page bg-white px40 py20">
        <CommonReportHeaderComponent patientInfo={patientInfo} title="CGM Report" />
        <div className="flex fd-c gap3">
          <CgmReportClinicInfoComponent
            patientInfo={patientInfo}
            careNoteWriter={careNote?.createdByUser || undefined}
          />
          <PrintCarePlanDiagnosisHealthConditionsComponent
            healthConditions={patientInfo?.patientHealthCondition?.healthConditions}
          />
          {careNote && <CgmReportCareNoteComponent careNote={careNote} />}
        </div>
      </div>
      <div className="pagebreak" />
      {
        medicationList.length > 0 && (
          <>
            <div className="page bg-white px40 py20">
              <CommonReportHeaderComponent patientInfo={patientInfo} title="CGM Report" />
              <LoadingOverlayComponent isLoading={medInfo.isLoading}>
                <PrintCarePlanMedicationComponent
                  medicationList={medicationList}
                  medication={medInfo.data?.data?.medicationManagement || {}}
                />
              </LoadingOverlayComponent>
            </div>
            <div className="pagebreak" />
          </>
        )
      }
      {
        !isDexcom && (
          <>
            <div className="page bg-white px40 py20 w100">
              <CommonReportHeaderComponent patientInfo={patientInfo} title="CGM Report" />
              <LoadingOverlayComponent
                isLoading={cgmReadingInfo.isLoading || cgmGoal?.isLoading}
              >
                <CgmVitalSummaryComponent
                  metricData={cgmReadingInfo?.data?.data?.metrics || undefined}
                  percentGoals={cgmGoal?.data?.data?.percentGoals || undefined}
                  cgmDeviceInfo={patientInfo?.devicesService?.getCgmDevice()}
                  startDate={startDate}
                  endDate={endDate}
                  isForReport
                />
              </LoadingOverlayComponent>
              <LoadingOverlayComponent isLoading={agpData === undefined}>
                {
              isAGPApplicable && (
                <>
                  <TextComponent className="bold mb10">
                    Ambulatory Glucose Profile (AGP)
                  </TextComponent>
                  <AGPChartComponent
                    interval={agpData?.interval ?? undefined}
                    data={agpData?.percentile}
                    cgmPatientContext={agpPatientContext}
                    chartHeight={CGMChartHeight.DEFAULT}
                    onChartFinish={setAgpReady}
                  />
                </>
              )
            }
              </LoadingOverlayComponent>
            </div>
            <div className="pagebreak" />
            <div className="page bg-white px40 py20 relative">
              <LoadingOverlayComponent isLoading={cgmData === undefined}>
                <CommonReportHeaderComponent patientInfo={patientInfo} title="CGM Report" />
                <TextComponent className="bold mb10">
                  Daily Glucose Profiles
                </TextComponent>
                <div className="text-gray-scale-2">Each daily profile represents a midnight-to-midnight period.</div>
                <CGMChartMainComponent
                  startDate={dayjs(startDate)}
                  endDate={dayjs(endDate)}
                  data={cgmData}
                  cgmPatientContext={cgmPatientContext}
                  availableDays={cgmAvailableDays}
                  isLoading={cgmReadingInfo.isLoading}
                  allowStartFromRight={daysInRange > CGM_DAYS_LIMIT}
                  showAsCompact={!isOneDayRange}
                  onChartFinish={setDailyChartReady}
                  noLimit
                />
                <div className="fs8 text-gray-scale-2">
                  *Source: Battelino, Tadej, et al. “Clinical Targets for Continuous
                  Glucose Monitoring Data Interpretation: Recommendations From the
                  International Consensus on Time in Range.” Diabetes Care, American Diabetes Association, 7 June 2019, https://doi.org/10.2337/dci19-0028.
                </div>
              </LoadingOverlayComponent>
            </div>
          </>
        )
      }
    </div>
  );
};

export default CgmReportPreviewComponent;
