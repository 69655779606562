import { DietEnumListComponent } from '../../../../enumComponent/DietEnumComponent/DietEnumListComponent';
import { LifestyleAssessment } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import YesNoComponent from '../../../../uiComponent/YesNoComponent/YesNoComponent';
import { MealAndSleepRoutineTableComponent } from '../MealAndSleepRoutineTableComponent/MealAndSleepRoutineTableComponent';
import { LifestyleAssessmentDisplayLayout } from './layout/LifestyleAssessmentDisplayLayout';
import { LifestyleAssessmentListDisplayLayout } from './layout/LifestyleAssessmentListDisplayLayout';
import { LifestyleAssessmentTitleLayout } from './layout/LifestyleAssessmentTitleLayout';
import { FoodBeverageInfoComponent } from './FoodBeverageInfoComponent';
import { PhysicalActivityInfoComponent } from './PhysicalActivityInfoComponent';
import { SmokingInfoComponent } from './SmokingInfoComponent';
import { DrinkingInfoComponent } from './DrinkingInfoComponent';

export interface LifestyleAssessmentComponentProps {
  data?: LifestyleAssessment | null;
}

export const LifestyleAssessmentComponent = ({
  data,
}: LifestyleAssessmentComponentProps) => (
  <div>
    <div>
      <LifestyleAssessmentTitleLayout
        title="Eating and Sleeping Routine"
      />
      <MealAndSleepRoutineTableComponent
        dataSource={data?.mealAndSleepRoutines || []}
      />
    </div>
    <div className="mt60">
      <LifestyleAssessmentTitleLayout
        title="Fast Food and Beverage Intake"
      />
      <LifestyleAssessmentDisplayLayout
        label="Does the patient eat fast food"
        content={(
          <YesNoComponent
            yes={data?.isEatingFastFood}
            showIcon={false}
          />
        )}
      />
      {
        data?.isEatingFastFood && (
          <LifestyleAssessmentDisplayLayout
            label="Food type and frequency"
            content={(
              <LifestyleAssessmentListDisplayLayout
                data={data?.fastFoodFreq || []}
                renderItem={(i) => <FoodBeverageInfoComponent info={i} />}
              />
            )}
          />
        )
      }
      <LifestyleAssessmentDisplayLayout
        label="Beverages the patient consumes"
        content={(
          <LifestyleAssessmentListDisplayLayout
            data={data?.beverageFreq || []}
            renderItem={(i) => <FoodBeverageInfoComponent info={i} />}
          />
        )}
      />
    </div>
    <div className="mt40">
      <LifestyleAssessmentTitleLayout
        title="Calorie Management"
      />
      <LifestyleAssessmentDisplayLayout
        label="Previous calorie management regimen"
        content={(
          <DietEnumListComponent
            value={data?.previousDiets || []}
            splitter=", "
          />
        )}
      />
    </div>
    <div className="mt40">
      <LifestyleAssessmentDisplayLayout
        label={(
          <LifestyleAssessmentTitleLayout
            title="Nutrition Understanding"
          />
        )}
        content={(
          <DisplayOrEmptyComponent value={data?.nutritionUnderstanding} />
        )}
      />
    </div>
    <div className="mt40">
      <LifestyleAssessmentTitleLayout
        title="Smoking and Drinking"
      />
      <LifestyleAssessmentDisplayLayout
        label="Does the patient currently smoke"
        content={(
          <YesNoComponent
            yes={data?.smokeDrinkRecord?.isSmoking}
            showIcon={false}
          />
        )}
      />
      <SmokingInfoComponent
        info={data?.smokeDrinkRecord}
      />
      <LifestyleAssessmentDisplayLayout
        label="Does the patient currently drink"
        content={(
          <YesNoComponent
            yes={data?.smokeDrinkRecord?.isDrinking}
            showIcon={false}
          />
        )}
      />
      <DrinkingInfoComponent
        info={data?.smokeDrinkRecord}
      />
    </div>
    <div className="mt40">
      <LifestyleAssessmentTitleLayout
        title="Stress Management"
      />
      <LifestyleAssessmentDisplayLayout
        label="Stress level"
        content={(
          <DisplayOrEmptyComponent value={data?.stressManagement?.stressLevel} />
        )}
      />
      <LifestyleAssessmentDisplayLayout
        label="Stress coping strategy"
        content={(
          <DisplayOrEmptyComponent value={data?.stressManagement?.strategy} />
        )}
      />
      <LifestyleAssessmentDisplayLayout
        label="Recent depression/anxiety"
        content={(
          <DisplayOrEmptyComponent value={data?.stressManagement?.recentDepression} />
        )}
      />
    </div>
    <div className="mt40">
      <LifestyleAssessmentTitleLayout
        title="Physical Activity"
      />
      <LifestyleAssessmentDisplayLayout
        label="Exercise type, time and intensity"
        content={(
          <LifestyleAssessmentListDisplayLayout
            data={data?.physicalActivities || []}
            renderItem={(i) => <PhysicalActivityInfoComponent info={i} />}
          />
        )}
      />
    </div>
    <div className="mt40">
      <LifestyleAssessmentDisplayLayout
        label={(
          <LifestyleAssessmentTitleLayout
            title="Additional Comments"
          />
        )}
        content={(
          <DisplayOrEmptyComponent value={data?.additionalComments} />
        )}
      />
    </div>
  </div>
);
