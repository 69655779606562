import { ShouldShow, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useDeepCompareMemo } from '../../../hooks/useDeepCompareEffect';
import { CGMOptOutReasonEnum } from '../../../uc-api-sdk';

export interface CGMServiceNextScheduleSubmitValue {
  cgmOptOut: boolean;
  cgmOptOutReason?: {
    associatedEnum?: CGMOptOutReasonEnum;
    value?: string;
  }
}

export const useCGMServiceNextScheduleForm = () => {
  const factory = useFormHookFactory({
    cgmOptOut: {
      name: 'cgmOptOut',
      label: 'Patient decides to opt-out CGM service',
    },
    cgmOptOutReason: {
      name: 'cgmOptOutReason',
      label: '',
    },
  });

  const shouldShowNote = () => (
    true
  );

  const shouldShowReason: ShouldShow = (getFieldValue) => (
    getFieldValue(factory.getName('cgmOptOut'))
  );

  return useDeepCompareMemo(() => ({
    ...factory,
    shouldShowNote,
    shouldShowReason,
  }), [
    factory,
    shouldShowNote,
    shouldShowReason,
  ]);
};
