import { message } from 'antd';
import { useMemo } from 'react';
import { VisitMethodEnum, VisitTypeEnum } from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { useWorklistActions } from '../../hook/useWorklistActions';
import { WorklistComponentCommonProps } from '../../types';
import { VisitSummaryInputComponent } from '../VisitSummaryInputComponent/VisitSummaryInputComponent';
import { WorklistButtonComponent } from '../WorklistButtonComponent/WorklistButtonComponent';
import { WorklistPatientInfoComponent } from '../WorklistPatientInfoComponent/WorklistPatientInfoComponent';
import { WorklistVisitInfoComponent } from '../WorklistVisitInfoComponent/WorklistVisitInfoComponent';
import { WorklistMTPRContextProvider } from '../../../../contexts/WorklistMTPRContext/WorklistMTPRContext';
import { InitialWorklistSectionComponent } from '../InitialWorklistSectionComponent/InitialWorklistSectionComponent';
import { useNestedFormControlContext } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import { MDFollowUpWorklistSectionComponent } from '../MDFollowUpWorklistSectionComponent/MDFollowUpWorklistSectionComponent';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

import './SimplifiedWorklistComponent.scss';
import { ICDCodeRecommendationContextProvider } from '../../../../contexts/ICDCodeRecommendationContext/ICDCodeRecommendationContext';
import { ErrorContextProvider } from '../../../../contexts/ErrorContext/ErrorContext';

export interface SimplifiedWorklistComponentProps extends WorklistComponentCommonProps {
  clinicId?: string;
 }

export const SimplifiedWorklistComponent = ({
  patientId,
  clinicEvent,
  clinicId,
  onClinicEventUpdate,
  onClose,
  onCheckOut,
  onCompleteCharting,
}: SimplifiedWorklistComponentProps) => {
  const updateHook = useUpdate('WORKLIST_UPDATED').updateValue;
  const {
    getVisitSummary,
    isVisitSummaryChanged,
    setVisitSummary,
    validateVisitSummary,
    errors,
  } = useWorklistActions(clinicEvent);
  const {
    handleOnSubmit,
  } = useNestedFormControlContext();

  const getClinicEventValues = () => ({ visitSummary: getVisitSummary() });

  const handleCheckOut = () => {
    onCheckOut?.(getClinicEventValues());
  };

  const handleCompleteCharting = () => {
    if (!validateVisitSummary()) return;
    handleOnSubmit({
      submitValidForms: false,
      onSubmit: (submitSent, error) => {
        if (!submitSent && error) {
          message.error('Please fill in all required fields');
          return;
        }
        onCompleteCharting?.(getClinicEventValues());
        updateHook();
      }
    });
  };

  const handleClose = () => {
    handleOnSubmit({
      onSubmit: () => {
        if (isVisitSummaryChanged) {
          onClinicEventUpdate?.(getClinicEventValues());
        }
        updateHook();
        onClose?.();
      },
    });
  };

  const worklistSection = useMemo(() => {
    switch (clinicEvent.visitType) {
      case VisitTypeEnum.INITIAL:
        return (
          <InitialWorklistSectionComponent
            patientId={patientId}
            clinicId={clinicId}
          />
        );
      case VisitTypeEnum.POST_MD_FOLLOW_UP:
        return (
          <MDFollowUpWorklistSectionComponent
            patientId={patientId}
            clinicId={clinicId}
          />
        );
      default:
        return null;
    }
  }, [clinicEvent.visitType]);

  return (
    <div className="simplified-worklist flex fr-reverse">
      <WorklistMTPRContextProvider
        clinicEventId={clinicEvent.id || ''}
        patientId={patientId}
        visitType={clinicEvent.visitType as VisitTypeEnum}
      >
        <ICDCodeRecommendationContextProvider>
          <FixedComponent className="worklist-navigation">
            <FixedComponent.Child isFixed>
              <WorklistVisitInfoComponent
                visitType={clinicEvent.visitType as VisitTypeEnum}
                visitMethod={clinicEvent.visitMethod as VisitMethodEnum}
                description={clinicEvent.description || ''}
                startTime={clinicEvent.checkInTime || ''}
                endTime={clinicEvent.checkOutTime || ''}
                onClose={handleClose}
              />
            </FixedComponent.Child>
            <FixedComponent.Child className="worklist-drawer-content">
              <WorklistPatientInfoComponent />
              <VisitSummaryInputComponent
                defaultValue={getVisitSummary() ?? undefined}
                onChange={setVisitSummary}
                showRequiredMark={errors.VISIT_SUMMARY}
                autoSize={false}
              />
            </FixedComponent.Child>
            <FixedComponent.Child isFixed>
              <WorklistButtonComponent
                clinicEvent={clinicEvent}
                onCheckout={handleCheckOut}
                onCompleteCharting={handleCompleteCharting}
                onClose={handleClose}
              />
            </FixedComponent.Child>
          </FixedComponent>
          <div className="worklist-section">
            <ErrorContextProvider value={Object.freeze({})}>
              {worklistSection}
            </ErrorContextProvider>
          </div>
        </ICDCodeRecommendationContextProvider>
      </WorklistMTPRContextProvider>
    </div>
  );
};
