import { InputNumber } from 'antd';
import { FreqSelectComponent } from '../../../../uiComponent/FreqSelectComponent/FreqSelectComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

import './LifestyleAssessmentFreqFormItemComponent.scss';

export interface LifestyleAssessmentFreqFormItemComponentProps {
  freqName: string;
  freqUnitName?: string;
}

export const LifestyleAssessmentFreqFormItemComponent = ({
  freqName,
  freqUnitName,
}: LifestyleAssessmentFreqFormItemComponentProps) => (
  <div className="lifestyle-assessment-freq">
    <FormItem
      name={freqName}
    >
      <InputNumber
        precision={0}
        min={0}
        placeholder="Type a number"
      />
    </FormItem>
    <span className="mx10">
      /
    </span>
    <FormItem
      name={freqUnitName}
    >
      <FreqSelectComponent type="shortCap" />
    </FormItem>
  </div>
);
