import dayjs from 'dayjs';
import { useState } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientGetCgmGoalById, VitalEnumType } from '../../../../uc-api-sdk';
import { DateRangeSelectComponent } from '../../../../uiComponent/DateRangeSelectComponent/DateRangeSelectComponent';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { CGMVitalContainer } from '../../container/CGMVitalContainer/CGMVitalContainer';
import { FromDateToDateDayjs } from '../../type';
import { BGVitalComponent } from '../BGVitalComponent/BGVitalComponent';
import { BOVitalComponent } from '../BOVitalComponent/BOVitalComponent';
import { BPVitalComponent } from '../BPVitalComponent/BPVitalComponent';
import { ExerciseVitalComponent } from '../ExerciseVitalComponent/ExerciseVitalComponent';
import { HSChartTableSummaryViewComponent } from '../HSChartTableSummaryViewComponent/HSChartTableSummaryViewComponent';
import { TMVitalComponent } from '../TMVitalComponent/TMVitalComponent';
import './VitalsComponent.scss';

export interface VitalsComponentProps {
  showGenerateReportButton?: boolean;
  isEligibleForCGMReview?: boolean;
}

export const VitalsComponent = ({
  showGenerateReportButton,
  isEligibleForCGMReview
}: VitalsComponentProps) => {
  const { info } = usePatientContext();
  const height = info?.patientInfoService?.getHeightWithUnit();
  const [fromToDate, setFromToDate] = useState<FromDateToDateDayjs>({
    fromDate: dayjs().startOf('days').add(-29, 'days'),
    toDate: dayjs().endOf('days'),
  });

  const cgmGoal = info?.id
    ? usePatientGetCgmGoalById({ params: { memberId: info.id } })
    : undefined;

  if (!info || !info.enrolledProgramService) {
    return null;
  }

  const { id: memberId, enrolledProgramService } = info;

  const showVital = (vital: VitalEnumType) => enrolledProgramService.hasVital(vital);

  return (
    <div className="vitals-component">
      {
        showVital(VitalEnumType.CGM)
        && (
          <CGMVitalContainer
            patientId={memberId}
            percentGoals={cgmGoal?.data?.data?.percentGoals || undefined}
            startDate={fromToDate.fromDate}
            endDate={fromToDate.toDate}
            showGenerateReportButton={showGenerateReportButton}
            isEligibleForCGMReview={isEligibleForCGMReview}
          />
        )
      }
      <div className="vitals-date-range">
        <PatientCommonCardTitleComponent>
          Episodic Vitals Monitoring
        </PatientCommonCardTitleComponent>
        <DateRangeSelectComponent
          value={fromToDate}
          onChange={setFromToDate}
        />
      </div>
      <div className="flex gap3 fd-c">
        {
          showVital(VitalEnumType.BG)
          && (
            <BGVitalComponent
              memberId={memberId}
              fromDate={fromToDate.fromDate}
              toDate={fromToDate.toDate}
            />
          )
        }
        {
          showVital(VitalEnumType.BP)
          && (
            <BPVitalComponent
              memberId={memberId}
              fromDate={fromToDate.fromDate}
              toDate={fromToDate.toDate}
            />
          )
        }
        {
          showVital(VitalEnumType.PO)
          && (
            <BOVitalComponent
              memberId={memberId}
              fromDate={fromToDate.fromDate}
              toDate={fromToDate.toDate}
            />
          )
        }
        <HSChartTableSummaryViewComponent
          memberId={memberId}
          fromDate={fromToDate.fromDate}
          toDate={fromToDate.toDate}
          height={height}
        />
        {
          showVital(VitalEnumType.TM)
          && (
            <TMVitalComponent
              memberId={memberId}
              fromDate={fromToDate.fromDate}
              toDate={fromToDate.toDate}
            />
          )
        }
        {
          showVital(VitalEnumType.EXERCISE)
          && (
            <ExerciseVitalComponent
              memberId={memberId}
              fromDate={fromToDate.fromDate}
              toDate={fromToDate.toDate}
            />
          )
        }
      </div>
    </div>
  );
};
