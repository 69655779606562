/* eslint-disable @typescript-eslint/no-explicit-any */
import { NamePath } from 'antd/lib/form/interface';
import { useCallback } from 'react';
import { isEqual } from 'lodash';
import { FormInput, FormOptions, useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../types/form';

export const useIterableFormHook = <T extends FormInput, K extends keyof T>(
  mainFormItemName: string,
  formInput: T,
  options: FormOptions = {},
) => {
  const factory = useFormHookFactory(formInput, options);

  const getMainFormItemName = useCallback(() => mainFormItemName, [mainFormItemName]);

  const makeObjectNamePath = useCallback((
    inputKey: K,
    fieldName?: string | number,
  ): NamePath => (
    fieldName !== undefined
      ? [getMainFormItemName(), fieldName, factory.getName(inputKey)]
      : factory.getName(inputKey)
  ), [getMainFormItemName, factory.getName]);

  const makeListNamePath = useCallback((
    inputKey: K,
    fieldName?: string | number,
  ): NamePath => (
    fieldName !== undefined
      ? [fieldName, factory.getName(inputKey)]
      : factory.getName(inputKey)
  ), [getMainFormItemName, factory.getName]);

  const getObjectValue = useCallback((
    inputKey: K,
    getFieldValue: GetFieldValue,
    fieldName?: string | number,
  ) => (
    fieldName !== undefined
      ? getFieldValue(makeObjectNamePath(inputKey, fieldName))
      : getFieldValue(factory.getName(inputKey))
  ), [makeObjectNamePath, factory.getName]);

  const getListValue = useCallback((
    inputKey: K,
    getFieldValue: GetFieldValue,
    fieldName?: number,
  ) => getObjectValue(inputKey, getFieldValue, fieldName), [getObjectValue]);

  // to check whole list
  const shouldUpdateList = useCallback((prev: any, curr: any) => (
    !isEqual(
      prev[getMainFormItemName()],
      curr[getMainFormItemName()]
    )
  ), [getMainFormItemName]);

  // to check an item within list
  const shouldUpdateFromList = useCallback((
    inputKeys: K[],
    fieldName: string | number = -1,
  ) => (prev: any, curr: any) => (
    inputKeys.some((inputKey) => (
      prev[getMainFormItemName()]?.[fieldName]?.[inputKey]
      !== curr[getMainFormItemName()]?.[fieldName]?.[inputKey]
    ))
  ), [
    getMainFormItemName,
  ]);

  // to check an item within object
  const shouldUpdateFromObject = useCallback(
    shouldUpdateFromList,
    [shouldUpdateFromList]
  );

  return {
    ...factory,
    getMainFormItemName,
    makeObjectNamePath,
    makeListNamePath,
    getListValue,
    getObjectValue,
    shouldUpdateFromList,
    shouldUpdateList,
    shouldUpdateFromObject,
  };
};
