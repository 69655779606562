import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import { useIterableFormHook } from '../../../../hooks/useIterableFormListHook/useIterableFormHook';
import { MealAndSleepRoutine } from '../../../../uc-api-sdk';

export interface SubmitValue extends MealAndSleepRoutine { }

export const useMealSleepRoutineForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('mealAndSleepRoutines', {
    type: {
      name: 'type',
      label: ' ',
      placeholder: '',
    },
    startTime: {
      name: 'startTime',
      label: 'Time Range',
      placeholder: 'hh:mm',
    },
    endTime: {
      name: 'endTime',
      label: 'Time Range',
      placeholder: 'hh:mm',
    },
    foodTypeAmount: {
      name: 'foodTypeAmount',
      label: 'Type/Amount/Quality',
      placeholder: 'Enter here',
    },
    frequency: {
      name: 'frequency',
      label: 'Frequency/week',
      placeholder: 'eg. 4 times/week',
    }
  }, options);

  return useGetContextValue({
    ...factory,
  });
};
