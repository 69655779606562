import {
  Button, Form, Space, message,
} from 'antd';
import { useMemo, useState } from 'react';
import { reduce, some } from 'lodash';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { VitalsScheduleComponent, VitalsScheduleValue } from '../VitalsMonitoringScheduleComponent/VitalsScheduleComponent/VitalsScheduleComponent';
import { useVitalsMonitoringScheduleForm } from '../../hook/useVitalsMonitoringScheduleForm/useVitalsMonitoringScheduleForm';
import { VitalMonitoringLabelComponent } from './VitalMonitoringLabelComponent/VitalMonitoringLabelComponent';
import { VitalsSelectionPopupButton } from '../VitalsMonitoringScheduleComponent/VitalsSelectionComponent/VitalsSelectionPopupButton';
import './VitalsMonitoringScheduleFormComponent.scss';
import { VitalType } from '../../helper/VitalHelper';
import { VitalsMonitoringExerciseComponent } from '../VitalsMonitoringScheduleComponent/VitalsMonitoringExerciseComponent/VitalsMonitoringExerciseComponent';
import { VitalMonitoringBGInputComponent, VitalMonitoringBGInputValue } from '../VitalsMonitoringScheduleComponent/VitalsMonitoringBGComponent/VitalMonitoringBGInputComponent/VitalMonitoringBGInputComponent';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { FormType } from '../../../Input/types';

export interface SubmitValue {
  BG: VitalMonitoringBGInputValue;
  HS: VitalsScheduleValue;
  BP: VitalsScheduleValue;
  TM: VitalsScheduleValue;
  PO: VitalsScheduleValue;
  CGM: VitalsScheduleValue;
  isEditVitals: true; // always true
}

export interface VitalsMonitoringScheduleFormComponentProps
  extends FormType<SubmitValue> {
  currentVitals: VitalType[];
  initialValues?: Partial<SubmitValue>;
  allowRemoveVital?: boolean;
  autoSetDefault?: boolean;
}

export const VitalsMonitoringScheduleFormComponent = ({
  currentVitals,
  initialValues,
  autoSetDefault,
  onCancel,
  onSubmit,
  onValuesChange,
  allowRemoveVital = true,
  formButtons,
}: VitalsMonitoringScheduleFormComponentProps) => {
  // helper function to create a vital map from vital array
  const createdVitalsMap = (vitalsArr: VitalType[], oldMap?: Record<VitalType, boolean>) => (
    reduce(vitalsArr, (res, v) => {
      res[v] = true;
      return res;
    }, oldMap ? { ...oldMap } : {} as Record<VitalType, boolean>)
  );

  const [vitals, setVitals] = useState(createdVitalsMap(currentVitals));
  const vitalsFactory = useVitalsMonitoringScheduleForm();

  // If it has any vitals beside exercise
  const hasVitals = (newVitals: Record<VitalType, boolean>) => (
    some(newVitals, (v, k) => v && (k as VitalType) !== 'EXERCISE')
  );

  const canRemove = (vitalType: VitalType) => () => {
    if (!allowRemoveVital) return false;
    if (hasVitals({ ...vitals, [vitalType]: false })) {
      return true;
    }
    message.warning('Patient must have at least one vital in the care plan.');
    return false;
  };

  const handleVitalChange = (newSelectedVitals: VitalType[]) => {
    setVitals(createdVitalsMap(newSelectedVitals, vitals));
  };

  const handleRemoveVital = (vitalType: VitalType) => {
    const newMap = { ...vitals };
    newMap[vitalType] = false;
    setVitals(newMap);
  };

  const selectedVitals = useMemo(() => reduce(vitals, (res, v, k) => {
    if (v) {
      res.push(k as VitalType);
    }
    return res;
  }, [] as VitalType[]), [vitals]);

  const renderVitalsScheduleComponent = (
    type: VitalType,
    formName: Parameters<typeof vitalsFactory.getLabel>[0],
  ) => (
    (vitals[type] ? (
      <FormItem
        name={vitalsFactory.getName(formName)}
        rules={vitalsFactory.getRequiredRules()}
      >
        <VitalsScheduleComponent
          label={(
            <VitalMonitoringLabelComponent
              vitalType={type}
              isNew={!currentVitals.includes(type)}
              name={vitalsFactory.getLabel(formName) as string}
              canRemove={canRemove(type)}
              onRemove={() => handleRemoveVital(type)}
            />
          )}
        />
      </FormItem>
    ) : null)
  );

  const renderBG = () => (
    <div className="bg-vital w60">
      <VitalMonitoringLabelComponent
        vitalType={VitalEnumType.BG}
        isNew={!currentVitals.includes(VitalEnumType.BG)}
        name={vitalsFactory.getLabel(VitalEnumType.BG) as string}
        canRemove={canRemove(VitalEnumType.BG)}
        onRemove={() => handleRemoveVital(VitalEnumType.BG)}
      />
      <FormItem
        name={vitalsFactory.getName(VitalEnumType.BG)}
        rules={vitalsFactory.getBGVitalScheduleRules()}
      >
        <VitalMonitoringBGInputComponent
          autoSetDefault={autoSetDefault}
        />
      </FormItem>
    </div>
  );

  const renderCGM = () => (
    <FormItem
      name={vitalsFactory.getName(VitalEnumType.CGM)}
      preserve
      hidden
    >
      <div />
    </FormItem>
  );

  const renderExercise = () => (
    <FormItem
      name={vitalsFactory.getName(VitalEnumType.EXERCISE)}
      label={vitalsFactory.getLabel(VitalEnumType.EXERCISE)}
    >
      <VitalsMonitoringExerciseComponent />
    </FormItem>
  );

  const processedInitialValues = useMemo(() => {
    if (autoSetDefault) {
      return vitalsFactory.getVitalDefaultSchedule();
    }
    return initialValues;
  }, [autoSetDefault, initialValues]);

  return (
    <Form
      form={vitalsFactory.form}
      onFinish={vitalsFactory.handleSubmit(onSubmit)}
      initialValues={processedInitialValues}
      onValuesChange={onValuesChange}
      disabled={vitalsFactory.formDisabled}
      layout="vertical"
      className="vitals-monitoring-scheduling-form-component"
      scrollToFirstError
    >
      <div>
        <div className="sub-title mb30 display-none">
          {/* only show for SingleVitalMonitoringScheduleDrawerComponent for now */}
          Review and setup the vitals monitoring goal with the patient.
        </div>
        <FormItem
          name={vitalsFactory.getName('isEditVitals')}
          noStyle
          // eslint-disable-next-line react/jsx-boolean-value
          initialValue={true}
        >
          <div />
        </FormItem>
        <div className="flex jc-sb">
          {vitals.BG ? renderBG() : null}
          <div>
            {renderVitalsScheduleComponent(VitalEnumType.BP, VitalEnumType.BP)}
            {renderVitalsScheduleComponent(VitalEnumType.PO, VitalEnumType.PO)}
            {renderVitalsScheduleComponent(VitalEnumType.HS, VitalEnumType.HS)}
            {renderVitalsScheduleComponent(VitalEnumType.TM, VitalEnumType.TM)}
            {vitals.EXERCISE ? renderExercise() : null}
          </div>
          {vitals.CGM ? renderCGM() : null}
        </div>
      </div>
      {
        formButtons === undefined
          ? (
            <div className="flex jc-e">
              <Space>
                <VitalsSelectionPopupButton
                  excludedVitals={selectedVitals}
                  onChange={handleVitalChange}
                />
                <Button onClick={onCancel}>Cancel</Button>
                <Button htmlType="submit" type="primary">Save</Button>
              </Space>
            </div>
          ) : formButtons
      }
    </Form>
  );
};
