import { trim } from 'lodash';
import { ReactNode } from 'react';
import { REQUEST_DATE_FORMAT } from '../../../../constants/timeFormat';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  ConsentRequest,
  SignatureCollectionMethodEnum,
  usePatientUpdateConsentForm
} from '../../../../uc-api-sdk';
import { ConsentFormComponent, ConsentFormComponentProps, SubmitValue } from '../../component/ConsentFormComponent/ConsentFormComponent';
import { showConsentFormSentModal } from '../../component/ConsentFormComponent/ConsentFormSentModal';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface ConsentFormContainerProps {
  patientId: string;
  initialValues?: Partial<SubmitValue>;
  onSubmit?: (v?: SubmitValue) => void;
  title?: ReactNode;
  onOk?: () => void;
  showConsentAlert?: boolean;
  extraInfo?: ReactNode;
}

export const ConsentFormContainer = ({
  patientId,
  initialValues,
  title,
  onSubmit,
  onOk,
  showConsentAlert,
  extraInfo,
}: ConsentFormContainerProps) => {
  const consentUpdate = usePatientUpdateConsentForm({});
  const signedUpdate = useUpdate('CONSENT_FORM_SIGNED').updateValue;

  const handleSubmit = async (v: SubmitValue) => {
    let userData: ConsentRequest['userData'] | undefined;
    let signedDate: string | undefined;
    if (
      v.signatureMethod === SignatureCollectionMethodEnum.TEAM_MEMBER_DEVICE
      && trim(v.initials)
    ) {
      userData = {
        initials: trim(v.initials),
      };
    }
    if (
      v.signatureMethod === SignatureCollectionMethodEnum.CLINIC_PRINTED
      && v.signDate
    ) {
      signedDate = v.signDate.startOf('day').utc().format(REQUEST_DATE_FORMAT);
    }
    ApiRequestHelper.tryCatch(
      consentUpdate.send({
        params: {
          memberId: patientId,
          request: {
            consentType: v.signatureMethod,
            userData,
            consentContent: v.consentContent,
            signedDate,
          },
        },
      }),
      {
        success: '',
        error: 'Failed to send/submit consent form',
        onSuccess: () => {
          if (v.signatureMethod === SignatureCollectionMethodEnum.APP) {
            showConsentFormSentModal(onOk);
          }
          onSubmit?.(v);
          signedUpdate();
        }
      }
    );
  };

  const processedInitialValues: ConsentFormComponentProps['initialValues'] = {
    signatureMethod: initialValues?.signatureMethod || SignatureCollectionMethodEnum.APP,
  };

  return (
    <ConsentFormComponent
      title={title}
      onSubmit={handleSubmit}
      isLoading={consentUpdate.isLoading}
      initialValues={processedInitialValues}
      patientId={patientId}
      showConsentAlert={showConsentAlert}
      extraInfo={extraInfo}
    />
  );
};
