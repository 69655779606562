import { Button, Modal } from 'antd';
import { appTransitionFAQsLink } from '../../../device/constant';
import { SendDownloadAppMsgBtnContainer } from '../../../unifiedCareApp/container/SendDownloadAppMsgBtnContainer/SendDownloadAppMsgBtnContainer';
import './UcTransitionGuideComponent.scss';

export interface UcTransitionGuideComponentProps {
  patientId: string;
  isOpen: boolean;
  close: () => void;
}

export const UcTransitionGuideComponent = ({
  patientId,
  isOpen,
  close
}: UcTransitionGuideComponentProps) => {
  const renderUcTransitionGuideContent = () => (
    <>
      <p>
        <strong>Why is there a new app?</strong>
        <p>
          The new app provides a better experience, faster performance, and additional features to
          help patients manage their health more effectively. The old app will no longer be updated
          or supported, so moving to Unified Care+ ensures they receive full care services.
        </p>
      </p>
      <p>
        <strong>How can I help patients download the new app?</strong>
        <p>
          You can guide patients to search for “Unified Care+” on the App Store/Google Play, or send
          the direct download link to their phone number from the Plus button. Once installed,
          patients should log in with the same phone number or Unified Care ID as before. All their
          health data, logs, and rewards automatically transfer, so they can continue using the new
          app as before.
        </p>
      </p>
      <p>
        <strong>FAQs</strong>
        <p>
          For more detailed FAQs regarding the Unified Care+ transition, please visit this document
          <a
            href={appTransitionFAQsLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary ml5"
          >
            here
          </a>
          .
        </p>
      </p>
    </>
  );

  return (
    <Modal
      footer={null}
      width={570}
      open={isOpen}
      centered
      onCancel={close}
      maskClosable={false}
      title={<div className="title">Unified Care+ Transition Guide</div>}
      destroyOnClose
    >
      {renderUcTransitionGuideContent()}
      <div className="flex jc-e">
        <div className="UcTransitionGuideComponent__send-download-frame">
          <SendDownloadAppMsgBtnContainer
            patientId={patientId}
            buttonText="Send Download SMS"
          />
        </div>
        <Button type="primary" onClick={close}>
          Got it
        </Button>
      </div>
    </Modal>
  );
};
