import { EnumComponentProps } from '..';
import { CGMOptOutReasonEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export { CGMOptOutReasonEnum };

export interface CGMOptOutReasonEnumComponentProps extends
  EnumComponentProps<CGMOptOutReasonEnum, 'default'> {
}

const getText = ({
  value,
  type = 'default',
}: CGMOptOutReasonEnumComponentProps) => {
  const getDefaultText = (v?: CGMOptOutReasonEnum) => {
    switch (v) {
      case CGMOptOutReasonEnum.EXPENSIVE:
        return 'Patient thought it\'s too expensive.';
      case CGMOptOutReasonEnum.NOT_USEFUL:
        return 'Patient found it\'s not useful.';
      case CGMOptOutReasonEnum.NO_LONGER_USEFUL:
        return 'Patient found it\'s useful but do not think additional wears would add value.';
      default:
        return '';
    }
  };

  switch (type) {
    case 'default':
    default: return getDefaultText(value);
  }
};

export const CGMOptOutReasonEnumComponent = ({
  value,
  type = 'default',
}: CGMOptOutReasonEnumComponentProps) => (
  value
    ? <span>{getText({ value, type })}</span>
    : <EmptyComponent />
);

CGMOptOutReasonEnumComponent.getText = getText;
