import { ConditionEnum } from '../../../../../uc-api-sdk';
import { PatientCommonLabelComponent } from '../../../../patient/component/PatientCommonLabelComponent/PatientCommonLabelComponent';
import { ConditionEumComponent } from '../../../../../enumComponent/ConditionEumComponent/ConditionEumComponent';

interface ClinicalGoalVitalsLabelComponentProps {
  value?: ConditionEnum ;
}

export const ClinicalGoalVitalsLabelComponent = ({
  value,
} : ClinicalGoalVitalsLabelComponentProps) => (
  <PatientCommonLabelComponent>
    <ConditionEumComponent value={value} />
  </PatientCommonLabelComponent>
);
