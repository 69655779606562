import { Form } from 'antd';
import { useInsuranceForm } from '../../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { InsuranceItemComponent } from '../../InsuranceItemComponent/InsuranceItemComponent';
import { WaystarProviderSelectContainer } from '../../../container/WaystarProviderSelectContainer/WaystarProviderSelectContainer';
import { InsuranceTypeEnum } from '../../../../../uc-api-sdk';

export interface InsurancePlanFormItemComponentProps {
  fieldName?: number;
  span?: number;
}

export const InsurancePlanFormItemComponent = ({
  fieldName,
  span = 10,
}: InsurancePlanFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });
  const handleChangeProviderId = (providerId?: string) => {
    insuranceForm.form.setFieldValue(
      insuranceForm.makeObjectNamePath('insuranceProviderId', fieldName),
      providerId,
    );
  };

  const handleChangeProviderAlias = (providerAlias?: string) => {
    insuranceForm.form.setFieldValue(
      insuranceForm.makeObjectNamePath('providerAlias', fieldName),
      providerAlias,
    );
  };

  const handleChangeInsuranceType = (insuranceType?: InsuranceTypeEnum[]) => {
    insuranceForm.form.setFieldValue(
      insuranceForm.makeObjectNamePath('insuranceType', fieldName),
      insuranceType,
    );
  };

  return (
    <>
      <InsuranceItemComponent
        label={insuranceForm.getLabel('provider')}
        content={(
          <FormItem
            noStyle
            shouldUpdate
          >
            {
              ({ getFieldValue }) => {
                const providerId = insuranceForm.getObjectValue(
                  'insuranceProviderId',
                  getFieldValue,
                  fieldName
                );
                const providerAlias = insuranceForm.getObjectValue(
                  'providerAlias',
                  getFieldValue,
                  fieldName
                );
                return (
                  <FormItem
                    name={insuranceForm.makeListNamePath('provider', fieldName)}
                    required
                  >
                    <WaystarProviderSelectContainer
                      initialProviderId={providerId}
                      providerAlias={providerAlias}
                      onChangeProviderId={handleChangeProviderId}
                      onChangeProviderAlias={handleChangeProviderAlias}
                      onChangeInsuranceType={handleChangeInsuranceType}
                    />
                  </FormItem>
                );
              }
            }
          </FormItem>
        )}
        span={span}
        required
        ellipsis={false}
      />
      <FormItem
        name={insuranceForm.makeListNamePath('insuranceProviderId', fieldName)}
        shouldUpdate
        hidden
      >
        <div />
      </FormItem>
      <FormItem
        name={insuranceForm.makeListNamePath('providerAlias', fieldName)}
        shouldUpdate
        hidden
      >
        <div />
      </FormItem>
      <FormItem
        name={insuranceForm.makeListNamePath('insuranceType', fieldName)}
        shouldUpdate
        hidden
      >
        <div />
      </FormItem>
    </>
  );
};
