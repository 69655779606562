import { CgmGoal } from '../uc-api-sdk';

export class CgmService {
  private cgmGoal?: CgmGoal;

  constructor(cgmGoalData?: CgmGoal) {
    this.cgmGoal = cgmGoalData;
  }

  public getCgmGoal() {
    return this.cgmGoal;
  }
}
