import classNames from 'classnames';
import { CGMDeviceAccountLinkingStatusComponent, CGMDeviceAccountLinkingStatusComponentProps } from './CGMDeviceAccountLinkingStatusComponent';
import { CGMDeviceUnlinkAccountButtonContainer } from '../../container/CGMDeviceUnlinkAccountButtonContainer/CGMDeviceUnlinkAccountButtonContainer';
import { CGMDeviceLinkAccountButtonContainer, CGMDeviceLinkAccountButtonContainerProps } from '../../container/CGMDeviceLinkAccountButtonContainer/CGMDeviceLinkAccountButtonContainer';
import { CGMDeviceBrandLogoComponent } from '../CGMDeviceBrandLogoComponent/CGMDeviceBrandLogoComponent';
import { CgmBrandEnum, CgmDeviceStatusEnum } from '../../../../uc-api-sdk';

export interface CGMDeviceAccountLinkingComponentProps
  extends CGMDeviceLinkAccountButtonContainerProps {
  brand: CgmBrandEnum;
  linkingStatus?: CGMDeviceAccountLinkingStatusComponentProps['linkingStatus'];
  onLinkAccountClick?: CGMDeviceLinkAccountButtonContainerProps['onClick'];
  onUnlinkAccountClick?: () => void;
  onLearnMoreClick?: () => void;
}

export const CGMDeviceAccountLinkingComponent = ({
  patientId,
  brand,
  model,
  linkingStatus,
  onLinkAccountClick,
  onUnlinkAccountClick,
  onLearnMoreClick,
}: CGMDeviceAccountLinkingComponentProps) => {
  const isLinked = linkingStatus?.status === CgmDeviceStatusEnum.LINK_SUCCESS;

  return (
    brand === CgmBrandEnum.DEXCOM ? (
      <div className="p14 secondary-gray ai-c">
        To authorize the data access with
        <CGMDeviceBrandLogoComponent brand={brand} />
        please ask patient to add Unified Care Clinic Code
        {' '}
        <span className="ihealth-uc-text">ihealthuc</span>
        {' '}
        in their Dexcom App.
      </div>
    ) : (
      <div className="cgm-account-linking-content">
        <div className="p14 secondary-gray flex ai-c">
          Link patient's
          <CGMDeviceBrandLogoComponent brand={brand} />
          account to Unified Care to initiate service.
        </div>
        <CGMDeviceAccountLinkingStatusComponent
          linkingStatus={linkingStatus}
          onLearnMoreClick={onLearnMoreClick}
        />
        <div
          className={classNames({
            'cgm-account-linking-content__buttons': true,
            'cgm-linked': isLinked,
          })}
        >
          {isLinked ? (
            <CGMDeviceUnlinkAccountButtonContainer
              brand={brand}
              externalUserId={linkingStatus?.externalUserId}
              onConfirm={onUnlinkAccountClick}
            />
          ) : (
            <CGMDeviceLinkAccountButtonContainer
              patientId={patientId}
              brand={brand}
              model={model}
              onClick={onLinkAccountClick}
            />
          )}
        </div>
      </div>
    )
  );
};
