import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';

export const CgmEmptySummaryComponent = () => (
  <div className="p24 fs12 text-gray-scale-2">
    <EmptyComponent
      type="reactNode"
      text={(
        <div>
          Available once 24 hours of data collected.
        </div>
      )}
    />
  </div>
);
