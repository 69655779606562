import { MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { PatientService } from '../../../../services/PatientService';
import { BasicMeasuresComponent } from '../../../patient/component/BasicMeasuresComponent/BasicMeasuresComponent';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { WorklistSocialSupportComponent } from '../WorklistSocialSupportComponent/WorklistSocialSupportComponent';
import { PatientProfileLifestyleAssessmentComponent } from '../../../intervention/component/PatientProfileLifestyleAssessmentComponent/PatientProfileLifestyleAssessmentComponent';

import './WorklistLifestyleAssessmentComponent.scss';

export interface WorklistLifestyleAssessmentComponentProps {
  patientId: string;
  patientInfoService: PatientService;
  onSubmit?: () => void;
}

export const WorklistLifestyleAssessmentComponent = ({
  patientId,
  patientInfoService,
  onSubmit,
}: WorklistLifestyleAssessmentComponentProps) => {
  const debouncedOnSubmit = useDebounce(() => onSubmit?.(), 500, [onSubmit]);

  return (
    <div className="flex fd-c gap3 worklist-lifestyle-assessment">
      <NestedFormComponent>
        <BasicMeasuresComponent
          patientInfoService={patientInfoService}
        />
        <WorklistContentComponent>
          <PatientProfileLifestyleAssessmentComponent
            patientId={patientId}
            showEdit={false}
            isEditing
            formButtons={null}
            parent={MixpanelEventsParents.Transcribing}
          />
        </WorklistContentComponent>
      </NestedFormComponent>
      <NestedFormComponent>
        <WorklistSocialSupportComponent
          patientId={patientId}
          patientInfoService={patientInfoService}
          onSubmit={debouncedOnSubmit}
        />
      </NestedFormComponent>
    </div>
  );
};
