import { ActivityLevelEnumComponent } from '../../../../enumComponent/ActivityLevelEnumComponent/ActivityLevelEnumComponent';
import { EatingRoutineEnumComponent } from '../../../../enumComponent/EatingRoutineEnumComponent/EatingRoutineEnumComponent';
import { FoodAccessEnumComponent } from '../../../../enumComponent/FoodAccessEnumComponent/FoodAccessEnumComponent';
import { FreqEnumComponent } from '../../../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import { SleepingRoutineEnumComponent } from '../../../../enumComponent/SleepingRoutineEnumComponent/SleepingRoutineEnumComponent';
import { Lifestyle } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import { TitleContentTextComponent } from '../../../../uiComponent/TitleContentTextComponent/TitleContentTextComponent';
import { OldLifestyleSmokingOrDrinkingComponent } from './OldLifestyleSmokingOrDrinkingComponent';

export interface VersionedLifestyleComponentProps {
  lifeStyle?: Lifestyle | null;
}

// ---
// WARNING: OLD UI for version view only, don't use this component
// ---
export const VersionedLifestyleComponent = ({
  lifeStyle,
}: VersionedLifestyleComponentProps) => (
  <div className="flex fd-c mt20">
    <TitleContentTextComponent
      title="Physical Activity Level"
      content={(
        <>
          <ActivityLevelEnumComponent
            value={lifeStyle?.physicalActivity?.activityLevel ?? undefined}
          />
          {
            (lifeStyle?.physicalActivity?.activityValue
              && lifeStyle?.physicalActivity?.activityFreq)
              ? (
                <div>
                  {lifeStyle?.physicalActivity?.activityValue}
                  {' '}
                  times per
                  {' '}
                  <FreqEnumComponent
                    value={lifeStyle?.physicalActivity?.activityFreq ?? undefined}
                    type="short"
                  />
                  {lifeStyle?.physicalActivity?.activityDuration ? (
                    <>
                      , duration
                      {' '}
                      {lifeStyle?.physicalActivity?.activityDuration}
                      {' '}
                      mins
                    </>
                  ) : null}
                </div>
              ) : null
          }
        </>
      )}
    />
    <TitleContentTextComponent
      title="How often do you get 7-8 hours of sleep?"
      content={(
        <SleepingRoutineEnumComponent
          value={lifeStyle?.sleepingRoutine ?? undefined}
        />
      )}
    />
    <TitleContentTextComponent
      title="Eating Routine"
      content={(
        <EatingRoutineEnumComponent
          value={lifeStyle?.eatingRoutine ?? undefined}
        />
      )}
    />
    <TitleContentTextComponent
      title="Food Accessibility"
      content={(
        <FoodAccessEnumComponent
          value={lifeStyle?.foodAccessibility ?? undefined}
        />
      )}
    />
    <TitleContentTextComponent
      title="Does Patient Smoke?"
      content={(
        <OldLifestyleSmokingOrDrinkingComponent
          value={lifeStyle?.smokingHistory?.smokingOrDrinking ?? undefined}
          quitYear={lifeStyle?.smokingHistory?.quitYear ?? undefined}
          activityFreq={lifeStyle?.smokingHistory?.activityFreq ?? undefined}
          activityValue={lifeStyle?.smokingHistory?.activityValue ?? undefined}
          type="smoke"
        />
      )}
    />
    <TitleContentTextComponent
      title="Does Patient Drink?"
      content={(
        <OldLifestyleSmokingOrDrinkingComponent
          value={lifeStyle?.drinkingHistory?.smokingOrDrinking ?? undefined}
          quitYear={lifeStyle?.drinkingHistory?.quitYear ?? undefined}
          activityFreq={lifeStyle?.drinkingHistory?.activityFreq ?? undefined}
          activityValue={lifeStyle?.drinkingHistory?.activityValue ?? undefined}
          drinkChoice={lifeStyle?.drinkingHistory?.drinkChoice ?? undefined}
          drinkQuantity={lifeStyle?.drinkingHistory?.drinkQuantity ?? undefined}
          type="drink"
        />
      )}
    />
    <TitleContentTextComponent
      title="Typical Lifestyle Pattern"
      content={(
        <DisplayOrEmptyComponent value={lifeStyle?.lifestylePattern} />
      )}
    />
    {
      lifeStyle?.notes && lifeStyle?.notes.length !== 0 && (
        <HistoryDrawerComponent history={lifeStyle?.notes} isLifeStyle />
      )
    }
  </div>
);
