import { map } from 'lodash';
import { Input } from 'antd';
import { FormListFieldData } from 'antd/es/form';
import { LifestyleAssessmentFormListComponent } from '../../LifestyleAssessmentFormListComponent';
import { PhysicalActivityInputCardLayout } from './PhysicalActivityInputCardLayout';
import { usePhysicalActivityForm } from '../../../../hook/useILifestyleAssessmentForm/usePhysicalActivityForm';
import FormItem from '../../../../../../uiComponent/FormItem/FormItem';
import { EnumSelectComponent } from '../../../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { ExerciseLevelEnum } from '../../../../../../uc-api-sdk';

import './PhysicalActivityFormListComponent.scss';
import { TimeSelectAllDayComponent } from '../../../../../../uiComponent/TimeSelectAllDayComponent/TimeSelectAllDayComponent';

export const IAPhysicalActivityFormListComponent = () => {
  const paForm = usePhysicalActivityForm();

  const renderItem = (
    field: FormListFieldData,
    remove: (idx: number) => void,
  ) => (
    <PhysicalActivityInputCardLayout
      key={field.name}
      type={(
        <FormItem
          name={paForm.getInfo('type', field.name).name}
        >
          <Input
            placeholder="eg. Running"
          />
        </FormItem>
      )}
      timePeriod={(
        <div className="flex f-w ai-bl">
          <FormItem
            name={paForm.getInfo('startTime', field.name).name}
          >
            <TimeSelectAllDayComponent
              className="physical-activity__time-select"
              placeholder="hh:mm"
              allowClear
            />
          </FormItem>
          <div>to</div>
          <FormItem
            name={paForm.getInfo('endTime', field.name).name}
          >
            <TimeSelectAllDayComponent
              className="physical-activity__time-select"
              placeholder="hh:mm"
              allowClear
            />
          </FormItem>
        </div>
      )}
      intensity={(
        <FormItem
          name={paForm.getInfo('intensity', field.name).name}
        >
          <EnumSelectComponent
            options={map(ExerciseLevelEnum)}
            className="physical-activity__intensity-select"
            placeholder="Select"
          />
        </FormItem>
      )}
      removeAction={() => remove(field.name)}
    />
  );

  return (
    <LifestyleAssessmentFormListComponent
      formListName={paForm.getMainFormItemName()}
      className="physical-activity__form-list"
    >
      {
        ({ fields, remove }) => (
          <div className="overflow-a">
            <PhysicalActivityInputCardLayout
              type={paForm.getLabel('type')}
              timePeriod={paForm.getLabel('startTime')}
              intensity={paForm.getLabel('intensity')}
            />
            {map(fields, (field) => renderItem(field, remove))}
          </div>
        )
      }
    </LifestyleAssessmentFormListComponent>
  );
};
