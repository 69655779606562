import { useServerInfoGet } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { Title } from '../../../../uiComponent/Title/Title';

export interface AppVersionContainerProps {

}

export const AppVersionContainer = () => {
  const hook = useServerInfoGet({
    options: { sendOnMount: true }
  });

  return (
    <Title.SubTitle className="m0">
      App version:
      {' '}
      <DisplayOrEmptyComponent value={hook.data?.data?.unifiedCare?.latestIOSVersion} />
    </Title.SubTitle>
  );
};
