import { AuthorityInfo, DeviceModelEnum, VitalEnumType } from '../../uc-api-sdk';

export const permissionKeyLabelMapping: Record<keyof AuthorityInfo, string> = {
  notificationStatus: 'Notification',
  cameraStatus: 'Camera',
  audioStatus: 'Audio',
  microphoneStatus: 'Microphone',
  photoLibraryStatus: 'Photo Library',
  contactsStatus: 'Contacts',
  locationAlwaysStatus: 'Location Always',
  locationWhenInUseStatus: 'Location When In Use',
  readContactsStatus: 'Read Contacts',
  writeContactsStatus: 'Write Contacts',
  coarseLocationStatus: 'Coarse Location',
  fineLocationStatus: 'Fine Location',
  autoUpdateStatus: 'Auto Update',
  openAppCountsAfterLogin: 'Open App Counts After Login',
};

export const deviceModelWithCuffSize = [
  DeviceModelEnum.BP3L,
  DeviceModelEnum.BPM1,
  DeviceModelEnum.BPM1_HOTSPOT,
  DeviceModelEnum.PY_802_LTE,
];

export const defaultAppDeviceModel = {
  [VitalEnumType.BG]: DeviceModelEnum.BG5S,
  [VitalEnumType.BP]: DeviceModelEnum.BP3L,
  [VitalEnumType.PO]: DeviceModelEnum.PO3,
  [VitalEnumType.HS]: DeviceModelEnum.HS2S,
  [VitalEnumType.TM]: DeviceModelEnum.TS28B,
} as Record<Partial<VitalEnumType>, DeviceModelEnum>;

export const unifiedCareAppTitle = 'Unified Care App';
export const unifiedCareAppOldTitle = '[OLD] Unified Care App';
export const unifiedCarePlusAppTitle = 'Unified Care+ App';
export const unifiedCarePlusAppDownloadLink = 'https://bit.ly/2JL8f0e'; // todo
export const appTransitionFAQsLink = 'https://docs.google.com/document/d/1uIOdEXfeYRSyR0pkyWvvWXQ_SO67JblZEFq2X8lTiNs/edit?tab=t.0#heading=h.41oav3ftpjr';
