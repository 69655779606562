import { useEffect } from 'react';
import dayjs from 'dayjs';
import { DisplayDateComponent } from '../../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface GoalFormTimeComponentProps {
  value?: string;
  onChange?: (value?: string) => void;
}

export const GoalFormTimeComponent = ({
  value,
  onChange,
}: GoalFormTimeComponentProps) => {
  useEffect(() => {
    if (!value) onChange?.(dayjs().toISOString());
  }, [value, onChange]);

  return (
    <DisplayDateComponent
      value={value}
      format="USA_DATE"
    />
  );
};
