/* eslint-disable camelcase */
import {
  MessagePayload,
  MessageType,
  FileUploadMessagePayload,
  ArticleMessagePayload,
} from '../../../../services/CHSServices/types/data';
import { MessageTextComponent } from '../MessageTextComponent/MessageTextComponent';
import { MessageImageComponent } from '../MessageImageComponent/MessageImageComponent';
import { MessagePdfComponent } from '../MessagePdfComponent/MessagePdfComponent';
import { MessageArticleItemComponent } from '../MessageArticleItemComponent/MessageArticleItemComponent';
import { CHSServices } from '../../../../services/CHSServices/CHSServices';
import { MessageMeasurementItemComponent } from '../MessageMeasurementItemComponent/MessageMeasurementItemComponent';
import { MessageMeasurementsItemComponent } from '../MessageMeasurementsItemComponent/MessageMeasurementItemComponent';
import { MessageFoodLogReviewComponent } from '../MessageFoodLogReviewComponent/MessageFoodLogReviewComponent';
import { MessageSymptomLogComponent } from '../MessageSymptomLogComponent/MessageSymptomLogComponent';
import { MessageAudioComponent } from '../MessageAudioComponent/MessageAudioComponent';

export interface MessageHistoryItemComponentProps {
  messagePayload: MessagePayload;
  messageId: string;
}

export const MessageHistoryItemComponent = ({
  messagePayload,
  messageId
}: MessageHistoryItemComponentProps) => {
  const {
    type,
    userRole,
    text,
    subType,
    originalText,
    translation,
    publisher,
  } = messagePayload;
  const isPatient = CHSServices.isPatient(userRole);

  if (type === MessageType.MEASUREMENT) {
    return (
      <MessageMeasurementItemComponent
        messagePayload={messagePayload}
      />
    );
  }

  if (type === MessageType.MEASUREMENTS) {
    return (
      <MessageMeasurementsItemComponent
        messagePayload={messagePayload}
      />
    );
  }

  if (type === MessageType.FILE_UPLOAD) {
    const fileUploadMessagePayload = messagePayload as FileUploadMessagePayload;
    const { fileKey } = fileUploadMessagePayload;
    const parsedFileKey = CHSServices.parseFileKey(fileKey);
    if (parsedFileKey.isPDF) {
      return (
        <MessagePdfComponent
          text={text}
          fileKey={parsedFileKey.fileKey}
          fileKeyName={parsedFileKey.name}
        />
      );
    }

    if (parsedFileKey.isAudio) {
      return (
        <MessageAudioComponent
          fileKey={parsedFileKey.fileKey}
        />
      );
    }

    return (
      <MessageImageComponent
        text={text}
        fileKey={parsedFileKey.fileKey}
        fileKeyName={parsedFileKey.name}
      />
    );
  }

  if (type === MessageType.FOOD_LOG_REQUEST) {
    return (
      <MessageFoodLogReviewComponent messagePayload={messagePayload} />
    );
  }

  if (type === MessageType.SYMPTOM_LOG) {
    return (<MessageSymptomLogComponent messagePayload={messagePayload} />);
  }

  if (type === MessageType.MSR) {
    return (<div />);
  }

  if (type === MessageType.TEXT) {
    const {
      articleMetaData,
    } = messagePayload as ArticleMessagePayload;

    if (articleMetaData) {
      return (
        <MessageArticleItemComponent
          url={articleMetaData.url}
          name={articleMetaData.name}
          summary={articleMetaData.summary}
          thumbnailImageUrl={articleMetaData.thumbnailImageUrl}
        />
      );
    }
    const msg = {
      isPatient,
      text,
      originalText,
      subType,
      translation,
      publisher,
    };
    return (
      <MessageTextComponent
        message={msg}
        messageId={messageId}
      />
    );
  }
  return null;
};
