import { Divider, Form } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import { isEmpty } from 'lodash';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { SubmitValue as ClinicalGoalSubmitValue } from '../../../intervention/component/ClinicalGoalComponent/ClinicalGoalFormComponent/ClinicalGoalCardFormComponent';
import { ClinicalGoalTemplateFetchContainer } from '../../../intervention/container/ClinicalGoalContainer/ClinicalGoalTemplateFetchContainer';
import { MTPRNoteFormItemComponent } from '../MTPRNoteFormItemComponent/MTPRNoteFormItemComponent';

import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { mergeFormValues } from '../../../../helpers/form';
import { useAutoSave, UseAutoSaveEnum } from '../../../../hooks/useAutoSave/useAutoSave';
import { MTPRAssistButtonsComponent } from '../MTPRAssistButtonsComponent/MTPRAssistButtonsComponent';
import './MTPRFormComponent.scss';

export interface SubmitValue extends ClinicalGoalSubmitValue {
  note?: string;
}

export interface MTPRFormComponentProps
  extends NestedFormComponentChildProps<SubmitValue> {
  patientId: string;
  initialValues?: SubmitValue;
  setIsMTPRDisabled?: (isDisabled: boolean) => void;
}

export const MTPRFormComponent = ({
  patientId,
  isLoading,
  initialValues,
  onSubmit,
  onCancel,
  onValuesChange,
  formButtons,
  disabled,
  setIsMTPRDisabled,
}: MTPRFormComponentProps) => {
  const { autoSavedValue, onAutoSaveChange, removeItem } = useAutoSave<SubmitValue>(
    UseAutoSaveEnum.MTPR,
    patientId,
  );
  const { info } = usePatientContext();

  // mimic onValuesChange of 1 Form
  const handleOnValuesChange: FormProviderProps['onFormChange'] = (
    __,
    info,
  ) => {
    const { changedFields, forms } = info;
    const changedValues = {} as SubmitValue;
    const allValues = mergeFormValues<SubmitValue>(forms);
    changedFields.forEach((field) => {
      const { value, name, touched } = field;
      // touched is when user makes change
      // if there is change but not touched,
      // call explicitly setFields with touched=true and/or onValuesChange
      if (!touched) return;
      const fieldName = name.toString() as keyof SubmitValue;
      changedValues[fieldName] = value;
    });
    if (!isEmpty(changedValues)) {
      onAutoSaveChange(allValues);
      onValuesChange?.(changedValues, allValues);
    }
  };
  const handleSubmit: FormProviderProps['onFormFinish'] = (
    __,
    info,
  ) => {
    const { forms } = info;
    const allValues = mergeFormValues<SubmitValue>(forms);
    onSubmit?.(allValues);
    removeItem();
  };

  const debouncedHandleSubmit = useDebounce(
    handleSubmit,
    500,
    [handleSubmit],
    { leading: true }
  );
  return (
    <div className="mtpr-form">
      <Form.Provider
        onFormChange={handleOnValuesChange}
        onFormFinish={debouncedHandleSubmit}
      >
        <ClinicalGoalTemplateFetchContainer
          onSubmit={() => 0}
          onCancel={onCancel}
          isLoading={!!isLoading}
          initialValues={{
            clinicalGoals: autoSavedValue?.clinicalGoals || initialValues?.clinicalGoals,
          }}
          formButtons={null}
          disabled={disabled}
          clinicId={info?.patientInfo?.clinicId || undefined}
        />
        <div className="mtpr-form-fixed-bottom">
          <Divider className="mtpr-form-divider" />
          <Form
            name="mtprNote"
            layout="vertical"
            initialValues={{ note: autoSavedValue?.note ?? initialValues?.note }}
            onFinish={() => 0}
            disabled={disabled}
          >
            <MTPRNoteFormItemComponent
              required={!disabled}
            />
            <div className="flex gap3">
              <MTPRAssistButtonsComponent
                patientId={patientId}
                updatedClinicalGoals={autoSavedValue?.clinicalGoals || initialValues?.clinicalGoals}
                isMTPRDisabled={disabled}
                setIsMTPRDisabled={setIsMTPRDisabled}
              />
            </div>
            {formButtons}
          </Form>
        </div>
      </Form.Provider>
    </div>
  );
};
