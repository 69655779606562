export { DeprecatedOrganizationTypeEnum } from '../../../types/medicalOrg';

export enum EntityType {
  CareDept = 'careDept',
  CallCenterDept = 'callCenterDept',
  CareCenter = 'careCenter',
  BillingDept = 'billingDept',
  CareGroup = 'careGroup',
}

export enum ClinicalOrgStatusEnum {
  ActivationPending = 'PENDING',
  Active = 'ACTIVE',
  OnHiatus = 'HIATUS',
  ServiceEnded = 'SERVICE_ENDED',
  Terminated = 'TERMINATED',
  Deleted = 'DELETED',
}

export enum PerformancePeriodEnum {
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek',
  Recent3Months = 'recent3Months',
}
