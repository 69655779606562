import { difference, union } from 'lodash';
import { CareNoteTagEnum } from '../../uc-api-sdk';

export const patientCareNoteTags = [
  CareNoteTagEnum.LIFE_EVENTS,
  CareNoteTagEnum.MEDICATION,
  CareNoteTagEnum.ENGAGEMENT,
  CareNoteTagEnum.CLINIC_PROVIDER,
  CareNoteTagEnum.IMPORTANT,
  CareNoteTagEnum.CARE_PLAN_REVIEW,
  CareNoteTagEnum.DISCHARGE_NOTE,
  CareNoteTagEnum.OTHERS,
];

export const callLogCareNoteTags = [
  CareNoteTagEnum.CALL_LOG,
  CareNoteTagEnum.COMPLIANCE_ALERT,
  CareNoteTagEnum.MEDICAL_ALERT,
  CareNoteTagEnum.VISIT,
  CareNoteTagEnum.TASK_NOTES,
  CareNoteTagEnum.LIFE_EVENTS,
  CareNoteTagEnum.MEDICATION,
  CareNoteTagEnum.ENGAGEMENT,
  CareNoteTagEnum.CARE_PLAN_REVIEW,
];

export const NON_REMOVABLE_TAGS = difference(
  Object.values(CareNoteTagEnum),
  union(patientCareNoteTags, callLogCareNoteTags)
);
