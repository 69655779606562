import { Form, Typography } from 'antd';
import { PatientComplexity, PatientComplexityEnum } from '../../../../uc-api-sdk';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { Title } from '../../../../uiComponent/Title/Title';
import { ComplexitySelectComponent } from './ComplexitySelectComponent';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PatientComplexitySource } from './ComplexityEditComponent';

interface ComplexityModalFormValues {
  complexity: PatientComplexityEnum;
}

export interface ComplexityModalComponentProps {
  complexityLevel?: PatientComplexityEnum | null;
  onSubmit: (newComplexity: PatientComplexity) => void;
  disabled?: boolean;
}

export const ComplexityModalComponent = ({
  complexityLevel,
  onSubmit,
  disabled,
}: ComplexityModalComponentProps) => (
  <ModalButton
    buttonType="link"
    button="Edit"
    className="complexity-modal"
    disabled={disabled}
  >
    {
      ({ close }) => {
        const handleOnSubmit = (values: ComplexityModalFormValues) => {
          const { complexity } = values;
          if (complexity !== complexityLevel) {
            onSubmit({
              level: complexity,
              source: PatientComplexitySource.ManualInput,
            });
          }
          close();
        };

        return (
          <Form
            onFinish={handleOnSubmit}
            className="complexity-modal__body flex fd-c gap3 mt30"
          >
            <Title>
              Please choose patient's Condition Complexity Level
            </Title>
            <Typography.Paragraph>
              Based on your clinical judgement, if you think patient need more intensive care,
              {' '}
              you might change patient's Condition Complexity Level.
            </Typography.Paragraph>
            <FormItem
              name="complexity"
              initialValue={complexityLevel}
              required
            >
              <ComplexitySelectComponent />
            </FormItem>
            <CancelSubmitButtonsComponent
              submitText="Done"
              onCancel={close}
            />
          </Form>
        );
      }
    }
  </ModalButton>
);
