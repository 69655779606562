import moment, { Moment } from 'moment';
import DatePicker from 'antd/es/date-picker';
import { useEffect } from 'react';
import { USA_DATE } from '../../../../constants/timeFormat';
import { CptCodeUnitEnum } from '../../../../uc-api-sdk';
import { useEffectWithPrevValue } from '../../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';

type PeriodPickerValue = [Moment | null, Moment | null] | null | undefined;

export interface PriorAuthPeriodPickerProps {
  cptCodeCategory?: CptCodeUnitEnum;
  submissionDate?: Moment | null;
  value?: PeriodPickerValue;
  onChange?: (values?: PeriodPickerValue) => void;
}

export const PriorAuthPeriodPicker = ({
  cptCodeCategory,
  submissionDate,
  value,
  onChange
}: PriorAuthPeriodPickerProps) => {
  const getNewAuthPeriod = (
    authStart?: Moment | null,
  ) => {
    const duration = (() => {
      switch (cptCodeCategory) {
        case CptCodeUnitEnum.THREE_MONTH_UNITS:
          return 3;
        case CptCodeUnitEnum.SIX_MONTH_UNITS:
          return 6;
        default:
          return null;
      }
    })();
    if (!duration || !authStart) return null;
    const newAuthEnd = moment(authStart?.toDate())
      .clone()
      .startOf('day')
      .add(duration, 'months')
      .add(-1, 'day');
    return [authStart, newAuthEnd.clone()] as [Moment, Moment];
  };

  const handleOnChange = (values: PeriodPickerValue) => {
    const endDateChanged = (
      value?.[1]?.format(USA_DATE) !== values?.[1]?.format(USA_DATE)
    );
    const newAuthPeriod = getNewAuthPeriod(values?.[0]);
    if (!endDateChanged && newAuthPeriod) {
      onChange?.(newAuthPeriod);
    } else {
      onChange?.(values);
    }
  };

  useEffect(() => {
    handleOnChange(value);
  }, [cptCodeCategory]);

  useEffectWithPrevValue(submissionDate, (prev) => {
    if (prev === undefined || !submissionDate) {
      return;
    }
    handleOnChange([submissionDate, value?.[1] || null]);
  });

  return (
    <DatePicker.RangePicker
      format={USA_DATE}
      placeholder={[USA_DATE, USA_DATE]}
      value={value}
      onCalendarChange={handleOnChange}
    />
  );
};
