import { DeleteOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { ReactNode } from 'react';

import './PhysicalActivityInputCardLayout.scss';

export interface PhysicalActivityInputCardLayoutProps {
  type: ReactNode;
  timePeriod: ReactNode;
  intensity: ReactNode;
  removable?: boolean;
  removeAction?: () => void;
}

export const PhysicalActivityInputCardLayout = ({
  type,
  timePeriod,
  intensity,
  removable = true,
  removeAction,
}: PhysicalActivityInputCardLayoutProps) => (
  <Row className="physical-activity__row mb5" gutter={[30, 10]}>
    <Col className="physical-activity__type">
      {type}
    </Col>
    <Col className="physical-activity__time-period">
      {timePeriod}
    </Col>
    <Col className="physical-activity__intensity">
      {intensity}
      {
        (removable && removeAction)
        && (
          <DeleteOutlined
            onClick={removeAction}
          />
        )
      }
    </Col>
  </Row>
);
