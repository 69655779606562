import { Form, FormListFieldData } from 'antd';
import { ValidateFields } from 'rc-field-form/lib/interface';
import { useEffect } from 'react';
import { InsuranceEligibilityStatusFormItemComponent } from './InsuranceCardFormItemComponent/InsuranceEligibilityStatusFormItemComponent';
import { InsuranceRelationshipToInsuredFormItemComponent } from './InsuranceCardFormItemComponent/InsuranceRelationshipToInsuredFormItemComponent';
import { InsurancePolicyNumberFormItemComponent } from './InsuranceCardFormItemComponent/InsurancePolicyNumberFormItemComponent';
import { InsuranceOrderOfBenefitFormItemComponent } from './InsuranceCardFormItemComponent/InsuranceOrderOfBenefitFormItemComponent';
import { InsurancePlanFormItemComponent } from './InsuranceCardFormItemComponent/InsurancePlanFormItemComponent';
import { FormType } from '../../../Input/types';
import { InsuranceCardSubmitValue, InsuranceSubmitValue, useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useInsuranceFormContext } from '../../context/InsuranceFormContext';
import { PriorAuthFormItemComponent } from '../PriorAuthFormItemComponent/PriorAuthFormItemComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { InsuranceSubmitCancelFormItemComponent } from '../InsuranceSubmitCancelFormItemComponent/InsuranceSubmitCancelFormItemComponent';
import { EligibilityStatusEnum } from '../../../../uc-api-sdk';

export interface InsuranceCardFormItemComponentProps extends FormType<InsuranceSubmitValue> {
  field: FormListFieldData;
  patientId?: string;
}

export const InsuranceCardFormItemComponent = ({
  field,
  patientId = '',
  onSubmit,
  onCancel,
}: InsuranceCardFormItemComponentProps) => {
  const {
    shouldShowPA,
  } = useInsuranceFormContext();
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });
  const cardChanges = Form.useWatch([insuranceForm.getMainFormItemName(), field.name]);

  const handleSubmit = useDebounce(async (
    insurance: InsuranceSubmitValue,
    validateFields: ValidateFields
  ) => {
    try {
      await validateFields();
      onSubmit?.(insurance);
    } catch (e) {
      // ignore
    }
  });

  useEffect(() => {
    if (insuranceForm.isSelfPay(cardChanges)) {
      const fieldName = field.name;
      insuranceForm.form.setFieldValue(
        insuranceForm.makeObjectNamePath('eligibilityStatus', fieldName),
        EligibilityStatusEnum.INACTIVE,
      );
      // reset report id
      insuranceForm.form.setFieldValue(
        insuranceForm.makeObjectNamePath('wayStarInquiryId', fieldName),
        undefined
      );
    }
  }, [
    (cardChanges as InsuranceCardSubmitValue)?.provider,
    (cardChanges as InsuranceCardSubmitValue)?.insuranceProviderId,
  ]);

  return (
    <>
      <InsurancePlanFormItemComponent
        fieldName={field.name}
      />
      <div className="flex gap3 mt20">
        <InsuranceOrderOfBenefitFormItemComponent
          fieldName={field.name}
        />
        <InsurancePolicyNumberFormItemComponent
          fieldName={field.name}
        />
        <InsuranceRelationshipToInsuredFormItemComponent
          fieldName={field.name}
        />
        <InsuranceEligibilityStatusFormItemComponent
          fieldName={field.name}
        />
      </div>
      <FormItem
        noStyle
        shouldUpdate
      >
        {
          ({ getFieldValue }) => {
            const insuranceId = insuranceForm.getObjectValue(
              'insuranceId',
              getFieldValue,
              field.name
            );
            const provider = insuranceForm.getObjectValue(
              'provider',
              getFieldValue,
              field.name
            );
            const providerId = insuranceForm.getObjectValue(
              'insuranceProviderId',
              getFieldValue,
              field.name
            );
            const policyNumber = insuranceForm.getObjectValue(
              'policyNumber',
              getFieldValue,
              field.name
            );
            const benefitOrder = insuranceForm.getObjectValue(
              'benefitOrder',
              getFieldValue,
              field.name
            );
            const showPA = shouldShowPA(benefitOrder);
            if (!showPA) {
              return null;
            }
            return (
              <div className="mt20">
                <PriorAuthFormItemComponent
                  patientId={patientId}
                  insuranceId={insuranceId}
                  primaryProvider={provider}
                  primaryProviderId={providerId}
                  primaryPolicy={policyNumber}
                  fieldName={field.name}
                />
              </div>
            );
          }
        }
      </FormItem>
      <div className="flex gap2 jc-e mt20">
        <FormItem noStyle shouldUpdate>
          {
            ({ getFieldsValue, validateFields }) => {
              const submitValues = getFieldsValue(true) as InsuranceSubmitValue;
              return (
                <InsuranceSubmitCancelFormItemComponent
                  fieldName={field.name}
                  onCancel={onCancel}
                  onSubmit={() => handleSubmit(submitValues, validateFields)}
                  submitText="Save"
                />
              );
            }
          }
        </FormItem>
      </div>
    </>
  );
};
