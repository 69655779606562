import { message } from 'antd';
import { FC, useEffect } from 'react';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { useBoolean } from '../../../../hooks/useBoolean/useBoolean';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { useFoodLogAddComment, useFoodLogRate } from '../../../../uc-api-sdk';
import { FoodLogProviderFormComponent, FoodLogProviderFormComponentProps, SubmitValue } from '../../component/FoodLogProviderFormComponent/FoodLogProviderFormComponent';

export interface GlucoseLevels {
  beforeMeal?: number;
  afterMeal?: number;
  beforeMealTime?: string;
  afterMealTime?: string;
}

export interface FoodLogProviderFormContainerProps extends FoodLogProviderFormComponentProps {
  id: string;
  showGlucoseResults?: boolean;
  glucoseLevels?: GlucoseLevels;
  refetch: () => void;
  activeKey: string;
  index: string;
}

export const FoodLogProviderFormContainer: FC<FoodLogProviderFormContainerProps> = ({
  id,
  initialValues,
  providerComments,
  showGlucoseResults,
  glucoseLevels,
  onSubmit,
  onCancel,
  refetch,
  activeKey,
  index,
}) => {
  const { value: isLoading, setTrue, setFalse } = useBoolean();
  const submitFoodLogRating = useFoodLogRate({});
  const submitFoodLogComment = useFoodLogAddComment({});
  const { send: sendMixpanel } = useMixpanelContext();
  const { info } = usePatientContext();
  const submitHook = useUpdate('FOOD_LOG_SUBMITTED');

  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.FoodLogStart,
    eventEnd: MixpanelEvents.FoodLogEnd,
    parent: MixpanelEventsParents.PatientProfile,
    patientId: info?.id,
    properties: {
      foodLogId: id,
    },
  });

  useEffect(() => {
    if (index === activeKey) {
      startEvent();
    }
  }, [activeKey]);

  const handleSubmit = async (value: SubmitValue) => {
    setTrue();
    sendMixpanel({ event: MixpanelEvents.PatientFoodLogSubmitReview });
    const isRating = initialValues.rate === undefined;
    // submit rating
    if (isRating) {
      await submitFoodLogRating.send({
        params: {
          foodLogId: id,
          foodLogRatingInput: {
            star: value.rate,
          },
        },
      });
    }
    // submit comment
    await submitFoodLogComment.send({
      params: {
        foodLogId: id,
        foodLogCommentInput: {
          originalText: value.note.originalText,
          translatedText: value.note.translatedText,
          text: value.note.translatedText || value.note.originalText,
        },
      },
    });
    await refetch();
    message.success('Reviewed the food diary successfully!');
    endEvent();
    onSubmit?.(value);
    submitHook.updateValue();
    setFalse();
  };

  return (
    <FoodLogProviderFormComponent
      initialValues={initialValues}
      glucoseLevels={glucoseLevels}
      providerComments={providerComments}
      showGlucoseResults={showGlucoseResults}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isLoading={isLoading}
    />
  );
};
