import { Button, Divider, Form } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { Dayjs } from 'dayjs';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { CgmReviewTipComponent } from '../../../../uiComponent/CgmReviewTipComponent/CgmReviewTipComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { CgmGoalContainer } from '../../../intervention/container/CgmGoalContainer/CgmGoalContainer';
import { ClinicalGoalContainer } from '../../../intervention/container/ClinicalGoalContainer/ClinicalGoalContainer';
import { MTPRDataDateComponent } from '../../../mtpr/component/MTPRDataDateComponent/MTPRDataDateComponent';
import { OutstandingCgmSummaryComponent } from '../../../mtpr/component/OutstandingCgmSummaryComponent/OutstandingCgmSummaryComponent';

import './OutstandingCGMReviewFormComponent.scss';

export interface CGMReviewSubmitValue {
  cgmReviewNote: string;
  endTime?: Dayjs;
  startTime?: Dayjs;
}

export interface OutstandingCGMReviewFormComponentProps {
  patientId: string;
  isLoading?: boolean;
  onSubmit?: (v: CGMReviewSubmitValue) => void;
  endTime?: Dayjs;
  startTime?: Dayjs;
}

export const OutstandingCGMReviewFormComponent = ({
  patientId,
  isLoading,
  onSubmit,
  startTime,
  endTime
}: OutstandingCGMReviewFormComponentProps) => {
  const [form] = useForm();
  const cgmFormName = 'cgmReviewForm';

  const onFormFinish: FormProviderProps['onFormFinish'] = (name, { forms }) => {
    if (name === cgmFormName) {
      Object.entries(forms).forEach((f) => {
        const formInstance = f[1];
        if (f[0] !== cgmFormName) {
          formInstance.submit();
        } else {
          onSubmit?.({ ...formInstance.getFieldsValue(), startTime, endTime });
        }
      });
    }
  };

  const debouncedHandleSubmit = useDebounce(
    onFormFinish,
    500,
    [onFormFinish],
    { leading: true }
  );

  return (
    <LoadingOverlayComponent
      isLoading={isLoading}
    >
      <div className="OutstandingCGMReviewFormComponent outstanding-mtpr flex gap1 fd-c">
        <div className="flex gap1 fd-c">
          <TextComponent size="large" bold>
            CGM Review
          </TextComponent>
          <TextComponent size="medium" color="gray2">
            <MTPRDataDateComponent
              startTime={startTime}
              endTime={endTime}
            />
          </TextComponent>
          <CgmReviewTipComponent />
          <Divider className="my12" />
          <Form.Provider onFormFinish={debouncedHandleSubmit}>
            <OutstandingCgmSummaryComponent
              startTime={startTime}
              endTime={endTime}
              memberId={patientId}
            />
            <CgmGoalContainer
              patientId={patientId}
              isEditing
              showEdit={false}
              formButtons={null}
              includeNote={false}
              showUpdateInfo={false}
              showTitle={false}
              isLoading={isLoading}
            />
            <Divider className="my12" />
            <ClinicalGoalContainer
              patientId={patientId}
              isEditing
              showEdit={false}
              formButtons={null}
              showUpdateInfo={false}
              showTitle={false}
            />
            <div className="mtpr-form-fixed-bottom">
              <Form
                name={cgmFormName}
                form={form}
                disabled={isLoading}
              >
                <FormItem
                  name="cgmReviewNote"
                  required
                >
                  <TextArea autoSize={{ minRows: 5 }} />
                </FormItem>
                <div className="form-cta flex jc-e">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Submit and Generate Report
                  </Button>
                </div>
              </Form>
            </div>
          </Form.Provider>
        </div>
      </div>
    </LoadingOverlayComponent>
  );
};
