import { RightOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Row
} from 'antd';
import {
  compact,
  difference,
  filter,
  find,
  isEqual,
  map,
  uniq
} from 'lodash';
import {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { scrollToFirstError } from '../../../../helpers/error/scrollToFirstError';
import { InsuranceSubmitValue } from '../../../../hooks/formHook/useInsuranceForm';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useDeepCompareEffectWithPrevValue } from '../../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';
import { useRefState } from '../../../../hooks/useRefState/useRefState';
import {
  AddOnServiceEnum, HealthConditionItem,
  PatientComplexity, PatientDiagnosesEnum,
  ProgramCategoryEnum,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { ClinicSelectComponent } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { ProviderSelectContainer, ProviderSelectContainerProps } from '../../../../uiComponent/Transcribing/component/ProviderSelectContainer/ProviderSelectContainer';
import { ConsentFormStatusBarComponent } from '../../../consentForm/component/ConsentFormStatusBarComponent/ConsentFormStatusBarComponent';
import { getProtocolFromDiagnoses } from '../../../ICDCode/helper';
import { FormType } from '../../../Input/types';
import { InsuranceFormContainer, InsuranceFormContainerProps } from '../../../Insurance/container/InsuranceFormContainer/InsuranceFormContainer';
import { useInsuranceBlockPrompt } from '../../../Insurance/hook/useInsuranceBlockPrompt';
import { PotentialPatientInfoComponent } from '../../../patient/component/PotentialPatientInfoComponent/PotentialPatientInfoComponent';
import { DiagnosisIcdCodeFormContainer } from '../../../patient/container/DiagnosisIcdCodeFormContainer/DiagnosisIcdCodeFormContainer';
import { useProgramEnrollmentForm } from '../../hook/useProgramEnrollmentForm/useProgramEnrollmentForm';
import { EnrollmentBasicInfoComponent } from '../EnrollmentBasicInfoComponent/EnrollmentBasicInfoComponent';
import { HaveSmartPhoneRadioComponent } from '../HaveSmartPhoneRadioComponent/HaveSmartPhoneRadioComponent';
import { IntroduceUnifiedCareBtnComponent } from '../IntroduceUnifiedCareBtnComponent/IntroduceUnifiedCareBtnComponent';
import { PatientVitalsAndProgramsComponent } from '../PatientVitalsAndProgramsComponent/PatientVitalsAndProgramsComponent';
import { RejectedAlertComponent } from '../RejectedAlertComponent/RejectedAlertComponent';

export interface SubmitValue extends InsuranceSubmitValue {
  diagnoses?: PatientDiagnosesEnum[];
  icdTable?: HealthConditionItem[] | null;
  complexity?: PatientComplexity | null;
  vitals?: VitalEnumType[];
  programs?: ProgramCategoryEnum[];
  notJoiningReason?: string;
  provider?: string;
  spokenLanguage?: string[];
  username?: string;
  mobilePhoneNum?: string;
  homePhoneNum?: string;
  countryCode?: string;
  appOTPLogin?: boolean;
  hasSmartPhone?: boolean;
  clinicId?: string;
  addOnServices?: AddOnServiceEnum[];
}

export interface ProgramEnrollmentFormComponentBasePros {
  patientInfo: PatientInfo;
}

export interface ProgramEnrollmentFormComponentProps extends
  Omit<FormType<SubmitValue>, 'onValuesChange'>,
  ProgramEnrollmentFormComponentBasePros {
  patientId: string;
  onValuesChange?: (
    changedValues: Partial<SubmitValue>,
    allValues?: SubmitValue
  ) => void;
}

export const ProgramEnrollmentFormComponent = ({
  patientId,
  initialValues,
  onSubmit,
  onValuesChange,
  patientInfo,
  isLoading,
}: ProgramEnrollmentFormComponentProps) => {
  const [
    inProgress,
    setInProgress,
  ] = useState<boolean>(false);
  const [
    eligiblePrograms,
    setEligiblePrograms,
  ] = useState<ProgramCategoryEnum[]>(initialValues?.programs || []);
  const {
    value: valid,
    setTrue,
    setFalse
  } = useBoolean(true);
  const updateInsurance = useUpdate('INSURANCE_UPDATED').updateValue;
  const { isProvider } = useLoggedInUserFromContext();
  const peForm = useProgramEnrollmentForm();
  const { form } = peForm;

  const {
    medicalOrganizationService,
    isLoading: isLoadingMedicalOrganization,
    fetchMedicalOrganization,
  } = usePatientMedicalOrganizationContext();
  const { medicalOrgAvailablePrograms, medicalOrgAddOnServices } = useMemo(() => ({
    medicalOrgAvailablePrograms: medicalOrganizationService?.availablePrograms(),
    medicalOrgAddOnServices: medicalOrganizationService?.addOnServices(),
  }), [isLoadingMedicalOrganization]);
  const [
    getInitialACPMCheck,
    setInitialAPCMCheck,
  ] = useRefState(false);

  const isPatientEnrolled = patientInfo.enrollmentService.isEnrolled();
  const isPatientRejected = patientInfo.enrollmentService.isRejected();
  // The current correct status is obtained from enrolledProgramService.
  // The patientInfo.enrollmentService is solely utilized for flow of patient enrollment.
  // The status is specifically confined to the Enrollment page.
  const isDischarged = patientInfo.enrolledProgramService?.isDischarged?.();
  const isEnrolled = patientInfo.enrolledProgramService?.isEnrolled?.();
  const associatedList = patientInfo.patientInfoService?.patientAssociatedClinics;
  const isAPCMEligible = !!patientInfo.patientInsurance?.markMedCareEligible;

  const {
    isInsuranceEdit,
    setIsInsuranceEdit,
    insuranceBlockPrompt,
  } = useInsuranceBlockPrompt();
  const debouncedInsuranceRefetch = useDebounce(() => {
    patientInfo.refetch(['patientInsuranceRefetch']);
  }, 100, [patientInfo?.refetch]);
  useUpdateListener('INSURANCE_UPDATED', debouncedInsuranceRefetch);

  const updateEligiblePrograms = useDebounce(async (
    diagnoses?: PatientDiagnosesEnum[],
    initialValues?: SubmitValue, // compare update on initial render
    skipRecommendation?: boolean,
  ) => {
    try {
      if (skipRecommendation) {
        setEligiblePrograms(medicalOrgAvailablePrograms || []);
        return;
      }
      setInProgress(true);
      if (diagnoses) {
        const { vitals = [], programs = [] } = await peForm.getPrefilledValues(
          diagnoses,
          medicalOrgAvailablePrograms || []
        );
        setEligiblePrograms((prev) => {
          if (prev.includes(ProgramCategoryEnum.APCM)) {
            return uniq([ProgramCategoryEnum.APCM, ...programs]);
          }
          return programs;
        });
        if (
          // prevent unnecessary update on initial render
          !initialValues
          || difference(vitals, initialValues.vitals || []).length
          || difference(programs, initialValues.programs || []).length
        ) {
          const currentPrograms = peForm.getValue('programs', form.getFieldValue) || [];
          let newPrograms = compact([...programs]) as ProgramCategoryEnum[];
          if (currentPrograms?.includes(ProgramCategoryEnum.APCM)) {
            // APCM has higher priority than CCM, can be changed manually
            newPrograms = newPrograms.filter((p) => p !== ProgramCategoryEnum.CCM);
            newPrograms = uniq([ProgramCategoryEnum.APCM, ...newPrograms]);
          }

          const currentVitals = peForm.getValue('vitals', form.getFieldValue) || [];
          let newVitals = compact([...vitals]) as VitalEnumType[];
          if (currentVitals?.includes(VitalEnumType.CGM)) {
            newVitals = uniq([VitalEnumType.CGM, ...newVitals]);
          }
          form.setFieldsValue({
            vitals: newVitals,
            programs: newPrograms,
          });
          onValuesChange?.({ vitals, programs: newPrograms });
        }
      }
    } catch (e) {
      // ignore
    } finally {
      setInProgress(false);
    }
  }, 200, [medicalOrgAvailablePrograms]);

  const onFormChange: FormType<SubmitValue>['onValuesChange'] = async (changedValues, allValues) => {
    if (peForm.getName('diagnoses') in changedValues) {
      updateEligiblePrograms(allValues.diagnoses);
    }
    if (peForm.getName('clinicId') in changedValues) {
      fetchMedicalOrganization(allValues.clinicId);
    }
    if (!allValues.vitals?.includes(VitalEnumType.CGM)) {
      form.setFieldsValue({
        addOnServices: allValues.addOnServices
          ?.filter((service) => service !== AddOnServiceEnum.CGM),
      });
    }
    onValuesChange?.(changedValues, allValues);
  };

  const handleFinishFailed: FormProps['onFinishFailed'] = ({ errorFields }) => {
    if (errorFields.length > 0) {
      scrollToFirstError(`[data-name="${errorFields[0].name[0]}"]`);
    }
  };

  const handleSubmit: FormProps['onFinish'] = (
    v: SubmitValue
  ) => {
    if (isInsuranceEdit) {
      insuranceBlockPrompt();
      return;
    }
    onSubmit?.(v);
  };

  const handleSubmitInsurance: InsuranceFormContainerProps['onSubmit'] = () => {
    debouncedInsuranceRefetch();
  };

  const handleConsentFormSubmit = () => {
    updateInsurance();
    patientInfo?.refetch(undefined, ['patientInsuranceRefetch']);
  };

  useDeepCompareEffectWithPrevValue(medicalOrgAvailablePrograms, (prev) => {
    if (
      !medicalOrgAvailablePrograms
      || isEqual(medicalOrgAvailablePrograms, prev)
    ) {
      return;
    }
    if (!prev) {
      // update eligible on mount after initial render
      updateEligiblePrograms(
        initialValues?.diagnoses,
        initialValues,
        isEnrolled, // skip recommended vitals and programs if patient is already enrolled
      );
      return;
    }
    // clinic changed from selection
    const currentDiagnoses = peForm.getValue('diagnoses', form.getFieldValue);
    updateEligiblePrograms(currentDiagnoses);
  });

  const handleOnProviderListLoaded: ProviderSelectContainerProps['onProviderListLoaded'] = (
    providerList,
  ) => {
    const clinicId = peForm.getValue('clinicId', peForm.form.getFieldValue);
    const currentProvider = peForm.getValue('provider', peForm.form.getFieldValue);
    const providerListIds = map(providerList, 'id');
    const associatedProviderId = find(associatedList, { clinicId })?.doctorId;
    // if provider list for new selected clinic includes the selected provider,
    // then keep selection
    // else select provider from associated list, if applicable
    // else select the first provider from the list
    if (!providerListIds.includes(currentProvider)) {
      let newProviderId = providerListIds[0] ?? undefined;
      if (associatedProviderId && providerListIds.includes(associatedProviderId)) {
        newProviderId = associatedProviderId;
      }
      form.setFieldValue(peForm.getName('provider'), newProviderId);
    }
  };

  useEffect(() => {
    if (!medicalOrgAvailablePrograms?.includes(ProgramCategoryEnum.APCM)) {
      return;
    }
    const shouldUpdatePrograms = !isEnrolled || getInitialACPMCheck();
    setInitialAPCMCheck(true);
    setEligiblePrograms((prev) => {
      const selectedPrograms = peForm.getValue('programs', form.getFieldValue) || [];
      let newSelectedPrograms = [...selectedPrograms];
      let newEligiblePrograms = [...prev];

      if (isAPCMEligible) {
        newEligiblePrograms = uniq([...prev, ProgramCategoryEnum.APCM]);
        newSelectedPrograms = filter(
          newSelectedPrograms,
          (p) => p !== ProgramCategoryEnum.CCM
        );
        newSelectedPrograms.push(ProgramCategoryEnum.APCM);
        if (prev.includes(ProgramCategoryEnum.RPM)) {
          // auto-select RPM when APCM is selected; no vice versa
          newSelectedPrograms.push(ProgramCategoryEnum.RPM);
        }
      } else {
        newEligiblePrograms = filter(
          prev,
          (p) => p !== ProgramCategoryEnum.APCM
        );
        newSelectedPrograms = filter(
          newSelectedPrograms,
          (p) => p && p !== ProgramCategoryEnum.APCM
        );
        // auto-select CCM, RPM if it's eligible
        [ProgramCategoryEnum.CCM, ProgramCategoryEnum.RPM].forEach((p) => {
          if (prev.includes(p)) newSelectedPrograms.push(p);
        });
      }

      newSelectedPrograms = uniq(newSelectedPrograms);
      // update program selection
      if (shouldUpdatePrograms && !isEqual(selectedPrograms, newSelectedPrograms)) {
        form.setFieldsValue({
          programs: newSelectedPrograms,
        });
      }
      return newEligiblePrograms;
    });
  }, [patientInfo.patientInsurance?.markMedCareEligible, medicalOrgAvailablePrograms]);

  useEffect(() => {
    const currentPrograms = peForm.getValue('programs', form.getFieldValue) || [];
    if (
      initialValues
      && difference(initialValues.programs, currentPrograms).length
    ) {
      peForm.form.resetFields(['programs']);
    }
  }, [initialValues]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={onFormChange}
      onFinish={handleSubmit}
      onFinishFailed={handleFinishFailed}
      disabled={isLoading}
      layout="vertical"
      className="flex h100"
    >
      <FixedComponent className="f1">
        <FixedComponent.Child>
          <div className="mr20">
            {
              (!isDischarged && isPatientRejected)
                ? (
                  <div className="mb20">
                    <RejectedAlertComponent />
                  </div>
                ) : null
            }
            <div className="mb20">
              <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['programs', 'vitals'])}>
                {() => (
                  <ConsentFormStatusBarComponent
                    patientInfo={patientInfo}
                    onSubmit={handleConsentFormSubmit}
                  />
                )}
              </FormItem>
            </div>
            <div className="ta-r">
              <IntroduceUnifiedCareBtnComponent />
            </div>
            <div className="mb20">
              <TextComponent
                className="mb5"
                size="large"
                bold
              >
                Basic Information
              </TextComponent>
              <Card>
                <EnrollmentBasicInfoComponent
                  form={form}
                  patientId={patientId}
                  setValid={setTrue}
                  setInvalid={setFalse}
                />
                <FormItem
                  required
                  info={peForm.getInfo('hasSmartPhone')}
                  className="my15"
                >
                  <HaveSmartPhoneRadioComponent />
                </FormItem>
                <Row
                  gutter={10}
                  className="confirm-patient-clinic"
                >
                  <FormItem
                    noStyle
                    shouldUpdate={peForm.shouldUpdate(['clinicId', 'provider'])}
                  >
                    {
                      ({ getFieldValue }) => {
                        const clinicId = peForm.getValue('clinicId', getFieldValue);
                        return (
                          <>
                            <Col span={12}>
                              <FormItem
                                info={peForm.getInfo('clinicId')}
                                required
                              >
                                <ClinicSelectComponent
                                  getAllClinics={!isProvider}
                                  disabled={isEnrolled}
                                  paginatedSearch={false}
                                />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem
                                info={peForm.getInfo('provider')}
                                required
                              >
                                <ProviderSelectContainer
                                  clinicIds={clinicId ? [clinicId] : undefined}
                                  onProviderListLoaded={handleOnProviderListLoaded}
                                />
                              </FormItem>
                            </Col>
                          </>
                        );
                      }
                    }
                  </FormItem>
                  <Col span={24} className="mt5">
                    <PotentialPatientInfoComponent
                      associatedList={associatedList}
                    />
                  </Col>
                </Row>
              </Card>
            </div>
            <div className="mb20">
              <TextComponent
                className="mb5"
                size="large"
                bold
              >
                Insurance & Eligibility
              </TextComponent>
              <Card>
                <InsuranceFormContainer
                  patientInfo={patientInfo.patientInfo || {}}
                  onEdit={setIsInsuranceEdit}
                  onSubmit={handleSubmitInsurance}
                />
              </Card>
            </div>
            <div className="mb20">
              <TextComponent
                className="mb5"
                size="large"
                bold
              >
                Diagnosis / ICD code
              </TextComponent>
              <Card>
                <DiagnosisIcdCodeFormContainer
                  form={form}
                  initialValues={{
                    icdTable: initialValues?.icdTable ?? undefined,
                  }}
                  patientId={patientId}
                  formButtons={null}
                />
              </Card>
            </div>

            <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['diagnoses', 'vitals', 'programs'])}>
              {({ getFieldValue }) => {
                const show = peForm.shouldVitalsAndPrograms(getFieldValue);
                const diagnoses = peForm.getValue('diagnoses', getFieldValue);
                return (
                  show
                    ? (
                      <PatientVitalsAndProgramsComponent
                        clinicProgramParticipation={medicalOrgAvailablePrograms}
                        addOnServices={medicalOrgAddOnServices}
                        isSaasModel={medicalOrganizationService?.isSaasModel}
                        eligiblePrograms={eligiblePrograms}
                        recommendedProtocol={getProtocolFromDiagnoses(diagnoses)}
                        isAPCMEligible={isAPCMEligible}
                      />
                    ) : null
                );
              }}
            </FormItem>
          </div>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed className="pt30">
          <FormItem
            noStyle
            shouldUpdate={peForm.shouldUpdate(['programs'])}
          >
            {({ getFieldValue }) => {
              const isProgramShow = peForm.shouldVitalsAndPrograms(getFieldValue);
              const programs = peForm.getValue('programs', getFieldValue) || [];
              if (isPatientEnrolled) {
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={(
                      !isProgramShow
                      || programs?.length === 0
                      || !valid
                      || inProgress
                    )}
                    loading={inProgress}
                  >
                    Submit
                  </Button>
                );
              }

              return (
                <div className="flex jc-c ali-c">
                  <Button
                    className="input-med"
                    type="primary"
                    htmlType="submit"
                    disabled={programs?.length === 0 || !valid}
                  >
                    Enroll Patient
                    {' '}
                    <RightOutlined />
                  </Button>
                </div>
              );
            }}
          </FormItem>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
