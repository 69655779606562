import { logoutCCP } from '../../../contexts/CallCenterContext/services/CallCenterServicesContext';
import { LocalKeyEnum } from '../../../hooks/useSessionStorage/storageKey';
import { useAuthLogout } from '../../../uc-api-sdk';

interface LogoutOptions {
  notifyServer?: boolean;
  redirectUrl?: string | null;
}

export const useLogout = () => {
  const logoutInfo = useAuthLogout({ options: { sendOnMount: false, retry: 0 } });

  const onLogout = async ({
    notifyServer,
    redirectUrl = '/',
  }: LogoutOptions = {}) => {
    try {
      if (notifyServer) {
        await logoutInfo.send({ params: {} });
      }
    } catch (error) {
      console.error(error);
    } finally {
      // logout AWS connect
      logoutCCP();
      // clean local token
      localStorage.removeItem(LocalKeyEnum.LOCAL_TOKEN);
      // clean sessionStorage
      sessionStorage.clear();
      window.dispatchEvent(new Event('session-storage'));
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  };

  return {
    onLogout,
    isLoggingOut: !!logoutInfo.isLoading,
  };
};
