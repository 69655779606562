import { Input } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useStressManagementForm } from '../../hook/useILifestyleAssessmentForm/useStressManagementForm';

export const StressManagementFormItemComponent = () => {
  const iaForm = useStressManagementForm();

  return (
    <>
      <FormItem
        info={iaForm.getInfo('stressLevel')}
      >
        <Input
          placeholder="Please describe here"
        />
      </FormItem>
      <FormItem
        info={iaForm.getInfo('stressCopingStrategy')}
      >
        <Input
          placeholder="Please describe here"
        />
      </FormItem>
      <FormItem
        info={iaForm.getInfo('recentDepressionAnxiety')}
      >
        <Input
          placeholder="Please describe here"
        />
      </FormItem>
    </>
  );
};
