import { Nullable, PatientCgmDevice, PatientDevice } from '../uc-api-sdk';

export class PatientDeviceService {
  private patientDevices?: Nullable<PatientDevice>;

  private cgmDevice?: Nullable<PatientCgmDevice>;

  constructor(patientDevices?: Nullable<PatientDevice>, cgmDevice?: Nullable<PatientCgmDevice>) {
    this.patientDevices = patientDevices;
    this.cgmDevice = cgmDevice;
  }

  getPatientDevices = () => this.patientDevices;

  public getDeviceList() {
    return this.patientDevices?.deviceList;
  }

  public getCgmDevice() {
    return this.cgmDevice || undefined;
  }
}
