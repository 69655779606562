import { useCallback, useMemo } from 'react';
import { FormItemProps } from 'antd';
import {
  flatMap,
  isNil,
  pickBy,
  some,
} from 'lodash';
import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { MonitoringVital, TaskRepeatEnum, VitalEnumType } from '../../../../uc-api-sdk';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';

export const useVitalsMonitoringScheduleForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    [VitalEnumType.BG]: {
      name: VitalEnumType.BG,
      label: <VitalsEnumTypeComponent value={VitalEnumType.BG} />,
    },
    [VitalEnumType.CGM]: {
      name: VitalEnumType.CGM,
      label: '',
    },
    [VitalEnumType.BP]: {
      name: VitalEnumType.BP,
      label: <VitalsEnumTypeComponent value={VitalEnumType.BP} />
    },
    [VitalEnumType.PO]: {
      name: VitalEnumType.PO,
      label: <VitalsEnumTypeComponent value={VitalEnumType.PO} />
    },
    [VitalEnumType.TM]: {
      name: VitalEnumType.TM,
      label: <VitalsEnumTypeComponent value={VitalEnumType.TM} />
    },
    [VitalEnumType.HS]: {
      name: VitalEnumType.HS,
      label: <VitalsEnumTypeComponent value={VitalEnumType.HS} />
    },
    [VitalEnumType.EXERCISE]: {
      name: VitalEnumType.EXERCISE,
      label: <VitalsEnumTypeComponent value={VitalEnumType.EXERCISE} />
    },
    isEditVitals: {
      name: 'isEditVitals',
      label: '',
    }
  }, options);

  const getRequiredRules = useCallback((): FormItemProps['rules'] => ([
    {
      validator: (_rule, value, callback) => {
        const { target, repeat } = value || {} as MonitoringVital;
        if (isNil(target) || !repeat) {
          callback('Missing!');
          return;
        }
        callback();
      },
    },
  ]), []);

  const getBGVitalScheduleRules = useCallback((): FormItemProps['rules'] => ([
    {
      validator: (_rule, value, callback) => {
        const { templateDetails } = value || {} as MonitoringVital;
        if (!templateDetails?.name || !templateDetails?.schedule) {
          callback('Missing!');
          return;
        }
        const BGSchedule = pickBy(templateDetails.schedule, (v) => !isNil(v));
        const selectedSchedule = flatMap(
          Object.values(BGSchedule),
          Object.values
        );
        if (!some(selectedSchedule, (v) => !!v)) {
          callback('Schedule is empty!');
          return;
        }
        callback();
      },
    },
  ]), []);

  const getVitalDefaultSchedule = useCallback(() => ({
    [factory.getName(VitalEnumType.BP)]: { target: 1, repeat: TaskRepeatEnum.DAILY },
    [factory.getName(VitalEnumType.PO)]: { target: 1, repeat: TaskRepeatEnum.DAILY },
    [factory.getName(VitalEnumType.HS)]: { target: 1, repeat: TaskRepeatEnum.WEEKLY },
    [factory.getName(VitalEnumType.TM)]: { target: 3, repeat: TaskRepeatEnum.DAILY },
    // default BG is set based on fetched templates inside BGInputContainer
  }), []);

  const response = useMemo(() => ({
    ...factory,
    getBGVitalScheduleRules,
    getRequiredRules,
    getVitalDefaultSchedule,
  }), [factory]);

  return response;
};
