import { Unpacked } from '../../lib/types';
import { Language } from '../../types/user';
import { Patient, PatientEnrolledProgram } from '../../uc-api-sdk';
import { CallCenterServicesValue } from './services/CallCenterServicesContext';
import { ConnectionInfo, ContactInfo } from './services/types';

// -- COMMON
export type NextCallback<T = unknown> = (...args: T[]) => unknown;
export type SubscriptionCallback<V, R = unknown> = (res: V) => R;
export type Connect = typeof connect;
export type ConnectSuccessFailOptions = connect.SuccessFailOptions;

// -- CONTACT
export type ConnectContact = connect.Contact & {
  getAttributes: () => connect.AttributeDictionary;
};

// -- AGENT
export type ConnectAgent = connect.Agent;
// user-defined states in Connect
export type ConnectAgentManualState = 'Available' | 'Offline';
export type ConnectAgentStateNames = `${connect.AgentAvailStates}` | `${connect.AgentErrorStates}` | ConnectAgentManualState;
export type ConnectAgentStateType = `${connect.AgentStateType}` | 'system' | 'error';
export type ConnectSoftphoneErrorType = `${connect.SoftphoneErrorTypes}`;
export interface ConnectFrameMediaDevice {
  deviceId: 'default' | string,
  kind: 'audioinput' | 'audiooutput' | 'videoinput',
  label: string,
  groupId: string,
}
export interface ConnectAgentStatus {
  name: ConnectAgentStateNames,
  type: ConnectAgentStateType,
  availabilityState: ConnectAgentStateNames,
  startTimestamp?: Date,
  contacts: ConnectContact[]
}

export interface ConnectErrorContext {
  errorType?: unknown;
  message: string;
}

export interface ConnectError {
  createdAt: Date;
  error: ConnectErrorContext;
}

// -- EVENT BUS
export type EventType = `${connect.EventType}`;
export interface EventSubscription {
  id: string,
  eventName: EventType,
  f: connect.Callback,
  unsubscribe: () => void,
}
export interface EventBus {
  trigger: (eventName: EventType) => void,
  subscribe: (eventName: EventType, f: connect.Callback) => EventSubscription,
  unsubscribeAll: () => void,
}

// -- CORE
export interface ConnectCore extends connect.Core {
  initialized: boolean,
  getEventBus: () => EventBus,
  _getCCPIframe: () => HTMLElement | null,
  getFrameMediaDevices: (timeout: number) => Promise<ConnectFrameMediaDevice[]>,
}

// -- LOCAL
// undefined when there is no login
export type CallCenterStatusName = 'Online' | 'Offline' | 'Busy' | 'Error' | undefined;
export type CallCenterConnectionStatusType = 'CALL_PENDING' | 'CALLING' | 'ANSWER_PENDING' | 'ANSWERING' | undefined;
export type CallCenterControlPanelOpenStateType = 'OPEN_MAXIMIZED' | 'CLOSED' | 'MINIMIZED';
export type CallCenterPopupType = null | 'CALL_LOG' | 'STICKY_NOTE' | 'LOGIN_PROMPT';
export type CallCenterMediaStatusValue = {
  default: ConnectFrameMediaDevice[],
  others: ConnectFrameMediaDevice[],
} | undefined;
export type CallCenterMediaStatus = {
  [key in ConnectFrameMediaDevice['kind']]: CallCenterMediaStatusValue
}
export type ConnectAgentStateToCallCenterStatus = {
  [x in ConnectAgentStateNames]: CallCenterStatusName
}
export type ConnectPatientAttribute = Unpacked<connect.AttributeDictionary[0]>;
export type ConnectFoundPatientAttribute = ConnectPatientAttribute['value'];
export type ConnectPatientAttributes = {
  patientId?: ConnectPatientAttribute | ConnectFoundPatientAttribute,
  name?: ConnectPatientAttribute | ConnectFoundPatientAttribute,
  enrolledProgramId?: ConnectPatientAttribute | ConnectFoundPatientAttribute,
  organizationId?: ConnectPatientAttribute | ConnectFoundPatientAttribute,
  language?: ConnectPatientAttribute | ConnectFoundPatientAttribute,
  isEp?: ConnectPatientAttribute | ConnectFoundPatientAttribute,
  found?: ConnectPatientAttribute | ConnectFoundPatientAttribute,
  newPortal?: ConnectPatientAttribute | ConnectFoundPatientAttribute,
}
type MayExistAttributeValue<T> = T extends ConnectPatientAttribute ? T['value'] : undefined;
// attribute props can be undefined when call made from CCP
export interface CallCenterPatientProfile {
  id?: MayExistAttributeValue<ConnectPatientAttributes['patientId']>,
  fullName: MayExistAttributeValue<ConnectPatientAttributes['name']>,
  enrolledProgram?: {
    id: MayExistAttributeValue<ConnectPatientAttributes['enrolledProgramId']>,
    isEnrolled: boolean,
  },
  language?: Language['code'],
  organization?: {
    id: MayExistAttributeValue<ConnectPatientAttributes['organizationId']>,
  },
  newPortal?: boolean;
}
export interface CallCenterCallInfo {
  patientProfile: CallCenterPatientProfile,
  allPatientProfiles: CallCenterPatientProfile[],
  connectionInfo: ConnectionInfo,
}

export interface CallCenterEnrolledProgramInfo {
  isEnrolled: boolean;
  info?: PatientEnrolledProgram;
}

export type CallCenterMakePhoneCallFunc = (
  phoneNumber: Parameters<CallCenterServicesValue['makePhoneCall']>['0'],
  patientInfo: Patient,
  enrolledProgramInfo: CallCenterEnrolledProgramInfo,
  cb?: Parameters<CallCenterServicesValue['makePhoneCall']>['1'],
) => void;

export interface CallInfo {
  phoneNumber: Parameters<CallCenterServicesValue['makePhoneCall']>['0'];
  patientId: string;
  patientFullName: string;
  clinicId: string;
  languages: Language['code'][];
  isEnrolled: boolean;
}

export type CallCenterMakePhoneCallSimplifiedFunc = (
  v: CallInfo,
  cb?: Parameters<CallCenterServicesValue['makePhoneCall']>['1']
) => void;

export type CallCenterSetAgentStateFunc = (
  cb?: Parameters<CallCenterServicesValue['setAgentState']>['1'],
  enqueueNextState?: Parameters<CallCenterServicesValue['setAgentState']>['2'],
) => void;

// -- CALL LOG
export interface CallLogInfo {
  patientProfile: CallCenterPatientProfile;
  contactInfo: ContactInfo;
  startTime?: Date;
  endTime?: Date;
  isSubmitted?: boolean;
  isClosed?: boolean;
}

export interface CallCenterCallLog {
  [contactId: string]: CallLogInfo
}

// -- METRIC
export enum CallLogMetricAction {
  CreateCallLog = 'CreateCallLog',
  UpdateCallLog = 'UpdateCallLog',
  RemoveCallLog = 'RemoveCallLog',
}
