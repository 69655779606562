import { useState } from 'react';

export const useSingleEditModeHook = <T extends string>() => {
  const [selectedIntervention, setSelectedIntervention] = useState<T>();

  const closeEditMode = () => {
    setSelectedIntervention(undefined);
  };

  const handleEdit = (type: T) => () => {
    if (selectedIntervention === type) {
      closeEditMode();
    } else {
      setSelectedIntervention(type);
    }
  };

  const isEditing = (type: T) => (
    type === selectedIntervention
  );

  const isEditingAny = () => !!selectedIntervention;

  return {
    closeEditMode,
    handleEdit,
    isEditing,
    isEditingAny,
  };
};
