import dayjs from 'dayjs';
import { isNil } from 'lodash';
import TimezoneService from '../../helpers/timezone/timezoneService';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface DueInComponentProps {
  dueDate?: string;
}

export const DueInComponent = ({
  dueDate,
}: DueInComponentProps) => {
  const dueDateDayjs = !isNil(dueDate) ? TimezoneService.calcDateTimeDayjs(dueDate) : undefined;
  const diffDays = dueDateDayjs?.diff(dayjs(), 'day', true);

  if (isNil(diffDays)) {
    return <EmptyComponent />;
  }
  return (
    <>
      {}
      {diffDays <= 0 ? ' Overdue'
        : (
          <>
            Due in
            {' '}
            {Math.ceil(diffDays)}
            d
          </>
        )}
    </>
  );
};
