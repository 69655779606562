import { ROLE_ENUM } from '../../types/roles';
import { RoleTypeEnum } from '../../uc-api-sdk';

export const adminPortalRoles = [
  ROLE_ENUM.SUPER_ADMIN,
  ROLE_ENUM.MANAGER,
  ROLE_ENUM.ADMIN,
];

export const carePortalRoles = [
  ROLE_ENUM.PROVIDER,
  ROLE_ENUM.MA,
  ROLE_ENUM.CALL_CENTER_REPRESENTATIVE,
  ROLE_ENUM.RD,
  ROLE_ENUM.HC,
  ROLE_ENUM.CA,
];

export const internalRoleTypes = [
  RoleTypeEnum.RD,
  RoleTypeEnum.HC,
  RoleTypeEnum.CA,
];

export const billerPortalRoles = [
  RoleTypeEnum.BILLER,
];

export const careRelatedRoles = [
  RoleTypeEnum.RD,
  RoleTypeEnum.HC,
  RoleTypeEnum.CA,
  RoleTypeEnum.PROVIDER,
  RoleTypeEnum.MA,
];

export const clinicBillingRoles = [
  RoleTypeEnum.CLINICAL_MANAGER,
  RoleTypeEnum.CLINICAL_GROUP_BILLER,
];
