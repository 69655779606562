import { Menu, MenuProps } from 'antd';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';
import { useVisibility } from '../../../contexts/VisibilityContext/VisibilityContext';
import useBoolean from '../../../hooks/useBoolean/useBoolean';
import useChangePage from '../../../hooks/useChangePage/useChangePage';
import { useRouter } from '../../../hooks/useRouter/useRouter';
import { Icons } from '../../../icons/Icons';
import { getMenuItem } from './SideMenuItem';
import { TicketSideMenuBtnContainer } from '../../ticket/container/TicketSideMenuBtnContainer/TicketSideMenuBtnContainer';
import '../../../styles/sideMenu.scss';

type SideMenuKey =
  'home'
  | 'care'
  | 'enrollment'
  | 'patients'
  | 'insights'
  | 'task'
  | 'utilities'
  | 'visits'
  | 'alerts';

export interface SideMenuComponentProps {
}

export const SideMenuComponent: FC<SideMenuComponentProps> = () => {
  const { value: collapsed } = useBoolean(true);
  const { send } = useMixpanelContext();
  const page = useChangePage();
  const { location } = useRouter();
  const { showPage } = useVisibility();

  const selectedKey = useMemo<SideMenuKey | undefined>(() => {
    const { pathname } = location;
    if (pathname.startsWith(page.createCarePortalHomeUrl())) {
      return 'home';
    }
    if (pathname.startsWith(page.createCarePortalPatientCareUrl())) {
      return 'care';
    }
    if (pathname.startsWith(page.createCarePortalEnrollmentUrl())) {
      return 'enrollment';
    }
    if (pathname.startsWith(page.createCarePortalPatientsUrl())) {
      return 'patients';
    }
    if (pathname.startsWith(page.createCarePortalInsightsUrl())) {
      return 'insights';
    }
    if (pathname.startsWith(page.createCarePortalTicketUrl())) {
      return 'task';
    }
    if (pathname.startsWith(page.createCarePortalUtilitiesUrl())) {
      return 'utilities';
    }
    if (pathname.startsWith(page.createProviderPortalMedicalAlertUrl())) {
      return 'alerts';
    }
    if (pathname.startsWith(page.createCarePortalVisitsAndEventsUrl())) {
      return 'visits';
    }
    return undefined;
  }, [location, page]);

  const items: MenuProps['items'] = [
    showPage.showHomePage ? getMenuItem({
      icon: <Icons.Home />,
      label: 'Home',
      key: 'home',
      onClick: () => {
        send({
          event: MixpanelEvents.Home,
        });
        page.goToCarePortalHome();
      },
    }) : null,
    showPage.showVisitsAndEvents ? getMenuItem({
      icon: <Icons.SideBarVisitsAndEventsIcon />,
      label: 'Visits',
      key: 'visits',
      onClick: () => {
        send({
          event: MixpanelEvents.Visits,
        });
        page.gotoVisitsAndEvents();
      },
    }) : null,
    showPage.showPatients ? getMenuItem({
      icon: <Icons.Patients />,
      label: 'Patients',
      key: 'patients',
      onClick: () => {
        send({
          event: MixpanelEvents.Patients,
        });
        page.goToCarePortalPatients();
      },
    }) : null,
    // getMenuItem({
    //   icon: <Icons.Enrollment />,
    //   label: 'Enrollment',
    //   key: 'enrollment',
    //   onClick: () => page.goToCarePortalEnrollment(),
    // }),
    showPage.showPatientCare ? getMenuItem({
      icon: <Icons.RoutineCare />,
      label: 'Patient Care',
      key: 'care',
      onClick: () => {
        send({
          event: MixpanelEvents.PatientCare,
        });
        page.goToCarePortalPatientCare();
      },
    }) : null,
    showPage.showTask ? getMenuItem({
      icon: <TicketSideMenuBtnContainer />,
      label: 'Task',
      key: 'task',
      onClick: () => {
        send({
          event: MixpanelEvents.Tasks,
        });
        page.goToCarePortalTicket();
      },
    }) : null,
    showPage.showMedicalAlerts ? getMenuItem({
      icon: <Icons.SideBarMedicalAlertIcon />,
      label: 'Alerts',
      key: 'alerts',
      onClick: () => {
        send({
          event: MixpanelEvents.MedicalAlerts,
        });
        page.goToProviderPortalMedicalAlert();
      },
    }) : null,
    showPage.showUtilities ? getMenuItem({
      icon: <Icons.UtilitiesMenu />,
      label: 'Utilities',
      key: 'utilities',
      onClick: () => {
        send({
          event: MixpanelEvents.Patients,
        });
        page.goToCarePortalUtilities();
      },
    }) : null,
    showPage.showClinicBilling ? getMenuItem({
      icon: <Icons.ClinicBilling />,
      label: 'Billing',
      key: 'clinicBilling',
      onClick: () => {
        send({
          event: MixpanelEvents.ClinicBilling,
        });
        page.goToCarePortalClinicBilling();
      },
    }) : null,

    showPage.showInsight ? getMenuItem({
      icon: <Icons.Insight />,
      label: 'Insights',
      key: 'insights',
      onClick: () => {
        send({
          event: MixpanelEvents.Insight,
        });
        page.goToCarePortalInsights();
      },
    }) : null,
  ];

  return (
    <div className="sidebar-component">
      <Menu
        className={classNames({
          'sidebar-menu': true,
          collapsed,
          'bg-uc-blue': true,
        })}
        selectedKeys={selectedKey ? [selectedKey] : []}
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
      />
    </div>
  );
};
