import React, { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Outlet } from 'react-router';
import { CarePortalPopupContextProvider } from '../../../contexts/CarePortalPopupContext/CarePortalPopupContext';
import { PreviewWarningContextProvider } from '../../../contexts/PreviewWarningContext/PreviewWarningContext';
import '../../../styles/portalLayout.scss';
import { ErrorBoundaryAppComponent } from '../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryAppComponent';
import { PreviewEnvWarningBannerComponent } from '../../../uiComponent/PreviewEnvWarningComponent/PreviewEnvWarningBannerComponent';
import { VisitsAndEventsContextProvider } from '../../visit/context/VisitsAndEventsContext';
import { WelcomePopupModalComponent } from '../../welcome/component/WelcomePopupModalComponent';
import { HeaderComponent } from '../HeaderComponent/HeaderComponent';
import { SideMenuComponent } from '../SideMenuComponent/SideMenuComponent';
import { ErrorBoundaryPageComponent } from '../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryPageComponent';
import { IntroducingNewPaymentPopupModalComponent } from '../../welcome/component/IntroducingNewPaymentPopupModalComponent/IntroducingNewPaymentPopupModalComponent';
import { NewVersionBannerComponent } from '../../../uiComponent/NewVersionBannerComponent/NewVersionBannerComponent';

export interface CarePortalLayoutComponentProps {

}

export const CarePortalLayoutComponent: FC<CarePortalLayoutComponentProps> = () => (
  <ErrorBoundaryAppComponent>
    <PreviewWarningContextProvider>
      <VisitsAndEventsContextProvider>
        <CarePortalPopupContextProvider>
          <div className="portal-layout">
            <div className="portal-header">
              <HeaderComponent />
              <NewVersionBannerComponent />
            </div>
            <PreviewEnvWarningBannerComponent />
            <div className="portal-body">
              <div className="portal-side-menu">
                <SideMenuComponent />
              </div>
              <div className="portal-content">
                <React.Suspense fallback="loading...">
                  <ErrorBoundaryPageComponent page="CARE_PORTAL_PAGE">
                    <Outlet />
                  </ErrorBoundaryPageComponent>
                </React.Suspense>
              </div>
            </div>
            <IntroducingNewPaymentPopupModalComponent />
            <WelcomePopupModalComponent />
          </div>
        </CarePortalPopupContextProvider>
      </VisitsAndEventsContextProvider>
    </PreviewWarningContextProvider>
  </ErrorBoundaryAppComponent>
);

export default CarePortalLayoutComponent;
