import { Col, Divider, Row } from 'antd';
import { isEmpty } from 'lodash';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { NestedFormControlProvider } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import { NestedFormSubmitButtonComponent } from '../../../../contexts/NestedFormControlContext/NestedFormSubmitButtonComponent';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { PatientProfileVitalsMonitoringFormContainer } from '../../container/PatientProfileVitalsMonitoringFormContainer/PatientProfileVitalsMonitoringFormContainer';
import { AddRemoveVitalsMonitoringSubmitValue } from '../../hook/useAddRemoveVitalsMonitoringForm';
import { PatientProfileServicesSubmitValue } from '../../hook/usePatientServicesForm';
import { ProgramEnrollmentSubmitValue } from '../../hook/useProfileProgramEnrollmentForm';
import { PatientProfileProgramsItemComponent } from '../PatientProfileProgramsItemComponent/PatientProfileProgramsItemComponent';
import { PatientProfileVitalsMonitoringComponent } from '../PatientProfileVitalsMonitoringComponent/PatientProfileVitalsMonitoringComponent';

import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface ProgramsSubmitValues
  extends ProgramEnrollmentSubmitValue,
  AddRemoveVitalsMonitoringSubmitValue,
  PatientProfileServicesSubmitValue { }

export interface PatientProfileVitalsToMonitorComponentProps {
  patientInfo?: PatientInfo;
  enrolledProgramService?: EnrolledProgramService;
  isEditing?: boolean;
  onSubmit?: (values: ProgramsSubmitValues) => void;
  onCancel?: () => void;
  isLoading?: boolean;
}

export const PatientProfileVitalsToMonitorComponent = ({
  patientInfo,
  enrolledProgramService,
  isEditing,
  onSubmit,
  onCancel,
  isLoading,
}: PatientProfileVitalsToMonitorComponentProps) => {
  useUpdateListener('PATIENT_DISCHARGED', () => {
    patientInfo?.refetch();
  });
  if (!patientInfo?.patientInfo || enrolledProgramService === undefined) return null;

  const handleOnSubmit = (values: ProgramsSubmitValues) => {
    const { vitals } = values;
    if (vitals && isEmpty(vitals)) {
      // prevent submit empty array of vitals
      return;
    }
    onSubmit?.(values);
  };

  return (
    <NestedFormControlProvider
      onFinish={handleOnSubmit}
      disabled={isLoading}
    >
      <Row>
        <Col span={12}>
          <PatientProfileProgramsItemComponent
            title="Vitals Monitoring"
            className="flex fd-c profile-vitals-monitoring"
            isEditing={isEditing}
            formContent={(
              <NestedFormComponent>
                <PatientProfileVitalsMonitoringFormContainer
                  patientInfo={patientInfo}
                  enrolledProgramService={enrolledProgramService}
                  isLoading={isLoading}
                  formButtons={null}
                />
              </NestedFormComponent>
            )}
          >
            <PatientProfileVitalsMonitoringComponent
              enrolledProgramService={enrolledProgramService}
            />
          </PatientProfileProgramsItemComponent>
        </Col>
      </Row>
      <Col span={24}>
        <Divider />
        <div className="flex jc-sb ai-c">
          {
            isEditing
            && (
              <NestedFormSubmitButtonComponent
                onCancel={onCancel}
                submitText="Save"
                reverseOrder
                noMargin
                isLoading={isLoading}
              />
            )
          }
        </div>
      </Col>
    </NestedFormControlProvider>
  );
};
