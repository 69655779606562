import { BasicEnumComponent } from '../../../../enumComponent/BasicEnumComponent/BasicEnumComponent';
import { getTimeOfDayByMinutes } from '../../../../helpers/time';
import { ExerciseDetail } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { FromToTimeDisplay } from '../../../../uiComponent/FromToTimeDisplay/FromToTimeDisplay';

import './PhysicalActivityInfoComponent.scss';

export interface PhysicalActivityInfoComponentProps {
  info?: ExerciseDetail;
}

export const PhysicalActivityInfoComponent = ({
  info,
}: PhysicalActivityInfoComponentProps) => (
  <div className="physical-activity-info">
    <DisplayOrEmptyComponent value={info?.type} />
    <span>,</span>
    <div className="ml5 flex ai-c">
      <FromToTimeDisplay
        startTime={getTimeOfDayByMinutes(info?.startTime)}
        endTime={getTimeOfDayByMinutes(info?.endTime)}
        format="hhmm_a"
      />
    </div>
    <span>,</span>
    <div className="ml5">
      {
        info?.intensity
          ? <BasicEnumComponent value={info?.intensity} />
          : <EmptyComponent />
      }
    </div>
  </div>
);
