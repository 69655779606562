import { ReactNode } from 'react';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { BeverageInputCardComponent } from '../LifestyleAssessmentFormListComponent/components/FastFoodBeverage/BeverageInputCardComponent';

export interface BeverageFormItemComponentProps {
  label?: ReactNode;
}

export const BeverageFormItemComponent = ({
  label = 'What beverages does the patient consume?'
}: BeverageFormItemComponentProps) => (
  <>
    <TextComponent
      color="gray2"
      className="mb5"
    >
      {label}
    </TextComponent>
    <BeverageInputCardComponent />
  </>
);
