import classNames from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import * as Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import { forEach } from 'lodash';
import {
  useRef
} from 'react';
import { MeasureUnitHelper } from '../../../../helpers/measurement/measureUnit';
import { useAxis } from '../../../../hooks/highcharts/useAxis';
import { useDrawYAxisWithColor } from '../../../../hooks/highcharts/useDrawYAxisWithColor';
import { useDeepCompareEffectWithInitialRender, useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { CgmMetricEnum, CgmPatientContext, CgmThresholdRange } from '../../../../uc-api-sdk';
import {
  CGMBounds, CGMChartHeight, CGMColorMap, CGMPlotBandColor, CGM_VALUE_LIMIT
} from '../../constant/cgmConstant';
import { CGMRange } from '../../container/CGMVitalContainer/type';
import { useCGMChartHelper } from '../../helper/useCGMChartHelper';

import './CGMChartComponent.scss';

dayjs.extend(timezone);
highchartsMore(Highcharts);

export interface CGMChartComponentProps {
  options?: Highcharts.Options;
  cgmPatientContext?: CgmPatientContext;
  timezone?: string;
  onChartClick?: (
    data: { x: number, y: number },
    chart: Highcharts.Chart,
  ) => void;
  isCompact?: boolean;
  unit?: string;
  dataMax?: number; // to sync max data between charts
  onChartFinish?: () => void;
}

export const CGMChartComponent = ({
  options = {},
  cgmPatientContext,
  timezone = cgmPatientContext?.timezone || dayjs.tz.guess(),
  onChartClick,
  isCompact,
  unit = MeasureUnitHelper.defaultBGUnit,
  dataMax,
  onChartFinish
}: CGMChartComponentProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const highchartRef = useRef<Highcharts.Chart | undefined>();
  const axisHelper = useAxis();
  const {
    makeYAxisWithColor
  } = useDrawYAxisWithColor();
  const {
    renderLabelWithUnit,
    addSpaceFromLastValue,
  } = useCGMChartHelper();

  const processedData = useDeepCompareMemo(() => {
    let maxDataPoint = dataMax || -1;
    if (maxDataPoint === -1) {
      forEach(options?.series, (s) => {
        if (!s) return;
        const { data = [] } = s as unknown as Highcharts.Series;
        const values = data.map((d) => d.y || 0);
        const max = Math.max(...values);
        maxDataPoint = Math.max(maxDataPoint, max);
      });
    }

    const thresholds = cgmPatientContext?.thresholds as Record<CgmMetricEnum, CgmThresholdRange>;
    const bounds = {
      lowerBound: thresholds?.TIR?.lowerBound || CGMBounds.LOW,
      upperBound: thresholds?.TAR_LEVEL_1?.lowerBound || CGMBounds.HIGH,
    };

    const ranges = {
      HIGH: {
        value: bounds.upperBound,
        color: CGMColorMap.HIGH
      },
      LOW: {
        value: bounds.lowerBound,
        color: CGMColorMap.LOW
      },
    } as Record<string, CGMRange>;

    return {
      maxDataPoint,
      lowerBound: bounds.lowerBound || CGMBounds.LOW,
      upperBound: bounds.upperBound || CGMBounds.HIGH,
      ranges,
    };
  }, [
    options.series,
    cgmPatientContext?.thresholds
  ]);

  useDeepCompareEffectWithInitialRender(() => {
    const {
      xAxis,
      yAxis,
      series: optionSeries,
      ...restOptions
    } = options || {};
    const series = optionSeries as unknown as Highcharts.Series[];
    let dataMaxValue = Math.max(processedData.maxDataPoint, CGM_VALUE_LIMIT);
    dataMaxValue = (
      dataMaxValue === processedData.maxDataPoint
        ? addSpaceFromLastValue(dataMaxValue) : dataMaxValue
    );

    const lowRangeData = series?.[0].data.map((d) => {
      if ((d.y || 0) >= processedData.lowerBound) {
        return [d.x, d.y, d.y];
      }
      return [d.x, d.y, processedData.lowerBound];
    });

    const parsedYAxis = axisHelper.makeRanges(processedData.ranges);
    const thresholdLines = parsedYAxis.plotLines.map((line) => ({
      ...line,
      label: {
        align: 'right',
        verticalAlign: 'center',
        useHTML: true,
        formatter() {
          return `
            <div
              class="threshold-label"
              style="background: ${line.color};"
            >
              ${line.value}
            </div>
          `;
        }
      }
    }));

    const finalOptions = {
      title: { text: '' },
      time: { timezone },
      legend: { enabled: false },
      plotOptions: {
        series: {
          // prevent from showing empty chart when data has no line
          marker: { radius: 0.5 },
          states: { hover: { halo: null } },
          animation: false,
        }
      },
      chart: {
        type: 'spline',
        height: isCompact ? CGMChartHeight.COMPACT : CGMChartHeight.FULL,
        events: {
          click(e) {
            const x = (e as Highcharts.ChartClickEventObject).xAxis[0].value;
            const y = (e as Highcharts.ChartClickEventObject).yAxis[0].value;
            const chart = this as unknown as Highcharts.Chart;
            onChartClick?.({ x, y }, chart);
          },
          load() {
            const chart = this as unknown as Highcharts.Chart;
            makeYAxisWithColor([
              {
                from: 0,
                to: processedData.lowerBound,
                color: CGMColorMap.LOW
              },
              {
                from: processedData.lowerBound,
                to: processedData.upperBound,
                color: CGMColorMap.NORMAL
              },
              {
                from: processedData.upperBound,
                to: dataMaxValue,
                color: CGMColorMap.HIGH
              },
            ], chart);
          },
        },
      },
      xAxis,
      yAxis: {
        title: '',
        min: 0,
        max: dataMaxValue,
        endOnTick: false,
        tickInterval: 50,
        labels: {
          useHTML: true,
          formatter(): string {
            const formatter = this as unknown as Highcharts.AxisLabelsFormatterContextObject;
            if (formatter.value === 0 && isCompact) {
              return renderLabelWithUnit('', unit);
            }
            if (formatter.value === 0) {
              return '';
            }
            if (formatter.value === dataMaxValue && !isCompact) {
              return renderLabelWithUnit(formatter.value, unit);
            }
            return formatter.value.toString();
          }
        },
        plotLines: thresholdLines,
        plotBands: [
          {
            from: processedData.lowerBound,
            to: processedData.upperBound,
            color: CGMPlotBandColor.LIGHT_GRAY,
          },
        ],
        gridLineDashStyle: 'Dash',
        ...yAxis,
      },
      tooltip: {
        shadow: false,
        shape: 'square',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        className: 'cgm-chart-tooltip',
        useHTML: true,
        formatter(): string {
          const formatter = this as unknown as Highcharts.TooltipFormatterContextObject;
          const time = dayjs(formatter.x).format('h:mm A');
          const value = Math.ceil(formatter.y || 0);
          return `
            <div class="cgm-chart-tooltip-body flex fd-c gap2">
              <div className="cgm-data-time">
                ${time}
              </div>
              <div class="flex ai-c">
                <div class="cgm-data-value">
                  ${value}
                </div>
                <div class="text-gray-scale-2">
                  ${unit}
                </div>
              </div>
            </div>
          `;
        }
      },
      series: [
        {
          ...series?.[0],
          type: 'areaspline',
          zIndex: 1,
          zones: [
            {
              value: processedData.lowerBound,
              color: CGMColorMap.LOW,
              fillColor: 'transparent'
            },
            {
              value: processedData.upperBound,
              color: CGMColorMap.NORMAL,
              fillColor: 'transparent'
            },
            {
              color: CGMColorMap.HIGH,
              fillColor: 'rgba(239, 151, 59, 0.2)'
            },
          ],
        },
        {
          name: 'LowRange',
          data: lowRangeData,
          type: 'arearange',
          lineColor: 'transparent',
          color: CGMColorMap.LOW,
          enableMouseTracking: false,
          zIndex: 0,
          linkedTo: ':previous',
          fillOpacity: 0.3,
        },
      ],
      ...restOptions,
    } as Highcharts.Options;

    if (chartContainerRef.current) {
      highchartRef.current = Highcharts.chart(
        chartContainerRef.current,
        finalOptions,
        onChartFinish
      );
    }

    return () => {
      highchartRef.current?.destroy();
      highchartRef.current = undefined;
    };
  }, [options, processedData]);

  return (
    <div
      ref={chartContainerRef}
      className={classNames({
        'cgm-chart w100': true,
        'cgm-chart-compact': isCompact,
      })}
    />
  );
};
