import { map } from 'lodash';
import { InputNumber } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { FreqSelectComponent } from '../../../../uiComponent/FreqSelectComponent/FreqSelectComponent';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { useSmokeDrinkForm } from '../../hook/useILifestyleAssessmentForm/useSmokeDrinkForm';
import { LifestyleAssessmentInputCardLayout } from '../LifestyleAssessmentComponent/layout/LifestyleAssessmentInputCardLayout';
import {
  FrequencyUnitEnum,
  SmokeDrinkRecord$AlcoholTypeEnum,
  SmokeDrinkRecord$ContainerTypeEnum,
} from '../../../../uc-api-sdk';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { SmokeDrinkQuitYearComponent } from './SmokeDrinkQuitYearComponent';

import './SmokeDrinkFormItemComponent.scss';
import { AlcoholContainerTypeEnumComponent } from '../../../../enumComponent/SmokeDrinkEnumComponent/AlcoholContainerTypeEnumComponent';
import { AlcoholTypeEnumComponent } from '../../../../enumComponent/SmokeDrinkEnumComponent/AlcoholTypeEnumComponent';

export const DrinkFormItemComponent = () => {
  const drinkForm = useSmokeDrinkForm();

  return (
    <>
      <FormItem
        info={drinkForm.getInfo('isDrinking')}
      >
        <YesNoRadioComponent />
      </FormItem>

      <div className="mt12">
        <FormItem
          noStyle
          shouldUpdate={drinkForm.shouldUpdate(['isDrinking'])}
        >
          {
            ({ getFieldValue }) => {
              const isDrinking = drinkForm.getObjectValue('isDrinking', getFieldValue);
              if (typeof isDrinking !== 'boolean') {
                return null;
              }
              return (
                <LifestyleAssessmentInputCardLayout>
                  {
                    isDrinking ? (
                      <div className="smoke-drink__freq">
                        <FormItem
                          info={drinkForm.getInfo('drinkingAmount')}
                        >
                          <InputNumber
                            min={0}
                            precision={0}
                            placeholder="Amt."
                          />
                        </FormItem>
                        <FormItem
                          info={drinkForm.getInfo('containerType')}
                        >
                          <EnumSelectComponent
                            options={map(SmokeDrinkRecord$ContainerTypeEnum)}
                            optionRenderer={AlcoholContainerTypeEnumComponent}
                            placeholder="Select"
                          />
                        </FormItem>
                        <div className="smoke-drink__freq__text">
                          of
                        </div>
                        <FormItem
                          info={drinkForm.getInfo('alcoholType')}
                        >
                          <EnumSelectComponent
                            options={map(SmokeDrinkRecord$AlcoholTypeEnum)}
                            optionRenderer={AlcoholTypeEnumComponent}
                            placeholder="Select"
                          />
                        </FormItem>
                        <div className="smoke-drink__freq__text">
                          /
                        </div>
                        <FormItem
                          info={drinkForm.getInfo('drinkingFrequency')}
                          initialValue={FrequencyUnitEnum.WEEKLY}
                        >
                          <FreqSelectComponent type="shortCap" />
                        </FormItem>
                      </div>
                    ) : (
                      <FormItem
                        info={drinkForm.getInfo('quitDrinkingYear')}
                      >
                        <SmokeDrinkQuitYearComponent />
                      </FormItem>
                    )
                  }
                </LifestyleAssessmentInputCardLayout>
              );
            }
          }
        </FormItem>
      </div>
    </>
  );
};
