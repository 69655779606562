import Input, { TextAreaProps } from 'antd/lib/input';
import { CgmPotentialTagComponent } from '../CgmPotentialTagComponent/CgmPotentialTagComponent';
import './CgmPotentialTextAreaComponent.scss';

export interface CgmPotentialTextAreaComponentProps extends TextAreaProps {
  showCgmTag?: boolean;
}

export const CgmPotentialTextAreaComponent = ({
  showCgmTag,
  ...props
}: CgmPotentialTextAreaComponentProps) => (
  <div className="CgmPotentialTextAreaComponent">
    {showCgmTag && (
      <CgmPotentialTagComponent />
    )}
    <Input.TextArea
      className="cgmPotential"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      autoSize={{
        minRows: 5
      }}
    />
  </div>
);
