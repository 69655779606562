import EnvConfig from '../../configs/envConfig/envConfig';
import { useRouter } from '../../hooks/useRouter/useRouter';
import { StorageKeyEnum, useSessionStorage } from '../../hooks/useSessionStorage';
import {
  adminPortalRoles, billerPortalRoles,
  carePortalRoles, careRelatedRoles, clinicBillingRoles, internalRoleTypes
} from '../../lib/constants/roles';
import { RoleTypeEnum } from '../../uc-api-sdk';
import { VisibilityProcessorArgs } from './VisibilityContext';

// eslint-disable-next-line import/prefer-default-export
export const defaultVisibilityProcessor = ({ loggedInUserContext }: VisibilityProcessorArgs) => {
  const { location } = useRouter();
  const {
    doesUserRoleIncludes,
    isProvider,
    isClinicalStaff,
    isInternalStaff,
    isClinicManager,
  } = loggedInUserContext;
  const [
    enableInsight,
  ] = useSessionStorage(StorageKeyEnum.ENABLE_INSIGHT);
  const isInternalUser = doesUserRoleIncludes(internalRoleTypes);
  const isInsightEnabled = EnvConfig.isInsightEnabled || enableInsight;
  const isCareUser = doesUserRoleIncludes(careRelatedRoles);

  const visibilityValues = {
    showSampleComponent1: loggedInUserContext.doesUserRoleIncludes([
      RoleTypeEnum.RD,
      RoleTypeEnum.MA,
    ]),
    showSampleComponent2: loggedInUserContext.doesUserRoleIncludes([RoleTypeEnum.SUPER_ADMIN]),
    showSampleComponentExtended: false,
    showOnsiteInfo: isInternalUser,
    showMTPR: loggedInUserContext.doesUserRoleIncludes([
      RoleTypeEnum.RD,
      RoleTypeEnum.HC,
    ]),
    showPlatformCarePortal: loggedInUserContext.doesUserRoleIncludes(carePortalRoles),
    showPlatformAdminPortal: loggedInUserContext.doesUserRoleIncludes(adminPortalRoles),
    showPlatformBillerPortal: loggedInUserContext.doesUserRoleIncludes(billerPortalRoles),
    showCalendarMyGoogleEvent: isInternalUser,
    showOtherClinics: isInternalUser,
    showOnCallCoverageMode: isInternalUser,
    showMedicalAlertEngineEdit: loggedInUserContext.isSuperAdmin,
    showHeaderItems: isProvider || isInternalStaff,
    showPage: {
      showHomePage: !isClinicalStaff,
      showVisitsAndEvents: isCareUser,
      showPatients: isCareUser,
      showPatientCare: !isClinicalStaff,
      showTask: isCareUser,
      showUtilities: !isClinicalStaff,
      showInsight: isInsightEnabled && (isClinicManager || !isClinicalStaff),
      showMedicalAlerts: isProvider,
      showClinicBilling: doesUserRoleIncludes(clinicBillingRoles)
    },
  };

  // true => user can access path
  const checkPathVisibility = () => {
    const { pathname } = location;
    const {
      showPlatformCarePortal,
      showPlatformAdminPortal,
      showPlatformBillerPortal,
      showPage
    } = visibilityValues;
    // check platform
    if (!showPlatformCarePortal && pathname.startsWith('/care-portal')) {
      return false;
    }
    if (!showPlatformAdminPortal && pathname.startsWith('/admin-portal')) {
      return false;
    }
    if (!showPlatformBillerPortal && pathname.startsWith('/biller-portal')) {
      return false;
    }
    // check page
    if (!showPage.showHomePage && pathname.includes('/home')) {
      return false;
    }
    if (!showPage.showVisitsAndEvents && pathname.includes('/visits-and-events')) {
      return false;
    }
    if (!showPage.showPatients && pathname.includes('/patients')) {
      // ignore because patients can be accessed by other roles through their platform
    }
    if (!showPage.showPatientCare && pathname.includes('/patient-care')) {
      return false;
    }
    if (!showPage.showTask && pathname.includes('/task')) {
      return false;
    }
    if (!showPage.showUtilities && pathname.includes('/utilities')) {
      return false;
    }
    if (!showPage.showInsight && pathname.includes('/insights')) {
      return false;
    }
    if (!showPage.showMedicalAlerts && pathname.includes('/medical-alerts')) {
      return false;
    }
    if (!showPage.showClinicBilling && pathname.includes('/clinic-billing')) {
      return false;
    }
    return true;
  };

  return {
    ...visibilityValues,
    checkPathVisibility
  };
};
