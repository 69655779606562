import { Alert, Button } from 'antd';
import { throttle } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce/useDebounce';

export const NewVersionBannerComponent = () => {
  const [isDiff, setIsDiff] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;
  const storageKey = 'UCFE_CURRENT_VERSION';

  const getData = (currentVersion: string | null) => {
    fetch('/version.json').then(
      r => r.json()
    ).then(
      d => {
        if (currentVersion && d.version !== currentVersion) {
          setIsDiff(true);
        }
        localStorage.setItem(storageKey, d.version);
      }
    );
  };

  const throttledGet = useMemo(() => throttle(getData, 60000), []);

  const hardReload = useDebounce(() => {
    // @ts-ignore
    window.location.reload(true);
  });

  useEffect(() => {
    const localVersion = localStorage.getItem(storageKey);
    throttledGet(localVersion);
  }, [currentRoute]);

  if (!isDiff) {
    return null;
  }

  return (
    <Alert
      message={(
        <div className="b5">
          A new version is available, please refresh the page or
          {' '}
          <Button onClick={hardReload}>Reload</Button>
        </div>
      )}
      type="warning"
      className="zi10 ta-c"
      closable
    />
  );
};
