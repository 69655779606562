import { ReactNode } from 'react';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';

export interface PluralComponentProps {
  value?: number;
  unit?: string;
  pluralEnd?: string;
  emptyUnit?: ReactNode;
}

export const PluralComponent = ({
  value,
  unit,
  pluralEnd = 's',
  emptyUnit = '',
}: PluralComponentProps) => {
  const getUnit = () => {
    if (!value || !unit) return unit;
    return value <= 1 ? unit : `${unit}${unit ? pluralEnd : ''}`;
  };

  return (
    <div className="flex">
      <div className="plural-value">
        <DisplayOrEmptyComponent value={value} />
      </div>
      <div className="plural-unit">
        {getUnit() || emptyUnit}
      </div>
    </div>
  );
};
