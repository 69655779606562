import { Form } from 'antd';
import { useCgmUpdateHelper } from '../../helper/useCgmUpdateHelper';
import { CGMFormSubmitValue, useCGMDeviceForm } from '../../component/CGMDeviceFormItemComponent/useCGMDeviceForm';
import { CGMDeviceContainer } from '../CGMDeviceContainer/CGMDeviceContainer';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { CGMDeviceFormItemComponent } from '../../component/CGMDeviceFormItemComponent/CGMDeviceFormItemComponent';
import { FormType } from '../../../Input/types';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';

export interface CGMDeviceFormContainerProps extends FormType<CGMFormSubmitValue | undefined> {
  patientId: string;
  onSave?: (values?: CGMFormSubmitValue) => void;
}

export const CGMDeviceFormContainer = ({
  patientId,
  onSave,
  onSubmit,
  onCancel,
  formButtons
}: CGMDeviceFormContainerProps) => {
  const cgmDeviceForm = useCGMDeviceForm();
  const {
    updatePatientCGMDeviceInfo,
    updatePatientCGMDevice
  } = useCgmUpdateHelper(
    patientId,
  );

  const handleFinish = (
    values?: CGMFormSubmitValue,
  ) => {
    if (onSave) {
      onSave(values);
      return;
    }
    updatePatientCGMDevice(values, {
      onSuccess: () => onSubmit?.(values),
    });
  };

  return (
    <CGMDeviceContainer
      patientId={patientId}
      getPatientCGMDevice
    >
      {
        ({
          cgmDeviceData,
          patientCGMDeviceInfo,
          patientCGMDeviceLinkStatusInfo,
        }) => {
          const patientCGMDevice = patientCGMDeviceInfo?.data?.data;
          const isLoading = (
            patientCGMDeviceInfo.isLoading
            || patientCGMDeviceLinkStatusInfo.isLoading
          );

          if (patientCGMDevice === undefined) {
            return <LoadingOverlayComponent isLoading />;
          }
          return (
            <Form
              form={cgmDeviceForm.form}
              onFinish={handleFinish}
              initialValues={patientCGMDevice || {}}
              colon={false}
              layout="vertical"
              className="cgm-device-form"
            >
              <OverlayLoading
                loading={isLoading}
              />
              <CGMDeviceFormItemComponent
                patientId={patientId}
                deviceData={cgmDeviceData}
              />
              <div className="cgm-device-form__buttons">
                {
                  formButtons
                  || (
                    <CancelSubmitButtonsComponent
                      submitText="Save"
                      onCancel={onCancel}
                      disabled={updatePatientCGMDeviceInfo.isLoading}
                    />
                  )
                }
              </div>
            </Form>
          );
        }
      }
    </CGMDeviceContainer>
  );
};
