import { ClockCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { USA_DATE } from '../../../../constants/timeFormat';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { logWarn } from '../../../../lib/helpers/logging';
import { EnrolledProgramStatusEnum, SignatureCollectionMethodEnum } from '../../../../uc-api-sdk';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import { EnrollmentConsentFormContainer, EnrollmentConsentFormContainerProps } from '../../../enrollment/container/EnrollmentConsentFormContainer/EnrollmentConsentFormContainer';
import { useVerifyConsentStatus } from '../../hook/useVerifyConsentStatus';
import { ConsentFormDrawerComponent } from '../ConsentFormDrawerComponent/ConsentFormDrawerComponent';
import { ReviewConsentDrawerComponent } from '../ReviewConsentComponent/ReviewConsentDrawerComponent';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

import './ConsentFormStatusBarComponent.scss';

export interface ConsentFormStatusBarComponentProps {
  patientInfo?: PatientInfo;
  onSubmit?: () => void;
  onClose?: () => void;
  showSignedStatus?: boolean;
}

export const ConsentFormStatusBarComponent = ({
  patientInfo,
  onSubmit,
  onClose,
  showSignedStatus = true,
}: ConsentFormStatusBarComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const {
    enrolledProgramService,
    enrollmentService,
    consentService,
    patientInfoService
  } = patientInfo || {};

  const isEnrollmentEnrolled = enrollmentService?.isEnrolled();
  const isEnrolled = enrolledProgramService?.isEnrolled();
  const actionText = isEnrolled ? 'update' : 'complete';

  const verify = useVerifyConsentStatus({
    memberId: patientInfo?.id,
    organizationId: patientInfoService?.getClinicId(),
    status: isEnrolled ? EnrolledProgramStatusEnum.ENROLLED : undefined,
    selectedPrograms: enrolledProgramService?.getPrograms(),
    selectedAddOnServices: enrolledProgramService?.addOnServicesList,
  });

  const refetchVerify = useDebounce(() => {
    verify.send({
      params: {
        request: {
          memberId: patientInfo?.id,
          organizationId: patientInfoService?.getClinicId(),
          status: EnrolledProgramStatusEnum.ENROLLED,
          selectedPrograms: enrolledProgramService?.getPrograms(),
          selectedAddOnServices: enrolledProgramService?.addOnServicesList,
        }
      }
    });
  }, 500, [
    verify.send,
    patientInfo?.id,
    patientInfoService?.getClinicId(),
    enrolledProgramService?.getPrograms(),
    enrolledProgramService?.addOnServicesList
  ]);

  const signedUpdate = useUpdate('CONSENT_FORM_SIGNED').updateValue;
  const sentUpdate = useUpdate('CONSENT_FORM_SENT').updateValue;
  useUpdateListener('CONSENT_FORM_SIGNED', refetchVerify);
  useUpdateListener('CONSENT_FORM_SENT', refetchVerify);

  const needToVerify = verify.data?.data;

  const handleClose = () => {
    close();
    onClose?.();
  };

  const handleOnSubmit: EnrollmentConsentFormContainerProps['onSubmit'] = (v) => {
    if (v?.signatureMethod === SignatureCollectionMethodEnum.APP) {
      sentUpdate();
    } else if (v?.signatureMethod) {
      signedUpdate();
    }
    onSubmit?.();
    handleClose();
  };

  if (!patientInfo || !enrolledProgramService || !consentService) {
    logWarn('Missing required info or services');
    return null;
  }

  const renderConsentFormDrawer = () => (
    <ConsentFormDrawerComponent
      open={isOpen}
      onClose={handleClose}
    >
      <EnrollmentConsentFormContainer
        patientInfo={patientInfo}
        onSubmit={handleOnSubmit}
        showConsentAlert={needToVerify === false}
      />
    </ConsentFormDrawerComponent>
  );

  if (
    (needToVerify !== undefined && !needToVerify)
    || (isEnrollmentEnrolled && !isEnrolled && !consentService.hasPendingConsent())
  ) {
    return (
      <>
        <AlertCardComponent
          type="custom"
          className="consent-form-status-bar updated-consent "
          // eslint-disable-next-line no-inline-styles/no-inline-styles
          icon={<InfoCircleFilled style={{ color: 'var(--secondary-red)' }} />}
          message={(
            <div className="flex ai-c">
              Patient need to sign a new consent to
              {' '}
              {actionText}
              {' '}
              enrollment
              <Typography.Text
                className="pointer ml5"
                underline
                onClick={open}
              >
                Sign consent
              </Typography.Text>
            </div>
          )}
        />
        {renderConsentFormDrawer()}
      </>
    );
  }

  if (
    showSignedStatus
    && isEnrolled
    && consentService?.isSigned()
  ) {
    return (
      <>
        <AlertCardComponent
          type="success"
          className="consent-form-status-bar signed-consent"
          message={(
            <div className="flex ai-c">
              Latest consent signed on
              {' '}
              {consentService.signedDateDayjs?.format(USA_DATE)}
              <ReviewConsentDrawerComponent
                patientInfo={patientInfo}
                onResignOpen={open}
              >
                <Typography.Text
                  className="pointer"
                  underline
                >
                  View Signed Consent Form
                </Typography.Text>
              </ReviewConsentDrawerComponent>
            </div>
          )}
        />
        {renderConsentFormDrawer()}
      </>
    );
  }

  if (consentService?.hasPendingConsent()) {
    // pending, waiting for patient to sign
    return (
      <>
        <AlertCardComponent
          type="custom"
          className="consent-form-status-bar pending-consent"
          // eslint-disable-next-line no-inline-styles/no-inline-styles
          icon={<ClockCircleFilled style={{ color: 'var(--secondary-orange)' }} />}
          message={(
            <div className="flex ai-c">
              Consent form sent. Awaiting patient's signature to
              {' '}
              {actionText}
              {' '}
              enrollment
              <Typography.Text
                className="pointer ml5"
                underline
                onClick={open}
              >
                Resend consent
              </Typography.Text>
            </div>
          )}
        />
        {renderConsentFormDrawer()}
      </>
    );
  }

  return null;
};
