import { InputNumber } from 'antd';
import { ReactNode } from 'react';
import { MeasureUnitHelper } from '../../../../helpers/measurement/measureUnit';
import { CgmMetricEnum } from '../../../../uc-api-sdk';
import FormItem, { FormItemProps } from '../../../../uiComponent/FormItem/FormItem';
import './CgmThresholdInputComponent.scss';

export interface CgmThresholdInputComponentProps {
  upperBound?: number;
  lowerBound?: number;
  metric: CgmMetricEnum;
  sign?: string;
  unit?: ReactNode;
  name?: FormItemProps['name'];
}

export const CgmThresholdInputComponent = ({
  upperBound,
  lowerBound,
  unit = (
    <div className="CgmGoalThresholdComponent__cgm-unit">
      {MeasureUnitHelper.defaultBGUnit}
    </div>),
  sign,
  metric,
  name,
}: CgmThresholdInputComponentProps) => {
  const getTitle = (v: CgmMetricEnum) => {
    switch (v) {
      case CgmMetricEnum.TBR_LEVEL_2:
        return 'TBR';
      case CgmMetricEnum.TBR_LEVEL_1:
        return 'TBR';
      case CgmMetricEnum.TIR:
        return 'TIR';
      case CgmMetricEnum.TAR_LEVEL_1:
        return 'TAR';
      case CgmMetricEnum.TAR_LEVEL_2:
        return 'TAR';
      default:
        return null;
    }
  };

  return (
    <div className="CgmThresholdInputComponent">
      <div className="text-gray-scale-2 fs12 flex">
        {getTitle(metric)}
        {sign || ' '}
        {lowerBound}
        {!sign && '-'}
        {upperBound}
        {unit}
      </div>
      <FormItem
        name={name}
        data-name={name}
      >
        <InputNumber
          className="w100px"
          addonBefore={metric === CgmMetricEnum.TIR ? '≥ ' : '< '}
          addonAfter="%"
          size="small"
          min={0}
        />
      </FormItem>
    </div>
  );
};
